

































































import { Component, Vue, Prop, Watch, Ref } from 'vue-property-decorator';
import { Getter, State, Action } from 'vuex-class';
import { Camera } from '@/models/camera';
import { Project } from '@/models/project';
import { Photo } from '@/models/photo';
import S3Image from '../components/s3-image/S3Image.vue';
// @ts-ignore: no d.ts file
import { vTeleport } from '@desislavsd/vue-teleport';
import videojs, { VideoJsPlayer } from 'video.js';
import { Storage } from 'aws-amplify';
import { BModal } from 'bootstrap-vue/esm';
import { Logger } from 'aws-amplify';
const logger = new Logger('SlowmotionView');

@Component({
  components: {
    S3Image,
    vTeleport,
  },
})
export default class SlowmotionView extends Vue {
  @Prop(String) private projectid!: string;
  @Prop(String) private cameraid!: string;

  @Ref('videoPlayer-slowmotion-modal') videoPlayerModal!: BModal;

  @State('projects', { namespace: 'projects' }) private projects!: Project[];

  @Getter('projectWithId', { namespace: 'projects' }) private projectWithId!: (id: string) => Project | undefined;
  @Getter('onlySlowmotion', { namespace: 'photo' }) private onlySlowmotion!: Photo[];

  @Action('findAllFor', { namespace: 'photo' }) private findPhotoAllForProject!: (payload: {
    projectId: string;
    cameraId: string;
  }) => Promise<void>;

  private project: Project | null = null;
  private player?: VideoJsPlayer;
  private options = {
    fluid: true,
    controls: true,
  };

  public get camera(): Camera | undefined {
    logger.info('get camera ' + this.cameraid);
    return (
      this.project?.cameras?.find((camera) => camera.id === this.cameraid) ||
      this.project?.previousCameras?.find((camera) => camera.id === this.cameraid)
    );
  }

  public get breadcrumbs(): unknown[] {
    const items = [
      {
        text: this.$tc('global.project', 2),
        to: '/',
      },
    ];

    if (this.project !== null) {
      items.push({
        text: this.project.name,
        to: '/project/' + this.project.id,
      });
    }
    items.push({
      text: 'Slow motion',
      to: '#',
    });
    return items;
  }

  public mounted(): void {
    if (this.projects) {
      this.onStateProjectLoaded(this.projects, undefined);
    }
    if (this.player) {
      this.player.dispose();
      this.player = undefined;
    }
    const videoPlayer = this.$refs['videoPlayer-slowmotion'] as Element | undefined;
    if (videoPlayer !== undefined) {
      this.player = videojs(videoPlayer, this.options, () => {
        logger.info('onPlayerReady', this.player);
      });
    }
  }
  public beforeDestroy(): void {
    if (this.player) {
      this.player.dispose();
    }
  }

  public async onClickedPhoto(photo: Photo): Promise<void> {
    this.videoPlayerModal.show();
    if (this.player) {
      const url = await Storage.get(photo.key);
      logger.info(url);
      this.player.src(url as string);
    }
  }

  @Watch('projects')
  private onStateProjectLoaded(val?: Project[], oldVal?: Project[]) {
    logger.info('onStateProjectLoaded', { val, oldVal });
    this.project = this.projectWithId(this.projectid) || null;
    this.findPhotoAllForProject({ projectId: this.projectid, cameraId: this.cameraid });
  }
}
