import { Vue, Prop, Ref } from 'vue-property-decorator';
import Component from 'vue-class-component';
// @ts-ignore no d.ts
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead';
import { Camera } from '@/models/camera';
import { City } from '@/models/city';
import { Project } from '@/models/project';
import { User } from '@/models/user';
import { State, Action } from 'vuex-class';
import { ProjectCreateRequest, ProjectUpdateRequest } from '@/services/projects/projects.interfaces';
// @ts-ignore no d.ts
import VueTagsInput from '@johmun/vue-tags-input';
import { Validations } from 'vuelidate-property-decorators';
import { email, helpers, required } from 'vuelidate/lib/validators';
// @ts-ignore no d.ts
import VueGoogleAutocomplete from 'vue-google-autocomplete';
import { BModal } from 'bootstrap-vue';
import { Logger } from 'aws-amplify';
import XRegExp from 'xregexp';
const logger = new Logger('ProjectModal');

type Tag = {
  text: string;
  tiClasses: string[];
};

const validusername = helpers.regex('validusername', XRegExp('^[\\p{L}\\p{M}\\p{S}\\p{N}\\p{P}]+$'));

@Component({
  components: {
    VueBootstrapTypeahead,
    VueTagsInput,
    VueGoogleAutocomplete,
  },
})
export default class ProjectModal extends Vue {
  @Prop(Object) private readonly project?: Project;
  @Prop({ default: false }) private hideFields!: boolean;

  @State('user', { namespace: 'profile' }) private user!: User;
  @State('cameras', { namespace: 'camera' }) private cameras!: Camera[];
  @State('loadingProject', { namespace: 'projects' }) private loadingProject!: boolean;

  @Action('loadProject', { namespace: 'projects' }) private loadProject!: () => Promise<Project[]>;
  @Action('loadCamera', { namespace: 'camera' }) private loadCamera!: () => Promise<Camera[]>;
  @Action('createNewProject', { namespace: 'projects' }) private createNewProject!: (
    projectCreateRequest: ProjectCreateRequest
  ) => Promise<Project[]>;
  @Action('updateProject', { namespace: 'projects' }) private updateProjectService!: (
    projectUpdateRequest: ProjectUpdateRequest
  ) => Promise<Project[]>;
  @Action('deleteProject', { namespace: 'projects' }) private deleteProjectService!: (
    project: Project
  ) => Promise<void>;

  @Ref('project-modal') readonly projectModal!: BModal;

  private form = {
    name: '',
    description: '',
    tags: [] as Tag[],
    city: undefined as City | undefined,
    coAdminText: '',
    coAdmin: [] as string[],
    coVisitorText: '',
    coVisitor: [] as string[],
    cameras: [] as Camera[],
    cameraSelect: null as Camera | null,
    unpaid: null as null | boolean,
    jetlag: null as null | boolean,
    lagduration: 0 as number,
    totalphoto: null as null | boolean,
  };

  @Validations()
  private validations = {
    form: {
      name: { required },
      city: { required },
      coAdminText: { validusername, email },
      coVisitorText: { validusername, email },
    },
  };

  private get camerasOptions(): unknown[] {
    const sortedCameras = this.cameras.slice().sort((a: Camera, b: Camera) => {
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });

    return [
      { text: ' -- ', value: null },
      ...sortedCameras.map((camera: Camera) => {
        return {
          text: camera.name,
          value: camera,
        };
      }),
    ];
  }

  private unpaidOptions = [
    { text: 'Oui', value: true },
    { text: 'Non', value: false },
  ];

  private jetlagOptions = [
    { text: 'Oui', value: true },
    { text: 'Non', value: false },
  ];

  private lagdurationOptions = Array.from({ length: 169 }, (_, i) => ({
    text: i.toString(),
    value: i,
  }));

  private totalphotoOptions = [
    { text: 'Oui', value: true },
    { text: 'Non', value: false },
  ];

  private get iframeUrl(): string {
    if (this.project) {
      const route = this.$router.resolve({
        name: 'iframegallery',
        params: { id: this.project.id },
      });
      return `${process.env.VUE_APP_BASE_URL}${route.href}`;
    } else {
      return '';
    }
  }

  private iframeCodeCopied = false;
  private iframeCoping = false;
  private minMatchingChars = 3;
  private tag = '';

  private get fullname() {
    if (this.project === undefined && this.user !== undefined) {
      return `${this.user.firstname} ${this.user.lastname}`;
    } else if (this.project !== undefined) {
      return `${this.project.owner.firstname} ${this.project.owner.lastname}`;
    } else {
      return '';
    }
  }

  public mounted(): void {
    if (this.project !== undefined) {
      this.form.name = this.project.name || '';
      this.form.description = this.project.description || '';
      this.form.tags =
        this.project.tags?.map((text) => {
          return { text, tiClasses: ['ti-valid'] };
        }) || [];
      this.form.cameras = this.project.cameras || [];
      this.form.unpaid = this.project.unpaid ?? false;
      this.form.jetlag= this.project.jetlag ?? false;
      this.form.lagduration= this.project.lagduration ?? 0;
      this.form.totalphoto = this.project.totalphoto ?? true;
      this.form.coAdmin = this.project.administrator?.map((u) => u.email) || [];
      this.form.coVisitor = this.project.guest?.map((u) => u.email) || [];
      this.form.city = this.project.city?.value ? this.project.city : undefined;
    }
  }

  public show(): void {
    this.projectModal.show();
  }

  public close(): void {
    this.projectModal.hide();
  }

  public doCopy(): void {
    const container = this.$refs.code;
    this.iframeCoping = true;
    setTimeout(() => {
      this.$copyText(this.iframeUrl, container).then(
        () => {
          this.iframeCodeCopied = true;
          this.iframeCoping = false;
        },
        (e) => {
          alert('Can not copy');
          logger.error(e);
          this.iframeCoping = false;
        }
      );
    }, 1000);
  }

  public addCoAdmin(): void {
    this.form.coAdmin.push(this.form.coAdminText);
    this.form.coAdmin = [...new Set(this.form.coAdmin)];
    this.form.coAdminText = '';
  }

  public addVisitor(): void {
    this.form.coVisitor.push(this.form.coVisitorText);
    this.form.coVisitor = [...new Set(this.form.coVisitor)];
    this.form.coVisitorText = '';
  }

  public processTags(tags: Tag[]): void {
    this.form.tags = tags;
  }

  public async createProject(): Promise<void> {
    if (this.form.city === undefined) {
      return;
    }

    logger.info('createProject');
    const projectCreateRequest: ProjectCreateRequest = {
      name: this.form.name,
      tags: this.form.tags.map((tag) => tag.text),
      description: this.form.description,
      city: this.form.city,
      coAdmin: this.form.coAdmin,
      visitor: this.form.coVisitor,
      camera: this.form.cameras,
      unpaid: this.form.unpaid ?? false,
      jetlag: this.form.jetlag ?? false,
      lagduration: this.form.lagduration ?? 0,
      totalphoto: this.form.totalphoto ?? false,
    };
    await this.createNewProject(projectCreateRequest).then(() =>
      Promise.all([this.loadProject(), this.loadCamera()]).then(() => this.close())
    );
  }

  public async updateProject(): Promise<void> {
    logger.info('updateProject');
    if (this.project !== undefined && this.form.city !== undefined) {
      logger.info(this.form);
      const projectUpdateRequest: ProjectUpdateRequest = {
        id: this.project.id,
        name: this.form.name,
        description: this.form.description,
        tags: this.form.tags.map((tag) => tag.text),
        city: this.form.city,
        coAdmin: this.form.coAdmin,
        visitor: this.form.coVisitor,
        camera: this.form.cameras,
        unpaid: this.form.unpaid !== null ? this.form.unpaid : false,
        jetlag: this.form.jetlag !== null ? this.form.jetlag : false,
        lagduration: this.form.lagduration !== null ? this.form.lagduration : 0,
        totalphoto: this.form.totalphoto !== null ? this.form.totalphoto : false,
      };
      await this.updateProjectService(projectUpdateRequest).then(() =>
        Promise.all([this.loadProject(), this.loadCamera()])
      );
      this.close();
    }
  }

  public async deleteProject(): Promise<void> {
    if (this.project !== undefined) {
      const project = this.project;
      logger.info('deleteProject');
      this.$bvModal
        .msgBoxConfirm(this.$t('projectmodal.confirmDeletion') as string, {
          title: this.$t('projectmodal.confirmDeletionTitle') as string,
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: this.$t('global.oktitle') as string,
          cancelTitle: this.$t('global.canceltitle') as string,
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async (confirmation) => {
          if (confirmation) {
            this.deleteProjectService(project).then(() => {
              this.close();
            });

            this.$bvModal.msgBoxOk(this.$t('projectmodal.projectInDeletion') as string, {
              title: this.$t('projectmodal.confirmDeletionTitle') as string,
              size: 'sm',
              buttonSize: 'sm',
              okVariant: 'info',
              okTitle: this.$t('projectlist.gobacktolist') as string,
              footerClass: 'p-2',
              hideHeaderClose: false,
              centered: true,
            });
          }
        })
        .catch((err) => {
          logger.error({ err });
        });
    }
  }

  public onCameraSelect(camera: Camera): void {
    logger.info('onCameraSelect', { camera });
    this.form.cameras.push(camera);
    this.form.cameras = [...new Set(this.form.cameras)];
    this.form.cameraSelect = null;
  }

  public getAddressData(addressData: any, placeResultData: any, id: string): void {
    logger.info(placeResultData.address_components);

    const country = placeResultData.address_components.find((addr: any) => addr.types.includes('country'));

    this.form.city = {
      country: country.long_name,
      countryCode: country.short_name,
      latlng: {
        lat: addressData.latitude,
        lng: addressData.longitude,
      },
      value: placeResultData.formatted_address,
    };
  }
  public handleError(error: unknown): void {
    alert(error);
  }
}
