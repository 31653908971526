import { Module } from 'vuex';
import { actions } from './actions';
import { getters } from './getters';
import { mutations } from './mutations';
import { ProjectState } from './types';
import { RootState } from '../types';

export const state: ProjectState = {
  loadingProject: false,
  premium: false,
  project: undefined,
  loadingTimelapse: false,
  timelapse: [],
  scenario: [],
  loadingScenario: false,
};

const namespaced = true;

export const project: Module<ProjectState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
