export enum Days {
  Monday = 0,
  Tuesday = 1,
  Wednesday = 2,
  Thursday = 3,
  Friday = 4,
  Saturday = 5,
  Sunday = 6,
}

export enum Crop {
  _169 = '169',
  carre = 'carre',
  original = 'original',
}

export enum Position {
  topleft = 'topleft',
  topright = 'topright',
  bottomleft = 'bottomleft',
  bottomright = 'bottomright',
}
