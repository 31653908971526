import { UserService } from './users/users.services';
import { ProjectService } from './projects/projects.services';
import { CameraService } from './cameras/cameras.services';
import { CityService } from './cities/cities.services';
import { PhotoService } from './photos/photos.services';
import { AlbumService } from './albums/albums.services';
import { WeatherService } from './weather/weather.services';
import { IframesService } from './iframes/iframes.services';

const apiName = 'erige-serverless-api';
export const apiConfiguration = {
  endpoints: [
    {
      name: 'erige-serverless-api',
      endpoint: process.env.VUE_APP_API_ENDPOINT,
      region: 'eu-west-3',
    },
  ],
};

export const iframesService = new IframesService(apiName);
export const userService = new UserService(apiName);
export const projectService = new ProjectService(apiName);
export const cityService = new CityService(apiName);
export const cameraService = new CameraService(apiName);
export const photoService = new PhotoService(apiName);
export const albumsService = new AlbumService(apiName);

export const weatherService = new WeatherService('1a834bd9e4ed04b615474844828a9ca2');
