import { MutationTree } from 'vuex';
import { PhotoState, PhotoMutation } from './types';

export const mutations: MutationTree<PhotoState> = {
  photos(state: PhotoState, photoMutation: PhotoMutation) {
    state.photos = photoMutation.photos;
    state.projectId = photoMutation.projectId;
    state.cameraId = photoMutation.cameraId;
    state.isLoading = false;
  },
  loading(state: PhotoState) {
    state.isLoading = true;
    state.photos = [];
  },
  notLoading(state: PhotoState) {
    state.isLoading = false;
  },
};
