import { Vue, Ref, Prop, Watch } from 'vue-property-decorator';
import Component from 'vue-class-component';
import { BModal } from 'bootstrap-vue/esm/components/modal';
import { Camera } from '@/models/camera';
import { Project } from '@/models/project';
import { Getter, State, Action } from 'vuex-class';
import { CameraUpdateRequest, CameraDeleteRequest } from '@/services/cameras/cameras.interfaces';
import { Storage } from 'aws-amplify';
import VueSlider from 'vue-slider-component';
// @ts-ignore no d.ts
import Datepicker from 'vuejs-datepicker';
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue';
// @ts-ignore no d.ts
import { en, fr } from 'vuejs-datepicker/dist/locale';
import BatterieLevelComponent from '@/components/batterielevel/batterielevel.component.vue';
import InputDays from '@/components/inputdays/inputdays.component.vue';
import { parse, isPast } from 'date-fns';
import { Validations } from 'vuelidate-property-decorators';
import { Shadow } from '@/models/shadow';
import { Logger } from 'aws-amplify';
const logger = new Logger('CameraModal');

// Can merge with timelapsefilter period interface
interface Time {
  HH?: string;
  mm?: string;
}
interface Period {
  begin: Time;
  end: Time;
}

@Component({
  components: {
    BatterieLevelComponent,
    VueSlider,
    VueTimepicker,
    Datepicker,
    'input-days': InputDays,
  },
})
export default class CameraModal extends Vue {
  public lastPhoto = '';

  @Prop({ type: Object, required: true }) private readonly camera!: Camera;

  @Ref('camera-modal') private modal!: BModal;

  @State('projects', { namespace: 'projects' }) private projects!: Project[];
  @State('locale', { namespace: 'profile' }) private locale!: string;
  @Prop({ required: true }) readonly isPremium!: boolean;
  @State('loadingCamera', { namespace: 'camera' }) private loadingCamera!: boolean;

  @Action('loadProject', { namespace: 'projects' }) private loadProject!: () => Promise<Project[]>;
  @Action('loadCamera', { namespace: 'camera' }) private loadCamera!: () => Promise<Camera[]>;
  @Action('updateCamera', { namespace: 'camera' }) private updateCameraService!: (
    cameraCreateRequest: CameraUpdateRequest
  ) => Promise<Camera[]>;
  @Action('updateConfiguration', { namespace: 'camera' }) private updateCameraConfigurationService!: (
    cameraShadow: Shadow
  ) => Promise<Shadow>;
  @Action('deleteCamera', { namespace: 'camera' }) private deleteCameraService!: (
    cameraDeleteRequest: CameraDeleteRequest
  ) => Promise<Camera[]>;

  @Getter('projectWithId', { namespace: 'projects' }) private projectWithId!: (id: string) => Project;

  private displayBatterieChart = false;
  private modalShown = false;
  private form = {
    name: '',
    serialNumber: '',
    phonenumber: '',
    serialNumberImported: false,
    serialNumberNotFound: false,
    projectId: null as string | null,
    toggleButton: false,
  };

  private shadowForm: Shadow | null = null;
  private captureDays: number[] = [];
  private isInfinite = false;
  private storeUSB = false;
  private startDate: Date | null = null;
  private endDate: Date | null = null;
  private slowMotionEnable = false;
  private slowMotionDate: Date | null = null;
  private slowMotionPeriod: Time = {};

  private period: Period | null = null;

  private exceptPeriod: Period = {
    begin: {
      HH: undefined,
      mm: undefined,
    },
    end: {
      HH: undefined,
      mm: undefined,
    },
  };

  private intervalsOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 20, 25, 30, 45]
    .map((x) => {
      return { text: `${x} minutes`, value: `${x}` };
    })
    .concat([
      { text: '1 heure', value: '60' },
      { text: '2 heures', value: '120' },
      { text: '6 heures', value: '360' },
      { text: '12 heures', value: '720' },
      { text: '24 heures', value: '1440' },
    ]);

  private get hdrOptions() {
    if (this.isPremium) {
      return [
        { text: 'Pas de HDR', value: '0' },
        { text: 'HDR', value: '1' },
        { text: 'Automatique', value: '2' },
      ];
    } else {
      return [{ text: 'Pas de HDR', value: '0' }];
    }
  }

  public get datepickerLocal(): unknown {
    return this.locale === 'en' ? en : fr;
  }

  public get shadowDesynchronized(): boolean {
    if (this.camera.shadow === undefined) {
      return false;
    }
    return (
      this.camera.shadow.desired.Camera_Mode !== this.camera.shadow.reported.Camera_Mode ||
      this.camera.shadow.desired.Date_Start !== this.camera.shadow.reported.Date_Start ||
      this.camera.shadow.desired.Date_End !== this.camera.shadow.reported.Date_End ||
      this.camera.shadow.desired.Photo_Interval !== this.camera.shadow.reported.Photo_Interval ||
      this.camera.shadow.desired.Time_Start !== this.camera.shadow.reported.Time_Start ||
      this.camera.shadow.desired.Time_End !== this.camera.shadow.reported.Time_End ||
      this.camera.shadow.desired.Except_Start !== this.camera.shadow.reported.Except_Start ||
      this.camera.shadow.desired.Except_End !== this.camera.shadow.reported.Except_End ||
      this.camera.shadow.desired.Capture_Day !== this.camera.shadow.reported.Capture_Day ||
      this.camera.shadow.desired.Photo_HDRmode !== this.camera.shadow.reported.Photo_HDRmode ||
      this.camera.shadow.desired.Video_Timetrigger !== this.camera.shadow.reported.Video_Timetrigger ||
      this.camera.shadow.desired.USB_Record !== this.camera.shadow.reported.USB_Record
    );
  }

  @Validations()
  private validations = {
    startDate: { different: () => this.shadowDifferent('Date_Start') },
    endDate: { different: () => this.shadowDifferent('Date_End') },
    captureDays: { different: () => this.shadowDifferent('Capture_Day') },
    storeUSB: { different: () => this.shadowDifferent('USB_Record') },
    slowMotionPeriod: { different: () => this.shadowDifferent('Video_Timetrigger') },
    slowMotionDate: { different: () => this.shadowDifferent('Video_Timetrigger') },
    period: {
      begin: { different: () => this.shadowDifferent('Time_Start') },
      end: { different: () => this.shadowDifferent('Time_End') },
    },
    exceptPeriod: {
      begin: { different: () => this.shadowDifferent('Except_Start') },
      end: { different: () => this.shadowDifferent('Except_End') },
    },
    shadowForm: {
      Camera_Mode: { different: () => this.shadowDifferent('Camera_Mode') },
      Date_Start: { different: () => this.shadowDifferent('Date_Start') },
      Date_End: { different: () => this.shadowDifferent('Date_End') },
      Photo_Interval: { different: () => this.shadowDifferent('Photo_Interval') },
      Time_Start: { different: () => this.shadowDifferent('Time_Start') },
      Time_End: { different: () => this.shadowDifferent('Time_End') },
      Except_Start: { different: () => this.shadowDifferent('Except_Start') },
      Except_End: { different: () => this.shadowDifferent('Except_End') },
      Capture_Day: { different: () => this.shadowDifferent('Capture_Day') },
      Photo_HDRmode: { different: () => this.shadowDifferent('Photo_HDRmode') },
      Video_Timetrigger: { different: () => this.shadowDifferent('Video_Timetrigger') },
      USB_Record: { different: () => this.shadowDifferent('USB_Record') },
    },
  };

  public shadowDifferent(key: string): boolean {
    // @ts-ignore it's ok
    return this.camera.shadow.reported[key] !== this.camera.shadow.desired[key];
  }

  // 01102019 to Date object
  public stringToDate(str: string): Date {
    return new Date(parseInt(str.slice(4, 8), 10), parseInt(str.slice(2, 4), 10) - 1, parseInt(str.slice(0, 2), 10));
  }

  // Date object to 01102019
  public dateToString(date: Date): string {
    return ('0' + date.getDate()).slice(-2) + ('0' + (date.getMonth() + 1)).slice(-2) + date.getFullYear();
  }

  // 0110 to Period
  public stringToPeriod(str: string): Time {
    return {
      HH: str.slice(0, 2),
      mm: str.slice(2, 4),
    };
  }

  // Date object to 01102019
  public periodToString(period: Time): string {
    const mm = period.mm === '' ? '00' : period.mm;
    const hh = period.HH === '' ? '00' : period.HH;
    return hh + '' + mm;
  }

  public toggleSlowmotion(enable: boolean): void {
    if (this.shadowForm && enable === false) {
      this.shadowForm.Video_Timetrigger = '';
    } else if (this.shadowForm && this.slowMotionDate !== null) {
      this.shadowForm.Video_Timetrigger = `${this.dateToString(this.slowMotionDate)}-${this.periodToString(
        this.slowMotionPeriod
      )}`;
    }
  }

  public datepickerSlowmotion(date: Date): void {
    if (this.shadowForm && this.slowMotionPeriod.HH && this.slowMotionPeriod.mm) {
      this.shadowForm.Video_Timetrigger = `${this.dateToString(date)}-${this.periodToString(this.slowMotionPeriod)}`;
    }
  }

  public timepicketSlowmotion($event: { data: Time; displayTime: string }): void {
    logger.info($event);
    if (
      this.shadowForm &&
      this.slowMotionDate &&
      $event.displayTime !== '' &&
      $event.data.HH !== '' &&
      $event.data.mm !== ''
    ) {
      this.shadowForm.Video_Timetrigger = `${this.dateToString(this.slowMotionDate)}-${this.periodToString(
        $event.data
      )}`;
    }
  }

  public datepickerSelectedBegin(date: Date): void {
    if (this.shadowForm) {
      this.shadowForm.Date_Start = this.dateToString(date);
    }
  }

  public datepickerSelectedEnd(date: Date): void {
    if (this.shadowForm) {
      this.shadowForm.Date_End = this.dateToString(date);
    }
  }

  public daysStringtoArray(str: string): number[] {
    return str
      .split('')
      .map((d, index) => {
        let day: number = parseInt(d, 10);
        if (day === 1) {
          day = index;
        } else {
          day = -1;
        }
        return day;
      })
      .filter((day) => {
        return day !== -1;
      });
  }

  public daysArrayToString(arr: number[]): string {
    return arr
      .reduce(
        (str, day) => {
          str[day] = 1;
          return str;
        },
        [0, 0, 0, 0, 0, 0, 0]
      )
      .join('');
  }

  public get projectsOptions(): unknown[] {
    if (this.projects) {
      return [
        { text: ' -- ', value: null },
        ...this.projects.map((project: Project) => {
          return {
            text: project.name,
            value: project.id,
          };
        }),
      ];
    } else {
      return [{ text: ' -- ', value: null }];
    }
  }

  public getRotation(): string {
    return this.camera && this.camera.rotate ? `rotate(${this.camera.rotate}deg)` : '';
  }

  public mounted(): void {
    this.form.name = this.camera.name;
    this.form.serialNumber = this.camera.serialNumber;
    this.form.phonenumber = this.camera.phonenumber ?? '';
    this.form.projectId = this.camera.projectId ?? null;
    if (this.camera.lastPhoto) {
      Storage.get(this.camera.lastPhoto.smallKey).then((result) => {
        this.$nextTick(() => {
          this.lastPhoto = result as string;
        });
      });
    }
    if (this.camera.shadow !== undefined) {
      this.shadowForm = Object.assign({}, this.camera.shadow.desired);
      this.captureDays = this.daysStringtoArray(this.shadowForm.Capture_Day);
      this.storeUSB = this.shadowForm.USB_Record === '1' ? true : false;
      this.startDate = this.stringToDate(this.shadowForm.Date_Start);
      this.endDate = this.stringToDate(this.shadowForm.Date_End);
      this.endDate = this.stringToDate(this.shadowForm.Date_End);
      this.period = {
        begin: this.stringToPeriod(this.shadowForm.Time_Start),
        end: this.stringToPeriod(this.shadowForm.Time_End),
      };
      this.exceptPeriod.begin = this.stringToPeriod(this.shadowForm.Except_Start);
      this.exceptPeriod.end = this.stringToPeriod(this.shadowForm.Except_End);
      if (this.shadowForm.Video_Timetrigger && this.shadowForm.Video_Timetrigger.length > 0) {
        const parsed = parse(this.shadowForm.Video_Timetrigger, 'ddMMyyy-HHmm', new Date());
        logger.info('onCameraChange', { parsed });
        this.slowMotionEnable = !isPast(parsed);
      } else {
        this.slowMotionEnable = false;
      }
    }
  }

  @Watch('captureDays')
  public updateCaptureDays(): void {
    if (this.shadowForm) {
      this.shadowForm.Capture_Day = this.daysArrayToString(this.captureDays);
    }
  }

  @Watch('isInfinite')
  public updateEndDate(): void {
    if (this.isInfinite && this.shadowForm) {
      this.endDate = new Date(2999, 11, 31);
      this.datepickerSelectedEnd(this.endDate);
    }
  }

  @Watch('storeUSB')
  public updateUSB(): void {
    if (this.shadowForm) {
      Vue.set(this.shadowForm, 'USB_Record', this.storeUSB ? '1' : '0');
    }
  }

  public updatePeriod(): void {
    if (this.shadowForm !== null && this.period !== null) {
      this.shadowForm.Time_Start = this.periodToString(this.period.begin);
      this.shadowForm.Time_End = this.periodToString(this.period.end);
    }
  }

  @Watch('exceptPeriod', { deep: true })
  public updateExceptPeriod(): void {
    if (this.shadowForm) {
      this.shadowForm.Except_Start = this.periodToString(this.exceptPeriod.begin);
      this.shadowForm.Except_End = this.periodToString(this.exceptPeriod.end);
    }
  }

  public show(): void {
    this.modal.show();
  }

  public close(): void {
    this.modal.hide();
  }

  public onModalShown(): void {
    this.modalShown = true;
  }
  public onModalHidden(): void {
    this.modalShown = false;
  }

  public onTabShown(tabIndex: number): void {
    if (tabIndex === 0) {
      this.displayBatterieChart = true;
    } else {
      this.displayBatterieChart = false;
    }
  }

  public async updateCamera(): Promise<void> {
    logger.info('updateCamera');
    if (this.camera !== undefined) {
      const cameraUpdateRequest: CameraUpdateRequest = {
        id: this.camera.id,
        name: this.form.name,
        serialNumber: this.form.serialNumber,
        phonenumber: this.form.phonenumber,
        project: this.form.projectId ? this.projectWithId(this.form.projectId) : undefined,
        rotate: this.camera.rotate !== undefined ? this.camera.rotate : 0, // Valeur par défaut, ou utilisez une valeur appropriée
        blur: this.camera.blur !== undefined ? this.camera.blur : false,
        live: this.camera.live !== undefined ? this.camera.live : false,
        remaining: this.camera.remaining !== undefined ? this.camera.remaining : 0,
      };
      return this.updateCameraService(cameraUpdateRequest)
        .then(async () => {
          if (this.shadowForm) {
            const cameraUpdateConfigurationRequest: Shadow = this.shadowForm;
            return this.updateCameraConfigurationService(cameraUpdateConfigurationRequest).then((updatedShadow) =>
              logger.info('UpdatedShadow', updatedShadow)
            );
          } else {
            return Promise.resolve();
          }
        })
        .then(() => Promise.all([this.loadProject(), this.loadCamera()]))
        .then(() => this.close());
    } else {
      this.close();
    }
  }

  public async deleteCamera(): Promise<void> {
    logger.info('deleteCamera');
    this.$bvModal
      .msgBoxConfirm('Please confirm that you want to delete everything.', {
        title: 'Please Confirm',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      })
      .then(async (value) => {
        logger.info('', { value });
        if (value) {
          await this.deleteCameraService({ id: this.camera.id });
          this.close();
        }
      })
      .catch((err) => {
        logger.error({ err });
      });
  }
}
