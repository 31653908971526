import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import createCache from 'vuex-cache';
import { RootState } from './types';
import { profile } from './profile/';
import { projects } from './projects/';
import { project } from './project/';
import { camera } from './camera/';
import { photo } from './photo/';
import { album } from './album/';
import { Auth } from 'aws-amplify';
import { CognitoUser } from '@aws-amplify/auth';
import { Logger } from 'aws-amplify';
const logger = new Logger('RootStore');

Vue.use(Vuex);

const store: StoreOptions<RootState> = {
  state: {
    version: '1.0.0', // a simple property
    socket: {
      isConnected: false,
      message: '',
      reconnectError: false,
    },
  },
  mutations: {
    SOCKET_ONOPEN(state, event) {
      Vue.prototype.$socket = event.currentTarget;
      state.socket.isConnected = true;
    },
    SOCKET_ONCLOSE(state) {
      state.socket.isConnected = false;
      state.socket.message = '';
    },
    SOCKET_ONERROR(state, event) {
      logger.error(event);
    },
    // default handler called for all methods
    SOCKET_ONMESSAGE(state, message) {
      logger.info(`SOCKET_ONMESSAGE ${JSON.stringify(message)}`);
      state.socket.message = message;
    },
    // mutations for reconnect methods
    SOCKET_RECONNECT(state, count) {
      logger.info(`reconnect ${count}`);
    },
    SOCKET_RECONNECT_ERROR(state) {
      state.socket.reconnectError = true;
    },
  },
  actions: {
    connectSocket: async ({ state }) => {
      logger.info('connectSocket');
      if (!state.socket.isConnected) {
        const cognitoUser: CognitoUser = await Auth.currentAuthenticatedUser();
        const userSession = cognitoUser.getSignInUserSession();
        if (userSession !== null) {
          Vue.prototype.$connect(
            `${process.env.VUE_APP_WSS_ENDPOINT}?Authorizer=${userSession.getIdToken().getJwtToken()}`
          );
        }
      }
    },
    disconnectSocket: ({ state }) => {
      logger.info('disconnectSocket');
      if (state.socket.isConnected) {
        Vue.prototype.$disconnect();
      }
    },
    sendMessage: (context, message) => {
      Vue.prototype.$socket.send(message);
    },
  },
  modules: {
    profile,
    projects,
    camera,
    photo,
    album,
    project,
  },
  plugins: [createCache({ timeout: 5000 })],
};

export default new Vuex.Store<RootState>(store);
