import { GetterTree } from 'vuex';
import { AlbumState } from './types';
import { RootState } from '../types';
import { Album } from '@/models/album';

export const getters: GetterTree<AlbumState, RootState> = {
  albumWithId: (state) => (id: string) => {
    return state.albums.find((album) => album.id === id);
  },
  albumSorted: (state) => () => {
    return state.albums.sort((a: Album, b: Album) => a.name.localeCompare(b.name));
  },
  albumFiltered: (state) => (id: string) => {
    return state.albums.filter((album) => album.id !== id).sort((a: Album, b: Album) => a.name.localeCompare(b.name));
  },
};
