
























































import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { State, Action, Getter, Mutation } from 'vuex-class';
import { isWithinInterval, isValid, isBefore, endOfDay } from 'date-fns';
import { Camera } from '@/models/camera';
import { Project } from '@/models/project';
import { Photo } from '@/models/photo';
import { Video } from '@/models/video';
import { TimelapseConfiguration } from '@/models/timelapseconfiguration';
import { TimelapseConfigurationUpdateRequest } from '@/services/projects/projects.interfaces';
import { Crop, Position } from '@/models/enums';
import TimelapsePreview from '@/components/timelapsepreview/timelapsepreview.component.vue';
import TimelapseFilter from '@/components/timelapsefilter/timelapsefilter.component.vue';
import TimelapseExclusion from '@/components/timelapseexclusion/timelapseexclusion.component.vue';
import TimelapsePhoto from '@/components/timelapsephoto/timelapsephoto.component.vue';
import TimelapseSettings from '@/components/timelapsesettings/timelapsesettings.component.vue';
// @ts-ignore: no d.ts file
import { vTeleport } from '@desislavsd/vue-teleport';
import { NotificationMessage, Socket } from '@/store/types';
import { Logger } from 'aws-amplify';
const logger = new Logger('Timelapse');

@Component({
  components: {
    TimelapsePreview,
    TimelapseFilter,
    TimelapseExclusion,
    TimelapsePhoto,
    TimelapseSettings,
    vTeleport,
  },
})
export default class Timelapse extends Vue {
  @Prop(String) private id!: string;

  @State('projects', { namespace: 'projects' }) private projects!: Project[];
  @Getter('projectWithId', { namespace: 'projects' }) private projectWithId!: (id: string) => Project;

  @State('premium', { namespace: 'project' }) readonly ownerPremium!: boolean;
  @State('timelapse', { namespace: 'project' }) private timelapse!: Video[];
  @State('loadingTimelapse', { namespace: 'project' }) private loadingTimelapse!: boolean;
  @Getter('timelapseForCamera', { namespace: 'project' }) private timelapseForCamera!: (cameraId: string) => Video;
  @Action('setProject', { namespace: 'project' }) private setProject!: (project: Project) => Promise<void>;
  @Action('loadTimelapse', { namespace: 'project' }) private loadTimelapse!: () => Promise<void>;
  @Action('saveTimelapseConfiguration', { namespace: 'project' }) private saveTimelapseConfiguration!: (
    config: TimelapseConfigurationUpdateRequest
  ) => Promise<void>;

  @Action('findAllFor', { namespace: 'photo' }) private findPhotoAllForProject!: (payload: {
    projectId: string;
    cameraId: string;
  }) => Promise<Photo[]>;
  @State('photos', { namespace: 'photo' }) private readonly photosStore!: Photo[];

  @State('socket') private socketMessage!: Socket;
  @Action('connectSocket') private connectSocket!: () => Promise<void>;
  @Action('disconnectSocket') private disconnectSocket!: () => Promise<void>;
  @Mutation('SOCKET_ONMESSAGE') private resetSocketMessage!: (object: string) => void;

  private project: Project | null = null;
  private lastVideo: Video | null = null;
  private timelapseRending = false;
  private leftPanelVisible = true;

  private imagesKey: Photo[] = [];

  private defaultImageModifier = {
    iso: 0,
    brightness: 0,
    contrast: 0,
    saturation: 0,
    white: 0,
    crop: Crop.original,
    erigeWatermark: true,
    watermarkPosition: Position.bottomright,
  };

  private get defaultConfig(): TimelapseConfiguration {
    return {
      ...this.defaultImageModifier,
      interval: {
        begin: NaN,
        end: NaN,
      },
      lastNDays: false,
      lastNDaysSpan: 30,
      period: {
        startHour: '00:00',
        endHour: '23:59',
      },
      exclude: [],
      days: [0, 1, 2, 3, 4, 5, 6],
      fps: 25,
      hdr: false,
      blue: [0, 6],
      gain: [this.camera?.isTikee ? 40 : 0, this.camera?.isTikee ? 1800 : 24],
      exposure: [0, this.camera?.isTikee ? 250 : 1000],
      clearness: [0, this.camera?.isTikee ? 60 : 40],
      photoExclusion: [] as string[],
      slowmotion: true,
    };
  }

  private config: TimelapseConfiguration | null = null;

  public get breadcrumbs(): unknown[] {
    const items = [
      {
        text: this.$tc('global.project', 2),
        to: '/',
      },
    ];
    if (this.project !== null) {
      items.push({
        text: this.project.name,
        to: '/project/' + this.project.id,
      });
    }
    items.push({
      text: this.$tc('global.timelapse'),
      to: '#',
    });
    return items;
  }

  resizing = false;

  startResize(event: MouseEvent) {
    this.resizing = true;
    window.addEventListener('mousemove', this.performResize);
    window.addEventListener('mouseup', this.stopResize);
  }

  performResize(event: MouseEvent) {
    if (this.resizing) {
      const leftElement = this.$el.querySelector('.left') as HTMLElement;
      if (leftElement !== null) {
        const newWidth = event.clientX - leftElement.getBoundingClientRect().left;
        leftElement.style.width = `${newWidth}px`;
      }
    }
  }

  stopResize() {
    if (this.resizing) {
      this.resizing = false;
      window.removeEventListener('mousemove', this.performResize);
      window.removeEventListener('mouseup', this.stopResize);
    }
  }

  public toggleLeftPanel() {
    this.leftPanelVisible = !this.leftPanelVisible;
  }

  public get timelapseGridStyle() {
    if (this.lastVideo) {
      return {
        'grid-template-areas':
          "'timelapse-filter timelapse-photos timelapse-photos' 'timelapse-preview timelapse-photos timelapse-photos'",
      };
    } else {
      return {
        'grid-template-areas':
          "'timelapse-filter timelapse-photos timelapse-photos' 'timelapse-filter timelapse-photos timelapse-photos'",
      };
    }
  }

  public get cameraSelected(): string | null {
    return (this.$route.query.camera as string) || null;
  }
  public set cameraSelected(s: string | null) {
    logger.info('set cameraSelected', s);
    if (s !== null) {
      this.$router.push({ path: this.$route.path, query: { ...this.$route.query, camera: s } });
    }
  }

  public get camera(): Camera | undefined {
    logger.info('get camera ' + this.cameraSelected);
    return (
      this.project?.cameras?.find((camera: Camera) => camera.id === this.cameraSelected) ||
      this.project?.previousCameras?.find((camera: Camera) => camera.id === this.cameraSelected)
    );
  }

  private get dataImagesKey() {
    if (this.config !== null) {
      const localConfig = Object.assign({}, this.config);
      return this.imagesKey
        .filter((item: Photo) => {
          // Filter by date
          if (!isNaN(localConfig.interval.begin) && !isNaN(localConfig.interval.end)) {
            const start = new Date(localConfig.interval.begin * 1000);
            const end = new Date(localConfig.interval.end * 1000);
            if (localConfig.interval.begin && localConfig.interval.end && isBefore(start, end)) {
              return isWithinInterval(new Date(item.createdAt), { start, end });
            }
          }
          if (localConfig.slowmotion === false && item.isSlowmotion === true) {
            return false;
          }
          return true;
        })
        .filter((item: Photo) => {
          // Filter by days
          const day = new Date(item.createdAt).getDay();
          return localConfig.days.includes(day);
        })
        .filter((item: Photo) => {
          // Filter by hours
          const date = new Date(item.createdAt);
          const mindate = new Date(date);
          const maxdate = new Date(date);
          if (localConfig.period.startHour) {
            mindate.setHours(
              parseInt(localConfig.period.startHour.split(':')[0] || '0', 10),
              parseInt(localConfig.period.startHour.split(':')[1] || '0', 10)
            );
          }
          if (localConfig.period.endHour) {
            maxdate.setHours(
              parseInt(localConfig.period.endHour.split(':')[0] || '0', 10),
              parseInt(localConfig.period.endHour.split(':')[1] || '0', 10)
            );
          }
          if (isValid(date) && isValid(mindate) && isValid(maxdate)) {
            try {
              return isWithinInterval(date, { start: mindate, end: maxdate });
            } catch (err) {
              // Begin is after end
            }
          }
          return true;
        })
        .filter((item: Photo) => {
          // Filter by hours excluded
          if (localConfig.exclude && localConfig.exclude.length > 0) {
            const date = new Date(item.createdAt);
            const mindate = new Date(date);
            const maxdate = new Date(date);
            mindate.setHours(
              parseInt(localConfig.exclude[0].startHour.split(':')[0] || '0', 10),
              parseInt(localConfig.exclude[0].startHour.split(':')[1] || '0', 10)
            );
            maxdate.setHours(
              parseInt(localConfig.exclude[0].endHour.split(':')[0] || '0', 10),
              parseInt(localConfig.exclude[0].endHour.split(':')[1] || '0', 10)
            );
            if (isValid(date) && isValid(mindate) && isValid(maxdate)) {
              try {
                return !isWithinInterval(date, { start: mindate, end: maxdate });
              } catch (err) {
                // Begin is after end
              }
            }
          }
          return true;
        })
        .filter((item: Photo) => {
          // Filter by exposition, gain...
          let keep = true;
          if (localConfig.hdr) {
            keep = keep && item.hdr === true;
          }
          if (localConfig.exposure.length === 2 && item.exposure !== undefined) {
            keep = keep && localConfig.exposure[0] <= item.exposure && item.exposure <= localConfig.exposure[1];
          }
          if (keep && localConfig.gain.length === 2 && item.gainControl !== undefined) {
            keep = keep && localConfig.gain[0] <= item.gainControl && item.gainControl <= localConfig.gain[1];
          }
          if (keep && localConfig.clearness.length === 2 && item.sharpness !== undefined) {
            keep = keep && localConfig.clearness[0] <= item.sharpness && item.sharpness <= localConfig.clearness[1];
          }
          if (keep && localConfig.blue.length === 2 && item.blueLevel !== undefined) {
            keep = keep && localConfig.blue[0] <= item.blueLevel && item.blueLevel <= localConfig.blue[1];
          }
          return keep;
        })
        .map((item) => ({ data: item }));
    } else {
      return this.imagesKey;
    }
  }

  public mounted(): void {
    logger.info('mounted');
    if (this.timelapse.length > 0) {
      this.onTimelapseLoaded(this.timelapse, []);
    }
    if (this.projects.length > 0) {
      this.onProjectsLoaded(this.projects, []);
    }
  }

  public beforeDestroy(): void {
    logger.info('beforeDestroy');
    // called when the route that renders this component is about to
    // be navigated away from.
    // has access to `this` component instance.
    this.disconnectSocket();
  }

  @Watch('projects')
  public onProjectsLoaded(val: Project[], oldVal: Project[]): void {
    logger.info('onProjectsLoaded', { val, oldVal });
    this.project = this.projectWithId(this.id);
    this.setProject(this.project);
  }

  @Watch('project')
  public onProjectLoaded(val: Project | null, oldVal: Project | null): void {
    logger.info('onProjectLoaded', { val, oldVal });
    if (val !== null) {
      this.onCameraSelectedChange();
    }
  }

  @Watch('photosStore')
  public onPhotoStoreUpdated(val: Photo[], oldVal: Photo[]): void {
    logger.info('onPhotoStoreUpdated', { val, oldVal });
    if (this.camera) {
      this.imagesKey = val.slice(0).sort((photo1: Photo, photo2: Photo) => {
        return photo1.createdAt.getTime() - photo2.createdAt.getTime();
      });
    }
  }

  @Watch('timelapse')
  public onTimelapseLoaded(val: Video[], oldVal: Video[]): void {
    logger.info('onTimelapseLoaded', { val, oldVal });
    if (val.length > 0 && this.cameraSelected !== null) {
      this.lastVideo = this.timelapseForCamera(this.cameraSelected);
      if (this.lastVideo?.timelapseConfiguration !== undefined) {
        const timelapseConfiguration = Object.assign({}, this.lastVideo.timelapseConfiguration);
        timelapseConfiguration.interval.end = endOfDay(timelapseConfiguration.interval.end * 1000).getTime() / 1000;
        this.config = Object.assign({ ...this.defaultConfig }, timelapseConfiguration);
      } else {
        this.config = { ...this.defaultConfig };
      }
    }
  }

  @Watch('socketMessage.message')
  public onSocketMessageChange(val: NotificationMessage): void {
    if (val.timelapse !== undefined && val.projectId === this.id && val.cameraId === this.cameraSelected) {
      switch (val.timelapse) {
        case 'ready':
          logger.info('ready');
          this.timelapseRending = false;
          this.loadTimelapse().then(() => {
            this.disconnectSocket();
          });
          break;
      }
    }
  }

  @Watch('cameraSelected')
  public onCameraSelectedChange(): void {
    logger.info('onCameraSelectedChange', { query: this.cameraSelected });
    if (this.cameraSelected !== null) {
      const indexCamera: number | undefined = this.project?.cameras
        ?.map((c: Camera) => c.id)
        .indexOf(this.cameraSelected);
      const indexPrevious: number | undefined = this.project?.previousCameras
        ?.map((c: Camera) => c.id)
        .indexOf(this.cameraSelected);
      if ((indexCamera !== undefined && indexCamera !== -1) || (indexPrevious !== undefined && indexPrevious !== -1)) {
        this.lastVideo = this.timelapseForCamera(this.cameraSelected) || null;
        this.findPhotoAllForProject({ projectId: this.id, cameraId: this.cameraSelected });
        this.lastVideo = this.timelapseForCamera(this.cameraSelected);
        if (this.lastVideo?.timelapseConfiguration !== undefined) {
          const timelapseConfiguration = JSON.parse(
            JSON.stringify(this.lastVideo.timelapseConfiguration)
          ) as TimelapseConfiguration;
          timelapseConfiguration.interval.end = endOfDay(timelapseConfiguration.interval.end * 1000).getTime() / 1000;
          this.config = Object.assign({ ...this.defaultConfig }, timelapseConfiguration);
        } else {
          this.config = { ...this.defaultConfig };
        }
      } else if (this.project?.cameras?.length ?? 0 > 0) {
        this.cameraSelected = this.project?.cameras?.[0].id ?? null;
      } else if (this.project?.previousCameras?.length ?? 0 > 0) {
        this.cameraSelected = this.project?.previousCameras?.[0].id ?? null;
      } else {
        this.cameraSelected = null;
      }
    }
  }

  public save(): void {
    if (this.camera !== undefined && this.project !== null && this.config !== null) {
      const timelapseConfiguration = Object.assign({}, this.config);
      timelapseConfiguration.interval.end = endOfDay(timelapseConfiguration.interval.end * 1000).getTime() / 1000;
      this.saveTimelapseConfiguration({
        cameraId: this.camera.id,
        projectId: this.project.id,
        timelapseConfiguration,
      });
      this.resetSocketMessage('');
      this.timelapseRending = true;
      this.connectSocket();
    }
  }
}
