import { Project } from '@/models/project';
import { Scenario } from '@/models/scenario';
import { Video } from '@/models/video';
import { MutationTree } from 'vuex';
import { ProjectState } from './types';

export const mutations: MutationTree<ProjectState> = {
  setProject(state: ProjectState, project: Project) {
    state.project = project;
  },
  setPremium(state: ProjectState, premium: boolean) {
    state.premium = premium;
  },
  unsetProject(state: ProjectState) {
    state.project = undefined;
    state.premium = false;
    state.scenario = [];
    state.timelapse = [];
  },
  loadingTimelapse(state: ProjectState) {
    state.loadingTimelapse = true;
  },
  loadingScenario(state: ProjectState) {
    state.loadingScenario = true;
  },
  timelapse(state: ProjectState, videos: Video[]) {
    state.timelapse = videos;
    state.loadingTimelapse = false;
  },
  scenario(state: ProjectState, scenario: Scenario[]) {
    state.scenario = scenario;
    state.loadingScenario = false;
  },
  updateTimelapse(state: ProjectState, video: Video) {
    const filtered = state.timelapse.filter((v) => v.cameraId !== video.cameraId);
    filtered.push(video);
    state.timelapse = filtered;
  },
  updateScenario(state: ProjectState, scenario: Scenario) {
    const filtered = state.scenario.filter((s) => s.id !== scenario.id);
    filtered.push(scenario);
    state.scenario = filtered;
  },
  removeScenario(state: ProjectState, scenario: Scenario) {
    const filtered = state.scenario.filter((s) => s.id !== scenario.id);
    state.scenario = filtered;
  },
};
