import { API } from 'aws-amplify';
import { Album, serializer } from '../../models/album';
import { AlbumCreateRequest, AlbumUpdateRequest, AlbumDeleteRequest } from './albums.interfaces';

export class AlbumService {
  public constructor(private readonly apiName: string) {}

  public async getMyAlbum(projectId: string, cameraId: string): Promise<Album[]> {
    return API.get(this.apiName, `/albums/search/${projectId}/${cameraId}`, {}).then((v) => serializer.parseAsArray(v));
  }

  public async getOne(albumId: string): Promise<Album | undefined> {
    return API.get(this.apiName, `/albums/${albumId}`, {}).then((v) => serializer.parse(v));
  }

  public async albumAddPhoto(albumId: string, photoId: string): Promise<Album | undefined> {
    return API.post(this.apiName, `/albums/${albumId}/add/${photoId}`, {}).then((v) => serializer.parse(v));
  }

  public async albumRemovePhoto(albumId: string, photoId: string): Promise<Album | undefined> {
    return API.post(this.apiName, `/albums/${albumId}/remove/${photoId}`, {}).then((v) => serializer.parse(v));
  }

  public async createNewAlbum(cameraCreateRequest: AlbumCreateRequest): Promise<Album[]> {
    return API.post(this.apiName, '/albums/new', {
      body: cameraCreateRequest,
    }).then((v) => serializer.parseAsArray(v));
  }

  public async updateAlbum(cameraUpdateRequest: AlbumUpdateRequest): Promise<Album[]> {
    return API.post(this.apiName, '/albums/update', {
      body: cameraUpdateRequest,
    }).then((v) => serializer.parseAsArray(v));
  }

  public async deleteAlbum(cameraDeleteRequest: AlbumDeleteRequest): Promise<void> {
    return API.post(this.apiName, '/albums/delete', {
      body: cameraDeleteRequest,
    });
  }
}
