


























































































































































































































import { Component, Vue, Ref, Watch, Prop } from 'vue-property-decorator';
// @ts-ignore no d.ts
import Datepicker from 'vuejs-datepicker';
// @ts-ignore no d.ts
import { en, fr } from 'vuejs-datepicker/dist/locale';
import { mixin as VueClickawayMixin } from 'vue-clickaway';
import S3Image from '@/components/s3-image/S3Image.vue';
import { Storage } from 'aws-amplify';
import VueSlider from 'vue-slider-component';
import { iframesService } from '@/services';
import { IframeProject } from '../models/project';
import { IframeCamera } from '../models/camera';
import { Camera } from '@/models/camera';
import { IframePhoto } from '../models/photo';
import { Logger, Auth } from 'aws-amplify';
const logger = new Logger('IframeGallery');

@Component({
  components: {
    Datepicker,
    S3Image,
    VueSlider,
  },
  mixins: [VueClickawayMixin],
})
export default class IframeGallery extends Vue {
  public isLoading = true;
  public get cameraSelectOption(): unknown[] {
    if (this.project) {
      return this.project.cameras.map((cam: IframeCamera) => {
        return { text: cam.name, value: cam };
      });
    } else {
      return [];
    }
  }

  public get datepickerFormat(): string {
    return this.locale === 'en' ? 'yyyy-dd-MM' : 'yyyy-MM-dd';
  }
  public get datepickerLocal(): unknown {
    return this.locale === 'en' ? en : fr;
  }

  public get dateFormat(): string {
    return this.locale === 'en' ? 'MM/DD/YYYY' : 'DD/MM/YYYY';
  }

  public get disabledDates(): unknown {
    const startDate = this.photos[0] ? this.photos[0].createdAt : null;
    const endDate = this.photos[this.photos.length - 1] ? this.photos[this.photos.length - 1].createdAt : null;
    if (startDate !== null && endDate !== null) {
      const to = new Date(startDate);
      to.setHours(to.getHours() + to.getTimezoneOffset() / 60);
      to.setHours(0);
      const from = new Date(endDate);
      from.setHours(to.getHours() + from.getTimezoneOffset() / 60);
      from.setDate(from.getDate() + 1);
      return {
        ranges: [
          {
            from: new Date(-8640000000000000), // Min date
            to,
          },
          {
            from,
            to: new Date(8640000000000000), // Max date
          },
        ],
      };
    } else {
      return {};
    }
  }

  public sliderValue: IframePhoto | null = null;
  public sliderIsDragging = false;
  public isSliderFirstItem = false;
  public isSliderLastItem = true;
  public calendarOpened = false;

  public isplaying = false;
  public playtimer: number | null = null;

  public locale: 'fr' | 'en' = 'fr';
  @Prop(String) private id!: string;
  @Ref('slider') private readonly slider!: VueSlider;

  private currentImageKey: IframePhoto | null = null;
  private isFullscreen = false;
  public project: IframeProject | null = null;
  public currentCamera: Camera | null = null;
  private photos: IframePhoto[] = [];
  private camera: IframeCamera[] = [];
  private pickedDate: Date | null = null;

  public get galleryControlStyle(): Record<string, string> {
    if (this.project && this.project.totalphoto === false) {
      return {
        'grid-template-columns': '2fr 1fr 1fr 34fr 1fr 1fr 1fr',
      };
    } else {
      return {
        'grid-template-columns': '2fr 1fr 1fr 1fr 5fr 1fr 1fr 25fr 1fr 1fr 1fr 1fr',
      };
    }
  }

  public mounted(): void {
    Auth.currentCredentials()
      .then(() => iframesService.iframeProject(this.id))
      .then((iframeProject: IframeProject | undefined) => {
        if (iframeProject !== undefined) {
          this.project = iframeProject;
          this.currentCamera = iframeProject.cameras ? iframeProject.cameras[0] : null;
          if (this.currentCamera !== null) {
            this.onCameraChange();
          }
        }
      });
    document.addEventListener('click', this.closeCalendar);
  }

  public getRotation(): string {
    return this.currentCamera && this.currentCamera.rotate ? `rotate(${this.currentCamera.rotate}deg)` : '';
  }

  public onCameraChange(): void {
    if (this.currentCamera) {
      this.isLoading = true; // Commence le chargement
      iframesService
        .iframePhoto(this.id, this.currentCamera.id)
        .then((photos) => {
          this.photos = [...photos.sort((a, b) => a.createdAt.getTime() - b.createdAt.getTime())];
          if (this.photos.length > 0) {
            this.currentImageKey = this.photos[this.photos.length - 1];
            this.pickedDate = new Date(this.currentImageKey.createdAt);
            this.sliderValue = this.currentImageKey;
            if (this.currentCamera?.isTikee === true) {
              this.$nextTick(() => {
                logger.info('zoom in', this.$refs.zoomerMedium);
                // @ts-ignore no typescript def for $refs
                this.$refs.zoomerMedium?.reset();
                // @ts-ignore no typescript def for $refs
                this.$refs.zoomerMedium?.zoomIn(1.2);
              });
            }
          }
          this.isLoading = false; // Termine le chargement
        })
        .catch(() => {
          this.isLoading = false; // Termine le chargement en cas d'erreur
        });
    }
  }

  public fullscreenChange(isFullscreen: boolean): void {
    this.isFullscreen = isFullscreen;
  }

  public onSliderChange(value: IframePhoto): void {
    if (!this.sliderIsDragging) {
      this.currentImageKey = value;
    }
    this.isSliderFirstItem = this.slider.getIndex() === 0;
    this.isSliderLastItem = this.slider.getIndex() === this.photos.length - 1;
  }

  public onSliderDragStart(): void {
    this.sliderIsDragging = true;
  }

  public onSliderDragEnd(): void {
    this.sliderIsDragging = false;
    this.currentImageKey = this.sliderValue;
  }

  public nextPhoto(): void {
    if (this.currentImageKey) {
      const index = this.photos.indexOf(this.currentImageKey);
      if (index !== -1 && index + 1 < this.photos.length) {
        this.currentImageKey = this.photos[index + 1];
        this.sliderValue = this.currentImageKey;
      }
      this.isSliderLastItem = index + 2 === this.photos.length;
    }
  }
  public prevPhoto(): void {
    if (this.currentImageKey) {
      const index = this.photos.indexOf(this.currentImageKey);
      if (index !== -1 && index - 1 >= 0) {
        this.currentImageKey = this.photos[index - 1];
        this.sliderValue = this.currentImageKey;
      }
      this.isSliderFirstItem = index - 1 === 0;
    }
  }

  public play(): void {
    if (!this.isplaying) {
      // Check if the slider is on the last item
      if (this.isSliderLastItem) {
        // If it is, reset it to the first item
        this.currentImageKey = this.photos[0];
        this.sliderValue = this.currentImageKey;
        this.isSliderFirstItem = true;
        this.isSliderLastItem = false;
      }

      this.isplaying = true;
      this.playtimer = window.setInterval(() => {
        if (this.currentImageKey && !this.isSliderLastItem) {
          this.nextPhoto();
        } else {
          this.pause();
        }
      }, 400);
    }
  }
  public pause(): void {
    if (this.isplaying) {
      this.isplaying = false;
    }
    if (this.playtimer !== null) {
      clearInterval(this.playtimer);
      this.playtimer = null;
    }
  }
  public closeCalendar(): void {
    this.calendarOpened = false;
    if (this.$refs.picker) {
      (this.$refs.picker as any).hideCalendar();
    }
  }

  @Watch('pickedDate')
  public onDatePick(val: Date, oldVal: Date | null): void {
    logger.info('pickedDate', { val, oldVal });
    if (oldVal !== null) {
      let closestPhoto: IframePhoto | null = null;
      let closestDistance = Infinity;
      this.photos.forEach((imageKey: IframePhoto) => {
        const photoDate = new Date(imageKey.createdAt);
        photoDate.setHours(photoDate.getHours() + photoDate.getTimezoneOffset() / 60);
        const localDistance = Math.sqrt(Math.pow(val.getTime() - photoDate.getTime(), 2));
        if (localDistance < closestDistance) {
          closestDistance = localDistance;
          closestPhoto = imageKey;
        }
      });
      if (closestPhoto) {
        this.currentImageKey = closestPhoto;
        this.sliderValue = this.currentImageKey;
      } else {
        alert('no image found');
      }
      this.closeCalendar();
    }
  }

  public pickedDateClick(event: MouseEvent): void {
    if (this.$refs.picker && this.calendarOpened === false) {
      event.stopPropagation(); // Empêche la propagation de l'événement de clic
      this.calendarOpened = true;
      // @ts-ignore refs
      this.$refs.picker.showCalendar();
    }
  }

  public pickedDateAway(event: MouseEvent): void {
    if (this.$refs.picker && this.calendarOpened === true) {
      event.stopPropagation(); // Empêche la propagation de l'événement de clic
      this.calendarOpened = true;
      // @ts-ignore refs
      this.$refs.picker.hideCalendar();
    }
  }

  public buildShareLink(key: string): string {
    return `${process.env.VUE_APP_API_ENDPOINT}/photos/${key}/viewer`;
  }

  public shareFacebook(): void {
    if (this.currentImageKey) {
      window.open(
        'https://www.facebook.com/sharer/sharer.php?u=' + this.buildShareLink(this.currentImageKey.id),
        '',
        'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600'
      );
    }
  }

  public shareTwitter(): void {
    if (this.currentImageKey) {
      window.open(
        'https://twitter.com/intent/tweet?text=' + this.buildShareLink(this.currentImageKey.id),
        '',
        'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600'
      );
    }
  }

  public shareLinkedin(): void {
    if (this.currentImageKey) {
      window.open(
        'https://www.linkedin.com/sharing/share-offsite/?url=' + this.buildShareLink(this.currentImageKey.id),
        '',
        'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600'
      );
    }
  }

  public shareEmail(): void {
    if (this.currentImageKey) {
      window.open(
        'mailto:someone@example.com?subject=Photo%20du%20chantier&body=Voilà une photo que je voudrais que tu vois: ' +
          encodeURIComponent(this.buildShareLink(this.currentImageKey.id))
      );
    }
  }

  public download(): void {
    if (this.currentImageKey) {
      Storage.get(this.currentImageKey.key).then((url) => window.open(url as string, '_blank'));
    }
  }
}
