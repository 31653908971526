import { Component, Vue, Prop, Ref, Watch } from 'vue-property-decorator';
import { Camera } from '@/models/camera';
import { Project } from '@/models/project';
import { Photo } from '@/models/photo';
import { Album } from '@/models/album';
import { Getter, State, Action } from 'vuex-class';
import { Storage } from 'aws-amplify';
import VueSlider from 'vue-slider-component';
// @ts-ignore no d.ts
import Datepicker from 'vuejs-datepicker';
// @ts-ignore no d.ts
import { en, fr } from 'vuejs-datepicker/dist/locale';
import { Validations } from 'vuelidate-property-decorators';
import { minLength, required } from 'vuelidate/lib/validators';
import { AlbumCreateRequest } from '@/services/albums/albums.interfaces';
import EditShareModal from '../editsharemodal/editsharemodal.component.vue';
import ImportPhotosModal from '../importphotos/importphotos.component.vue';
import S3Image from '../s3-image/S3Image.vue';
import BlurModal from '@/components/blurmodal/blurmodal.component.vue';
import { BTooltip } from 'bootstrap-vue';
import { Logger } from 'aws-amplify';
import { format } from 'date-fns';
import { User } from '@/models/user';
const logger = new Logger('Gallery');

@Component({
  components: {
    VueSlider,
    Datepicker,
    EditShareModal,
    ImportPhotosModal,
    S3Image,
    BlurModal,
    BTooltip,
  },
})
export default class Gallery extends Vue {
  public imagesKey: Photo[] = [];
  public currentImageKey: Photo | null = null;
  public sliderValue: Photo | null = null;
  public sliderIsDragging = false;
  public isSliderFirstItem = false;
  public isSliderLastItem = true;
  public switchBeforeAfter = false;
  public createdAlbum = '';
  public selectedAlbum = '';
  private photoFavAlbum = true;
  private pickedDate: Date | null = null;

  @Validations()
  private validations = {
    selectedAlbum: { required },
    createdAlbum: { required, minLength: minLength(3) },
  };

  @Prop({ required: true, type: Object }) private readonly camera!: Camera;
  @Prop({ required: true, type: Object }) private readonly project!: Project;
  @Prop({ required: true, type: Boolean }) private readonly newPhotoAvailable!: boolean;
  @Prop({ required: true }) readonly isPremium!: boolean;
  @Prop({ required: true }) readonly isGuest!: boolean;

  @Ref('slider') private readonly slider!: VueSlider;

  @State('locale', { namespace: 'profile' }) private locale!: string;
  @State('user', { namespace: 'profile' }) private user!: User;
  @State('isLoading', { namespace: 'photo' }) private readonly isLoadingPhoto!: boolean;
  @State('photos', { namespace: 'photo' }) private readonly photosStore?: Photo[];
  @Getter('onlyPhoto', { namespace: 'photo' }) private onlyPhoto!: Photo[];

  @State('albums', { namespace: 'album' }) private readonly albums!: Album[];
  @Action('createNewAlbum', { namespace: 'album' }) private readonly createNewAlbum!: (
    cameraCreateRequest: AlbumCreateRequest
  ) => Promise<Album[]>;
  @Action('addPhoto', { namespace: 'album' }) private readonly addPhoto!: (payload: {
    albumId: string;
    photoId: string;
  }) => Promise<void>;

  public get zoom(): boolean {
    return this.$route.query.theater !== undefined;
  }

  public set zoom(set: boolean) {
    if (set && this.$route.query.theater === undefined) {
      this.$router.push({ path: this.$route.path, query: { ...this.$route.query, theater: '' } });
    } else if (this.$route.query.theater === '') {
      this.$router.push({ path: this.$route.path, query: { ...this.$route.query, theater: undefined } });
    }
  }

  public get dateFormat(): string {
    return this.locale === 'en' ? 'MM / DD / YYYY' : 'DD / MM / YYYY';
  }

  public get disabledDates(): unknown {
    const startDate = this.imagesKey[0] ? this.imagesKey[0].createdAt : null;
    const endDate = this.imagesKey[this.imagesKey.length - 1]
      ? this.imagesKey[this.imagesKey.length - 1].createdAt
      : null;
    if (startDate && endDate) {
      const to = new Date(startDate);
      to.setHours(to.getHours() + to.getTimezoneOffset() / 60);
      to.setHours(0);
      const from = new Date(endDate);
      from.setHours(to.getHours() + from.getTimezoneOffset() / 60);
      from.setDate(from.getDate() + 1);
      return {
        ranges: [
          {
            from: new Date(-8640000000000000), // Min date
            to,
          },
          {
            from,
            to: new Date(8640000000000000), // Max date
          },
        ],
      };
    } else {
      return {};
    }
  }

  public get datepickerFormat(): string {
    return this.locale === 'en' ? 'yyyy-dd-MM' : 'yyyy-MM-dd';
  }

  public get datepickerLocal(): unknown {
    return this.locale === 'en' ? en : fr;
  }

  public get isAdmin(): boolean {
    return (
      this.user !== undefined &&
      (this.project.owner.id === this.user.id || this.project.administratorId.includes(this.user.id))
    );
  }

  public mounted(): void {
    logger.info('mounted');
    document.addEventListener('keyup', this.onNextSlide);
    if (this.photosStore && this.photosStore.length > 0) {
      this.onPhotoStoreUpdated(this.photosStore, []);
    }
    if (this.zoom) {
      document.addEventListener('keyup', this.onNextSlide);
    }
  }

  private filterImagesWithinLagDuration(images: Photo[], lagduration: number): Photo[] {
    const now = new Date();
    const cutoffTime = new Date(now.getTime() - lagduration * 60 * 60 * 1000); // lagduration hours ago
    return images.filter((image) => new Date(image.createdAt) < cutoffTime);
  }

  public getRotation(): string {
    return this.camera && this.camera.rotate ? `rotate(${this.camera.rotate}deg)` : '';
  }

  public togglDirection(mode: 'r' | 'b' | 's'): void {
    this.$emit('switchmode', mode);
  }

  public refreshPhoto(): void {
    // eslint-disable-next-line vue/custom-event-name-casing
    this.$emit('refreshPhoto');
  }

  @Watch('pickedDate')
  public onDatePick(val: Date, oldVal: Date | null): void {
    logger.info('pickedDate', { val, oldVal });
    if (oldVal !== null) {
      let closestPhoto: Photo | null = null;
      let closestDistance = Infinity;
      this.imagesKey.forEach((imageKey: Photo) => {
        const photoDate = new Date(imageKey.createdAt);
        photoDate.setHours(photoDate.getHours() + photoDate.getTimezoneOffset() / 60);
        const localDistance = Math.sqrt(Math.pow(val.getTime() - photoDate.getTime(), 2));
        if (localDistance < closestDistance) {
          closestDistance = localDistance;
          closestPhoto = imageKey;
        }
      });
      if (closestPhoto) {
        this.currentImageKey = closestPhoto;
        this.sliderValue = this.currentImageKey;
      } else {
        alert('no image found');
      }
    }
  }

  @Watch('photosStore')
  public onPhotoStoreUpdated(val: Photo[], oldVal: Photo[]): void {
    logger.info('onPhotoStoreUpdated', { val, oldVal });
    if (this.isGuest && this.project.jetlag) {
      this.imagesKey = this.filterImagesWithinLagDuration(
        this.onlyPhoto.sort((photo1: Photo, photo2: Photo) => {
          return photo1.createdAt.getTime() - photo2.createdAt.getTime();
        }),
        this.project.lagduration
      );
    } else {
      this.imagesKey = this.onlyPhoto.sort((photo1: Photo, photo2: Photo) => {
        return photo1.createdAt.getTime() - photo2.createdAt.getTime();
      });
    }
    this.currentImageKey = this.imagesKey.length > 0 ? this.imagesKey[this.imagesKey.length - 1] : null;
    this.sliderValue = this.currentImageKey;
    if (this.currentImageKey && this.pickedDate === null) {
      this.pickedDate = new Date(this.currentImageKey.createdAt);
      this.pickedDate.setHours(this.pickedDate.getHours() + this.pickedDate.getTimezoneOffset() / 60);
      this.pickedDate.setHours(12);
      this.pickedDate.setMinutes(0);
      this.pickedDate.setSeconds(0);
    }
    if (this.camera.isTikee === true && this.currentImageKey !== null) {
      this.$nextTick(() => {
        logger.info('zoom in', this.$refs.zoomerMedium);
        // @ts-ignore no typescript def for $refs
        this.$refs.zoomerMedium?.reset();
        // @ts-ignore no typescript def for $refs
        this.$refs.zoomerMedium?.zoomIn(1.1);
      });
    }
  }

  public get sliderData(): string[] {
    return this.imagesKey.map((imageKey) => imageKey.smallKey);
  }

  public onSliderChange(value: Photo): void {
    if (!this.sliderIsDragging) {
      this.currentImageKey = value;
    }
    this.isSliderFirstItem = this.slider.getIndex() === 0;
    this.isSliderLastItem = this.slider.getIndex() === this.imagesKey.length - 1;
  }

  public onSliderDragStart(): void {
    this.sliderIsDragging = true;
  }

  public onSliderDragEnd(): void {
    this.sliderIsDragging = false;
    this.currentImageKey = this.sliderValue;
  }

  public onNextSlide(event: KeyboardEvent): void {
    if (event.keyCode === 37) {
      this.onPrevImageClick();
    } else if (event.keyCode === 39) {
      this.onNextImageClick();
    } else if (event.keyCode === 27 && this.zoom) {
      this.zoom = false;
    }
  }

  public onPrevImageClick(): void {
    if (this.slider) {
      const index = this.slider.getIndex() as number;
      if (index >= 1) {
        this.slider.setIndex(index - 1);
      } else {
        this.slider.setIndex(0);
      }
    }
  }

  public onNextImageClick(): void {
    if (this.slider) {
      const index = this.slider.getIndex() as number;
      if (index < this.imagesKey.length - 1) {
        this.slider.setIndex(index + 1);
      } else {
        this.slider.setIndex(this.imagesKey.length - 1);
      }
    }
  }

  public onshow(): void {
    logger.info('onShow');
    this.showCreateAlbum();
    this.showSelectAlbum();
    // eslint-disable-next-line vue/custom-event-name-casing
    this.$root.$emit('bv::show::popover', 'popover-favorite');
  }

  public onClose(): void {
    this.showCreateAlbum();
    this.showSelectAlbum();
    // eslint-disable-next-line vue/custom-event-name-casing
    this.$root.$emit('bv::hide::popover', 'popover-favorite');
  }

  public onDownload(): void {
    if (this.currentImageKey !== null) {
      const imageKey = this.currentImageKey;
      Storage.get(imageKey.key, { download: true }).then((response) => {
        saveAs(
          // @ts-ignore amplify no doc
          new Blob([response.Body], { type: 'images/jpeg' }),
          `${format(imageKey.createdAt, 'yyyyMMdd-HHmmss')}.jpg`
        );
      });
    }
  }

  public showCreateAlbum(): void {
    logger.info('showCreateAlbum');
    this.$v.selectedAlbum.$reset();
    this.selectedAlbum = '';
    this.photoFavAlbum = !this.photoFavAlbum;
  }

  public showSelectAlbum(): void {
    logger.info('showSelectAlbum');
    this.$v.createdAlbum.$reset();
    this.createdAlbum = '';
    this.photoFavAlbum = !this.photoFavAlbum;
  }

  private async onSelectAlbum(albumId: string) {
    logger.info('onSelectAlbum ' + albumId);
    this.$v.selectedAlbum.$touch();
    if (!this.$v.selectedAlbum.$invalid && this.currentImageKey !== null) {
      await this.addPhoto({ albumId, photoId: this.currentImageKey.id });
      this.onClose();
    }
  }

  private async onCreateAlbum(albumName: string) {
    logger.info('onCreateAlbum ' + albumName);
    this.$v.createdAlbum.$touch();
    if (!this.$v.createdAlbum.$invalid && this.currentImageKey !== null) {
      this.createNewAlbum({
        cameraId: this.camera.id,
        projectId: this.project.id,
        name: albumName,
        photoId: this.currentImageKey.id,
      });
      this.onClose();
    }
  }
}
