import { Component, Vue, Prop } from 'vue-property-decorator';
import { photoService } from '@/services/';
import VueSlider from 'vue-slider-component';
import { TimelapseConfiguration, ImageModifier } from '@/models/timelapseconfiguration';
import { Crop, Position } from '@/models/enums';
import PremiumOverlay from '../premiumoverlay/premiumoverlay.component.vue';
import { Logger } from 'aws-amplify';
const logger = new Logger('TimelapseSettings');

interface HTMLInputEvent extends Event {
  target: HTMLInputElement & EventTarget;
}

@Component({
  components: {
    VueSlider,
    PremiumOverlay,
  },
})
export default class TimelapseSettings extends Vue {
  @Prop({ required: true, type: Object }) private readonly value!: TimelapseConfiguration;
  @Prop({ required: true }) readonly isPremium!: boolean;
  private visible = false;

  private activeModifier: string | null = null;
  private show = true;
  // Both are used inside the component template
  private Crop = Crop;
  private Position = Position;
  private validLogo: boolean | null = null;

  private get model() {
    return this.value;
  }
  private set model(val: TimelapseConfiguration) {
    const compare = JSON.stringify(val).localeCompare(JSON.stringify(this.value));
    if (compare !== 0) {
      this.$emit('input', this.model);
    }
  }
  /*
  private model: ImageModifier = { ...this.value };
  private get computedModel(): ImageModifier {
    return Object.assign({}, this.model);
  }
  @Watch('computedModel', { deep: true })
  public onModelChange(val: ImageModifier, oldVal: ImageModifier): void {
    const compare = JSON.stringify(val).localeCompare(JSON.stringify(oldVal));
    if (compare !== 0) {
      this.$emit('input', this.model);
    }
  }

  @Watch('value', { deep: true })
  public onValueChange(val: TimelapseConfiguration): void {
    this.model = { ...val };
  }
  */

  private originalmodel: ImageModifier = {
    iso: 0,
    brightness: 0,
    contrast: 0,
    saturation: 0,
    white: 0,
    crop: Crop.original,
    watermarkPosition: Position.bottomleft,
    erigeWatermark: true,
    customWatermarkPicture: undefined,
  };
  private datas: number[] = [...Array(101).keys()].map((x) => x - 50);

  public mounted(): void {
    logger.info('mounted');
  }

  public process = (dotsPos: number[]): number[][] => [[50, dotsPos[0]]];

  public getCustomWatermark(key: string): string {
    if (/data:(image\/.+);base64,/.test(key)) {
      return key;
    } else {
      return `https://${process.env.VUE_APP_MAIN_STORE_BUCKET}.s3.${process.env.VUE_APP_MAIN_STORE_REGION}.amazonaws.com/public/${key}`;
    }
  }

  public removeCustomWatermarkPicture(): void {
    this.model.customWatermarkPicture = undefined;
  }

  public changeActiveModifier($event: Event): void {
    // @ts-ignore dom event
    this.activeModifier = $event.currentTarget.id;
  }

  public onUpload($event: HTMLInputEvent): void {
    logger.info('onUpload', $event);
    let file: File;
    if ($event && $event.target && $event.target.files) {
      file = $event.target.files[0];
      photoService
        .fileToBase64(file)
        .then((base64: string) => {
          Vue.set(this.model, 'customWatermarkPicture', base64);
          this.validLogo = true;
        })
        .catch((error) => {
          if (error.message && error.message === 'INVALID_SIZE') {
            this.model.customWatermarkPicture = undefined;
            this.validLogo = false;
          } else {
            throw error;
          }
        });
    }
  }

  public resetSlider(): void {
    if (this.activeModifier !== null) {
      // @ts-ignore it's ok
      this.model[this.activeModifier] = this.originalmodel[this.activeModifier];
    }
  }
}
