import { ActionTree } from 'vuex';
import { ProjectState } from './types';
import { RootState } from '../types';
import { projectService, userService } from '@/services';
import { Blur } from '@/models/blur';
import {
  TimelapseConfigurationUpdateRequest,
  ScenarioConfigurationCreateRequest,
  ScenarioConfigurationUpdateRequest,
} from '@/services/projects/projects.interfaces';
import { Scenario } from '@/models/scenario';
import { Camera } from '@/models/camera';
import { Video } from '@/models/video';
import { Project } from '@/models/project';
import { Logger } from 'aws-amplify';
const logger = new Logger('ProjectStore');

export const actions: ActionTree<ProjectState, RootState> = {
  async setProject({ state, commit, dispatch }, project: Project) {
    logger.info('setProject');
    if (state.project?.id !== project.id) {
      commit('setProject', project);
      const d1 = dispatch('loadTimelapse');
      const d2 = dispatch('loadScenario');
      const d3 = dispatch('loadProjectOwner');
      return Promise.all([d1, d2, d3]);
    } else {
      return Promise.resolve([]);
    }
  },
  async loadProjectOwner({ state, commit }) {
    logger.info('loadProjectOwner');
    if (state.project !== undefined) {
      userService.isUserPremium(state.project.owner.id).then((isPremium) => commit('setPremium', isPremium));
    }
  },
  async loadBlurProject(ignore: unknown, { projectId, cameraId }: { projectId: string; cameraId: string }) {
    logger.info('loadBlurProject');
    return await projectService.loadBlurRegion(projectId, cameraId);
  },
  async saveBlurProject(
    { dispatch },
    { projectId, cameraId, blurs }: { projectId: string; cameraId: string; blurs: Blur[] }
  ) {
    logger.info('saveBlurProject');
    return projectService
      .saveBlurRegion(projectId, cameraId, blurs)
      .then(() => dispatch('loadBlurProject', { projectId, cameraId }));
  },
  async loadTimelapse({ state, commit }) {
    logger.info('loadTimelapse');
    if (state.project !== undefined) {
      const currentProject = Object.assign({}, state.project);
      commit('loadingTimelapse');
      const currentCamera: Promise<Video | undefined>[] = currentProject.cameras.map((camera: Camera) =>
        projectService.getTimelapseConfiguration(currentProject.id, camera.id)
      );
      const previousCamera: Promise<Video | undefined>[] =
        currentProject.previousCameras?.map((camera: Camera) =>
          projectService.getTimelapseConfiguration(currentProject.id, camera.id)
        ) ?? [];
      const videos: (Video | undefined)[] = await Promise.all([...currentCamera, ...previousCamera]);
      commit(
        'timelapse',
        videos.filter((v) => v !== undefined)
      );
      return videos;
    } else {
      commit('unsetProject');
      return [];
    }
  },
  async loadScenario({ state, commit }) {
    logger.info('loadScenario', {
      camerasId: state.project?.camerasId,
      previousCamerasId: state.project?.previousCamerasId,
    });
    if (state.project !== undefined) {
      const currentProject = Object.assign({}, state.project);
      commit('loadingScenario');
      const currentCamera: Promise<Scenario[]>[] = currentProject.camerasId.map((cameraId) =>
        projectService.getScenarios(currentProject.id, cameraId)
      );
      const previousCamera: Promise<Scenario[]>[] =
        currentProject.previousCamerasId?.map((cameraId) => projectService.getScenarios(currentProject.id, cameraId)) ??
        [];
      const scenarios: Scenario[][] = await Promise.all([...currentCamera, ...previousCamera]);
      commit(
        'scenario',
        scenarios.flat().filter((s) => s !== undefined)
      );
      return scenarios;
    } else {
      commit('unsetProject');
      return [];
    }
  },
  async saveTimelapseConfiguration({ commit }, config: TimelapseConfigurationUpdateRequest) {
    logger.info('saveTimelapseConfiguration');
    return projectService
      .updateTimelapseConfiguration(config.projectId, config.cameraId, config.timelapseConfiguration)
      .then((video) => {
        commit('updateTimelapse', video);
        return video;
      });
  },
  async createScenario({ commit }, config: ScenarioConfigurationCreateRequest) {
    logger.info('createScenario');
    return projectService
      .scenarioCreateNew(config.projectId, config.cameraId, config.timelapseConfiguration, config.name)
      .then((scenario) => {
        commit('updateScenario', scenario);
        return scenario;
      });
  },
  async updateScenario({ commit }, config: ScenarioConfigurationUpdateRequest) {
    logger.info('updateScenario');
    return projectService
      .scenarioUpdate(config.scenarioId, config.timelapseConfiguration, config.name)
      .then((scenario) => {
        commit('updateScenario', scenario);
        return scenario;
      });
  },
  async deleteScenario({ commit }, scenario: Scenario) {
    logger.info('deleteScenario', { scenario });
    return projectService.scenarioDelete(scenario.id).then(() => {
      logger.info('deleteScenario done');
      commit('removeScenario', scenario);
    });
  },
};
