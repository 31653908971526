import { Component, Vue, Prop } from 'vue-property-decorator';
import { Days } from '../../models/enums';

@Component({
  components: {},
})
export default class InputDays extends Vue {
  @Prop({ required: true, type: Array }) private readonly value!: Days[];
  @Prop({ required: true, type: Boolean, default: false }) private readonly different!: boolean;
  @Prop() private readonly desired!: string;
  @Prop() private readonly reported!: string;
  // private days: Days[] = [];
  private get days(): Days[] {
    return this.value;
  }
  private set days(val: Days[]) {
    this.$emit('input', val);
  }

  public get diff(): boolean[] {
    const array = [];
    for (let i = 0; i < this.desired.length; i++) {
      array[i] = this.desired[i] !== this.reported[i];
    }
    return array;
  }
  /*
  @Watch('days')
  public updateValue(): void {
    this.$emit('input', this.days);
  }

  public mounted(): void {
    this.days = this.value;
  }
  */
}
