import { MutationTree } from 'vuex';
import { ProjectsState } from './types';
import { Project } from '@/models/project';

export const mutations: MutationTree<ProjectsState> = {
  loadingProject(state: ProjectsState) {
    state.loadingProject = true;
  },
  projects(state: ProjectsState, projects: Project[]) {
    state.projects = projects;
    state.loadingProject = false;
  },
};
