import { Component, Vue, Prop, Watch, Ref } from 'vue-property-decorator';
import { Camera } from '@/models/camera';
import { Project } from '@/models/project';
import { Scenario } from '@/models/scenario';
import ScenarioModal from '@/components/scenariomodal/scenariomodal.component.vue';
import { State, Action } from 'vuex-class';
import ScenarioPlayer from '@/components/scenarioplayer/scenarioplayer.component.vue';
import PremiumOverlay from '../premiumoverlay/premiumoverlay.component.vue';
import { Storage } from 'aws-amplify';
import { Logger } from 'aws-amplify';
import Shepherd from 'shepherd.js';
import 'shepherd.js/dist/css/shepherd.css';
import { offset } from '@floating-ui/dom';
const logger = new Logger('Scenarii');

@Component({
  components: {
    ScenarioModal,
    ScenarioPlayer,
    PremiumOverlay,
  },
})
export default class Scenarii extends Vue {
  @Prop({ type: Object, required: true }) private readonly camera!: Camera;
  @Prop({ type: Object, required: true }) private readonly project!: Project;
  @Prop({ required: true }) readonly isPremium!: boolean;
  @Prop({ required: true }) readonly isGuest!: boolean;

  @Ref('scenario-modal') private readonly scenarioModal!: ScenarioModal;

  @State('scenario', { namespace: 'project' }) private readonly scenario!: Scenario[];

  @Action('deleteScenario', { namespace: 'project' }) private readonly deleteScenario!: (s: Scenario) => Promise<void>;

  private visible = true;
  private visiblemonthly = false;
  private visibleweekly = false;
  private visiblesemestrial = false;
  private visiblequarter = false;
  private visibleother = true;
  private visiblePeriodique = true;

  private visiblemenu = {
    monthly: false,
    weekly: false,
    semestrial: false,
    quarter: false,
  };

  private currentScene: Scenario | null = null;

  public onCreateScene(): void {
    logger.info('onCreateScene');
    this.currentScene = null;
    this.$nextTick(() => {
      // @ts-ignore no docs
      this.scenarioModal.show();
    });
  }

  private get scenarii() {
    console.log('All scenarios:', this.scenario);
    const filteredScenarios = this.scenario.filter((scenario) => scenario.video.cameraId === this.camera.id);
    console.log('Filtered scenarios:', filteredScenarios);
    return filteredScenarios;
  }

  getScenarioCategories() {
    const filteredScenarios = this.scenario.filter((scenario) => scenario.video.cameraId === this.camera.id);

    const monthlyScenario: Scenario[] = [];
    const weeklyScenario: Scenario[] = [];
    const semestrialScenario: Scenario[] = [];
    const quarterScenario: Scenario[] = [];
    const otherScenario: Scenario[] = [];

    filteredScenarios.forEach((scenario) => {
      if (scenario.name && scenario.name.match(/\d{4}-\d{2}-[A-Za-z]+/)) {
        monthlyScenario.push(scenario);
      } else if (scenario.name && scenario.name.includes('Semaine du')) {
        weeklyScenario.push(scenario);
      } else if (scenario.name && scenario.name.includes('Trimestre')) {
        quarterScenario.push(scenario);
      } else if (scenario.name && scenario.name.includes('Semestre')) {
        semestrialScenario.push(scenario);
      } else {
        otherScenario.push(scenario); // Scenarios that do not match any specific rule
      }
    });

    // Sorting monthly scenarios
    monthlyScenario.sort((a, b) => {
      const dateA = a.name.match(/\d{4}-\d{2}/);
      const dateB = b.name.match(/\d{4}-\d{2}/);
      return dateA ? dateA[0].localeCompare(dateB ? dateB[0] : '') : 0;
    });

    // Sorting weekly scenarios
    weeklyScenario.sort((a, b) => {
      const dateAString = a.name.match(/Semaine du (\d{2}-\d{2}-\d{4})/);
      const dateBString = b.name.match(/Semaine du (\d{2}-\d{2}-\d{4})/);

      // Extract the date parts
      const [dayA, monthA, yearA] = dateAString ? dateAString[1].split('-').map(Number) : [0, 0, 0];
      const [dayB, monthB, yearB] = dateBString ? dateBString[1].split('-').map(Number) : [0, 0, 0];

      // Create Date objects
      const dateA = new Date(yearA, monthA - 1, dayA);
      const dateB = new Date(yearB, monthB - 1, dayB);

      // Compare the Date objects
      return dateA.getTime() - dateB.getTime();
    });

    semestrialScenario.sort((a, b) => {
      const pattern = /(\d{4}) - (\d+)(?:er|ème) Semestre/;
      const matchA = a.name.match(pattern);
      const matchB = b.name.match(pattern);

      // Extract the year and semester parts
      const yearA = matchA ? parseInt(matchA[1], 10) : 0;
      const semesterA = matchA ? parseInt(matchA[2], 10) : 0;
      const yearB = matchB ? parseInt(matchB[1], 10) : 0;
      const semesterB = matchB ? parseInt(matchB[2], 10) : 0;

      // Compare the years and semesters
      return yearA !== yearB ? yearA - yearB : semesterA - semesterB;
    });

    quarterScenario.sort((a, b) => {
      const pattern = /(\d{4}) - (\d+)(?:er|ème) Trimestre/;
      const matchA = a.name.match(pattern);
      const matchB = b.name.match(pattern);

      // Extract the year and semester parts
      const yearA = matchA ? parseInt(matchA[1], 10) : 0;
      const semesterA = matchA ? parseInt(matchA[2], 10) : 0;
      const yearB = matchB ? parseInt(matchB[1], 10) : 0;
      const semesterB = matchB ? parseInt(matchB[2], 10) : 0;

      // Compare the years and semesters
      return yearA !== yearB ? yearA - yearB : semesterA - semesterB;
    });

    return {
      monthly: monthlyScenario,
      weekly: weeklyScenario,
      quarter: quarterScenario,
      semestrial: semestrialScenario,
      other: otherScenario,
    };
  }

  getReadyVideoCount(scenarios: Scenario[]): number {
    return scenarios.filter((scenario) => scenario.video && scenario.video.key).length;
  }

  private get computedReadyVideo(): number {
    return this.scenarii ? this.scenarii.filter((scene) => scene.video.key).length : 0;
  }

  private async onDeleteScene(scenario: Scenario) {
    const titleMessage = this.$t('global.confirm').toString();
    const deleteMessage = this.$t('global.delete').toString();
    const cancelMessage = this.$t('global.close').toString();
    return this.$bvModal
      .msgBoxConfirm(this.$t('scenarii.modaldeletesceneheadertitle').toString(), {
        title: titleMessage,
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: deleteMessage,
        cancelTitle: cancelMessage,
        headerClass: 'text-danger',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      })
      .then((value) => {
        if (value) {
          this.deleteScenario(scenario);
        }
      });
  }

  private onSettingsScene(scenario: Scenario): void {
    if (this.isGuest === false) {
      this.currentScene = scenario;
      this.$nextTick(() => {
        // @ts-ignore no docs
        this.scenarioModal.show();
      });
    }
  }

  private onPlayScene(scenario: Scenario): void {
    this.currentScene = scenario;
  }

  private onVideoDownload(key: string) {
    Storage.get(key, { download: true }).then((response) => {
      // @ts-ignore no docs
      saveAs(new Blob([response.Body], { type: 'video/mp4' }), 'video');
    });
  }

  public onMonthlyScenarioCreated(): void {
    // Logique pour traiter le scénario mensuel créé
    console.log('Scénario mensuel créé');
  }

  public onWeeklyScenarioCreated(): void {
    // Logique pour traiter le scénario hebdomadaire créé
    console.log('Scénario hebdomadaire créé');
  }

  public onQuarterScenarioCreated(): void {
    // Logique pour traiter le scénario trimestriel créé
    console.log('Scénario trimestriel créé');
  }

  getTotalReadyCount(): number {
    // Placeholder logic - replace with actual methods or variables if different
    return (
      this.getReadyVideoCount(this.getScenarioCategories().weekly) +
      this.getReadyVideoCount(this.getScenarioCategories().monthly) +
      this.getReadyVideoCount(this.getScenarioCategories().quarter)
    );
  }

  getTotalScenarioCount(): number {
    // Placeholder logic - replace with actual methods or variables if different
    return (
      this.getScenarioCategories().weekly.length +
      this.getScenarioCategories().monthly.length +
      this.getScenarioCategories().quarter.length
    );
  }

  public handleClickOnSection(type: string): void {
    let scenarios = [];
    switch (type) {
      case 'weekly':
        scenarios = this.getScenarioCategories().weekly;
        break;
      case 'monthly':
        scenarios = this.getScenarioCategories().monthly;
        break;
      case 'quarterly':
        scenarios = this.getScenarioCategories().quarter;
        break;
      case 'ponctuel':
        scenarios = this.getScenarioCategories().other;
        break;
    }
    if (!scenarios.length) {
      this.startTour();
    }
  }

  public startTour(): void {
    const tour = new Shepherd.Tour({
      defaultStepOptions: {
        cancelIcon: {
          enabled: true,
        },
        classes: 'shepherd-content', // Ajoutez votre propre classe CSS
        scrollTo: true,
        when: {
          show() {
            const currentStep = Shepherd.activeTour?.getCurrentStep();
            if (!currentStep) return;
            const currentStepElement = currentStep.getElement();
            if (!currentStepElement) return;
            const header = currentStepElement.querySelector('.shepherd-footer');
            if (!header) return;
            const progress = document.createElement('div');
            const innerBar = document.createElement('span');
            const progressPercentage = ((tour.steps.indexOf(currentStep) + 1) / tour.steps.length) * 100 + '%';
            progress.className = 'shepherd-progress-bar';
            innerBar.style.width = progressPercentage;
            if (document.getElementsByClassName('shepherd-button').length == 1) {
              progress.style.minWidth = '260px';
            }
            progress.appendChild(innerBar);
            const headerh = currentStepElement.querySelector('.shepherd-header');
            const progressh = document.createElement('span');
            progressh.style.marginRight = '0px';
            progressh.innerText = `${tour.steps.indexOf(currentStep) + 1}/${tour.steps.length}`;
            if (headerh) {
              headerh.insertBefore(progressh, currentStepElement.querySelector('.shepherd-cancel-icon'));
            }
            header.insertBefore(progress, currentStepElement.querySelector('.shepherd-button'));
          },
        },
      },
      useModalOverlay: true,
      keyboardNavigation: false,
    });

    tour.addStep({
      title: this.$t('plateformcentral.step15title').toString(),
      text: this.$t('plateformcentral.step15text').toString(),
      attachTo: {
        element: '.newbuton', // La classe CSS d  e l'élément que vous voulez souligner
        on: 'right',
      },
      buttons: [
        {
          action: () => {
            localStorage.setItem('tourIgnored', 'true');
            tour.complete();
          },
          text: this.$t('projectlist.donotshow').toString(),
          classes: 'shepherd-button-ignore',
        },
        {
          action: () => {
            const newButton = document.querySelector('.newbuton') as HTMLElement;
            if (newButton) {
              newButton.click();
            }
          },
          text: this.$t('projectlist.next').toString(),
          classes: 'shepherd-button-next',
        },
      ],
      advanceOn: { selector: '.newbuton', event: 'click' },
      floatingUIOptions: {
        middleware: [offset({ mainAxis: 15, crossAxis: 0 })],
      },
      scrollTo: false,
    });
    tour.start();
    const newButton = document.querySelector('.newbuton') as HTMLElement;

    if (newButton) {
      newButton.addEventListener('click', () => {
        tour.complete();
      });
    }
  }
}
