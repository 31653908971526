import { API } from 'aws-amplify';
import {
  GetTimelapseConfigurationResult,
  GetProjectResult,
  ProjectCreateRequest,
  ProjectUpdateRequest,
  GetScenariosResult,
  ScenarioConfigurationCreateRequest,
  ScenarioConfigurationCreateResult,
  ScenarioConfigurationUpdateRequest,
} from './projects.interfaces';
import { Project, serializer as projectSerializer } from '../../models/project';
import { Blur, serializer as blurSerializer } from '../../models/blur';
import { Video, serializer as videoSerializer } from '../../models/video';
import { TimelapseConfiguration } from '../../models/timelapseconfiguration';
import { Scenario, serializer as scenarioSerializer } from '../../models/scenario';

export class ProjectService {
  public constructor(private readonly apiName: string) {}

  public async getMyProject(): Promise<Project[]> {
    return API.get(this.apiName, '/projects/', {}).then((response: GetProjectResult) => {
      return projectSerializer.parseAsArray(response.projects);
    });
  }

  public async createNewProject(project: ProjectCreateRequest): Promise<Project[]> {
    return API.post(this.apiName, '/projects/new', {
      body: project,
    }).then((response: GetProjectResult) => {
      return projectSerializer.parseAsArray(response.projects);
    });
  }

  public async updateProject(projectUpdateRequest: ProjectUpdateRequest): Promise<Project[]> {
    return API.post(this.apiName, '/projects/update', {
      body: projectUpdateRequest,
    }).then((response: GetProjectResult) => {
      return projectSerializer.parseAsArray(response.projects);
    });
  }

  public async deleteProject(project: Project): Promise<void> {
    return API.del(this.apiName, `/projects/${project.id}`, {});
  }

  public async loadBlurRegion(projectId: string, cameraId: string): Promise<Blur[]> {
    return API.get(this.apiName, `/projects/${projectId}/${cameraId}/blur`, {}).then((v) =>
      blurSerializer.parseAsArray(v)
    );
  }

  public async saveBlurRegion(projectId: string, cameraId: string, blurs: Blur[]): Promise<void> {
    return API.post(this.apiName, `/projects/${projectId}/${cameraId}/blur`, {
      body: { blurs },
    });
  }

  public async getTimelapseConfiguration(projectId: string, cameraId: string): Promise<Video | undefined> {
    return API.get(this.apiName, `/timelapse/${projectId}/${cameraId}`, {}).then(
      (response: GetTimelapseConfigurationResult) => {
        return videoSerializer.parse(response.video);
      }
    );
  }

  public async updateTimelapseConfiguration(
    projectId: string,
    cameraId: string,
    timelapseConfiguration: TimelapseConfiguration
  ): Promise<Video | undefined> {
    return API.post(this.apiName, `/timelapse/${projectId}/${cameraId}`, {
      body: timelapseConfiguration,
    }).then((response: GetTimelapseConfigurationResult) => {
      return videoSerializer.parse(response.video);
    });
  }

  public async getScenarios(projectId: string, cameraId: string): Promise<Scenario[]> {
    return API.get(this.apiName, `/scenario/search?projectId=${projectId}&cameraId=${cameraId}`, {}).then(
      (response: GetScenariosResult) => {
        return scenarioSerializer.parseAsArray(response.scenarios);
      }
    );
  }

  public async scenarioCreateNew(
    projectId: string,
    cameraId: string,
    timelapseConfiguration: TimelapseConfiguration,
    name: string
  ): Promise<Scenario | undefined> {
    const body: ScenarioConfigurationCreateRequest = { timelapseConfiguration, projectId, cameraId, name };
    return API.post(this.apiName, `/scenario/create`, {
      body,
    }).then((response: ScenarioConfigurationCreateResult) => {
      return scenarioSerializer.parse(response.scenario);
    });
  }

  public async scenarioUpdate(
    scenarioId: string,
    timelapseConfiguration: TimelapseConfiguration,
    name: string
  ): Promise<Scenario | undefined> {
    const body: ScenarioConfigurationUpdateRequest = { timelapseConfiguration, name, scenarioId };
    return API.post(this.apiName, `/scenario/${scenarioId}`, {
      body,
    }).then((response: ScenarioConfigurationCreateResult) => {
      return scenarioSerializer.parse(response.scenario);
    });
  }

  public async scenarioDelete(scenarioId: string): Promise<void> {
    return API.post(this.apiName, `/scenario/${scenarioId}/delete`, {});
  }
}
