import { Component, Vue, Prop, Ref } from 'vue-property-decorator';
import CameraModal from '@/components/cameramodal/cameramodal.component.vue';
import CameraModalCreate from '@/components/cameramodalcreate/cameramodalcreate.component.vue';
import { Camera } from '@/models/camera';
import { Project } from '@/models/project';
import { User } from '@/models/user';
import ProjectModal from '@/components/projectmodal/projectmodal.component.vue';
import { State } from 'vuex-class';
import { Storage } from 'aws-amplify';

@Component({
  components: {
    ProjectModal,
    CameraModal,
    CameraModalCreate,
  },
})
export default class ProjectCard extends Vue {
  @Prop({ required: true }) readonly isPremium!: boolean;

  public lastPhotos: {
    url: string;
    rotation: number;
    createdAt: Date;
    ratio?: string;
    zoom?: number;
    apiFetchInterval?: number;
    id: string;
  }[] = [];
  public currentPhotoIndex = 0;
  public nextPhotoIndex = (this.currentPhotoIndex + 1) % this.lastPhotos.length;
  public overlayshow = false;
  public isTransitioning = false;
  public imageInterval: number | null = null;
  public camera: Camera | null = null;
  public selectedPhotoIndex = -1;
  public currentPhotoId = '';

  @Prop({ type: Object, required: true }) private readonly project!: Project;
  @State('user', { namespace: 'profile' }) private user!: User;

  public nextImage(): void {
    this.currentPhotoIndex = (this.currentPhotoIndex + 1) % this.lastPhotos.length;
    this.currentPhotoId = this.lastPhotos[this.currentPhotoIndex].id;
    this.nextPhotoIndex = (this.currentPhotoIndex + 1) % this.lastPhotos.length;
    this.camera =
      this.project.cameras.find((camera) => camera.id === this.lastPhotos[this.currentPhotoIndex].id) || null;

    // Arrêter l'intervalle
    if (this.imageInterval !== null) {
      window.clearInterval(this.imageInterval);
      this.imageInterval = null;
    }

    // Démarrer l'intervalle à nouveau
    this.imageInterval = window.setInterval(() => {
      this.nextImage();
    }, 4000);
  }

  public prevImage(): void {
    this.currentPhotoIndex = (this.currentPhotoIndex - 1 + this.lastPhotos.length) % this.lastPhotos.length;
    this.currentPhotoId = this.lastPhotos[this.currentPhotoIndex].id;
    this.nextPhotoIndex = (this.currentPhotoIndex + 1) % this.lastPhotos.length;
    this.camera =
      this.project.cameras.find((camera) => camera.id === this.lastPhotos[this.currentPhotoIndex].id) || null;

    // Arrêter l'intervalle
    if (this.imageInterval !== null) {
      window.clearInterval(this.imageInterval);
      this.imageInterval = null;
    }

    // Démarrer l'intervalle à nouveau
    this.imageInterval = window.setInterval(() => {
      this.prevImage();
    }, 4000);
  }

  public isMoreThan48Hours(photo: { createdAt: Date; apiFetchInterval?: number }): boolean {
    const diffTime = Math.abs(new Date().getTime() - new Date(photo.createdAt).getTime());
    const diffHours = Math.ceil(diffTime / (1000 * 60 * 60));
    if (diffHours >= 48) {
      return photo.apiFetchInterval === 60; // Si le diffHours est supérieur ou égal à 48, alors vérifier que apiFetchInterval est 60
    }
    return false; // Si le diffHours est inférieur à 48, alors retourner false
  }

  public isDifferentFetchIntervalAndMoreThan48Hours(photo: { createdAt: Date; apiFetchInterval?: number }): boolean {
    const diffTime = Math.abs(new Date().getTime() - new Date(photo.createdAt).getTime());
    const diffHours = Math.ceil(diffTime / (1000 * 60 * 60));
    return diffHours >= 48 && photo.apiFetchInterval !== 60;
  }

  public handleClick(event: MouseEvent): void {
    const elementHeight = (event.target as Element).clientHeight;
    const clickPosition = event.clientY - (event.target as Element).getBoundingClientRect().top;

    if (clickPosition < elementHeight - 40) {
      const routeData = this.$router.resolve({
        name: 'plateformcentral',
        params: { id: this.project.id, cameraId: this.lastPhotos[this.currentPhotoIndex].id },
      });
      window.open(routeData.href, '_blank');
    }
    // Arrêtez l'intervalle lorsque la modale est ouverte
    if (this.imageInterval !== null) {
      window.clearInterval(this.imageInterval);
      this.imageInterval = null;
    }
  }

  public handleClickOnDate(): void {
    if (this.imageInterval !== null) {
      window.clearInterval(this.imageInterval);
      this.imageInterval = null;
    }
  
    const modal = this.$refs['camera-modal-' + this.currentPhotoId] as Vue & { show: () => void };
    modal.show();
  }

  public startInterval(): void {
    // Démarrer l'intervalle lorsque la modale est fermée
    if (this.imageInterval === null) {
      this.imageInterval = window.setInterval(() => {
        this.nextImage();
      }, 4000);
    }
  }

  public mounted(): void {
    const allCameras = [...(this.project.cameras || []), ...(this.project.previousCameras || [])];

    if (allCameras.length > 0) {
      const promises = allCameras.map(camera => {
        // Cette logique assume que lastPhoto peut exister sur les caméras actuelles et précédentes
        if (camera.lastPhoto) {
          const modifiedKey = camera.lastPhoto.smallKey.replace('-small', '-medium');
          return Storage.get(modifiedKey).then((url: any) => {
            this.lastPhotos.push({
              url: url as string,
              id: camera.id,
              rotation: camera.rotate || 0,
              createdAt: camera.lastPhoto?.createdAt ?? new Date(),
              ratio: '',
              zoom: 1,
              apiFetchInterval: camera.apiFetchInterval || 0,
            });
          });
        }
        return Promise.resolve();
      });

      Promise.all(promises).then(() => {
        if (this.lastPhotos.length > 1) {
          this.nextPhotoIndex = (this.currentPhotoIndex + 1) % this.lastPhotos.length;
        }

        // Définir le ratio de l'image et le niveau de zoom
        this.lastPhotos.forEach((photo) => {
          const img = new Image();
          img.onload = () => {
            photo.ratio = `${img.width}:${img.height}`;
            if (photo.ratio === '1920:691') {
              photo.zoom = 1.4;
            }
          };
          img.src = photo.url;
        });
        if (this.project.cameras.length > 0) {
          this.camera = this.project.cameras[0];
        }
      });
    }

    // Initialiser l'intervalle d'images
    this.imageInterval = window.setInterval(() => {
      this.nextImage();
    }, 4000); // 5000 millisecondes = 5 secondes
  }

  public beforeDestroy(): void {
    // Assurez-vous de nettoyer l'intervalle lorsque le composant est détruit
    if (this.imageInterval !== null) {
      window.clearInterval(this.imageInterval);
    }
  }

  public get currentPhoto(): string {
    return this.lastPhotos[this.currentPhotoIndex]?.url || '';
  }

  public get currentPhotoRotation(): number {
    return this.lastPhotos[this.currentPhotoIndex]?.rotation || 0;
  }

  public get nextPhoto(): string {
    return this.lastPhotos[this.nextPhotoIndex]?.url || '';
  }

  public get nextPhotoRotation(): number {
    return this.lastPhotos[this.nextPhotoIndex]?.rotation || 0;
  }

  public get isAdmin(): boolean {
    return (
      this.user !== undefined &&
      (this.project.owner.id === this.user.id || this.project.administratorId.includes(this.user.id))
    );
  }
}
