import 'reflect-metadata';
import { jsonObject, jsonMember, toJson, TypedJSON } from 'typedjson';

@jsonObject
@toJson
export class Photo {
  @jsonMember public id!: string;
  @jsonMember public createdAt!: Date;
  @jsonMember public weekday!: number;
  @jsonMember public hour!: string;
  @jsonMember public key!: string;
  @jsonMember public cameraId!: string;
  @jsonMember public projectId!: string;
  @jsonMember public exposure?: number;
  @jsonMember public hdr?: boolean;
  @jsonMember public blueLevel?: number;
  @jsonMember public gainControl?: number;
  @jsonMember public sharpness?: number;
  // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  @jsonMember public isSlowmotion: boolean = false;
  public isSelected = false;

  public get smallKey(): string {
    return `${this.key}-small`;
  }

  public get mediumKey(): string {
    return `${this.key}-medium`;
  }

  public get thumbnailKey(): string | undefined {
    return this.isSlowmotion ? `${this.key}-thumbnail` : undefined;
  }
}

export const serializer = new TypedJSON(Photo);

// eslint-disable-next-line max-classes-per-file
@jsonObject
@toJson
export class IframePhoto
  implements Pick<Photo, 'id' | 'createdAt' | 'key' | 'isSlowmotion' | 'smallKey' | 'mediumKey' | 'thumbnailKey'>
{
  @jsonMember
  public id!: string;
  @jsonMember
  public createdAt!: Date;
  @jsonMember
  public key!: string;
  @jsonMember
  public isSlowmotion!: boolean;

  public get smallKey(): string {
    return `${this.key}-small`;
  }

  public get mediumKey(): string {
    return `${this.key}-medium`;
  }

  public get thumbnailKey(): string | undefined {
    return this.isSlowmotion ? `${this.key}-thumbnail` : undefined;
  }
}

export const iframePhotoSerializer = new TypedJSON(IframePhoto);

// eslint-disable-next-line max-classes-per-file
@jsonObject
@toJson
export class PublicPhoto {
  @jsonMember id!: string;
  @jsonMember createdAt!: Date;
  @jsonMember photoId!: string;
  @jsonMember key!: string;
  @jsonMember putUrl?: string;
}
