import { GetterTree } from 'vuex';
import { PhotoState } from './types';
import { RootState } from '../types';

export const getters: GetterTree<PhotoState, RootState> = {
  onlyPhoto: (state) => {
    return state.photos.filter((p) => p.isSlowmotion === undefined || p.isSlowmotion === false);
  },
  onlySlowmotion: (state) => {
    return state.photos.filter((p) => p.isSlowmotion);
  },
};
