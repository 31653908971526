import { ActionTree } from 'vuex';
import { PhotoState } from './types';
import { RootState } from '../types';
import { Photo } from '@/models/photo';
import { photoService } from '@/services/';
import { DeletePhotoRequest, ImportPhotosRequest } from '@/services/photos/photos.interfaces';
import { Logger } from 'aws-amplify';
const logger = new Logger('PhotoStore');

function* chunks<T>(arr: T[], n: number) {
  for (let i = 0; i < arr.length; i += n) {
    yield arr.slice(i, i + n);
  }
}

export const actions: ActionTree<PhotoState, RootState> = {
  async findAllFor({ commit }, { projectId, cameraId }) {
    logger.info('action findAllFor', { projectId, cameraId });
    commit('loading');
    return photoService
      .findAllFor(projectId, cameraId)
      .then((photos: Photo[]) => {
        commit('photos', { photos, projectId, cameraId });
        return photos;
      })
      .catch((error) => {
        logger.warn(error);
        commit('notLoading');
      });
  },
  async deletePhotos({ state, commit }, deletePhotoRequest: DeletePhotoRequest): Promise<void> {
    logger.info('action deletePhotos', { deletePhotoRequest });
    if (!state.isLoading && state.projectId === deletePhotoRequest.projectId) {
      commit('loading');

      const deleteRequest = [...chunks(deletePhotoRequest.photoIds, 500)];
      return Promise.all(
        deleteRequest.map((ids) => {
          return photoService.deletePhoto(deletePhotoRequest.projectId, ids);
        })
      )
        .then(() => {
          return photoService.findAllFor(deletePhotoRequest.projectId, deletePhotoRequest.cameraId);
        })
        .then((photos: Photo[]) => {
          commit('photos', { photos, projectId: deletePhotoRequest.projectId });
        })
        .catch((error) => {
          logger.warn(error);
          commit('notLoading');
        });
    }
  },
  async importPhotos({ state }, importPhotosRequest: ImportPhotosRequest) {
    logger.info('action importPhotos', { ...importPhotosRequest, stateProjectId: state.projectId });
    if (!state.isLoading && state.projectId === importPhotosRequest.targetProject) {
      photoService.importPhotos(importPhotosRequest);
    }
  },
};
