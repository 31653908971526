import { Component, Vue, Watch } from 'vue-property-decorator';
import { Auth } from 'aws-amplify';
import { Validations } from 'vuelidate-property-decorators';
import { required, email, minLength } from 'vuelidate/lib/validators';
import { Logger } from 'aws-amplify';
const logger = new Logger('ForgotPassword');

@Component
export default class ForgotPassword extends Vue {
  private waitingCode = false;
  private doneReset = false;

  private form = {
    email: '',
    code: '',
    password: '',
    error: '',
  };

  @Validations()
  private validations() {
    if (this.waitingCode) {
      return {
        form: {
          email: { required, email, notExist: this.notExist },
          code: { required, minLength: minLength(6) },
          password: {
            required: required,
            minLength: minLength(8),
            containsUppercase: (value: string) => {
              return /[A-Z]/.test(value);
            },
            containsLowercase: (value: string) => {
              return /[a-z]/.test(value);
            },
            containsNumber: (value: string) => {
              return /[0-9]/.test(value);
            },
          },
        },
      };
    } else {
      return {
        form: {
          email: { required, email, notExist: this.notExist },
        },
      };
    }
  }

  public onSubmit(event: Event): boolean {
    event.preventDefault();
    if (this.waitingCode) {
      // Collect confirmation code and new password, then
      Auth.forgotPasswordSubmit(this.form.email, this.form.code, this.form.password)
        .then(() => {
          this.doneReset = true;
        })
        .catch((err) => logger.info({ err }));
    } else {
      Auth.forgotPassword(this.form.email)
        .then((data) => {
          logger.info({ data });
          this.waitingCode = true;
        })
        .catch((err) => {
          if (err.code === 'UserNotFoundException') {
            logger.warn('User not found');
            this.form.error = 'userNotFoundException';
          } else {
            logger.error({ err });
          }
        });
    }
    return false;
  }

  public onCancel(event: Event): boolean {
    event.preventDefault();
    this.$emit('cancel');
    return false;
  }

  @Watch('form.email')
  private onEmailChange() {
    this.form.error = '';
  }

  private notExist() {
    return this.form.error === '';
  }
}
