import 'core-js/stable';

import Vue from 'vue';
import Component from 'vue-class-component';
import Vuelidate from 'vuelidate';

// Register the router hooks with their names
Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteLeave',
  'beforeRouteUpdate', // for vue-router 2.2+
]);

import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';

import {
  CollapsePlugin,
  ButtonPlugin,
  NavbarPlugin,
  LinkPlugin,
  ListGroupPlugin,
  FormPlugin,
  ModalPlugin,
  FormInputPlugin,
  CardPlugin,
  OverlayPlugin,
  BreadcrumbPlugin,
  FormSelectPlugin,
  LayoutPlugin,
  FormGroupPlugin,
  TablePlugin,
  InputGroupPlugin,
  FormCheckboxPlugin,
  TabsPlugin,
  PopoverPlugin,
  TooltipPlugin,
  AlertPlugin,
  ProgressPlugin,
  FormTextareaPlugin,
} from 'bootstrap-vue';

import '@aws-amplify/ui-vue';
import Amplify from 'aws-amplify';
// @ts-ignore: no d.ts file
import VueZoomer from 'vue-zoomer';
// @ts-ignore: no d.ts file
import fullscreen from 'vue-fullscreen';
import VueFilterDateFormat from '@vuejs-community/vue-filter-date-format';
import VueFilterDateParse from '@vuejs-community/vue-filter-date-parse';
// @ts-ignore: no d.ts file
import VueNativeSock from 'vue-native-websocket';

import { apiConfiguration } from './services';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
// @ts-ignore: no d.ts file
import VueVirtualScroller from 'vue-virtual-scroller';
// @ts-ignore: no d.ts file
import VirtualCollection from 'vue-virtual-collection';
import 'video.js/dist/video-js.css';
import VueSocialSharing from 'vue-social-sharing';
// @ts-ignore: no d.ts file
import TrendChart from 'vue-trend-chart';
import VueClipboard from 'vue-clipboard2';
import axios from 'axios';
import VueAxios from 'vue-axios';

Vue.config.productionTip = false;
Amplify.Logger.LOG_LEVEL = process.env.VUE_APP_LOGGER_LEVEL || 'ERROR';

Vue.use(CollapsePlugin);
Vue.use(ButtonPlugin);
Vue.use(NavbarPlugin);
Vue.use(LinkPlugin);
Vue.use(FormPlugin);
Vue.use(ModalPlugin);
Vue.use(FormInputPlugin);
Vue.use(CardPlugin);
Vue.use(OverlayPlugin);
Vue.use(BreadcrumbPlugin);
Vue.use(FormSelectPlugin);
Vue.use(LayoutPlugin);
Vue.use(FormGroupPlugin);
Vue.use(TablePlugin);
Vue.use(InputGroupPlugin);
Vue.use(FormCheckboxPlugin);
Vue.use(TabsPlugin);
Vue.use(PopoverPlugin);
Vue.use(TooltipPlugin);
Vue.use(AlertPlugin);
Vue.use(ListGroupPlugin);
Vue.use(ProgressPlugin);
Vue.use(FormTextareaPlugin);

Vue.use(VueFilterDateFormat);
Vue.use(VueFilterDateParse);
Vue.use(VueZoomer);
Vue.use(fullscreen);
Vue.use(Vuelidate);
Vue.use(VueVirtualScroller);
Vue.use(VirtualCollection);
Vue.use(VueNativeSock, process.env.VUE_APP_WSS_ENDPOINT, {
  store,
  connectManually: true,
  format: 'json',
  reconnection: true, // (Boolean) whether to reconnect automatically (false)
  reconnectionAttempts: 5, // (Number) number of reconnection attempts before giving up (Infinity),
  reconnectionDelay: 3000, // (Number) how long to initially wait before attempting a new (1000)
});
// @ts-ignore VueSocialSharing declared as type
Vue.use(VueSocialSharing);
Vue.use(TrendChart);
Vue.use(VueAxios, axios);

Vue.use(VueClipboard);

Amplify.configure({
  API: apiConfiguration,
  Storage: {
    AWSS3: {
      bucket: process.env.VUE_APP_MAIN_STORE_BUCKET,
      region: process.env.VUE_APP_MAIN_STORE_REGION,
    },
  },
  Auth: {
    identityPoolId: process.env.VUE_APP_COGNITO_IDENTITY_POOL_ID,
    region: process.env.VUE_APP_COGNITO_REGION,
    userPoolId: process.env.VUE_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.VUE_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,
  },
});

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    Vue,
    dsn: 'https://a38e6cadcafe4de18772fcd8a8eaebee@o1037260.ingest.sentry.io/6005081',
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ['localhost', 'app.teye.io', /^\//],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.0,
  });
}

new Vue({
  i18n,
  store,
  router,
  render: (h) => h(App),
}).$mount('#app');
