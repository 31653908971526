import { ActionTree } from 'vuex';
import { CameraState } from './types';
import { RootState } from '../types';
import { cameraService } from '@/services';
import { Camera } from '@/models/camera';
import {
  CameraTikeeCreateRequest,
  CameraTikeeUpdateRequest,
  CameraUpdateRequest,
  CameraDeleteRequest,
} from '@/services/cameras/cameras.interfaces';
import { Shadow } from '@/models/shadow';
import { Logger } from 'aws-amplify';
const logger = new Logger('CameraStore');

export const actions: ActionTree<CameraState, RootState> = {
  async loadCamera({ commit }) {
    logger.info('loadCamera');
    commit('loadingCamera');
    const cameras: Camera[] = await cameraService.getMyCamera();
    commit('cameras', cameras);
    return cameras;
  },
  async createCameraTikee({ commit, dispatch }, cameraCreateRequest: CameraTikeeCreateRequest) {
    logger.info('createCameraTikee');
    commit('loadingCamera');
    await cameraService.createCameraTikee(cameraCreateRequest);
    return dispatch('loadCamera');
  },
  async updateCameraTikee({ commit, dispatch }, cameraTikeeUpdateRequest: CameraTikeeUpdateRequest) {
    logger.info('updateCameraTikee');
    commit('loadingCamera');
    await cameraService.updateCameraTikee(cameraTikeeUpdateRequest);
    return dispatch('loadCamera');
  },
  async importAllPhotoCameraTikee({ commit, dispatch }, cameraId: string) {
    logger.info('importAllPhotoCameraTikee');
    commit('loadingCamera');
    await cameraService.importAllPhotoCameraTikee(cameraId);
    return dispatch('loadCamera');
  },
  async updateCamera({ commit, dispatch }, cameraUpdateRequest: CameraUpdateRequest) {
    logger.info('updateCamera');
    commit('loadingCamera');
    await cameraService.updateCamera(cameraUpdateRequest);
    return dispatch('loadCamera');
  },
  async updateConfiguration({ dispatch, commit }, cameraUpdateRequest: Shadow) {
    logger.info('updateConfiguration');
    commit('loadingCamera');
    const shadow: Shadow = await cameraService.updateConfiguration(cameraUpdateRequest);
    await dispatch('loadCamera');
    return shadow;
  },
  async deleteCamera({ dispatch, commit }, cameraDeleteRequest: CameraDeleteRequest) {
    logger.info('deleteCamera');
    commit('loadingCamera');
    await cameraService.deleteCamera(cameraDeleteRequest);
    return dispatch('loadCamera');
  },
};
