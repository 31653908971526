import { render, staticRenderFns } from "./editsharemodal.component.html?vue&type=template&id=684b5833&scoped=true&"
import script from "./editsharemodal.component.ts?vue&type=script&lang=ts&"
export * from "./editsharemodal.component.ts?vue&type=script&lang=ts&"
import style0 from "./editsharemodal.component.scss?vue&type=style&index=0&id=684b5833&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "684b5833",
  null
  
)

export default component.exports