import { API } from 'aws-amplify';
import { City, serializer } from '../../models/city';
import { GetCitiesResult } from './cities.interfaces';

export class CityService {
  public constructor(private readonly apiName: string) {}

  public async searchCities(search: string): Promise<City[]> {
    return API.post(this.apiName, '/cities/search', {
      body: {
        search,
      },
    }).then((response: GetCitiesResult) => {
      return serializer.parseAsArray(response.cities);
    });
  }
}
