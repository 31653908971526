import { render, staticRenderFns } from "./guestoverlay.component.html?vue&type=template&id=7345f630&scoped=true&"
import script from "./guestoverlay.component.ts?vue&type=script&lang=ts&"
export * from "./guestoverlay.component.ts?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7345f630",
  null
  
)

export default component.exports