


















































































































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Getter, State, Action } from 'vuex-class';
import { Project } from '@/models/project';
import { Album, PhotoAlbum } from '@/models/album';
import S3Image from '../components/s3-image/S3Image.vue';
import { Validations } from 'vuelidate-property-decorators';
import { required } from 'vuelidate/lib/validators';
// @ts-ignore: no d.ts file
import { vTeleport } from '@desislavsd/vue-teleport';
import { photoService } from '@/services';
import { NotificationMessage, Socket } from '@/store/types';
import { Logger } from 'aws-amplify';
import { User } from '@/models/user';
const logger = new Logger('AlbumView');

@Component({
  components: {
    S3Image,
    vTeleport,
  },
})
export default class AlbumView extends Vue {
  @Prop(String) private id!: string;

  @State('projects', { namespace: 'projects' }) private projects!: Project[];
  @State('socket') private socketMessage!: Socket;
  @State('premium', { namespace: 'profile' }) private isPremium!: boolean;
  @State('locale', { namespace: 'profile' }) locale!: string;
  @State('user', { namespace: 'profile' }) private user!: User;

  @Action('loadOneAlbum', { namespace: 'album' }) private loadOneAlbum!: (albumId: string) => Promise<Album>;
  @Action('loadAlbum', { namespace: 'album' }) private loadAlbum!: (payload: {
    projectId: string;
    cameraId: string;
  }) => Promise<Album[]>;
  @Action('addPhoto', { namespace: 'album' }) private readonly addPhoto!: (payload: {
    albumId: string;
    photoId: string;
  }) => Promise<void>;
  @Action('removePhoto', { namespace: 'album' }) private readonly removePhoto!: (payload: {
    albumId: string;
    photoId: string;
  }) => Promise<void>;
  @Action('connectSocket') private connectSocket!: () => Promise<void>;
  @Action('disconnectSocket') private disconnectSocket!: () => Promise<void>;

  @Getter('albumWithId', { namespace: 'album' }) private albumWithId!: (id: string) => Album | undefined;
  @Getter('projectWithId', { namespace: 'projects' }) private projectWithId!: (id: string) => Project | undefined;
  @Getter('albumFiltered', { namespace: 'album' }) private readonly albumFiltered!: (id: string) => Album[];

  private album: Album | null = null;
  private project: Project | null = null;
  private selectedAlbum = '';

  private showAlertCantDownload = false;
  private zipGenerating = false;
  private zipLink: string[] | null = null;
  private get showModalZip(): boolean {
    return this.zipGenerating || this.zipLink !== null;
  }
  private set showModalZip(b: boolean) {
    if (b === false) {
      this.zipGenerating = false;
      this.zipLink = null;
    }
  }
  public get dateFormat(): string {
    return this.locale === 'en' ? 'MM / DD / YYYY - HH:mm' : 'DD / MM / YYYY - HH:mm';
  }

  public get guest(): boolean | undefined {
    return this.project?.guestId.includes(this.user.id);
  }

  @Validations()
  private validations = {
    selectedAlbum: { required },
  };

  public mounted(): void {
    this.loadDataAlbum();
  }

  public beforeDestroy(): void {
    logger.info('beforeDestroy');
    // called when the route that renders this component is about to
    // be navigated away from.
    // has access to `this` component instance.
    this.disconnectSocket();
  }

  public get breadcrumbs(): unknown[] {
    const items = [
      {
        text: this.$tc('global.project', 2),
        to: '/',
      },
    ];

    if (this.project) {
      items.push({
        text: this.project.name,
        to: '/project/' + this.project.id,
      });
    }
    if (this.album) {
      items.push({
        text: this.album.name,
        to: '#',
      });
    }
    return items;
  }

  public get selectedPhotos(): PhotoAlbum[] {
    if (this.album && this.album.photos) {
      return this.album.photos.filter((photo) => {
        return photo.isSelected;
      });
    }
    return [];
  }

  public get zoomedPhoto(): PhotoAlbum | null {
    if (this.zoomedPhotoIndex !== undefined && this.album) {
      return this.album.photos[this.zoomedPhotoIndex];
    } else {
      return null;
    }
  }

  public get zoomedPhotoIndex(): number | undefined {
    if (this.$route.query.zoom !== undefined) {
      return parseInt(this.$route.query.zoom as string, 10);
    } else {
      return undefined;
    }
  }

  public set zoomedPhotoIndex(photoIndex: number | undefined) {
    if (photoIndex === undefined) {
      this.$router.push({ path: this.$route.path, query: { ...this.$route.query, zoom: undefined } });
    } else if (this.$route.query !== { ...this.$route.query, zoom: photoIndex.toString() }) {
      this.$router.push({ path: this.$route.path, query: { ...this.$route.query, zoom: photoIndex.toString() } });
    }
  }

  public unzoom(): void {
    this.zoomedPhotoIndex = undefined;
  }

  public zoomPrevious(): void {
    if (this.zoomedPhotoIndex !== undefined && this.zoomedPhotoIndex >= 0) {
      this.zoomedPhotoIndex = this.zoomedPhotoIndex - 1;
    }
  }

  public zoomNext(): void {
    if (this.zoomedPhotoIndex !== undefined && this.album && this.zoomedPhotoIndex < this.album.photos.length - 1) {
      this.zoomedPhotoIndex = this.zoomedPhotoIndex + 1;
    }
  }

  private loadDataAlbum(): void {
    this.loadOneAlbum(this.id).then((album) => {
      logger.info('loadDataAlbum', { album });
      this.album = album;
      if (this.album) {
        this.project = this.projectWithId(this.album.projectId) || null;
      }
      this.loadAlbum({ projectId: this.album.projectId, cameraId: this.album.cameraId });
    });
  }
  private selectAll(): void {
    if (this.album && this.album.photos) {
      Vue.set(
        this.album,
        'photos',
        this.album.photos.map((photo) => {
          photo.isSelected = true;
          // logger.info({isSelected: photo.isSelected, id: photo.id})
          return photo;
        })
      );
    }
  }

  private unselectAll(): void {
    if (this.album && this.album.photos) {
      Vue.set(
        this.album,
        'photos',
        this.album.photos.map((photo) => {
          photo.isSelected = false;
          return photo;
        })
      );
    }
  }

  private onClickedPhoto(index: number): void {
    if (this.album && this.album.photos) {
      Vue.set(
        this.album,
        'photos',
        this.album.photos.map((photo, i) => {
          if (i === index) {
            if (photo.isSelected === true) {
              photo.isSelected = false;
            } else {
              photo.isSelected = true;
            }
          }
          return photo;
        })
      );
    }
  }

  private onDeletePhotos(): void {
    this.$bvModal
      .msgBoxConfirm(this.$tc('albumview.modaldeletephotobody', this.selectedPhotos.length), {
        title: 'Please Confirm',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      })
      .then(async (value: boolean | null) => {
        logger.info('', { value });
        if (value === true) {
          for (const photo of this.selectedPhotos) {
            await this.removePhoto({ albumId: this.id, photoId: photo.id });
          }
          if (this.selectedPhotos.length === this.album?.photos.length) {
            this.$router.push({ name: 'plateformcentral', params: { id: this.project?.id ?? '' } });
          } else {
            this.loadOneAlbum(this.id)
              .then((album) => {
                this.album = album;
              })
              .catch((error) => {
                logger.error('', error);
                this.$router.push({ name: 'plateformcentral', params: { id: this.project?.id ?? '' } });
              });
          }
        }
      })
      .catch((err) => {
        logger.error({ err });
      });
  }

  private async onSelectAlbum(albumSelected: string) {
    logger.info('onSelectAlbum ' + albumSelected);
    this.$v.selectedAlbum.$touch();
    if (!this.$v.selectedAlbum.$invalid) {
      for (const photo of this.selectedPhotos) {
        await this.addPhoto({ albumId: albumSelected, photoId: photo.id });
        await this.removePhoto({ albumId: this.id, photoId: photo.id });
      }
      if (this.selectedPhotos.length === this.album?.photos.length) {
        this.$router.push({ name: 'plateformcentral', params: { id: this.project?.id ?? '' } });
      } else {
        this.loadOneAlbum(this.id)
          .then((album) => {
            this.album = album;
            this.onClose();
            this.unselectAll();
            this.selectedAlbum = '';
          })
          .catch((error) => {
            logger.error('', error);
            this.$router.push({ name: 'plateformcentral', params: { id: this.id } });
          });
      }
    }
  }

  private onShow(): void {
    logger.info('onShow');
    // eslint-disable-next-line vue/custom-event-name-casing
    this.$root.$emit('bv::show::popover', 'popover-change-album');
  }

  private onClose(): void {
    this.$v.selectedAlbum.$reset();
    this.selectedAlbum = '';
    // eslint-disable-next-line vue/custom-event-name-casing
    this.$root.$emit('bv::hide::popover', 'popover-change-album');
  }

  private async download(): Promise<void> {
    if (this.album !== null) {
      this.zipLink = null;
      this.zipGenerating = true;
      await this.connectSocket();
      photoService.requestZipPhoto(
        this.album.projectId,
        this.selectedPhotos.map((photo) => photo.id)
      );
    }
  }

  @Watch('socketMessage.message')
  private onSocketMessageChange(val: NotificationMessage) {
    if (val && val.zip && val.zip === 'ready') {
      logger.info('onSocketMessageChange', { val });
      if (this.showModalZip) {
        this.zipGenerating = false;
        this.zipLink = (val.url as string).split('|');
      }
    }
  }

  @Watch('projects')
  private onStateProjectLoaded(val?: Project[], oldVal?: Project[]) {
    logger.info('onStateProjectLoaded', { val, oldVal });
    if (this.album !== null) {
      this.project = this.projectWithId(this.album.projectId) || null;
    }
  }
}
