



























































































import { Component, Vue, Watch } from 'vue-property-decorator';
// @ts-ignore no d.ts
import { vTeleportLocation } from '@desislavsd/vue-teleport';
import LocalChanger from '../localchanger/localchanger.component.vue';
import { Action, State } from 'vuex-class';
import { User } from '@/models/user';
import { Chrome } from 'vue-color';

@Component({
  components: {
    LocalChanger,
    vTeleportLocation,
    'color-picker': Chrome,
  },
})
export default class Navbar extends Vue {
  @State('user', { namespace: 'profile' }) readonly user!: User;
  @State('authorized', { namespace: 'profile' }) readonly authorized!: boolean;
  @State('premium', { namespace: 'profile' }) readonly premium!: boolean;

  @Action('logout', { namespace: 'profile' }) logout!: () => Promise<void>;

  colors = [
    { id: 0, color: '#001326' },
    { id: 1, color: '#001a34' },
    { id: 2, color: '#002142' },
    { id: 3, color: '#001c38' },
    { id: 4, color: '#5078a0' },
    { id: 5, color: '#5fc9e4' },
    { id: 6, color: '#4dffcd' },
    // ...reste des couleurs
  ];
  showColorPicker = false;
  selectedColor = { hex: '' };
  selectedSquareId: number | null = null;

  private openColorPicker(color: string, id: number): void {
    console.log('Square clicked! Opening color picker...');
    if (this.selectedSquareId === id) {
      // Si le même carré est sélectionné, fermez le color picker
      this.showColorPicker = false;
      this.selectedSquareId = null;
    } else {
      this.showColorPicker = false;
      this.selectedColor = { hex: this.colors[id].color };
      this.selectedSquareId = id;
      this.$nextTick(() => {
        this.showColorPicker = true;
      });
    }
  }

  get fullname(): string {
    if (this.user !== undefined) {
      return `${this.user.firstname} ${this.user.lastname ? this.user.lastname[0] : ''}.`;
    } else {
      return '';
    }
  }
  get maxData(): number {
    return this.premium ? 1024 : 100;
  }
  signOut(): Promise<void> {
    return this.logout();
  }

  mounted() {
    const savedColors = localStorage.getItem('colors');
    if (savedColors) {
      this.colors = JSON.parse(savedColors);
    }
  }

  public requestFullscreen(): void {
    let el: any = document.documentElement;

    if (el.requestFullscreen) {
      el.requestFullscreen();
    } else if (el['mozRequestFullScreen']) {
      // Firefox
      el['mozRequestFullScreen']();
    } else if (el['webkitRequestFullscreen']) {
      // Chrome, Safari and Opera
      el['webkitRequestFullscreen']();
    } else if (el['msRequestFullscreen']) {
      // IE/Edge
      el['msRequestFullscreen']();
    }
  }

  public exitFullscreen(): void {
    let doc: any = document;

    if (doc.exitFullscreen) {
      doc.exitFullscreen();
    } else if (doc['mozCancelFullScreen']) {
      // Firefox
      doc['mozCancelFullScreen']();
    } else if (doc['webkitExitFullscreen']) {
      // Chrome, Safari and Opera
      doc['webkitExitFullscreen']();
    } else if (doc['msExitFullscreen']) {
      // IE/Edge
      doc['msExitFullscreen']();
    }
  }

  public toggleFullscreen(): void {
    let doc: any = document;

    if (
      !doc.fullscreenElement &&
      !doc['mozFullScreenElement'] &&
      !doc['webkitFullscreenElement'] &&
      !doc['msFullscreenElement']
    ) {
      // if there are no elements in fullscreen mode, request fullscreen mode
      this.requestFullscreen();
    } else {
      // if there are elements in fullscreen mode, exit fullscreen mode
      this.exitFullscreen();
    }
  }

  @Watch('selectedColor')
  private onSelectedColorChange(newColor: { hex: string }) {
    if (this.selectedSquareId !== null) {
      this.colors[this.selectedSquareId].color = newColor.hex;
      // Ajoutez la logique pour modifier la variable CSS ici
      switch (this.selectedSquareId) {
        case 0: // changer le bleu-fonce
          document.documentElement.style.setProperty('--bleu-fonce', newColor.hex);
          break;
        case 1: // changer le bleu-gradient-mid
          document.documentElement.style.setProperty('--bleu-gradient-mid', newColor.hex);
          break;
        case 2: // changer le bleu-gradient-end
          document.documentElement.style.setProperty('--bleu-gradient-end', newColor.hex);
          break;
        case 3: // changer le bleu-clair
          document.documentElement.style.setProperty('--bleu-clair', newColor.hex);
          break;
        case 4: // changer le bleu-normal
          document.documentElement.style.setProperty('--bleu-normal', newColor.hex);
          break;
        case 5: // changer le bleu-light
          document.documentElement.style.setProperty('--bleu-light', newColor.hex);
          break;
        case 6: // changer le vert
          document.documentElement.style.setProperty('--vert', newColor.hex);
          break;
      }
    }
  }

  @Watch('colors')
  private saveColorsToLocalStorage() {
    localStorage.setItem('colors', JSON.stringify(this.colors));
  }
}
