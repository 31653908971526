import { Component, Vue, Prop, Ref, Watch } from 'vue-property-decorator';
import { State, Getter } from 'vuex-class';
import { Camera } from '@/models/camera';
import { Project } from '@/models/project';
import { Album } from '@/models/album';
import { Photo } from '@/models/photo';
// @ts-ignore no d.ts
import VirtualCollection from 'vue-virtual-collection';
import PremiumOverlay from '../premiumoverlay/premiumoverlay.component.vue';
import S3Image from '../s3-image/S3Image.vue';
import { Logger } from 'aws-amplify';
const logger = new Logger('AlbumComponent');

@Component({
  components: {
    S3Image,
    PremiumOverlay,
  },
})
export default class AlbumComponent extends Vue {
  @Ref('photos') public readonly photosRef!: HTMLDivElement;
  @Ref('scroller') public readonly scroller!: VirtualCollection;
  @Prop({ type: Object, required: true }) private readonly camera!: Camera;
  @Prop({ type: Object, required: true }) private readonly project!: Project;
  @Prop({ required: true }) readonly isPremium!: boolean;

  @State('loadingAlbum', { namespace: 'album' }) private readonly loadingAlbum!: boolean;
  @State('albums', { namespace: 'album' }) private readonly albums!: Album[];

  @State('photos', { namespace: 'photo' }) private photos!: Photo[];
  @Getter('onlySlowmotion', { namespace: 'photo' }) private slowMotions!: Photo[];

  private visible = false;

  private scrollerWidth = 0;
  private scrollerColumns = 0;
  private scrollerImageWidth = 0;
  private items: { data: Album }[] = [];

  public mounted(): void {
    if (this.albums.length > 0) {
      this.onAlbumsStoreChange(this.albums, []);
    }
    this.$nextTick(() => {
      window.addEventListener('resize', this.updatePhotosSize);
    });
  }

  public updatePhotosSize(): void {
    if (this.scroller) {
      const w = this.photosRef.clientWidth;
      this.scrollerColumns = Math.ceil(w / 150);
      this.scrollerWidth = w;
      this.scrollerImageWidth = (w - 10 - this.scrollerColumns * 5) / this.scrollerColumns;
      this.items = this.items.slice(0);
    }
  }

  public cellSizeAndPositionGetter(item: Photo, index: number): unknown {
    // compute size and position
    const w = this.scrollerImageWidth + 5;
    const h = this.scrollerImageWidth;
    return {
      width: w,
      height: h,
      x: (index % this.scrollerColumns) * w,
      y: Math.floor(index / this.scrollerColumns) * h,
    };
  }

  @Watch('albums')
  public onAlbumsStoreChange(val: Album[], oldVal: Album[]): void {
    logger.info('onAlbumsStoreChange', { val, oldVal });
    this.updateAlbum();
  }

  @Watch('photos')
  public onPhotoPropsChange(val: Photo[], oldVal: Photo[]): void {
    logger.info('onPhotoPropsChange', { val, oldVal });
    this.updateAlbum();
  }

  public onClick(albumId: string): void {
    logger.info('onClick', { albumId });
    if (albumId === 'slowmotion') {
      this.$router.push({ name: 'slowmotionview', params: { cameraid: this.camera.id, projectid: this.project.id } });
    } else {
      this.$router.push({ name: 'albumview', params: { id: albumId } });
    }
  }

  private updateAlbum() {
    this.items = this.albums.map((a) => ({ data: a }));
    if (this.slowMotions.length > 0) {
      this.items.push({
        data: {
          cameraId: this.camera.id,
          projectId: this.project.id,
          name: 'Video',
          id: 'slowmotion',
          photos: this.slowMotions,
        },
      });
    }
    this.items.sort((a, b) => a.data.name.localeCompare(b.data.name));
    this.$nextTick(() => {
      this.updatePhotosSize();
    });
  }
}
