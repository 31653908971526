






import { Component, Vue, Watch } from 'vue-property-decorator';
import SignupComponent from '@/components/signup/signup.component.vue';
import { State } from 'vuex-class';
import { CognitoUser } from '@aws-amplify/auth';
const namespace = 'profile';

@Component({
  components: {
    SignupComponent,
  },
})
export default class Signup extends Vue {
  @State('cognitoUser', { namespace }) public cognitoUser?: CognitoUser | undefined;

  public mounted(): void {
    if (this.cognitoUser === undefined) {
      this.$router.push({ name: 'login' });
    }
  }

  @Watch('cognitoUser')
  onNewPasswordRequired(val: boolean): void {
    if (val === undefined) {
      this.$router.push({ name: 'login' });
    }
  }
}
