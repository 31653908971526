import 'reflect-metadata';
import { jsonObject, jsonMember, toJson, TypedJSON } from 'typedjson';
import { Video } from './video';

@jsonObject
@toJson
export class Scenario {
  @jsonMember
  public id!: string;

  @jsonMember
  public name!: string;

  @jsonMember
  public video!: Video;
}

export const serializer = new TypedJSON(Scenario);
