import { Component, Vue, Prop } from 'vue-property-decorator';
import { Camera } from '@/models/camera';
import { Project } from '@/models/project';
import { User } from '@/models/user';
import ProjectModal from '@/components/projectmodal/projectmodal.component.vue';
import { City } from '@/models/city';
import { State, Getter } from 'vuex-class';
import { LMap, LTileLayer, LMarker, LPopup } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.webpack.css'; // Re-uses images from ~leaflet package
import 'leaflet-defaulticon-compatibility';
import L, { LatLngBounds } from 'leaflet';

@Component({
  components: {
    Description,
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    ProjectModal,
  },
})
export default class Description extends Vue {
  @Prop({ type: Object, required: true }) private readonly camera!: Camera;
  @Prop({ type: Object, required: true }) private project!: Project;
  @Getter('projectWithId', { namespace: 'projects' }) readonly projectWithId!: (id: string) => Project | undefined;
  @State('locale', { namespace: 'profile' }) private readonly locale!: string;
  @State('projects', { namespace: 'projects' }) private allProject!: Project[];
  @State('user', { namespace: 'profile' }) private user!: User;

  private projects: Project[] = [];
  private visible = true;
  private url = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
  private attribution = '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors';
  private center: [number, number] = [48.858093, 2.294694]; // Coordinates for Paris, France
  private zoom = 4;
  private showMap = false; // Set to true to display the map by default
  private showMosaicAndList = true;

  public get isAdmin(): boolean {
    if (this.user && this.project) {
      return (
        this.project.owner.id === this.user.id ||
        (this.project.administratorId && this.project.administratorId.includes(this.user.id))
      );
    }
    return false;
  }

  private mounted() {
    this.calculateMapBounds();
  }

  private async calculateMapBounds() {
    const project = this.project;

    if (!project) {
      return;
    }

    const { latlng } = project.city;
    const { lat, lng } = latlng;

    const paddingFactor = 0.2;

    const bounds = new L.LatLngBounds([lat, lng], [lat, lng]).pad(paddingFactor);

    this.center = [lat, lng];

    const map = this.$refs.map as any;
    map.fitBounds(bounds);
  }
}
