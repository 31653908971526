import Vue from 'vue';
import Router, { RouteConfig } from 'vue-router';
import ProjectList from '@/views/ProjectList.vue';
import CameraView from '@/views/CameraView.vue';
import PlateformCentral from '@/views/PlateformCentral.vue';
import Timelapse from '@/views/Timelapse.vue';
import Login from '@/views/Login.vue';
import Signup from '@/views/Signup.vue';
import PasswordLost from '@/views/PasswordLost.vue';
import { CognitoUser } from '@aws-amplify/auth';
import store from '../store';
// @ts-ignore missing d.ts
import VueBodyClass from 'vue-body-class';
import ProfileView from '@/views/ProfileView.vue';
import SlowmotionView from '@/views/SlowmotionView.vue';
import AlbumView from '@/views/AlbumView.vue';
import IframeGallery from '@/views/IframeGallery.vue';
import { Auth } from 'aws-amplify';
import { Logger } from 'aws-amplify';
const logger = new Logger('router');

Vue.use(Router);

const routes: RouteConfig[] = [
  { path: '/login', name: 'login', component: Login, meta: { bodyClass: 'login' } },
  { path: '/signup', name: 'signup', component: Signup, meta: { bodyClass: 'login' } },
  { path: '/resetpassword', name: 'passwordlost', component: PasswordLost, meta: { bodyClass: 'login' } },
  {
    path: '/logout',
    name: 'logout',
    beforeEnter: async (to, from, next) => {
      await store.dispatch('profile/logout');
      next({ name: 'login' });
    },
  },
  { path: '/camera', name: 'camera', component: CameraView, meta: { requiresAuth: true, bodyClass: 'camera' } },
  { path: '/', name: 'projectlist', component: ProjectList, meta: { requiresAuth: true, bodyClass: 'project-list' } },
  { path: '/profile', name: 'profileview', component: ProfileView, meta: { requiresAuth: true, bodyClass: 'profile' } },
  {
    path: '/plan',
    name: 'planview',
    beforeEnter() {
      // Put the full page url including the protocol http(s) below
      window.location.href = 'https://teye.prototyper.fr/produit/#subscription';
    },
  },
  /*{ path: '/plan', name: 'planview', component: PlanView, meta: { requiresAuth: true, bodyClass: 'profile' } },
  {
    path: '/confirmplan',
    name: 'planconfirmview',
    component: PlanConfirmView,
    meta: { requiresAuth: true, bodyClass: 'profile' },
  },*/
  {
    path: '/project/:id/',
    name: 'plateformcentral',
    component: PlateformCentral,
    meta: { requiresAuth: true, bodyClass: 'plateform-central-body' },
    props: true,
  },
  {
    path: '/album/:id',
    name: 'albumview',
    component: AlbumView,
    meta: { requiresAuth: true, bodyClass: 'album-view' },
    props: true,
  },
  {
    path: '/slowmotion/:projectid/:cameraid',
    name: 'slowmotionview',
    component: SlowmotionView,
    meta: { requiresAuth: true, bodyClass: 'album-view' },
    props: true,
  },
  {
    path: '/project/:id/timelapse',
    name: 'timelapse',
    component: Timelapse,
    meta: { requiresAuth: true, bodyClass: 'timelapse-body' },
    props: true,
  },
  {
    path: '/iframe/photos/:id',
    name: 'iframegallery',
    component: IframeGallery,
    meta: { bodyClass: 'iframe-body' },
    props: true,
  },
  { path: '*', redirect: '/' },
];

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

const vueBodyClass = new VueBodyClass(routes);
router.beforeEach((to, from, next) => {
  vueBodyClass.guard(to, next);
});

router.beforeResolve((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    Auth.currentAuthenticatedUser()
      .then((data: CognitoUser) => {
        if (data?.getSignInUserSession() !== null) {
          next();
        } else {
          next({
            name: 'login',
          });
        }
      })
      .catch((e) => {
        logger.warn('%c Unauthorized request', 'color: red; font-weight: bold;', { e });
        next({
          name: 'login',
        });
      });
  }
  next();
});

export default router;
