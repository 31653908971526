import { Module } from 'vuex';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import { PhotoState } from './types';
import { RootState } from '../types';

export const state: PhotoState = {
  isLoading: false,
  projectId: undefined,
  photos: [],
  cameraId: undefined,
};

const namespaced = true;

export const photo: Module<PhotoState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
