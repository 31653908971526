import 'reflect-metadata';
import { jsonObject, jsonMember, toJson, jsonArrayMember, TypedJSON } from 'typedjson';
import { BatteryLevel } from './batterylevel';
import { CameraShadow } from './camerashadow';
import { LastPhoto } from './lastphoto';

@jsonObject
@toJson
export class Camera {
  @jsonMember id!: string;
  @jsonMember serialNumber!: string;
  @jsonMember name!: string;
  @jsonMember phonenumber?: string;
  @jsonMember createdAt!: Date;
  @jsonMember projectId?: string;
  @jsonArrayMember(BatteryLevel) batteryLevels: BatteryLevel[] = [];
  @jsonMember lastPhoto?: LastPhoto;
  @jsonMember shadow?: CameraShadow;
  @jsonMember isTikee?: boolean;
  @jsonMember apiUrl?: string;
  @jsonMember apiFetchInterval?: number;
  @jsonMember rotate?: number;
  @jsonMember blur?: boolean;
  @jsonMember live!: boolean;
  @jsonMember remaining?: number;
  @jsonMember apiImportInProgress?: boolean;
  @jsonMember optic?: '4260x1534' | '2664x1998' | '5496x3672';
}

export const serializer = new TypedJSON(Camera);

// eslint-disable-next-line max-classes-per-file
@jsonObject
@toJson
export class IframeCamera implements Pick<Camera, 'id' | 'name'> {
  @jsonMember id!: string;
  @jsonMember name!: string;
  @jsonMember isTikee?: boolean;
  @jsonMember rotate?: number;
}
