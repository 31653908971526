import { ActionTree } from 'vuex';
import { Auth } from 'aws-amplify';
import { ProfileState, LoginActionParam, CompleteNewPasswordActionParam } from './types';
import { RootState } from '../types';
import { userService } from '@/services';
import i18n from '../../i18n';
import { Locale } from 'vue-i18n';
import { CognitoUser } from '@aws-amplify/auth';
import { Cache } from 'aws-amplify';
import * as Sentry from '@sentry/browser';
import { Logger } from 'aws-amplify';
const logger = new Logger('ProfileStore');

export const actions: ActionTree<ProfileState, RootState> = {
  async login({ commit, dispatch }, loginActionParam: LoginActionParam) {
    logger.info('login', { loginActionParam });
    commit('resetLogin');
    try {
      const cognitoUser = await Auth.signIn(loginActionParam.email, loginActionParam.password);
      logger.info(cognitoUser);
      if (cognitoUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
        const { requiredAttributes } = cognitoUser.challengeParam;
        commit('cognitoUser', cognitoUser);
        commit('newPassword', requiredAttributes);
      } else {
        await dispatch('fetchCognitoUser');
        await dispatch('fetchUserSession');
      }
    } catch (err) {
      logger.info(`Login Error [${JSON.stringify(err)}]`);
      if (err) {
        commit('errorLogin', err.code || err);
      }
      return;
    }
  },
  async completeNewPassword({ dispatch, commit }, completeNewPasswordActionParam: CompleteNewPasswordActionParam) {
    logger.info('completeNewPassword', { completeNewPasswordActionParam });
    await Auth.completeNewPassword(
      completeNewPasswordActionParam.cognitoUser, // the Cognito User Object
      completeNewPasswordActionParam.newPassword, // the new password
      // OPTIONAL, the required attributes
      {
        family_name: completeNewPasswordActionParam.family_name,
        given_name: completeNewPasswordActionParam.given_name,
      }
    );
    const userUpdated = await userService.saveMe(completeNewPasswordActionParam.updateUser);
    commit('user', userUpdated);
    return dispatch('fetchCognitoUser');
  },
  async fetchUserSession({ commit }) {
    return userService.getUserMe().then((user) => {
      commit('user', user);
    });
  },
  async fetchCognitoUser({ commit, dispatch }) {
    logger.info(`fetchCognitoUser`);
    try {
      const cognitoUser: CognitoUser = await Auth.currentAuthenticatedUser();
      await Auth.verifiedContact(cognitoUser).then(({ verified, unverified }) => {
        logger.info({ verified, unverified });
        // @ts-ignore Amplify library not valid
        if (unverified.email !== undefined) {
          commit('setAccountVerified', false);
        }
      });
      const userSession = cognitoUser.getSignInUserSession();
      if (userSession === null) {
        return dispatch('logout');
      }
      const expires = userSession.getIdToken().payload.exp - Math.floor(new Date().getTime() / 1000);
      logger.info(`Token expires in ${expires} seconds`);
      if (expires === -1) {
        return dispatch('logout');
      } else {
        setTimeout(async () => {
          logger.info('Renewing Token');
          return Auth.currentSession()
            .then((session) => {
              cognitoUser.refreshSession(session.getRefreshToken(), () => {
                return dispatch('fetchCognitoUser');
              });
            })
            .catch((error) => {
              logger.warn('enable to renew token', { error });
              return dispatch('logout');
            });
        }, expires * 1000);
        commit('cognitoUser', cognitoUser);
        Sentry.configureScope(async (scope) => {
          const currentUserInfo = await Auth.currentUserInfo();
          scope.setUser({ email: currentUserInfo.attributes.email, id: currentUserInfo.attributes.sub });
        });
        return Promise.resolve(cognitoUser);
      }
    } catch (err) {
      commit('resetLogin');
      return Promise.reject(err);
    }
  },
  async logout({ commit }) {
    logger.info(`logout`);
    Cache.clear();
    return Auth.signOut()
      .then(() => {
        commit('resetLogin');
      })
      .catch(() => {
        commit('resetLogin');
      });
  },
  async changeUserLocal({ state, commit }, locale: Locale) {
    if (state.user) {
      const userUpdated = await userService.changeUserLocal(locale);
      commit('user', userUpdated);
    }
    commit('locale', locale);
    i18n.locale = locale;
  },
  async changeUserNotification({ state, commit }, enable: boolean) {
    if (state.user) {
      const userUpdated = await userService.changeUserNotification(enable);
      commit('user', userUpdated);
    }
  },
};
