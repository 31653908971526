import { Module } from 'vuex';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import { AlbumState } from './types';
import { RootState } from '../types';

export const state: AlbumState = {
  loadingAlbum: false,
  albums: [],
};

const namespaced = true;

export const album: Module<AlbumState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
