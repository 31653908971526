import { API, Auth } from 'aws-amplify';
import { GetUserResult, UpdateUser } from './users.interfaces';
import { User, serializer } from '../../models/user';
import { VideoPreset } from '../../models/videopreset';
import { Logger } from 'aws-amplify';
const logger = new Logger('UserService');

export class UserService {
  public constructor(private readonly apiName: string) {}

  public async getUserMe(): Promise<User | undefined> {
    return API.get(this.apiName, '/users/me', {}).then((response: GetUserResult) => {
      return serializer.parse(response.user);
    });
  }

  public async isUserPremium(userId: string): Promise<boolean> {
    return API.get(this.apiName, `/users/${userId}`, {}).then((response) => {
      return response.isAdmin as boolean;
    });
  }

  public async saveMe(user: UpdateUser): Promise<User | undefined> {
    return API.post(this.apiName, '/users/me', {
      body: user,
    }).then((response: GetUserResult) => {
      return serializer.parse(response.user);
    });
  }

  public async changeUserLocal(locale: string): Promise<User | undefined> {
    return API.post(this.apiName, '/users/me/locale', {
      body: { locale },
    }).then((response: GetUserResult) => {
      return serializer.parse(response.user);
    });
  }

  public async changeUserNotification(enable: boolean): Promise<User | undefined> {
    return API.post(this.apiName, '/users/me/notification', {
      body: { enable },
    }).then((response: GetUserResult) => {
      return serializer.parse(response.user);
    });
  }

  public async passwordChangeConfirm(): Promise<void> {
    return API.post(this.apiName, '/users/me/passwordchange', {});
  }

  public async emailAvailable(email: string): Promise<boolean> {
    const code = '000000';
    return await Auth.confirmSignUp(email, code, {
      // If set to False, the API will throw an AliasExistsException error if the phone number/email used already exists as an alias with a different user
      forceAliasCreation: false,
    })
      .then((data) => {
        // It will shouldn't happen because code is invalid
        logger.info('UserService.emailExists', { data });
        return false;
      })
      .catch((err) => {
        switch (err.code) {
          case 'UserNotFoundException':
            return true;
          case 'NotAuthorizedException':
            return false;
          case 'AliasExistsException':
            // Email alias already exists
            return false;
          case 'CodeMismatchException':
            return false;
          case 'ExpiredCodeException':
            return false;
          default:
            return false;
        }
      });
  }

  public async getTimelapsePreset(): Promise<VideoPreset[]> {
    return API.get(this.apiName, '/videos/preset', {});
  }

  public async saveTimelapsePreset(body: VideoPreset): Promise<VideoPreset[]> {
    return API.post(this.apiName, '/videos/preset', { body });
  }

  public async deletePreset(selected: VideoPreset): Promise<void> {
    logger.info(selected);
    return API.del(this.apiName, '/videos/preset/' + selected.id, {});
  }
}
