import 'reflect-metadata';
import { jsonObject, jsonMember, toJson } from 'typedjson';
import { Shadow } from './shadow';

@jsonObject
@toJson
export class CameraShadow {
  @jsonMember
  public desired!: Shadow;

  @jsonMember
  public reported!: Shadow;
}
