










































































































































































































import { Component, Vue } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import { Auth } from 'aws-amplify';
import { Validations } from 'vuelidate-property-decorators';
import { required, minLength, sameAs } from 'vuelidate/lib/validators';
// @ts-ignore: no d.ts file
import { vTeleport } from '@desislavsd/vue-teleport';
import { userService } from '@/services/index';
import LocalChanger from '@/components/localchanger/localchanger.component.vue';
import { User } from '@/models/user';
import { Logger } from 'aws-amplify';
const logger = new Logger('ProfileView');

@Component({
  components: {
    vTeleport,
    LocalChanger,
  },
})
export default class ProfileView extends Vue {
  @State('user', { namespace: 'profile' }) private user!: User;
  @State('premium', { namespace: 'profile' }) private isPremium!: boolean;
  @Action('changeUserNotification', { namespace: 'profile' }) changeUserNotification!: (
    enable: boolean
  ) => Promise<void>;
  private form = {
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
    error: '',
  };
  private loading = false;
  private toggleFormPassword = false;
  private showFormPasswordAlert = false;
  private toggleFormBank = false;
  private get maxData() {
    return this.isPremium ? 1024 : 100;
  }
  private enabledNewsletter = false;

  @Validations()
  private validations = {
    form: {
      oldPassword: { required, minLength: minLength(8) },
      newPassword: { required, minLength: minLength(8) },
      confirmNewPassword: {
        sameAsPassword: sameAs('newPassword'),
      },
    },
  };

  public get breadcrumbs(): unknown[] {
    return [
      {
        text: this.$t('global.profile'),
        to: '#',
      },
    ];
  }

  public onSubmit(event: Event): void {
    event.preventDefault();
    this.loading = true;
    Auth.currentAuthenticatedUser()
      .then((user) => {
        return Auth.changePassword(user, this.form.oldPassword, this.form.newPassword);
      })
      .then(() => userService.passwordChangeConfirm())
      .then(() => {
        this.loading = false;
        this.toggleFormPassword = false;
        this.showFormPasswordAlert = true;
      })
      .catch((err) => {
        this.loading = false;
        logger.warn(err);
        if (err && err.code === 'NotAuthorizedException') {
          this.form.error = err.code;
        }
      });
  }

  public onToggleFormPassword(event: Event): void {
    event.preventDefault();
    this.toggleFormPassword = !this.toggleFormPassword;
  }

  public onToggleFormBank(event: Event): void {
    event.preventDefault();
    this.toggleFormBank = !this.toggleFormBank;
  }

  public changeEventNotification(): void {
    logger.info('changeEventNotification');
    this.changeUserNotification(this.user.notification ?? false);
  }

  public changeEventNewsletter(): void {
    logger.info('changeEventNewsletter');
  }
}
