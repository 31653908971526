


















import Vue from 'vue';
import Component from 'vue-class-component';
import { Storage } from 'aws-amplify';
import { Prop, Watch } from 'vue-property-decorator';
import { Logger } from 'aws-amplify';
const logger = new Logger('S3Image');

@Component
export default class S3Image extends Vue {
  @Prop() readonly imagePath!: string;
  @Prop() readonly imageClass!: string;
  @Prop() readonly width!: string;
  @Prop() readonly height!: string;

  url: string | null = null;
  error = '';

  @Watch('imagePath')
  onImagePathChange(): void {
    this.getImage();
  }

  mounted(): void {
    this.getImage();
  }

  getImage(): void {
    if (!this.imagePath) {
      this.setError('Image path not provided.');
    }
    Storage.get(this.imagePath, { level: 'public' })
      .then((url) => {
        this.url = url as string;
      })
      .catch((e) => this.setError(e));
  }

  blowUp(url: string): void {
    this.$emit('click', url);
  }

  setError(e: string): void {
    this.error = e;
    logger.error(this.error);
  }

  onLoad(): void {
    this.$emit('load');
  }
}
