import { MutationTree } from 'vuex';
import { AlbumState } from './types';
import { Album } from '@/models/album';

export const mutations: MutationTree<AlbumState> = {
  loadingAlbum(state: AlbumState) {
    state.albums = [];
    state.loadingAlbum = true;
  },
  albums(state: AlbumState, albums: Album[]) {
    state.albums = albums;
    state.loadingAlbum = false;
  },
  album(state: AlbumState, album: Album) {
    state.albums = [album];
    state.loadingAlbum = false;
  },
  updateAlbum(state: AlbumState, album: Album) {
    state.albums = [album, ...state.albums.filter((a) => a.id !== album.id)];
    state.loadingAlbum = false;
  },
  removeAlbum(state: AlbumState, album: Album) {
    state.albums = state.albums.filter((a) => a.id !== album.id);
    state.loadingAlbum = false;
  },
};
