import { GetterTree } from 'vuex';
import { ProjectsState } from './types';
import { RootState } from '../types';

export const getters: GetterTree<ProjectsState, RootState> = {
  projectWithId: (state) => (id: string) => {
    return state.projects.find((project) => project.id === id);
  },
  filter: (state) => (searchString: string) => {
    const searchArray = searchString.toLowerCase().split(' ');
    return state.projects.filter((project) => {
      const projectTextIndex =
        project.name.toLowerCase() +
        ' ' +
        (project.city && project.city.name ? project.city.name.toLowerCase() : '') +
        ' ' +
        (project.tags?.join(' ') ?? '');
      return searchArray.every((searchWord) => {
        return projectTextIndex.indexOf(searchWord) > -1;
      });
    });
  },
};
