import 'reflect-metadata';
import { jsonObject, jsonMember, toJson, TypedJSON } from 'typedjson';

@jsonObject
@toJson
export class Area {
  @jsonMember
  public x!: number;

  @jsonMember
  public y!: number;

  @jsonMember
  public w!: number;

  @jsonMember
  public h!: number;
}

export const serializer = new TypedJSON(Area);
