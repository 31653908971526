import { API } from 'aws-amplify';
import { Photo, PublicPhoto, serializer } from '../../models/photo';
import { GetPhotoResult, DownloadZipRequest, UploadModifiedPhoto, ImportPhotosRequest } from './photos.interfaces';
import { saveAs } from 'file-saver';
import { Logger } from 'aws-amplify';
const logger = new Logger('PhotoService');

export class PhotoService {
  public constructor(private readonly apiName: string) {}

  public async findAllFor(projectId: string, cameraId: string): Promise<Photo[]> {
    return this.findAllLoop(`/projects/${projectId}/photos/${cameraId}`);
  }

  private async findAllLoop(url: string): Promise<Photo[]> {
    return API.get(this.apiName, url, {}).then((response: GetPhotoResult<Photo>) => {
      if (response.next) {
        return this.findAllLoop(response.next).then((arr) => [...arr, ...serializer.parseAsArray(response.photos)]);
      } else {
        return serializer.parseAsArray(response.photos);
      }
    });
  }

  public async requestZipPhoto(projectId: string, photoIds: string[]): Promise<void> {
    const body: DownloadZipRequest = {
      photoIds,
    };
    return API.post(this.apiName, `/projects/${projectId}/photos/zip`, {
      body,
    });
  }

  public async uploadModifiedVersion(photoId: string): Promise<PublicPhoto> {
    const body: UploadModifiedPhoto = {
      photoId,
    };
    return API.post(this.apiName, `/photos/${photoId}/modified-versions`, {
      body,
    });
  }

  public async deletePhoto(projectId: string, photoIds: string[]): Promise<void> {
    const body: DownloadZipRequest = {
      photoIds,
    };
    return API.post(this.apiName, `/projects/${projectId}/photos/delete`, {
      body,
    });
  }

  public async importPhotos(importPhotosRequest: ImportPhotosRequest): Promise<Photo[]> {
    return API.post(this.apiName, `/projects/${importPhotosRequest.targetProject}/photos/import`, {
      body: importPhotosRequest,
    }).then((v) => serializer.parseAsArray(v));
  }

  public base64ToBlob(data: string): Blob {
    let mimeString = '';
    let raw;
    let uInt8Array;
    let i;
    const rImageType = /data:(image\/.+);base64,/;
    raw = data.replace(rImageType, (header, imageType) => {
      mimeString = imageType;
      return '';
    });
    raw = atob(raw);
    const rawLength = raw.length;
        uInt8Array = new Uint8Array(rawLength); // eslint-disable-line
    for (i = 0; i < rawLength; i += 1) {
      uInt8Array[i] = raw.charCodeAt(i);
    }
    return new Blob([uInt8Array], { type: mimeString });
  }

  public downloadPhotoFromBase64(name: string, base64: string): void {
    logger.info({ base64 });
    const supportingFileAPI = !!(window.File && window.FileList && window.FileReader);
    let blob;
    let type;

    if (supportingFileAPI) {
      blob = this.base64ToBlob(base64);
      logger.info({ type });
      type = blob.type.split('/')[1];
      if (name.split('.').pop() !== type) {
        name += '.' + type;
      }

      // Library: FileSaver - saveAs
            saveAs(blob, name); // eslint-disable-line
    } else {
      alert('This browser needs a file-server');
      const w = window.open();
      if (w) {
        w.document.body.innerHTML = '<img src="' + base64 + '">';
      }
    }
  }

  public fileToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const img = new Image();
        img.onload = () => {
          logger.info('The width of the image is ' + img.width + 'px.');
          if (img.width <= 200) {
            resolve(reader.result as string);
          } else {
            reject(new Error('INVALID_SIZE'));
          }
        };
        img.src = reader.result as string;
      };
      reader.onerror = (error) => reject(error);
    });
  }
}
