import 'reflect-metadata';
import { Area } from './area';
import { jsonObject, jsonMember, toJson, TypedJSON } from 'typedjson';

@jsonObject
@toJson
export class Blur {
  @jsonMember
  public photo?: string;

  @jsonMember
  public begin?: number;

  @jsonMember
  public end?: number;

  @jsonMember
  public noEnd?: boolean;

  @jsonMember
  public area!: Area;
}

export const serializer = new TypedJSON(Blur);
