import { jsonObject, jsonMember, toJson } from 'typedjson';
import { Photo } from './photo';

@jsonObject
@toJson
export class LastPhoto implements Pick<Photo, 'id' | 'key' | 'createdAt'> {
  @jsonMember
  public id!: string;

  @jsonMember
  public key!: string;

  @jsonMember
  public createdAt!: Date;

  public get smallKey(): string {
    return `${this.key}-small`;
  }
}
