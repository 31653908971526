import { Component, Vue, Prop } from 'vue-property-decorator';
import { Camera } from '@/models/camera';
import { Project } from '@/models/project';
import CameraModal from '@/components/cameramodal/cameramodal.component.vue';
import CameraModalCreate from '@/components/cameramodalcreate/cameramodalcreate.component.vue';
import { Getter } from 'vuex-class';
import BatterieLevelComponent from '@/components/batterielevel/batterielevel.component.vue';
import { Storage } from 'aws-amplify';

@Component({
  components: {
    CameraModal,
    CameraModalCreate,
    BatterieLevelComponent,
  },
})
export default class CameraCard extends Vue {
  @Prop({ required: true }) readonly isPremium!: boolean;
  @Prop({ required: true }) private readonly camera!: Camera;
  @Getter('projectWithId', { namespace: 'projects' }) private projectWithId!: (id: string) => Project | undefined;

  public lastPhoto = {
    url: '',
    rotation: 0,
    createdAt: new Date(),
    ratio: '',
    zoom: 1,
  };

  public currentPhotoIndex = 0;
  public nextPhotoIndex = 0;
  public overlayshow = false;
  public isTransitioning = false;
  public imageInterval: number | null = null;
  public selectedPhotoIndex = -1;
  public currentPhotoId = '';

  private toggleBatVide = false;
  private interval: number | null = null;

  public get batteriePicto(): string {
    const base = 'picto-erige ';
    if (!this.camera.shadow) {
      return `${base} picto-batterie-charge`;
    } else if (this.camera.shadow?.reported?.Power_supply === '1') {
      return `${base} picto-batterie-charge`;
    } else if (this.camera.shadow?.reported?.Battery_Status !== '0') {
      return `${base} picto-batterie-${this.camera.shadow.reported.Battery_Status}`;
    } else {
      if (this.interval) {
        clearInterval(this.interval);
      }
      this.interval = window.setInterval(() => {
        this.toggleBatVide = !this.toggleBatVide;
      }, 2000);
      return `${base} picto-batterie-0`;
    }
  }

  public get project(): Project | undefined {
    return this.camera?.projectId ? this.projectWithId(this.camera.projectId) : undefined;
  }

  public get city(): string {
    const project = this.camera?.projectId ? this.projectWithId(this.camera.projectId) : undefined;
    return project ? project.city.name ?? '' : '';
  }

  public getRotation(): string {
    return this.camera && this.camera.rotate ? `rotate(${this.camera.rotate}deg)` : '';
  }

  public isMoreThan48Hours(photo: { createdAt: Date; apiFetchInterval?: number }): boolean {
    const diffTime = Math.abs(new Date().getTime() - new Date(photo.createdAt).getTime());
    const diffHours = Math.ceil(diffTime / (1000 * 60 * 60));
    return diffHours >= 48 && photo.apiFetchInterval === 60;
  }

  public isDifferentFetchIntervalAndMoreThan48Hours(photo: { createdAt: Date; apiFetchInterval?: number }): boolean {
    const diffTime = Math.abs(new Date().getTime() - new Date(photo.createdAt).getTime());
    const diffHours = Math.ceil(diffTime / (1000 * 60 * 60));
    return diffHours >= 48 && photo.apiFetchInterval !== 60;
  }

  public mounted(): void {
    if (this.camera && this.camera.lastPhoto) {
      Storage.get(this.camera.lastPhoto.smallKey).then((result) => {
        this.$nextTick(() => {
          this.lastPhoto = {
            url: result as string,
            createdAt: this.camera.lastPhoto?.createdAt ?? new Date(),
            rotation: this.camera.rotate || 0,
            ratio: '',
            zoom: 1,
          };

          const img = new Image();
          img.onload = () => {
            this.lastPhoto.ratio = `${img.width}:${img.height}`;
            if (this.lastPhoto.ratio === '1920:691') {
              this.lastPhoto.zoom = 1.4;
            }
          };
          img.src = this.lastPhoto.url;
        });
      });
    }
  }
}

