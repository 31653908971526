import { GetterTree } from 'vuex';
import { ProfileState } from './types';
import { RootState } from '../types';
import { Auth } from 'aws-amplify';
import { CognitoUserSession } from 'amazon-cognito-identity-js';

export const getters: GetterTree<ProfileState, RootState> = {
  user(state) {
    return state.user;
  },

  authorized(state) {
    return state.authorized;
  },

  async getJWT(state) {
    if (state.authorized) {
      const session: CognitoUserSession = await Auth.currentSession();
      return session.getIdToken().getJwtToken();
    } else {
      return undefined;
    }
  },
};
