import { ActionTree } from 'vuex';
import { ProjectsState } from './types';
import { RootState } from '../types';
import { projectService } from '@/services';
import { Project } from '@/models/project';
import { ProjectCreateRequest, ProjectUpdateRequest } from '@/services/projects/projects.interfaces';
import { Logger } from 'aws-amplify';
const logger = new Logger('ProjectsStore');

export const actions: ActionTree<ProjectsState, RootState> = {
  async loadProject({ commit }) {
    logger.info('loadProject');
    commit('loadingProject');
    const projects: Project[] = await projectService.getMyProject();
    commit('projects', projects);
    return projects;
  },
  async createNewProject({ commit }, projectCreateRequest: ProjectCreateRequest) {
    logger.info('createNewProject');
    commit('loadingProject');
    const projects: Project[] = await projectService.createNewProject(projectCreateRequest);
    commit('projects', projects);
    return projects;
  },
  async updateProject({ commit, dispatch }, projectUpdateRequest: ProjectUpdateRequest) {
    logger.info('updateProject');
    commit('loadingProject');
    const projects: Project[] = await projectService.updateProject(projectUpdateRequest);
    commit('projects', projects);
    await dispatch('camera/loadCamera', {}, { root: true });
    return projects;
  },
  async deleteProject({ dispatch, commit }, project: Project) {
    logger.info('deleteProject');
    commit('loadingProject');
    await projectService.deleteProject(project);
    await dispatch('loadProject');
  },
};
