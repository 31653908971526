import { Vue, Ref, Prop } from 'vue-property-decorator';
import Component from 'vue-class-component';
import { BModal } from 'bootstrap-vue/esm/components/modal';
import { Photo, PublicPhoto } from '@/models/photo';
import { photoService } from '@/services/';
import { Storage } from 'aws-amplify';
// Load Style Code
import 'tui-image-editor/dist/tui-image-editor.css';
import 'tui-color-picker/dist/tui-color-picker.css';

import ImageEditor from 'tui-image-editor';
import { customTheme } from './customTheme';
import S3Image from '../s3-image/S3Image.vue';
import PremiumOverlay from '../premiumoverlay/premiumoverlay.component.vue';
import { Logger } from 'aws-amplify';
const logger = new Logger('EditShareModal');

@Component({
  components: {
    S3Image,
    PremiumOverlay,
  },
})
export default class EditShareModal extends Vue {
  @Prop({ type: Object, required: true }) private readonly image!: Photo;
  @Prop({ required: true }) readonly isPremium!: boolean;

  @Ref('edit-share-modal') private modal!: BModal;
  @Ref('imageEditorRef') private imageEditorRef!: HTMLDivElement;

  private imageEditor!: ImageEditor;

  private locale = new Proxy(
    {},
    {
      get: (target: unknown, name: unknown) => {
        if (typeof name === 'string' && name !== '_isVue') {
          return this.translate(`imageeditor.${name}`);
        } else {
          return name;
        }
      },
    }
  );

  private photoUploading = false;

  public translate(key: string): unknown {
    return this.$t(key);
  }

  public show(): void {
    logger.info('show');
    this.modal.show();
  }

  public onShown(): void {
    // IMPORANT ISSUE WITH ITEXT Fabric https://github.com/fabricjs/fabric.js/issues/3695
    document.querySelector('#edit-share-modal___BV_modal_content_')?.removeAttribute('tabindex');
    logger.info('onShown');
    Storage.get(this.image.mediumKey, { expires: 3600 }).then((link) => {
      this.imageEditor = new ImageEditor(this.imageEditorRef, {
        usageStatistics: false,
        selectionStyle: {
          cornerSize: 20,
          rotatingPointOffset: 70,
        },
        includeUI: {
          loadImage: {
            path: link as string,
            name: 'empty',
          },
          menuBarPosition: 'bottom',
          theme: customTheme,
          // @ts-ignore no info
          locale: this.locale,
        },
      });
      // this.imageEditor.on('undoStackChanged', this.editorChanged);
    });
  }

  public close(): void {
    this.modal.hide();
    logger.info('close');
  }

  public async saveImageBeforeSharing(): Promise<string> {
    this.photoUploading = true;
    return photoService.uploadModifiedVersion(this.image.id).then(async (result: PublicPhoto) => {
      if (result.putUrl) {
        const options = {
          headers: {
            'Content-Type': 'image/jpeg',
            'Content-Encoding': 'base64',
          },
        };
        const dataUrl = this.imageEditor.toDataURL();
        const buffer = Buffer.from(dataUrl.replace(/^data:image\/\w+;base64,/, ''), 'base64');
        await this.axios.put(result.putUrl, buffer, options);
        this.photoUploading = false;
        return result.id;
      } else {
        throw new Error('no signed url returned by backend');
      }
    });
  }

  public buildShareLink(key: string): string {
    return `${process.env.VUE_APP_API_ENDPOINT}/photos/${key}/viewer`;
  }

  public shareFacebook(): void {
    if (this.isPremium) {
      this.saveImageBeforeSharing().then((id) => {
        window.open(
          'https://www.facebook.com/sharer/sharer.php?u=' + this.buildShareLink(id),
          '',
          'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600'
        );
      });
    }
  }

  public shareTwitter(): void {
    if (this.isPremium) {
      this.saveImageBeforeSharing().then((id) => {
        window.open(
          'https://twitter.com/intent/tweet?text=' + this.buildShareLink(id),
          '',
          'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600'
        );
      });
    }
  }

  public shareLinkedin(): void {
    if (this.isPremium) {
      this.saveImageBeforeSharing().then((id) => {
        window.open(
          'https://www.linkedin.com/sharing/share-offsite/?url=' + this.buildShareLink(id),
          '',
          'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600'
        );
      });
    }
  }

  public shareEmail(): void {
    if (this.isPremium) {
      this.saveImageBeforeSharing().then((id) => {
        window.open(
          'mailto:someone@example.com?subject=Photo%20du%20chantier&body=Voilà une photo que je voudrais que tu vois: ' +
            encodeURIComponent(this.buildShareLink(id))
        );
      });
    }
  }

  public download(): void {
    if (this.isPremium) {
      logger.info('download');
      const imageName = this.imageEditor.getImageName();
      const dataURL = this.imageEditor.toDataURL();
      photoService.downloadPhotoFromBase64(imageName, dataURL);
    }
  }
}
