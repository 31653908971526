import { Vue, Ref, Prop } from 'vue-property-decorator';
import Component from 'vue-class-component';
import { BModal } from 'bootstrap-vue/esm/components/modal';
import TimelapseFilter from '@/components/timelapsefilter/timelapsefilter.component.vue';
import TimelapseSettings from '@/components/timelapsesettings/timelapsesettings.component.vue';
import { Camera } from '@/models/camera';
import S3Image from '@/components/s3-image/S3Image.vue';
import { Project } from '@/models/project';
import { Blur } from '@/models/blur';
import { Area } from '@/models/area';
import { Photo } from '@/models/photo';
import { Storage } from 'aws-amplify';
import VueSlider from 'vue-slider-component';
import { State, Action } from 'vuex-class';
import PremiumOverlay from '../premiumoverlay/premiumoverlay.component.vue';
import { Logger } from 'aws-amplify';
const logger = new Logger('BlurModal');

interface Rectangle extends Area {
  color: string;
}

interface BlurForm extends Blur {
  photoBegin?: Photo;
  photoEnd?: Photo;
}

@Component({
  components: {
    TimelapseFilter,
    TimelapseSettings,
    S3Image,
    VueSlider,
    PremiumOverlay,
  },
})
export default class BlurModal extends Vue {
  get dateFormat(): string {
    return this.locale === 'en' ? 'MM/DD/YY' : 'DD/MM/YY';
  }

  get dateFormatWithHour(): string {
    return this.locale === 'en' ? 'MM/DD/YY HH:mm' : 'DD/MM/YY HH:mm';
  }

  get blurToApply(): Blur[] {
    logger.info('compute blurToApply');
    return this.blurs.filter((blur) => {
      const date = new Date(this.photo.createdAt).getTime() / 1000;
      const begin = new Date((blur.begin ?? 0) * 1000).getTime() / 1000;
      if (blur.photo) {
        return blur.photo === this.photo.id;
      } else if (blur.noEnd) {
        return begin <= date;
      } else {
        const end = new Date((blur.end ?? 0) * 1000).getTime() / 1000;
        return begin <= date && date <= end;
      }
    });
  }
  @Prop({ type: Object, required: true }) readonly camera!: Camera;
  @Prop({ type: Object, required: true }) readonly project!: Project;
  @Prop({ type: Array, required: true }) readonly photos!: Photo[];
  @Prop({ type: Object, required: true }) readonly value!: Photo;
  @Prop({ required: true }) readonly isPremium!: boolean;
  // private photo!: Photo;
  private get photo(): Photo {
    return this.value;
  }
  private set photo(photo: Photo) {
    if (JSON.stringify(photo) !== JSON.stringify(this.value)) {
      logger.info('onModelChange', photo.key);
      this.$emit('input', photo);
      this.preDraw(photo);
    }
  }

  @Ref('blur-modal') readonly modal!: BModal;

  @State('locale', { namespace: 'profile' }) readonly locale!: string;
  @Action('loadBlurProject', { namespace: 'project' }) loadBlurProject!: (data: {
    projectId: string;
    cameraId: string;
  }) => Promise<Blur[]>;
  @Action('saveBlurProject', { namespace: 'project' }) saveBlurProject!: (data: {
    projectId: string;
    cameraId: string;
    blurs: Blur[];
  }) => Promise<Blur[]>;
  private loadingBlur = false;

  private canvas: HTMLCanvasElement | null = null;
  private ctx: CanvasRenderingContext2D | null = null;

  private drag = false;
  private imageObj: HTMLImageElement = new Image();

  private blurs: BlurForm[] = [];
  private currentBlurIndex = -1;
  private mainSliderValue: Photo | null = null;
  private mainSliderIsDragging = false;

  /*
  @Watch('photo', { deep: true })
  onModelChange(photo: Photo): void {
    if (JSON.stringify(photo) !== JSON.stringify(this.value)) {
      logger.info('onModelChange', photo.key);
      this.$emit('input', photo);
      this.preDraw();
    }
  }
  @Watch('value', { deep: true })
  onValueChange(value: Photo): void {
    logger.info('onValueChange');
    this.photo = Object.create(value);
  }
  */

  marks(val: Photo | undefined): number | unknown {
    if (val === undefined) {
      return 0;
    } else {
      const blur = this.blurs.find((b) => b.photo === val.id);
      if (blur === undefined) {
        return 0;
      } else {
        return {
          label: '',
          style: {
            width: '8px',
            height: '8px',
            display: 'block',
            backgroundColor: (blur.area as Rectangle).color,
            transform: 'translate(-2px, -2px)',
          },
          labelStyle: {
            color: 'red',
          },
        };
      }
    }
  }

  mounted(): void {
    logger.info('mounted');
    this.loadingBlur = true;
    this.loadBlurProject({ projectId: this.project.id, cameraId: this.camera.id }).then((data) => {
      this.loadingBlur = false;
      this.blurs = data.map((blur: Blur) => {
        const photoBegin = this.photos.find((p) => new Date(p.createdAt).getTime() / 1000 === blur.begin);
        const photoEnd = this.photos.find((p) => blur.end && new Date(p.createdAt).getTime() / 1000 === blur.end);
        return {
          ...blur,
          photoBegin,
          photoEnd,
          area: { ...blur.area, color: this.getRandomColor() },
        };
      });
    });
  }

  mouseMove(e: MouseEvent): void {
    if (this.drag) {
      logger.info('mouseMove');
      const { x, y } = this.getMousePos(e);
      logger.info({
        x,
        y,
        areaX: this.blurs[this.currentBlurIndex].area.x,
        areaY: this.blurs[this.currentBlurIndex].area.y,
      });
      this.blurs[this.currentBlurIndex].area.w = x - this.blurs[this.currentBlurIndex].area.x;
      this.blurs[this.currentBlurIndex].area.h = y - this.blurs[this.currentBlurIndex].area.y;
      this.draw();
    }
  }

  show(): void {
    this.modal.show();
  }

  onShown(): void {
    logger.info('onShown');
    if (this.canvas === null) {
      this.canvas = document.getElementById('blur-canvas') as HTMLCanvasElement;
      this.mainSliderValue = this.photos[0];
      this.ctx = this.canvas.getContext('2d');

      this.canvas.addEventListener(
        'mousedown',
        (e) => {
          logger.info('mousedown');
          if (this.currentBlurIndex !== -1) {
            const { x, y } = this.getMousePos(e);
            this.blurs[this.currentBlurIndex].area.x = x;
            this.blurs[this.currentBlurIndex].area.y = y;
            this.drag = true;
          }
        },
        false
      );
      this.canvas.addEventListener(
        'mouseup',
        () => {
          logger.info('mouseup');
          this.drag = false;
        },
        false
      );
      this.canvas.addEventListener('mousemove', this.mouseMove, false);

      this.changeCurrentBlur(0);
    }
    if (this.photo !== null) {
      this.$nextTick().then(() => this.preDraw(this.photo));
    }
  }

  close(): void {
    logger.info('close');
    this.modal.hide();
  }

  private getMousePos(evt: MouseEvent): { x: number; y: number } {
    if (this.canvas === null) {
      logger.warn('no canvas');
      return { x: 0, y: 0 };
    }
    const rect = this.canvas.getBoundingClientRect();
    return {
      x: ((evt.clientX - rect.left) / (rect.right - rect.left)) * this.canvas.width,
      y: ((evt.clientY - rect.top) / (rect.bottom - rect.top)) * this.canvas.height,
    };
  }

  private getRwRh = (rect: Rectangle) => {
    let rw;
    let rh;
    if (rect.w < 0) {
      rw = rect.x + rect.w;
    } else {
      rw = rect.x;
    }
    if (rect.h < 0) {
      rh = rect.y + rect.h;
    } else {
      rh = rect.y;
    }
    return { rw, rh };
  };

  private async preDraw(photo: Photo): Promise<void> {
    logger.info('preDraw', photo.mediumKey);
    if (photo.mediumKey !== undefined) {
      return Storage.get(photo.mediumKey).then((signedUrl) => {
        this.imageObj = new Image();
        this.imageObj.crossOrigin = 'Anonymous';
        logger.info(signedUrl);
        this.imageObj.onload = () => {
          logger.info('image loaded');
          this.draw();
        };
        this.imageObj.src = signedUrl as string;
      });
    }
  }

  private draw() {
    if (this.ctx !== null && this.canvas !== null) {
      const imageWidth = this.imageObj.width;
      const imageHeight = this.imageObj.height;
      const canvasWidth = this.canvas.clientWidth;
      const canvasHeight = this.canvas.clientHeight;
      logger.info('draw', { imageWidth, imageHeight, canvasWidth, canvasHeight });

      this.ctx.beginPath();
      this.ctx.filter = `blur(8px)`;
      this.drawImagFit(this.imageObj, this.ctx, this.canvas);

      const floux: ImageData[] = this.blurToApply
        .map((blur) => {
          if (blur.area.w !== 0 && blur.area.h !== 0) {
            return this.ctx?.getImageData(blur.area.x, blur.area.y, blur.area.w, blur.area.h);
          } else {
            return undefined;
          }
        })
        .filter((x): x is ImageData => x !== undefined);
      logger.info({ floux, blurToApply: this.blurToApply, blurs: this.blurs });

      this.ctx.clearRect(0, 0, canvasWidth, canvasHeight);
      this.ctx.beginPath();
      this.ctx.filter = 'none';
      this.drawImagFit(this.imageObj, this.ctx, this.canvas);
      for (let index = 0; index < floux.length; index++) {
        const rect = this.blurToApply[index].area as Rectangle;
        this.putImageData(floux[index], rect, this.ctx);
      }
    }
  }

  private drawImagFit(img: HTMLImageElement, ctx: CanvasRenderingContext2D, canvas: HTMLCanvasElement) {
    // get the scale
    const scale = Math.min(canvas.width / img.width, canvas.height / img.height);
    // get the top left position of the image
    const x = canvas.width / 2 - (img.width / 2) * scale;
    const y = canvas.height / 2 - (img.height / 2) * scale;
    ctx.drawImage(img, x, y, img.width * scale, img.height * scale);
  }

  private putImageData(imgData: ImageData, rect: Rectangle, ctx: CanvasRenderingContext2D) {
    logger.info('putImageData', rect);
    const { rw, rh } = this.getRwRh(rect);
    ctx.beginPath();
    ctx.putImageData(imgData, rw, rh);
    ctx.strokeStyle = rect.color;
    ctx.strokeRect(rect.x, rect.y, rect.w, rect.h);
  }

  private getRandomColor() {
    let colors = [
      '#1abc9c',
      '#2ecc71',
      '#3498db',
      '#9b59b6',
      '#34495e',
      '#16a085',
      '#27ae60',
      '#2980b9',
      '#8e44ad',
      '#2c3e50',
      '#f1c40f',
      '#e67e22',
      '#e74c3c',
      '#ecf0f1',
      '#95a5a6',
      '#f39c12',
      '#d35400',
      '#c0392b',
      '#bdc3c7',
      '#7f8c8d',
    ];
    colors = colors.filter((c) => this.blurs.map((r) => (r.area as Rectangle).color).indexOf(c) === -1);
    return colors[Math.floor(Math.random() * (colors.length - 1))];
  }

  private onSliderChange(blurIndex: number, e: Photo[] | Photo) {
    const blur = this.blurs[blurIndex];
    if (Array.isArray(e)) {
      blur.begin = new Date(e[0].createdAt).getTime() / 1000;
      blur.photoBegin = e[0];
      blur.end = new Date(e[1].createdAt).getTime() / 1000;
      blur.photoEnd = e[1];
    } else {
      blur.photoBegin = e;
      blur.begin = new Date(e.createdAt).getTime() / 1000;
    }
  }

  private onMainSliderChange(photo: Photo) {
    if (!this.mainSliderIsDragging) {
      this.photo = photo;
    }
  }
  private onMainSliderDragStart() {
    logger.info('onMainSliderDragStart');
    this.mainSliderIsDragging = true;
  }

  private onMainSliderDragEnd() {
    if (this.mainSliderValue !== null) {
      logger.info('onMainSliderDragEnd');
      this.mainSliderIsDragging = false;
      this.photo = this.mainSliderValue;
    }
  }

  private onNoEndChange(blurIndex: number, noEnd: boolean) {
    logger.info('onNoEndChange', blurIndex, noEnd);
    const blur = this.blurs[blurIndex];
    blur.noEnd = noEnd;
    if (noEnd) {
      const save1 = blur.begin;
      const save2 = blur.photoBegin;
      blur.begin = 0;
      blur.photoBegin = undefined;
      blur.end = undefined;
      blur.photoEnd = undefined;
      this.$nextTick().then(() => {
        blur.begin = save1;
        blur.photoBegin = save2;
      });
    }
  }

  private changeCurrentBlur(blurIndex: number) {
    logger.info('changeCurrentBlur', {
      blurIndex,
      valid: this.currentBlurIndex !== blurIndex && blurIndex < this.blurs.length,
    });
    if (this.currentBlurIndex !== blurIndex && blurIndex < this.blurs.length) {
      this.currentBlurIndex = blurIndex;
      const blur = this.blurs[blurIndex];
      let newPhoto: Photo | undefined;
      if (blur.photo) {
        newPhoto = this.photos.find((p) => p.id === blur.photo);
      } else {
        newPhoto = this.photos.find(
          (p) => new Date(p.createdAt).getTime() > new Date((blur.begin as number) * 1000).getTime()
        );
      }
      if (newPhoto !== undefined) {
        this.mainSliderValue = newPhoto;
        this.preDraw(newPhoto);
      }
    }
  }

  private addregion() {
    logger.info('addregion');
    this.blurs.push({
      area: { h: 0, w: 0, x: 0, y: 0, color: this.getRandomColor() } as Rectangle,
      begin: new Date(this.photos[0].createdAt).getTime() / 1000,
      photoBegin: this.photos[0],
      end: new Date(this.photos[this.photos.length - 1].createdAt).getTime() / 1000,
      photoEnd: this.photos[this.photos.length - 1],
      noEnd: false,
    });
    this.currentBlurIndex = this.blurs.length - 1;
  }

  private addphoto() {
    logger.info('addphoto');
    this.blurs.push({
      area: { h: 0, w: 0, x: 0, y: 0, color: this.getRandomColor() } as Rectangle,
      photo: this.photo?.id,
      noEnd: false,
    });
    this.currentBlurIndex = this.blurs.length - 1;
  }

  private save() {
    logger.info('save');
    this.loadingBlur = true;
    this.saveBlurProject({ projectId: this.project.id, cameraId: this.camera.id, blurs: this.blurs }).then(() => {
      this.loadingBlur = false;
      this.close();
    });
  }
}
