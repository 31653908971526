import 'reflect-metadata';
import { jsonObject, jsonMember, toJson, TypedJSON } from 'typedjson';

@jsonObject
@toJson
export class BatteryLevel {
  @jsonMember
  public date!: Date;

  @jsonMember
  public level!: number;
}

export const serializer = new TypedJSON(BatteryLevel);
