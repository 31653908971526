import { API } from 'aws-amplify';
import { Camera, serializer } from '../../models/camera';
import {
  CameraTikeeCreateRequest,
  CameraTikeeUpdateRequest,
  CameraUpdateRequest,
  CameraDeleteRequest,
} from './cameras.interfaces';
import { Shadow } from '../../models/shadow';

export class CameraService {
  public constructor(private readonly apiName: string) {}

  public async getMyCamera(): Promise<Camera[]> {
    return API.get(this.apiName, '/cameras/v2', {}).then((v) => serializer.parseAsArray(v.cameras));
  }

  public async createCameraTikee(cameraCreateRequest: CameraTikeeCreateRequest): Promise<Camera | undefined> {
    return API.post(this.apiName, '/cameras/tikee/new', {
      body: cameraCreateRequest,
    }).then((v) => serializer.parse(v));
  }

  public async updateCameraTikee(cameraUpdateRequest: CameraTikeeUpdateRequest): Promise<Camera | undefined> {
    return API.post(this.apiName, '/cameras/tikee/update', {
      body: cameraUpdateRequest,
    }).then((v) => serializer.parse(v));
  }

  public async importAllPhotoCameraTikee(cameraId: string): Promise<void> {
    return API.post(this.apiName, `/cameras/${cameraId}/import/tikee`, {});
  }

  public async updateCamera(cameraUpdateRequest: CameraUpdateRequest): Promise<Camera | undefined> {
    return API.post(this.apiName, '/cameras/update', {
      body: cameraUpdateRequest,
    }).then((v) => serializer.parse(v));
  }

  public async updateConfiguration(cameraUpdateConfigurationRequest: Shadow): Promise<Shadow> {
    return await API.post(this.apiName, '/cameras/update/configuration', {
      body: cameraUpdateConfigurationRequest,
    }).then((response) => {
      return response.state.desired as Shadow;
    });
  }

  public async deleteCamera(cameraDeleteRequest: CameraDeleteRequest): Promise<void> {
    return API.post(this.apiName, '/cameras/delete', {
      body: cameraDeleteRequest,
    });
  }

  public async requestVideoStream(cameraId: string): Promise<string> {
    return API.post(this.apiName, `/cameras/${cameraId}/livestream`, {});
  }
}
