import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Camera } from '@/models/camera';
import { Project } from '@/models/project';
import BatterieLevelComponent from '@/components/batterielevel/batterielevel.component.vue';
import { weatherService } from '@/services/';
import { State } from 'vuex-class';
import { ForecastResult, WeatherResult } from '@/services/weather/weather.interfaces';
import { Cache } from 'aws-amplify';
import { addHours } from 'date-fns';
import { Logger } from 'aws-amplify';
const logger = new Logger('Data');

@Component({
  components: {
    BatterieLevelComponent,
  },
})
export default class Data extends Vue {
  @Prop({ type: Object, required: true }) private readonly camera!: Camera;
  @Prop({ type: Object, required: true }) private project!: Project;
  @State('locale', { namespace: 'profile' }) private readonly locale!: string;

  private visible = false;

  private weather: WeatherResult | null = null;
  private forecast: ForecastResult | null = null;
  private showBatteryGraph: boolean | null = false;

  private get isDeleted(): boolean {
    return (
      this.project.previousCameras !== undefined &&
      this.project.previousCameras.map((c) => c.id).indexOf(this.camera.id) !== -1
    );
  }

  private get temperatureUnit(): 'metric' | 'imperial' {
    return this.locale === 'en' ? 'imperial' : 'metric';
  }

  private get weatherOfDayPicto(): string {
    return this.getPictoOf(this.weather);
  }

  private toggleBatVide = false;
  private interval: NodeJS.Timeout | null = null;
  private get batteriePicto(): string {
    const base = 'picto-erige ';
    if (this.camera.shadow === undefined) {
      return `${base}`;
    } else if (this.camera.shadow.reported.Power_supply === '1') {
      return `${base} picto-batterie-charge`;
    } else if (this.camera.shadow.reported.Battery_Status !== '0') {
      return `${base} picto-batterie-${this.camera.shadow.reported.Battery_Status}`;
    } else {
      if (this.interval) {
        clearInterval(this.interval);
      }
      this.interval = setInterval(() => {
        this.toggleBatVide = !this.toggleBatVide;
      }, 2000);
      return `${base} picto-batterie-0`;
    }
  }

  private mounted() {
    logger.info('mounted', { camera: this.camera });
    this.onLocaleChange();

    if (this.project.id && this.project.city && this.project.city.latlng) {
      this.forecast = Cache.getItem(`forecast-${this.project.id}`);
      logger.info('get forecast cache', { forecast: this.forecast });
      if (this.forecast === null) {
        weatherService
          .forecastByCoordinate(this.project.city.latlng.lat, this.project.city.latlng.lng)
          .then((forecastResult: ForecastResult) => {
            this.forecast = forecastResult;
            logger.info('set forecast cache', { forecast: this.forecast });
            const expiration = addHours(new Date(), 12);
            Cache.setItem('forecast-' + this.project.id, forecastResult, { expires: expiration.getTime() });
          });
      }
    }
  }

  private getPictoOf(weather: WeatherResult | null): string {
    const base = 'picto-erige ';
    if (weather === null || weather.weather === undefined || weather.weather[0] === undefined) {
      return base;
    } else {
      switch (weather.weather[0].main) {
        case 'Clear':
          return base + 'picto-meteo-soleil';
        case 'Clouds':
          if (weather.weather[0].icon === '02d' || weather.weather[0].icon === '02n') {
            return base + 'picto-meteo-mixte';
          } else {
            return base + 'picto-meteo-nuage';
          }
        case 'Drizzle':
        case 'Rain':
        case 'Thunderstorm':
        case 'Snow':
        case 'Atmosphere':
        default:
          return base + 'picto-meteo-pluie';
      }
    }
  }

  @Watch('locale')
  private onLocaleChange() {
    if (this.project.id && this.project.city && this.project.city.latlng) {
      this.weather = Cache.getItem(`weather-${this.project.id}-${this.locale}`);
      if (this.weather === null) {
        weatherService
          .weatherByCoordinate(this.project.city.latlng.lat, this.project.city.latlng.lng, this.temperatureUnit)
          .then((weatherResult) => {
            this.weather = weatherResult;
            const expiration = addHours(new Date(), 12);
            Cache.setItem(`weather-${this.project.id}-${this.locale}`, weatherResult, {
              expires: expiration.getTime(),
            });
          });
      }
    }
  }
}
