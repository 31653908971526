import 'reflect-metadata';
import { jsonObject, jsonMember, toJson, TypedJSON } from 'typedjson';

@jsonObject
@toJson
export class User {
  @jsonMember id!: string;
  @jsonMember createdAt!: Date;
  @jsonMember firstLogin?: boolean;
  @jsonMember email!: string;
  @jsonMember firstname?: string;
  @jsonMember lastname?: string;
  @jsonMember birthday?: Date;
  @jsonMember company?: string;
  @jsonMember function?: string;
  @jsonMember acceptedCgu?: boolean;
  @jsonMember acceptedNewslettre?: boolean;
  @jsonMember notification?: boolean;
  @jsonMember locale?: string;
  @jsonMember volumeData?: number;
}

export const serializer = new TypedJSON(User);

// eslint-disable-next-line max-classes-per-file
@jsonObject
@toJson
export class SmallUser implements Pick<User, 'id' | 'firstname' | 'lastname' | 'email' | 'locale'> {
  @jsonMember
  public firstname?: string;
  @jsonMember
  public lastname?: string;
  @jsonMember
  public email!: string;
  @jsonMember
  public locale?: string;
  @jsonMember
  public id!: string;
}
