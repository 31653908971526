
























































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { State, Action, Getter, Mutation } from 'vuex-class';
import { Camera } from '@/models/camera';
import { Project } from '@/models/project';
import { User } from '@/models/user';
import { Photo } from '@/models/photo';
import { Video } from '@/models/video';
import { Album } from '@/models/album';
import ProjectModal from '@/components/projectmodal/projectmodal.component.vue';
// @ts-ignore: no d.ts file
import { vTeleport } from '@desislavsd/vue-teleport';
import Gallery from '../components/gallery/gallery.component.vue';
import GalleryCompare from '../components/gallerycompare/gallerycompare.component.vue';
import AlbumComponent from '../components/album/album.component.vue';
import Scenario from '../components/scenario/scenario.component.vue';
import TimelapseCard from '../components/timelapsecard/timelapsecard.component.vue';
import Livestream from '../components/livestream/livestream.component.vue';
import Data from '../components/data/data.component.vue';
import Description from '../components/description/description.component.vue';
import { NotificationMessage, Socket } from '@/store/types';
import { Logger } from 'aws-amplify';
import Shepherd from 'shepherd.js';
import 'shepherd.js/dist/css/shepherd.css';
import { offset } from '@floating-ui/dom';
const logger = new Logger('PlateformCentral');

@Component({
  components: {
    vTeleport,
    Gallery,
    GalleryCompare,
    AlbumComponent,
    Scenario,
    TimelapseCard,
    Livestream,
    Data,
    Description,
    ProjectModal,
  },
})
export default class PlateformCentral extends Vue {
  @Prop(String) readonly id!: string;
  @Prop({ default: null }) readonly cameraId!: string;

  @State('user', { namespace: 'profile' }) private user!: User;
  @State('premium', { namespace: 'profile' }) readonly isPremium!: boolean;

  @State('projects', { namespace: 'projects' }) readonly projects!: Project[];
  @Getter('projectWithId', { namespace: 'projects' }) readonly projectWithId!: (id: string) => Project | undefined;

  @State('timelapse', { namespace: 'project' }) readonly timelapse!: Video[];
  @State('premium', { namespace: 'project' }) readonly ownerPremium!: boolean;
  @Getter('timelapseForCamera', { namespace: 'project' }) readonly timelapseForCamera!: (
    cameraId: string
  ) => Video | null;
  @Action('setProject', { namespace: 'project' }) readonly setProject!: (project: Project) => Promise<void>;

  @Action('findAllFor', { namespace: 'photo' }) readonly findPhotoAllForProject!: (payload: {
    projectId: string;
    cameraId: string;
  }) => Promise<Photo[]>;
  @Action('loadAlbum', { namespace: 'album' }) readonly loadAlbum!: (payload: {
    projectId: string;
    cameraId: string;
  }) => Promise<Album[]>;

  @State('socket') readonly socketMessage!: Socket;
  @Action('connectSocket') readonly connectSocket!: () => Promise<void>;
  @Action('disconnectSocket') readonly disconnectSocket!: () => Promise<void>;
  @Mutation('SOCKET_ONMESSAGE') readonly resetSocketMessage!: (object: string) => void;

  private project: Project | null = null;
  private lastVideo: Video | null = null;
  private galleryComparaison: string | null = null;
  private newPhotoAvailable = false;

  public get cameraSelected(): string | null {
    return (this.$route.query.camera as string) || null;
  }
  public set cameraSelected(s: string | null) {
    if (s !== null && this.$route.query.camera !== s) {
      this.$router.replace({ path: this.$route.path, query: { ...this.$route.query, camera: s } });
    }
  }

  public get camera(): Camera | undefined {
    logger.info('get camera ' + this.cameraSelected);
    return (
      this.project?.cameras?.find((camera) => camera.id === this.cameraSelected) ||
      this.project?.previousCameras?.find((camera) => camera.id === this.cameraSelected)
    );
  }

  public get breadcrumbs(): unknown[] {
    const items = [
      {
        text: this.$tc('global.project', 2),
        to: '/',
      },
    ];
    if (this.project) {
      items.push({
        text: this.project.name,
        to: '#',
      });
    }
    return items;
  }

  resizing = false;

  startResize(event: MouseEvent) {
    this.resizing = true;
    window.addEventListener('mousemove', this.performResize);
    window.addEventListener('mouseup', this.stopResize);
  }

  performResize(event: MouseEvent) {
    if (this.resizing) {
      const galleryElement = this.$el.querySelector('.gallery') as HTMLElement;
      if (galleryElement !== null) {
        const newWidth = event.clientX - galleryElement.getBoundingClientRect().left;
        galleryElement.style.width = `${newWidth}px`;
      }
    }
  }

  stopResize() {
    if (this.resizing) {
      this.resizing = false;
      window.removeEventListener('mousemove', this.performResize);
      window.removeEventListener('mouseup', this.stopResize);
    }
  }

  public get camerasOptions(): unknown[] {
    const project = this.project;
    const data = [
      ...(project?.cameras?.map((camera) => {
        return {
          text: `${camera.name}`,
          value: camera.id,
        };
      }) ?? []),
      ...(project?.previousCameras?.map((camera) => {
        return {
          text: `${camera.name} (deleted)`,
          value: camera.id,
        };
      }) ?? []),
    ];
    if (data.length > 0) {
      return data;
    } else {
      return [{ text: this.$t('plateformcentral.noCamera').toString(), value: null }];
    }
  }

  public get guest(): boolean | undefined {
    return this.project?.guestId.includes(this.user.id);
  }

  public get isAdmin(): boolean {
    if (this.user && this.project) {
      return (
        this.project.owner.id === this.user.id ||
        (this.project.administratorId && this.project.administratorId.includes(this.user.id))
      );
    }
    return false;
  }

  public initShepherd() {
    if (this.project && this.project.unpaid) {
      const tour = new Shepherd.Tour({
        defaultStepOptions: {
          cancelIcon: {
            enabled: true,
          },
        },
      });

      tour.addStep({
        title: 'Avis de paiement en retard',
        text: "<strong>Attention:</strong> Une situation d'impayés a été remarqué sur votre compte. <br>Afin de continuer à bénéficier de votre interface, merci de vérifier les factures en attente de règlement ou de vous rapprocher de votre commercial.",
        buttons: [
          {
            text: 'Compris',
            action: tour.next,
          },
        ],
      });

      tour.start();
    }
  }

  public mounted(): void {
    logger.info('mounted');
    this.onStateProjectLoaded(this.projects, undefined);
    this.connectSocket();
    this.$nextTick(() => {
      setTimeout(() => {
        this.initShepherd();
      }, 5000);

      setTimeout(() => {
        if (localStorage.getItem('tourIgnored')) {
          return;
        }
        if (window.innerWidth >= 700) {
          const tour = new Shepherd.Tour({
            defaultStepOptions: {
              cancelIcon: {
                enabled: true,
              },
              classes: 'shepherd-content', // Ajoutez votre propre classe CSS
              scrollTo: true,
              when: {
                show() {
                  const currentStep = Shepherd.activeTour?.getCurrentStep();
                  if (!currentStep) return;
                  const currentStepElement = currentStep.getElement();
                  if (!currentStepElement) return;
                  const header = currentStepElement.querySelector('.shepherd-footer');
                  if (!header) return;
                  const progress = document.createElement('div');
                  const innerBar = document.createElement('span');
                  const progressPercentage = ((tour.steps.indexOf(currentStep) + 1) / tour.steps.length) * 100 + '%';
                  progress.className = 'shepherd-progress-bar';
                  innerBar.style.width = progressPercentage;
                  if (document.getElementsByClassName('shepherd-button').length == 1) {
                    progress.style.minWidth = '260px';
                  }
                  progress.appendChild(innerBar);
                  const headerh = currentStepElement.querySelector('.shepherd-header');
                  const progressh = document.createElement('span');
                  progressh.style.marginRight = '0px';
                  progressh.innerText = `${tour.steps.indexOf(currentStep) + 1}/${tour.steps.length}`;
                  if (headerh) {
                    headerh.insertBefore(progressh, currentStepElement.querySelector('.shepherd-cancel-icon'));
                  }
                  header.insertBefore(progress, currentStepElement.querySelector('.shepherd-button'));
                },
              },
            },
            useModalOverlay: true,
            keyboardNavigation: false,
          });

          tour.addStep({
            title: this.$t('plateformcentral.step1title').toString(),
            text: this.$t('plateformcentral.step1text').toString(),
            attachTo: {
              element: 'nothing', // La classe CSS d  e l'élément que vous voulez souligner
              on: 'left',
            },
            buttons: [
              {
                action: () => {
                  localStorage.setItem('tourIgnored', 'true');
                  tour.complete();
                },
                text: this.$t('projectlist.donotshow').toString(),
                classes: 'shepherd-button-ignore',
              },
              {
                action: tour.next,
                text: this.$t('projectlist.next').toString(),
                classes: 'shepherd-button-next',
              },
            ],
          });
          tour.addStep({
            title: this.$t('plateformcentral.step2title').toString(),
            text: this.$t('plateformcentral.step2text').toString(),
            attachTo: {
              element: '.cameraSelectList ', // La classe CSS de l'élément que vous voulez souligner
              on: 'right',
            },
            buttons: [
              {
                action: tour.back,
                text: this.$t('projectlist.previous').toString(),
                classes: 'shepherd-button-ignore',
              },
              {
                action: tour.next,
                text: this.$t('projectlist.next').toString(),
                classes: 'shepherd-button-next',
              },
            ],
            floatingUIOptions: {
              middleware: [offset({ mainAxis: 10, crossAxis: 0 })],
            },
          });
          tour.addStep({
            title: this.$t('plateformcentral.step3title').toString(),
            text: this.$t('plateformcentral.step3text').toString(),
            attachTo: {
              element: '.gallery-photo-preview-action', // La classe CSS de l'élément que vous voulez souligner
              on: 'right',
            },
            buttons: [
              {
                action: tour.back,
                text: this.$t('projectlist.previous').toString(),
                classes: 'shepherd-button-ignore',
              },
              {
                action: tour.next,
                text: this.$t('projectlist.next').toString(),
                classes: 'shepherd-button-next',
              },
            ],
            floatingUIOptions: {
              middleware: [offset({ mainAxis: 10, crossAxis: -60 })],
            },
            canClickTarget: false,
          });

          tour.addStep({
            title: this.$t('plateformcentral.step4title').toString(),
            text: this.$t('plateformcentral.step4text').toString(),
            attachTo: {
              element: '.gallery-action', // La classe CSS de l'élément que vous voulez souligner
              on: 'right',
            },
            buttons: [
              {
                action: tour.back,
                text: this.$t('projectlist.previous').toString(),
                classes: 'shepherd-button-ignore',
              },
              {
                action: tour.next,
                text: this.$t('projectlist.next').toString(),
                classes: 'shepherd-button-next',
              },
            ],
            floatingUIOptions: {
              middleware: [offset({ mainAxis: 20, crossAxis: 0 })],
            },
            canClickTarget: false,
          });
          tour.addStep({
            title: this.$t('plateformcentral.step41title').toString(),
            text: this.$t('plateformcentral.step41text').toString(),
            attachTo: {
              element: '.picto-coeur', // La classe CSS de l'élément que vous voulez souligner
              on: 'right',
            },
            buttons: [
              {
                action: tour.back,
                text: this.$t('projectlist.previous').toString(),
                classes: 'shepherd-button-ignore',
              },
              {
                action: tour.next,
                text: this.$t('projectlist.next').toString(),
                classes: 'shepherd-button-next',
              },
            ],
            floatingUIOptions: {
              middleware: [offset({ mainAxis: 20, crossAxis: 0 })],
            },
            canClickTarget: false,
          });
          tour.addStep({
            title: this.$t('plateformcentral.step42title').toString(),
            text: this.$t('plateformcentral.step42text').toString(),
            attachTo: {
              element: '.zoomerbtn', // La classe CSS de l'élément que vous voulez souligner
              on: 'right',
            },
            buttons: [
              {
                action: tour.back,
                text: this.$t('projectlist.previous').toString(),
                classes: 'shepherd-button-ignore',
              },
              {
                action: tour.next,
                text: this.$t('projectlist.next').toString(),
                classes: 'shepherd-button-next',
              },
            ],
            floatingUIOptions: {
              middleware: [offset({ mainAxis: 20, crossAxis: 0 })],
            },
            canClickTarget: false,
          });
          tour.addStep({
            title: this.$t('plateformcentral.step43title').toString(),
            text: this.$t('plateformcentral.step43text').toString(),
            attachTo: {
              element: '.picto-fullscreen', // La classe CSS de l'élément que vous voulez souligner
              on: 'right',
            },
            buttons: [
              {
                action: tour.back,
                text: this.$t('projectlist.previous').toString(),
                classes: 'shepherd-button-ignore',
              },
              {
                action: tour.next,
                text: this.$t('projectlist.next').toString(),
                classes: 'shepherd-button-next',
              },
            ],
            floatingUIOptions: {
              middleware: [offset({ mainAxis: 20, crossAxis: 0 })],
            },
            canClickTarget: false,
          });
          tour.addStep({
            title: this.$t('plateformcentral.step45title').toString(),
            text: this.$t('plateformcentral.step45text').toString(),
            attachTo: {
              element: '.picto-share', // La classe CSS de l'élément que vous voulez souligner
              on: 'right',
            },
            buttons: [
              {
                action: tour.back,
                text: this.$t('projectlist.previous').toString(),
                classes: 'shepherd-button-ignore',
              },
              {
                action: tour.next,
                text: this.$t('projectlist.next').toString(),
                classes: 'shepherd-button-next',
              },
            ],
            floatingUIOptions: {
              middleware: [offset({ mainAxis: 20, crossAxis: 0 })],
            },
            canClickTarget: false,
          });
          tour.addStep({
            title: this.$t('plateformcentral.step47title').toString(),
            text: this.$t('plateformcentral.step47text').toString(),
            attachTo: {
              element: '.picto-download', // La classe CSS de l'élément que vous voulez souligner
              on: 'right',
            },
            buttons: [
              {
                action: tour.back,
                text: this.$t('projectlist.previous').toString(),
                classes: 'shepherd-button-ignore',
              },
              {
                action: tour.next,
                text: this.$t('projectlist.next').toString(),
                classes: 'shepherd-button-next',
              },
            ],
            floatingUIOptions: {
              middleware: [offset({ mainAxis: 20, crossAxis: 0 })],
            },
            canClickTarget: false,
          });
          tour.addStep({
            title: this.$t('plateformcentral.step5title').toString(),
            text: this.$t('plateformcentral.step5text').toString(),
            attachTo: {
              element: '.comparebtn', // La classe CSS de l'élément que vous voulez souligner
              on: 'right',
            },
            buttons: [
              {
                action: tour.back,
                text: this.$t('projectlist.previous').toString(),
                classes: 'shepherd-button-ignore',
              },
              {
                action: tour.next,
                text: this.$t('projectlist.next').toString(),
                classes: 'shepherd-button-next',
              },
            ],
            floatingUIOptions: {
              middleware: [offset({ mainAxis: 20, crossAxis: 0 })],
            },
            canClickTarget: false,
          });
          tour.addStep({
            title: this.$t('plateformcentral.step6title').toString(),
            text: this.$t('plateformcentral.step6text').toString(),
            attachTo: {
              element: '.timelapsecard', // La classe CSS de l'élément que vous voulez souligner
              on: 'left',
            },
            buttons: [
              {
                action: function () {
                  // Aller directement à l'étape 10 (index 9)
                  tour.show(3);
                },
                text: this.$t('projectlist.previous').toString(),
                classes: 'shepherd-button-ignore',
              },
              {
                action: tour.next,
                text: this.$t('projectlist.next').toString(),
                classes: 'shepherd-button-next',
              },
            ],
            floatingUIOptions: {
              middleware: [offset({ mainAxis: 15, crossAxis: 0 })],
            },
            canClickTarget: false,
          });
          tour.addStep({
            title: this.$t('plateformcentral.step7title').toString(),
            text: this.$t('plateformcentral.step7text').toString(),
            attachTo: {
              element: '.scenariicard', // La classe CSS de l'élément que vous voulez souligner
              on: 'right',
            },
            buttons: [
              {
                action: tour.back,
                text: this.$t('projectlist.previous').toString(),
                classes: 'shepherd-button-ignore',
              },
              {
                action: function () {
                  let gripElement = document.querySelector('.album-title');
                  if (gripElement) {
                    (gripElement as HTMLElement).click();
                  }
                  tour.next();
                },
                text: this.$t('projectlist.next').toString(),
                classes: 'shepherd-button-next',
              },
            ],
            floatingUIOptions: {
              middleware: [offset({ mainAxis: 15, crossAxis: 0 })],
            },
            canClickTarget: false,
          });
          tour.addStep({
            title: this.$t('plateformcentral.step8title').toString(),
            text: this.$t('plateformcentral.step8text').toString(),
            attachTo: {
              element: '.albumcard', // La classe CSS de l'élément que vous voulez souligner
              on: 'left',
            },
            buttons: [
              {
                action: function () {
                  let gripElement = document.querySelector('.album-title');
                  if (gripElement) {
                    (gripElement as HTMLElement).click();
                  }
                  tour.back();
                },
                text: this.$t('projectlist.previous').toString(),
                classes: 'shepherd-button-ignore',
              },
              {
                action: function () {
                  let gripElement = document.querySelector('.album-title');
                  if (gripElement) {
                    (gripElement as HTMLElement).click();
                  }
                  let dataElement = document.querySelector('.data-title');
                  if (dataElement) {
                    (dataElement as HTMLElement).click();
                  }
                  tour.show(15);
                },
                text: this.$t('projectlist.next').toString(),
                classes: 'shepherd-button-next',
              },
            ],
            floatingUIOptions: {
              middleware: [offset({ mainAxis: 15, crossAxis: 0 })],
            },
            canClickTarget: false,
          });
          tour.addStep({
            title: this.$t('plateformcentral.step9title').toString(),
            text: this.$t('plateformcentral.step9text').toString(),
            attachTo: {
              element: '.picto-coeur', // La classe CSS de l'élément que vous voulez souligner
              on: 'left',
            },
            buttons: [
              {
                action: tour.back,
                text: this.$t('projectlist.previous').toString(),
                classes: 'shepherd-button-ignore',
              },
            ],
            advanceOn: { selector: '.picto-coeur', event: 'click' },
            floatingUIOptions: {
              middleware: [offset({ mainAxis: 10, crossAxis: 0 })],
            },
          });
          tour.addStep({
            title: this.$t('plateformcentral.step10title').toString(),
            text: this.$t('plateformcentral.step10text').toString(),
            attachTo: {
              element: '.popover', // La classe CSS de l'élément que vous voulez souligner
              on: 'right',
            },
            advanceOn: { selector: '.creeralbum', event: 'click' },
            floatingUIOptions: {
              middleware: [offset({ mainAxis: 10, crossAxis: 0 })],
            },
          });
          tour.addStep({
            title: this.$t('plateformcentral.step11title').toString(),
            text: this.$t('plateformcentral.step11text').toString(),
            attachTo: {
              element: '.datacard', // La classe CSS de l'élément que vous voulez souligner
              on: 'left',
            },
            buttons: [
              {
                action: function () {
                  let dataElement = document.querySelector('.data-title');
                  if (dataElement) {
                    (dataElement as HTMLElement).click();
                  }
                  let gripElement = document.querySelector('.album-title');
                  if (gripElement) {
                    (gripElement as HTMLElement).click();
                  }
                  tour.show(12);
                },
                text: this.$t('projectlist.previous').toString(),
                classes: 'shepherd-button-ignore',
              },
              {
                action: function () {
                  let dataElement = document.querySelector('.data-title');
                  if (dataElement) {
                    (dataElement as HTMLElement).click();
                  }
                  tour.next();
                },
                text: this.$t('projectlist.next').toString(),
                classes: 'shepherd-button-next',
              },
            ],
            floatingUIOptions: {
              middleware: [offset({ mainAxis: 15, crossAxis: 0 })],
            },
            canClickTarget: false,
          });
          tour.addStep({
            title: this.$t('plateformcentral.step12title').toString(),
            text: this.$t('plateformcentral.step12text').toString(),
            attachTo: {
              element: '.descriptioncard', // La classe CSS de l'élément que vous voulez souligner
              on: 'left',
            },
            buttons: [
              {
                action: function () {
                  let dataElement = document.querySelector('.data-title');
                  if (dataElement) {
                    (dataElement as HTMLElement).click();
                  }
                  tour.back();
                },
                text: this.$t('projectlist.previous').toString(),
                classes: 'shepherd-button-ignore',
              },
              {
                action: function () {
                  let gripElement = document.querySelector('.cc-nsge');
                  if (gripElement) {
                    (gripElement as HTMLElement).click();
                  }
                  tour.next();
                },
                text: this.$t('projectlist.next').toString(),
                classes: 'shepherd-button-next',
              },
            ],
            floatingUIOptions: {
              middleware: [offset({ mainAxis: 15, crossAxis: 0 })],
            },
            canClickTarget: false,
          });
          tour.addStep({
            title: this.$t('plateformcentral.step13title').toString(),
            text: this.$t('plateformcentral.step13text').toString(),
            attachTo: {
              element: '.cc-1hqb', // La classe CSS de l'élément que vous voulez souligner
              on: 'left',
            },
            buttons: [
              {
                action: function () {
                  let dataElement = document.querySelector('.cc-imbb');
                  if (dataElement) {
                    (dataElement as HTMLElement).click();
                  }
                  tour.back();
                },
                text: this.$t('projectlist.previous').toString(),
                classes: 'shepherd-button-ignore',
              },
              {
                action: function () {
                  let gripElement = document.querySelector('.cc-nsge');
                  if (gripElement) {
                    (gripElement as HTMLElement).click();
                  }
                  tour.next();
                },
                text: this.$t('projectlist.next').toString(),
                classes: 'shepherd-button-next',
              },
            ],
            floatingUIOptions: {
              middleware: [offset({ mainAxis: 15, crossAxis: 0 })],
            },
          });
          tour.addStep({
            title: this.$t('plateformcentral.step14title').toString(),
            text: this.$t('plateformcentral.step14text').toString(),
            attachTo: {
              element: '.fa-circle-info', // La classe CSS de l'élément que vous voulez souligner
              on: 'bottom',
            },
            buttons: [
              {
                action: function () {
                  let dataElement = document.querySelector('.cc-imbb');
                  if (dataElement) {
                    (dataElement as HTMLElement).click();
                  }
                  tour.back();
                },
                text: this.$t('projectlist.previous').toString(),
                classes: 'shepherd-button-ignore',
              },
              {
                action: tour.next,
                text: this.$t('projectlist.donotshow').toString(),
                classes: 'shepherd-button-next',
              },
            ],
            floatingUIOptions: {
              middleware: [offset({ mainAxis: 15, crossAxis: 0 })],
            },
            canClickTarget: false,
          });
          tour.start();
        }
      }, 2000);
    });
  }

  public startTour(): void {
    const tour = new Shepherd.Tour({
      defaultStepOptions: {
        cancelIcon: {
          enabled: true,
        },
        classes: 'shepherd-content', // Ajoutez votre propre classe CSS
        scrollTo: true,
        when: {
          show() {
            const currentStep = Shepherd.activeTour?.getCurrentStep();
            if (!currentStep) return;
            const currentStepElement = currentStep.getElement();
            if (!currentStepElement) return;
            const header = currentStepElement.querySelector('.shepherd-footer');
            if (!header) return;
            const progress = document.createElement('div');
            const innerBar = document.createElement('span');
            const progressPercentage = ((tour.steps.indexOf(currentStep) + 1) / tour.steps.length) * 100 + '%';
            progress.className = 'shepherd-progress-bar';
            innerBar.style.width = progressPercentage;
            if (document.getElementsByClassName('shepherd-button').length == 1) {
              progress.style.minWidth = '260px';
            }
            progress.appendChild(innerBar);
            const headerh = currentStepElement.querySelector('.shepherd-header');
            const progressh = document.createElement('span');
            progressh.style.marginRight = '0px';
            progressh.innerText = `${tour.steps.indexOf(currentStep) + 1}/${tour.steps.length}`;
            if (headerh) {
              headerh.insertBefore(progressh, currentStepElement.querySelector('.shepherd-cancel-icon'));
            }
            header.insertBefore(progress, currentStepElement.querySelector('.shepherd-button'));
          },
        },
      },
      useModalOverlay: true,
      keyboardNavigation: false,
    });

    tour.addStep({
      title: this.$t('plateformcentral.step1title').toString(),
      text: this.$t('plateformcentral.step1text').toString(),
      attachTo: {
        element: 'nothing', // La classe CSS d  e l'élément que vous voulez souligner
        on: 'left',
      },
      buttons: [
        {
          action: () => {
            localStorage.setItem('tourIgnored', 'true');
            tour.complete();
          },
          text: this.$t('projectlist.donotshow').toString(),
          classes: 'shepherd-button-ignore',
        },
        {
          action: tour.next,
          text: this.$t('projectlist.next').toString(),
          classes: 'shepherd-button-next',
        },
      ],
    });
    tour.addStep({
      title: this.$t('plateformcentral.step2title').toString(),
      text: this.$t('plateformcentral.step2text').toString(),
      attachTo: {
        element: '.cameraSelectList ', // La classe CSS de l'élément que vous voulez souligner
        on: 'right',
      },
      buttons: [
        {
          action: tour.back,
          text: this.$t('projectlist.previous').toString(),
          classes: 'shepherd-button-ignore',
        },
        {
          action: tour.next,
          text: this.$t('projectlist.next').toString(),
          classes: 'shepherd-button-next',
        },
      ],
      floatingUIOptions: {
        middleware: [offset({ mainAxis: 10, crossAxis: 0 })],
      },
    });
    tour.addStep({
      title: this.$t('plateformcentral.step3title').toString(),
      text: this.$t('plateformcentral.step3text').toString(),
      attachTo: {
        element: '.gallery-photo-preview-action', // La classe CSS de l'élément que vous voulez souligner
        on: 'right',
      },
      buttons: [
        {
          action: tour.back,
          text: this.$t('projectlist.previous').toString(),
          classes: 'shepherd-button-ignore',
        },
        {
          action: tour.next,
          text: this.$t('projectlist.next').toString(),
          classes: 'shepherd-button-next',
        },
      ],
      floatingUIOptions: {
        middleware: [offset({ mainAxis: 10, crossAxis: -60 })],
      },
      canClickTarget: false,
    });

    tour.addStep({
      title: this.$t('plateformcentral.step4title').toString(),
      text: this.$t('plateformcentral.step4text').toString(),
      attachTo: {
        element: '.gallery-action', // La classe CSS de l'élément que vous voulez souligner
        on: 'right',
      },
      buttons: [
        {
          action: tour.back,
          text: this.$t('projectlist.previous').toString(),
          classes: 'shepherd-button-ignore',
        },
        {
          action: tour.next,
          text: this.$t('projectlist.next').toString(),
          classes: 'shepherd-button-next',
        },
      ],
      floatingUIOptions: {
        middleware: [offset({ mainAxis: 20, crossAxis: 0 })],
      },
      canClickTarget: false,
    });
    tour.addStep({
      title: this.$t('plateformcentral.step41title').toString(),
      text: this.$t('plateformcentral.step41text').toString(),
      attachTo: {
        element: '.picto-coeur', // La classe CSS de l'élément que vous voulez souligner
        on: 'right',
      },
      buttons: [
        {
          action: tour.back,
          text: this.$t('projectlist.previous').toString(),
          classes: 'shepherd-button-ignore',
        },
        {
          action: tour.next,
          text: this.$t('projectlist.next').toString(),
          classes: 'shepherd-button-next',
        },
      ],
      floatingUIOptions: {
        middleware: [offset({ mainAxis: 20, crossAxis: 0 })],
      },
      canClickTarget: false,
    });
    tour.addStep({
      title: this.$t('plateformcentral.step42title').toString(),
      text: this.$t('plateformcentral.step42text').toString(),
      attachTo: {
        element: '.zoomerbtn', // La classe CSS de l'élément que vous voulez souligner
        on: 'right',
      },
      buttons: [
        {
          action: tour.back,
          text: this.$t('projectlist.previous').toString(),
          classes: 'shepherd-button-ignore',
        },
        {
          action: tour.next,
          text: this.$t('projectlist.next').toString(),
          classes: 'shepherd-button-next',
        },
      ],
      floatingUIOptions: {
        middleware: [offset({ mainAxis: 20, crossAxis: 0 })],
      },
      canClickTarget: false,
    });
    tour.addStep({
      title: this.$t('plateformcentral.step43title').toString(),
      text: this.$t('plateformcentral.step43text').toString(),
      attachTo: {
        element: '.picto-fullscreen', // La classe CSS de l'élément que vous voulez souligner
        on: 'right',
      },
      buttons: [
        {
          action: tour.back,
          text: this.$t('projectlist.previous').toString(),
          classes: 'shepherd-button-ignore',
        },
        {
          action: tour.next,
          text: this.$t('projectlist.next').toString(),
          classes: 'shepherd-button-next',
        },
      ],
      floatingUIOptions: {
        middleware: [offset({ mainAxis: 20, crossAxis: 0 })],
      },
      canClickTarget: false,
    });
    tour.addStep({
      title: this.$t('plateformcentral.step45title').toString(),
      text: this.$t('plateformcentral.step45text').toString(),
      attachTo: {
        element: '.picto-share', // La classe CSS de l'élément que vous voulez souligner
        on: 'right',
      },
      buttons: [
        {
          action: tour.back,
          text: this.$t('projectlist.previous').toString(),
          classes: 'shepherd-button-ignore',
        },
        {
          action: tour.next,
          text: this.$t('projectlist.next').toString(),
          classes: 'shepherd-button-next',
        },
      ],
      floatingUIOptions: {
        middleware: [offset({ mainAxis: 20, crossAxis: 0 })],
      },
      canClickTarget: false,
    });
    tour.addStep({
      title: this.$t('plateformcentral.step47title').toString(),
      text: this.$t('plateformcentral.step47text').toString(),
      attachTo: {
        element: '.picto-download', // La classe CSS de l'élément que vous voulez souligner
        on: 'right',
      },
      buttons: [
        {
          action: tour.back,
          text: this.$t('projectlist.previous').toString(),
          classes: 'shepherd-button-ignore',
        },
        {
          action: tour.next,
          text: this.$t('projectlist.next').toString(),
          classes: 'shepherd-button-next',
        },
      ],
      floatingUIOptions: {
        middleware: [offset({ mainAxis: 20, crossAxis: 0 })],
      },
      canClickTarget: false,
    });
    tour.addStep({
      title: this.$t('plateformcentral.step5title').toString(),
      text: this.$t('plateformcentral.step5text').toString(),
      attachTo: {
        element: '.comparebtn', // La classe CSS de l'élément que vous voulez souligner
        on: 'right',
      },
      buttons: [
        {
          action: tour.back,
          text: this.$t('projectlist.previous').toString(),
          classes: 'shepherd-button-ignore',
        },
        {
          action: tour.next,
          text: this.$t('projectlist.next').toString(),
          classes: 'shepherd-button-next',
        },
      ],
      floatingUIOptions: {
        middleware: [offset({ mainAxis: 20, crossAxis: 0 })],
      },
      canClickTarget: false,
    });
    tour.addStep({
      title: this.$t('plateformcentral.step6title').toString(),
      text: this.$t('plateformcentral.step6text').toString(),
      attachTo: {
        element: '.timelapsecard', // La classe CSS de l'élément que vous voulez souligner
        on: 'left',
      },
      buttons: [
        {
          action: function () {
            // Aller directement à l'étape 10 (index 9)
            tour.show(3);
          },
          text: this.$t('projectlist.previous').toString(),
          classes: 'shepherd-button-ignore',
        },
        {
          action: tour.next,
          text: this.$t('projectlist.next').toString(),
          classes: 'shepherd-button-next',
        },
      ],
      floatingUIOptions: {
        middleware: [offset({ mainAxis: 15, crossAxis: 0 })],
      },
      canClickTarget: false,
    });
    tour.addStep({
      title: this.$t('plateformcentral.step7title').toString(),
      text: this.$t('plateformcentral.step7text').toString(),
      attachTo: {
        element: '.scenariicard', // La classe CSS de l'élément que vous voulez souligner
        on: 'right',
      },
      buttons: [
        {
          action: tour.back,
          text: this.$t('projectlist.previous').toString(),
          classes: 'shepherd-button-ignore',
        },
        {
          action: function () {
            let gripElement = document.querySelector('.album-title');
            if (gripElement) {
              (gripElement as HTMLElement).click();
            }
            tour.next();
          },
          text: this.$t('projectlist.next').toString(),
          classes: 'shepherd-button-next',
        },
      ],
      floatingUIOptions: {
        middleware: [offset({ mainAxis: 15, crossAxis: 0 })],
      },
      canClickTarget: false,
    });
    tour.addStep({
      title: this.$t('plateformcentral.step8title').toString(),
      text: this.$t('plateformcentral.step8text').toString(),
      attachTo: {
        element: '.albumcard', // La classe CSS de l'élément que vous voulez souligner
        on: 'left',
      },
      buttons: [
        {
          action: function () {
            let gripElement = document.querySelector('.album-title');
            if (gripElement) {
              (gripElement as HTMLElement).click();
            }
            tour.back();
          },
          text: this.$t('projectlist.previous').toString(),
          classes: 'shepherd-button-ignore',
        },
        {
          action: function () {
            let gripElement = document.querySelector('.album-title');
            if (gripElement) {
              (gripElement as HTMLElement).click();
            }
            let dataElement = document.querySelector('.data-title');
            if (dataElement) {
              (dataElement as HTMLElement).click();
            }
            tour.show(15);
          },
          text: this.$t('projectlist.next').toString(),
          classes: 'shepherd-button-next',
        },
      ],
      floatingUIOptions: {
        middleware: [offset({ mainAxis: 15, crossAxis: 0 })],
      },
      canClickTarget: false,
    });
    tour.addStep({
      title: this.$t('plateformcentral.step9title').toString(),
      text: this.$t('plateformcentral.step9text').toString(),
      attachTo: {
        element: '.picto-coeur', // La classe CSS de l'élément que vous voulez souligner
        on: 'left',
      },
      buttons: [
        {
          action: tour.back,
          text: this.$t('projectlist.previous').toString(),
          classes: 'shepherd-button-ignore',
        },
      ],
      advanceOn: { selector: '.picto-coeur', event: 'click' },
      floatingUIOptions: {
        middleware: [offset({ mainAxis: 10, crossAxis: 0 })],
      },
    });
    tour.addStep({
      title: this.$t('plateformcentral.step10title').toString(),
      text: this.$t('plateformcentral.step10text').toString(),
      attachTo: {
        element: '.popover', // La classe CSS de l'élément que vous voulez souligner
        on: 'right',
      },
      advanceOn: { selector: '.creeralbum', event: 'click' },
      floatingUIOptions: {
        middleware: [offset({ mainAxis: 10, crossAxis: 0 })],
      },
    });
    tour.addStep({
      title: this.$t('plateformcentral.step11title').toString(),
      text: this.$t('plateformcentral.step11text').toString(),
      attachTo: {
        element: '.datacard', // La classe CSS de l'élément que vous voulez souligner
        on: 'left',
      },
      buttons: [
        {
          action: function () {
            let dataElement = document.querySelector('.data-title');
            if (dataElement) {
              (dataElement as HTMLElement).click();
            }
            let gripElement = document.querySelector('.album-title');
            if (gripElement) {
              (gripElement as HTMLElement).click();
            }
            tour.show(12);
          },
          text: this.$t('projectlist.previous').toString(),
          classes: 'shepherd-button-ignore',
        },
        {
          action: function () {
            let dataElement = document.querySelector('.data-title');
            if (dataElement) {
              (dataElement as HTMLElement).click();
            }
            tour.next();
          },
          text: this.$t('projectlist.next').toString(),
          classes: 'shepherd-button-next',
        },
      ],
      floatingUIOptions: {
        middleware: [offset({ mainAxis: 15, crossAxis: 0 })],
      },
      canClickTarget: false,
    });
    tour.addStep({
      title: this.$t('plateformcentral.step12title').toString(),
      text: this.$t('plateformcentral.step12text').toString(),
      attachTo: {
        element: '.descriptioncard', // La classe CSS de l'élément que vous voulez souligner
        on: 'left',
      },
      buttons: [
        {
          action: function () {
            let dataElement = document.querySelector('.data-title');
            if (dataElement) {
              (dataElement as HTMLElement).click();
            }
            tour.back();
          },
          text: this.$t('projectlist.previous').toString(),
          classes: 'shepherd-button-ignore',
        },
        {
          action: function () {
            let gripElement = document.querySelector('.cc-nsge');
            if (gripElement) {
              (gripElement as HTMLElement).click();
            }
            tour.next();
          },
          text: this.$t('projectlist.next').toString(),
          classes: 'shepherd-button-next',
        },
      ],
      floatingUIOptions: {
        middleware: [offset({ mainAxis: 15, crossAxis: 0 })],
      },
      canClickTarget: false,
    });
    tour.addStep({
      title: this.$t('plateformcentral.step13title').toString(),
      text: this.$t('plateformcentral.step13text').toString(),
      attachTo: {
        element: '.cc-1hqb', // La classe CSS de l'élément que vous voulez souligner
        on: 'left',
      },
      buttons: [
        {
          action: function () {
            let dataElement = document.querySelector('.cc-imbb');
            if (dataElement) {
              (dataElement as HTMLElement).click();
            }
            tour.back();
          },
          text: this.$t('projectlist.previous').toString(),
          classes: 'shepherd-button-ignore',
        },
        {
          action: function () {
            let gripElement = document.querySelector('.cc-nsge');
            if (gripElement) {
              (gripElement as HTMLElement).click();
            }
            tour.next();
          },
          text: this.$t('projectlist.next').toString(),
          classes: 'shepherd-button-next',
        },
      ],
      floatingUIOptions: {
        middleware: [offset({ mainAxis: 15, crossAxis: 0 })],
      },
      canClickTarget: false,
    });
    tour.addStep({
      title: this.$t('plateformcentral.step14title').toString(),
      text: this.$t('plateformcentral.step14text').toString(),
      attachTo: {
        element: '.fa-circle-info', // La classe CSS de l'élément que vous voulez souligner
        on: 'bottom',
      },
      buttons: [
        {
          action: function () {
            let dataElement = document.querySelector('.cc-imbb');
            if (dataElement) {
              (dataElement as HTMLElement).click();
            }
            tour.back();
          },
          text: this.$t('projectlist.previous').toString(),
          classes: 'shepherd-button-ignore',
        },
        {
          action: tour.next,
          text: this.$t('projectlist.donotshow').toString(),
          classes: 'shepherd-button-next',
        },
      ],
      floatingUIOptions: {
        middleware: [offset({ mainAxis: 15, crossAxis: 0 })],
      },
    });
    tour.start();
  }

  public beforeDestroy(): void {
    logger.info('beforeDestroy');
    // called when the route that renders this component is about to
    // be navigated away from.
    // has access to `this` component instance.
    this.disconnectSocket();
  }

  public refreshPhoto(): void {
    if (this.cameraSelected !== null) {
      this.findPhotoAllForProject({ projectId: this.id, cameraId: this.cameraSelected }).then(() => {
        this.newPhotoAvailable = false;
      });
    }
  }

  @Watch('socketMessage.message')
  public onSocketMessageChange(val: NotificationMessage): void {
    if (val && val.photo) {
      switch (val.photo) {
        case 'new_photo':
          if (val.projectId === this.project?.id && val.cameraId === this.cameraSelected) {
            logger.info('new photo');
            this.newPhotoAvailable = true;
            break;
          }
      }
    }
  }

  @Watch('projects')
  public async onStateProjectLoaded(val?: Project[], oldVal?: Project[]): Promise<void> {
    logger.info('onStateProjectLoaded', { val, oldVal });
    this.project = this.projectWithId(this.id) || null;
    if (this.project !== null) {
      await this.setProject(this.project);
      if (this.cameraSelected === null) {
        this.cameraSelected =
          this.project.camerasId[0] ||
          (this.project.previousCamerasId !== undefined ? this.project.previousCamerasId[0] ?? null : null);
      } else {
        this.onCameraSelectedChange(this.cameraSelected);
      }
    }
  }

  @Watch('timelapse')
  public onTimelapseLoaded(): void {
    this.lastVideo = this.cameraSelected !== null ? this.timelapseForCamera(this.cameraSelected) : null;
  }

  @Watch('cameraSelected')
  public onCameraSelectedChange(cameraSelected: string | null): void {
    logger.info('onCameraSelectedChange', { query: cameraSelected });
    if (cameraSelected !== null) {
      const indexCamera: number | undefined = this.project?.cameras?.map((c) => c.id).indexOf(cameraSelected);
      const indexPrevious: number | undefined = this.project?.previousCameras
        ?.map((c: Camera) => c.id)
        .indexOf(cameraSelected);
      if ((indexCamera !== undefined && indexCamera !== -1) || (indexPrevious !== undefined && indexPrevious !== -1)) {
        this.lastVideo = this.timelapseForCamera(cameraSelected) || null;
        this.findPhotoAllForProject({ projectId: this.id, cameraId: cameraSelected });
        this.loadAlbum({ projectId: this.id, cameraId: cameraSelected });
      } else {
        cameraSelected = null;
      }
    } else if (this.project?.cameras?.length ?? 0 > 0) {
      cameraSelected = this.project?.cameras?.[0].id ?? null;
    } else if (this.project?.previousCameras?.length ?? 0 > 0) {
      cameraSelected = this.project?.previousCameras?.[0].id ?? null;
    }
  }
}
