






import { Component, Vue, Watch } from 'vue-property-decorator';
import LoginComponent from '@/components/login/login.component.vue';
import { State } from 'vuex-class';
const namespace = 'profile';

@Component({
  components: {
    LoginComponent,
  },
})
export default class Home extends Vue {
  @State('newPasswordRequired', { namespace }) public newPasswordRequired!: boolean;

  @Watch('newPasswordRequired')
  onNewPasswordRequired(val: boolean): void {
    if (val) {
      this.$router.push({ name: 'signup' });
    }
  }
}
