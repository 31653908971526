

































import Vue from 'vue';
import Component from 'vue-class-component';
import { State, Action, Mutation } from 'vuex-class';
import { Auth } from 'aws-amplify';
import Navbar from './components/navbar/navbar.vue';
import { required, minLength } from 'vuelidate/lib/validators';
import { Watch } from 'vue-property-decorator';
import { Logger } from 'aws-amplify';
import { Validations } from 'vuelidate-property-decorators';
const logger = new Logger('App');

@Component({
  components: {
    Navbar,
  },
})
export default class App extends Vue {
  verificationCodeSent: boolean | null = null;
  verificationCode = '';

  @Validations()
  validations = {
    verificationCode: {
      required,
      minLength: minLength(6),
    },
  };

  get isLocal(): boolean {
    return process.env.NODE_ENV === 'local';
  }
  get isIframe(): boolean {
    return this.$route.name === 'iframegallery';
  }

  @State('authorized', { namespace: 'profile' }) authorized!: boolean;
  @State('accountVerified', { namespace: 'profile' }) accountVerified!: boolean;

  @Action('fetchCognitoUser', { namespace: 'profile' }) fetchCognitoUser!: () => Promise<void>;
  @Action('fetchUserSession', { namespace: 'profile' }) fetchUserSession!: () => Promise<void>;

  @Action('loadCamera', { namespace: 'camera' }) loadCamera!: () => Promise<void>;
  @Action('loadProject', { namespace: 'projects' }) loadProject!: () => Promise<void>;

  @Mutation('setAccountVerified', { namespace: 'profile' }) setAccountVerified!: (b: boolean) => void;

  mounted(): void {
    if (!this.isIframe) {
      this.fetchCognitoUser()
        .then(() => {
          if (this.authorized) {
            this.loadData();
          }
        })
        .catch((err) => {
          logger.error(err);
        });
    }
  }

  loadData(): void {
    this.fetchUserSession().then(() => Promise.all([this.loadCamera(), this.loadProject()]));
  }

  validateEmail(): void {
    logger.info('validateEmail');
    Auth.verifyCurrentUserAttribute('email')
      .then(() => {
        logger.info('a verification code is sent');
        this.verificationCodeSent = true;
      })
      .catch((e) => {
        logger.error('failed with error', e);
        this.verificationCodeSent = null;
      });
  }

  validateEmailVerify(): void {
    logger.info('validateEmailVerify');
    // To verify attribute with the code
    Auth.verifyCurrentUserAttributeSubmit('email', this.verificationCode)
      .then(() => {
        logger.info('email verified');
        this.setAccountVerified(true);
      })
      .catch((e) => {
        logger.info('failed with error', e);
      });
  }

  @Watch('authorized')
  onAuthorizedChange(authorized: boolean, oldAuthorized: boolean): void {
    logger.info('authorized', { authorized, oldAuthorized });
    // La personne vient de se logger depuis la page login
    if (authorized !== undefined && oldAuthorized === undefined && this.$route.path === '/signup') {
      this.$router.push({ name: 'projectlist' });
      this.loadData();
    } else if (authorized !== undefined && this.$route.path === '/login' && !oldAuthorized) {
      this.$router.push({ name: 'projectlist' });
      this.loadData();
    } else if (!authorized && this.$route.path !== '/login' && oldAuthorized) {
      this.$router.push({ name: 'login' });
    }
  }
}
