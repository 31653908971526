import 'reflect-metadata';
import { jsonObject, jsonMember, toJson, jsonArrayMember } from 'typedjson';
import { Crop, Days, Position } from './enums';

@jsonObject
@toJson
export class IntervalDate {
  @jsonMember public begin!: number;
  @jsonMember public end!: number;
}

// eslint-disable-next-line max-classes-per-file
@jsonObject
@toJson
export class IntervalTime {
  @jsonMember public startHour!: string;
  @jsonMember public endHour!: string;
}

// eslint-disable-next-line max-classes-per-file
@jsonObject
export class ImageModifier {
  @jsonMember public iso!: number;
  @jsonMember public brightness!: number;
  @jsonMember public contrast!: number;
  @jsonMember public saturation!: number;
  @jsonMember public white!: number;
  @jsonMember({ constructor: String }) public crop!: Crop;
  @jsonMember public erigeWatermark!: boolean;
  @jsonMember public customWatermarkPicture?: string;
  @jsonMember({ constructor: String }) public watermarkPosition!: Position;
}

// eslint-disable-next-line max-classes-per-file
@jsonObject
@toJson
export class TimelapseConfiguration extends ImageModifier {
  @jsonMember public interval!: IntervalDate;
  @jsonMember public lastNDays!: boolean;
  @jsonMember public lastNDaysSpan!: number;
  @jsonMember public period!: IntervalTime;
  @jsonArrayMember(IntervalTime) public exclude?: IntervalTime[];
  @jsonArrayMember(Number) public days!: Days[];
  @jsonMember public fps!: number;
  @jsonMember public hdr!: boolean;
  @jsonArrayMember(Number) public blue!: number[];
  @jsonArrayMember(Number) public gain!: number[];
  @jsonArrayMember(Number) public exposure!: number[];
  @jsonArrayMember(Number) public clearness!: number[];
  @jsonArrayMember(String) public photoExclusion!: string[];
  @jsonMember public slowmotion!: boolean;
}
