import 'reflect-metadata';
import { jsonObject, jsonMember, jsonArrayMember, toJson, TypedJSON } from 'typedjson';
import { SmallUser } from './user';
import { Camera } from './camera';
import { City } from './city';
import { LastPhoto } from './lastphoto';

@jsonObject
@toJson
export class Project {
  @jsonMember id!: string;
  @jsonMember createdAt!: Date;
  @jsonMember owner!: SmallUser;
  @jsonArrayMember(SmallUser) guest: SmallUser[] = [];
  @jsonArrayMember(String) guestId: string[] = [];
  @jsonArrayMember(SmallUser) administrator: SmallUser[] = [];
  @jsonArrayMember(String) administratorId: string[] = [];
  @jsonArrayMember(Camera) cameras: Camera[] = [];
  @jsonArrayMember(String) camerasId: string[] = [];
  @jsonArrayMember(Camera, { isRequired: false }) previousCameras?: Camera[];
  @jsonArrayMember(String, { isRequired: false }) previousCamerasId?: string[];
  @jsonMember name!: string;
  @jsonMember description?: string;
  @jsonArrayMember(String, { isRequired: false }) tags?: string[];
  @jsonMember city!: City;
  @jsonMember unpaid?: boolean;
  @jsonMember jetlag?: boolean;
  @jsonMember lagduration!: number;
  @jsonMember totalphoto!: boolean;
  @jsonMember({ isRequired: false }) lastPhoto?: LastPhoto;
  @jsonMember({ isRequired: false }) logo?: string;
  // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  @jsonMember volumeData: number = 0;
}

export const serializer = new TypedJSON(Project);

// eslint-disable-next-line max-classes-per-file
@jsonObject
@toJson
export class IframeProject implements Pick<Project, 'id' | 'camerasId' | 'cameras' | 'name' | 'city' | 'totalphoto'> {
  @jsonMember
  public id!: string;
  @jsonArrayMember(String)
  public camerasId!: string[];
  @jsonArrayMember(Camera)
  public cameras!: Camera[];
  @jsonMember
  public name!: string;
  @jsonMember
  public city!: City;
  @jsonMember
  totalphoto!: boolean;
}

export const iframeProjectSerializer = new TypedJSON(IframeProject);
