import { Vue, Ref, Prop } from 'vue-property-decorator';
import Component from 'vue-class-component';
import { BModal } from 'bootstrap-vue/esm/components/modal';
import { Photo } from '@/models/photo';
import { photoService } from '@/services/';
import { Camera } from '@/models/camera';
// @ts-ignore no d.ts
import VirtualCollection from 'vue-virtual-collection';
import { Project } from '@/models/project';
import S3Image from '../s3-image/S3Image.vue';
import { Action, State } from 'vuex-class';
import { ImportPhotosRequest } from '@/services/photos/photos.interfaces';
import VueI18n from 'vue-i18n';
import { Logger } from 'aws-amplify';
const logger = new Logger('ImportPhotosModal');

@Component({
  components: {
    S3Image,
  },
})
export default class ImportPhotosModal extends Vue {
  public get importablePhotosCollection(): { data: Photo }[] {
    return this.importablePhotos.map((photo) => {
      return { data: photo };
    });
  }
  @Ref('scroller') public readonly scroller!: VirtualCollection;

  public sourceCameraId: string | null = null;
  public sourceCameras: Camera[] = [];
  public sourceProjectId: string | null = null;
  public importablePhotos: Photo[] = [];
  public loading = false;
  @Prop({ type: Object, required: true }) private readonly targetProject!: Project;
  @Prop({ type: Object, required: true }) private readonly targetCamera!: Camera;

  @Ref('import-photos-modal') private modal!: BModal;
  @Ref('photos') private photosMosaic!: HTMLDivElement;

  @State('projects', { namespace: 'projects' }) private sourceProjects!: Project[];
  @Action('importPhotos', { namespace: 'photo' }) private importPhotosService!: (
    importPhotosRequest: ImportPhotosRequest
  ) => Promise<void>;

  private imageWidth = 150;
  private imageHeight = 100;
  private scrollerColumns = 3;
  private scrollerRows = 3;
  private scrollerWidth = this.imageWidth * this.scrollerColumns;
  private scrollerHeight = this.imageHeight * this.scrollerRows;
  private scrollerImageWidth = this.imageWidth;

  public translate(key: string): VueI18n.TranslateResult {
    return this.$t(key);
  }

  public show(): void {
    logger.info('show');
    this.modal.show();
  }

  public mounted(): void {
    logger.info('mounted', { sourceCameraId: this.sourceCameraId });
    if (this.sourceProjects.length > 0) {
      this.sourceProjectId = this.sourceProjects[0].id;
    }

    this.loading = true;
    this.sourceCameras =
      this.sourceProjects
        .find((project) => project.id === this.sourceProjectId)
        ?.cameras?.filter(
          (camera) => camera.id !== this.targetCamera.id || this.sourceProjectId !== this.targetProject.id
        ) || [];
    const previousCam: Camera[] =
      this.sourceProjects
        .find((project) => project.id === this.sourceProjectId)
        ?.previousCameras?.filter((camera) => camera.id !== this.targetCamera.id) ?? [];
    this.sourceCameras = [...this.sourceCameras, ...previousCam];
    logger.info({ sourceCameras: this.sourceCameras });
    this.sourceCameraId = null;
    if (this.sourceCameras.length > 0) {
      this.sourceCameraId = this.sourceCameras[0].id;
    }
    this.loading = true;
    if (this.sourceCameraId !== null && this.sourceProjectId !== null) {
      photoService.findAllFor(this.sourceProjectId, this.sourceCameraId).then((data) => {
        this.importablePhotos = data;
      });
    } else {
      this.importablePhotos = [];
    }
    this.loading = false;
  }

  public onShown(): void {
    logger.info('onShown');
  }

  public close(): void {
    this.modal.hide();
    logger.info('close');
  }

  public cellSizeAndPositionGetter(item: Photo, index: number): unknown {
    // compute size and position
    const w = this.scrollerImageWidth;
    const h = this.scrollerImageWidth * (112 / 150);
    return {
      width: w,
      height: h,
      x: (index % this.scrollerColumns) * w,
      y: Math.floor(index / this.scrollerColumns) * h,
    };
  }

  public importPhotos(): void {
    logger.info('importPhotos');
    if (this.sourceCameraId !== null && this.sourceProjectId !== null) {
      const sourceCameraId = this.sourceCameraId;
      const sourceProjectId = this.sourceProjectId;
      this.$bvModal
        .msgBoxConfirm(this.$t('importphotosmodal.confirm', { count: this.importablePhotos.length }) as string, {
          title: this.$t('importphotosmodal.importtitle') as string,
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: this.$t('global.oktitle') as string,
          cancelTitle: this.$t('global.canceltitle') as string,
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        })
        .then((confirmation) => {
          if (confirmation) {
            logger.info('call importPhotosService');
            this.importPhotosService({
              sourceCamera: sourceCameraId,
              sourceProject: sourceProjectId,
              targetCamera: this.targetCamera.id,
              targetProject: this.targetProject.id,
            }).then(() => this.close());

            this.$bvModal.msgBoxOk(this.$t('importphotosmodal.wait') as string, {
              title: this.$t('importphotosmodal.importconfirmed') as string,
              size: 'sm',
              buttonSize: 'sm',
              okVariant: 'info',
              okTitle: this.$t('importphotosmodal.ok') as string,
              footerClass: 'p-2',
              hideHeaderClose: false,
              centered: true,
            });
          }
        })
        .catch((err) => {
          logger.error({ err });
        });
    }
  }

  public onProjectChange(sourceProjectId: string | null): void {
    this.loading = true;
    this.sourceCameras =
      this.sourceProjects
        .find((project) => project.id === sourceProjectId)
        ?.cameras?.filter(
          (camera) => camera.id !== this.targetCamera.id || sourceProjectId !== this.targetProject.id
        ) || [];
    const previousCam: Camera[] =
      this.sourceProjects
        .find((project) => project.id === this.sourceProjectId)
        ?.previousCameras?.filter((camera) => camera.id !== this.targetCamera.id) ?? [];
    this.sourceCameras = [...this.sourceCameras, ...previousCam];
    logger.info({ sourceCameras: this.sourceCameras });
    this.sourceCameraId = null;
    if (this.sourceCameras.length > 0) {
      this.sourceCameraId = this.sourceCameras[0].id;
    }
    this.onCameraChange(this.sourceCameraId);
  }

  public async onCameraChange(cameraId: string | null): Promise<void> {
    this.loading = true;
    if (cameraId !== null && this.sourceProjectId !== null) {
      this.importablePhotos = await photoService.findAllFor(this.sourceProjectId, cameraId);
    } else {
      this.importablePhotos = [];
    }
    this.loading = false;
  }
}
