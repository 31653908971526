import { Vue, Prop } from 'vue-property-decorator';
import Component from 'vue-class-component';
import { Scenario } from '@/models/scenario';
import videojs, { VideoJsPlayer } from 'video.js';
import { Storage } from 'aws-amplify';
import { Video } from '@/models/video';
import { Logger } from 'aws-amplify';
const logger = new Logger('ScenarioPlayer');

@Component({
  components: {},
})
export default class ScenarioPlayer extends Vue {
  @Prop({ type: Object }) private readonly scenario?: Scenario;
  @Prop({ type: Object }) private readonly video?: Video;

  private videoUrl: string | null = null;
  private player?: VideoJsPlayer;
  private options = {
    fluid: true,
    controls: true,
  };

  public get videoPlayerRef(): string {
    if (this.scenario !== undefined) {
      return `videoPlayer-${this.scenario.id}`;
    } else if (this.video !== undefined) {
      return `videoPlayer-${this.video.projectId}-${this.video.cameraId}`;
    } else {
      return '';
    }
  }

  public get scenarioPlayerRef(): string {
    if (this.scenario !== undefined) {
      return `scenario-player-${this.scenario.id}`;
    } else if (this.video !== undefined) {
      return `scenario-player-${this.video.projectId}-${this.video.cameraId}`;
    } else {
      return '';
    }
  }

  public mounted(): void {
    if (this.player) {
      this.player.dispose();
      this.player = undefined;
    }
    let promiseUrl;
    if (this.video?.key !== undefined) {
      promiseUrl = Storage.get(this.video.key);
    } else if (this.scenario?.video.key !== undefined) {
      promiseUrl = Storage.get(this.scenario?.video.key);
    }
    if (promiseUrl !== undefined) {
      promiseUrl.then((url) => {
        this.videoUrl = url as string;
        logger.info('', url);
        this.$nextTick(() => {
          const videoPlayer = this.$refs[this.videoPlayerRef] as Element | undefined;
          logger.info('', videoPlayer);
          if (videoPlayer !== undefined) {
            this.player = videojs(videoPlayer, this.options, () => {
              logger.info('onPlayerReady', this.player);

              if (this.scenario && this.$route.query.scenario === this.scenario.id) {
                logger.info('param defined');
                const scenarioPlayer = this.$refs[this.scenarioPlayerRef] as ScenarioPlayer;
                scenarioPlayer.show();
              }
            });
          }
        });
      });
    }
  }

  public beforeDestroy(): void {
    if (this.player) {
      this.player.dispose();
    }
  }

  public show(): void {
    const ref = this.$refs[this.scenarioPlayerRef];
    if (ref !== undefined) {
      // @ts-ignore no doc
      ref.show();
    } else {
      logger.warn('modal show but ref is undefined', { scenarioPlayerRef: this.scenarioPlayerRef, ref });
    }
  }

  public onVideoDownload(bvModalEvt: MouseEvent): void {
    bvModalEvt.preventDefault();
    if (this.video?.key !== undefined) {
      Storage.get(this.video.key, { download: true }).then((response) => {
        // @ts-ignore amplify doc
        saveAs(new Blob([response.Body], { type: 'video/mp4' }), 'video');
      });
    } else if (this.scenario?.video.key !== undefined) {
      Storage.get(this.scenario.video.key, { download: true }).then((response) => {
        // @ts-ignore amplify doc
        saveAs(new Blob([response.Body], { type: 'video/mp4' }), 'video');
      });
    }
  }

  public buildShareLink(object: Scenario | Video | undefined): string {
    if (object === undefined) {
      return '';
    } else if ('video' in object) {
      return `${process.env.VUE_APP_API_ENDPOINT}/scenario/${object.id}/viewer`;
    } else {
      return `${process.env.VUE_APP_API_ENDPOINT}/timelapse/${object.projectId}/${object.cameraId}/viewer`;
    }
  }

  public shareFacebook(): void {
    window.open(
      'https://www.facebook.com/sharer/sharer.php?u=' + this.buildShareLink(this.scenario || this.video),
      '',
      'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600'
    );
  }

  public shareTwitter(): void {
    window.open(
      'https://twitter.com/intent/tweet?text=' + this.buildShareLink(this.scenario || this.video),
      '',
      'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600'
    );
  }

  public shareLinkedin(): void {
    window.open(
      'https://www.linkedin.com/sharing/share-offsite/?url=' + this.buildShareLink(this.scenario || this.video),
      '',
      'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600'
    );
  }

  public shareEmail(): void {
    window.open(
      'mailto:someone@example.com?subject=Photo%20du%20chantier&body=Voilà une photo que je voudrais que tu vois: ' +
        encodeURIComponent(this.buildShareLink(this.scenario || this.video))
    );
  }
}
