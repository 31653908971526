function getIcon(name: string) {
  return require(`./svg/${name}.svg`);
}

export const customTheme = {
  'common.bi.image': '',
  'common.bisize.width': '0px',
  'common.bisize.height': '0px',
  'common.backgroundImage': '',
  'common.backgroundColor': '#001c38',
  'common.border': 'none',

  // header
  'header.backgroundImage': 'none',
  'header.backgroundColor': 'transparent;display:none',
  'header.border': '0px',

  // load button
  'loadButton.border': 'none; display:none',

  // download button
  'downloadButton.border': 'none; display:none',

  // main icons
  'menu.normalIcon.path': getIcon('icon-d'),
  'menu.normalIcon.name': 'icon-d',
  'menu.activeIcon.path': getIcon('icon-b'),
  'menu.activeIcon.name': 'icon-b',
  'menu.disabledIcon.path': getIcon('icon-a'),
  'menu.disabledIcon.name': 'icon-a',
  'menu.hoverIcon.path': getIcon('icon-c'),
  'menu.hoverIcon.name': 'icon-c',
  'menu.iconSize.width': '32px',
  'menu.iconSize.height': '32px',

  // submenu primary color
  'submenu.backgroundColor': '#1e1e1e',
  'submenu.partition.color': '#3c3c3c',

  // submenu icons
  'submenu.normalIcon.path': getIcon('icon-d'),
  'submenu.normalIcon.name': 'icon-d',
  'submenu.activeIcon.path': getIcon('icon-c'),
  'submenu.activeIcon.name': 'icon-c',
  'submenu.iconSize.width': '32px',
  'submenu.iconSize.height': '32px',

  // submenu labels
  'submenu.normalLabel.color': '#5fc9e4',
  'submenu.normalLabel.fontWeight': 'lighter',
  'submenu.activeLabel.color': '#fff',
  'submenu.activeLabel.fontWeight': 'lighter',

  // checkbox style
  'checkbox.border': '1px solid #ccc',
  'checkbox.backgroundColor': '#fff',

  // rango style
  'range.pointer.color': '#fff',
  'range.bar.color': '#666',
  'range.subbar.color': '#d1d1d1',
  'range.value.color': '#fff',
  'range.value.fontWeight': 'lighter',
  'range.value.fontSize': '11px',
  'range.value.border': '1px solid #353535',
  'range.value.backgroundColor': '#151515',
  'range.title.color': '#fff',
  'range.title.fontWeight': 'lighter',

  // colorpicker style
  'colorpicker.button.border': '1px solid #1e1e1e',
  'colorpicker.title.color': '#fff',
};
