import { MutationTree } from 'vuex';
import { CameraState } from './types';
import { Camera } from '@/models/camera';

export const mutations: MutationTree<CameraState> = {
  loadingCamera(state: CameraState) {
    state.loadingCamera = true;
  },
  cameras(state: CameraState, cameras: Camera[]) {
    state.loadingCamera = false;
    state.cameras = cameras;
  },
};
