import { Component, Vue, Prop, Watch, Ref } from 'vue-property-decorator';
import { Camera } from '@/models/camera';
import { Project } from '@/models/project';
import { Photo } from '@/models/photo';
import { TimelapseConfiguration } from '@/models/timelapseconfiguration';
import { State, Action } from 'vuex-class';
import VueSlider from 'vue-slider-component';
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue';
import * as d3 from 'd3';
import * as Plotly from 'plotly.js';
import { ModeBarDefaultButtons } from 'plotly.js';
import { Storage } from 'aws-amplify';
// @ts-ignore no d.ts
import VirtualCollection from 'vue-virtual-collection';
import S3Image from '../s3-image/S3Image.vue';
import { photoService } from '@/services';
import { DeletePhotoRequest } from '@/services/photos/photos.interfaces';
import { NotificationMessage, Socket } from '@/store/types';
import { Logger } from 'aws-amplify';
const logger = new Logger('TimelapsePhoto');

@Component({
  components: {
    VueSlider,
    VueTimepicker,
    S3Image,
  },
})
export default class TimelapsePhoto extends Vue {
  svg: any;
  @Ref('scroller') readonly scroller?: VirtualCollection | undefined;
  @Ref('photos') readonly photos!: HTMLDivElement;

  @Prop({ required: true, type: Object }) readonly camera!: Camera;
  @Prop({ required: true, type: Object }) readonly project!: Project;
  @Prop({ required: true, type: Object }) readonly value!: TimelapseConfiguration;
  @Prop({ required: true }) readonly dataImagesKey!: { data: Photo }[];
  @Prop() readonly total?: number;
  @Prop({ required: true, type: Boolean }) public readonly timelapseRending!: boolean;
  @Prop({ required: true }) readonly isPremium!: boolean;
  @Prop({ type: Boolean, default: true }) readonly withinInterval!: boolean;

  @State('locale', { namespace: 'profile' }) locale!: string;
  @State('socket') socketMessage!: Socket;
  @Action('connectSocket') private connectSocket!: () => Promise<void>;
  @Action('disconnectSocket') private disconnectSocket!: () => Promise<void>;
  @State('isLoading', { namespace: 'photo' }) readonly loadingPhoto!: boolean;
  @State('loadingTimelapse', { namespace: 'project' }) readonly loadingTimelapse!: boolean;
  @Action('deletePhotos', { namespace: 'photo' }) deletePhotos!: (
    deletePhotoRequest: DeletePhotoRequest
  ) => Promise<void>;

  private get model(): TimelapseConfiguration {
    return this.value;
  }

  private set model(val: TimelapseConfiguration) {
    const compare = JSON.stringify(val).localeCompare(JSON.stringify(this.value));
    if (compare !== 0) {
      this.$emit('input', val);
    }
  }
  /*
  private model: TimelapseConfiguration = { ...this.value };
  private get computedModel(): TimelapseConfiguration {
    return Object.assign({}, this.model);
  }
  @Watch('computedModel', { deep: true })
  public onModelChange(val: TimelapseConfiguration, oldVal: TimelapseConfiguration): void {
    const compare = JSON.stringify(val).localeCompare(JSON.stringify(oldVal));
    if (compare !== 0) {
      this.$emit('input', val);
    }
  }

  @Watch('value', { deep: true })
  public onValueChange(val: TimelapseConfiguration): void {
    this.model = { ...val };
  }
  */

  public get dateFormat(): string {
    return this.locale === 'en' ? 'MM / DD / YY - HH:mm' : 'DD / MM / YY - HH:mm';
  }

  public get duree(): string {
    if (this.withinInterval) {
      const date = new Date(0);
      date.setSeconds(this.dataImagesKey.length / this.model.fps);
      return date.toISOString().substr(11, 8);
    } else {
      return '';
    }
  }

  private scrollerWidth = 0;
  private scrollerHeight = 0;
  private scrollerColumns = 0;
  private scrollerImageWidth = 0;
  private photosPerRow = 8; // Valeur par défaut
  private minPhotosPerRow = 4;
  private maxPhotosPerRow = 20;
  private lastSelectedPhoto: Photo | undefined = undefined;
  private dataReady = false;
  private selectedPhoto: Photo[] = [];
  private zipGenerating = false;
  private zipLink: string[] | null = null;
  private rerender = 0;
  private sortAscending = true;
  private sortAscendingExposure = true;
  private sortAscendingSharpness = true;
  private showChart = false;
  public mousePosition = { x: 0, y: 0 };
  public hoverInfoPosition = { x: 0, y: 0 };
  public graphActivated = false;

  public set showModalZip(set: boolean) {
    if (set && this.$route.query.modal === undefined) {
      this.$router.push({ path: this.$route.path, query: { ...this.$route.query, modal: 'zip' } });
    } else if (this.$route.query.modal === 'zip') {
      this.disconnectSocket();
      this.$router.push({ path: this.$route.path, query: { ...this.$route.query, modal: undefined } });
    }
  }

  public get showModalZip(): boolean {
    return this.$route.query.modal === 'zip';
  }

  public get zoomedPhoto(): Photo | null {
    return this.zoomedPhotoIndex !== undefined ? this.dataImagesKey[this.zoomedPhotoIndex].data : null;
  }

  public get zoomedPhotoIndex(): number | undefined {
    return this.$route.query.zoom ? parseInt(this.$route.query.zoom as string, 10) : undefined;
  }

  public set zoomedPhotoIndex(photoIndex: number | undefined) {
    if (photoIndex === undefined) {
      this.$router.push({ path: this.$route.path, query: { ...this.$route.query, zoom: undefined } });
    } else if (this.$route.query !== { ...this.$route.query, zoom: photoIndex.toString() }) {
      this.$router.push({ path: this.$route.path, query: { ...this.$route.query, zoom: photoIndex.toString() } });
    }
  }

  public changeSortMethod(event: Event) {
    const target = event.target as HTMLSelectElement;
    switch (target.value) {
      case 'sortByDateAsc':
        this.sortByDate(true);
        break;
      case 'sortByDateDesc':
        this.sortByDate(false);
        break;
      case 'sortByExposureAsc':
        this.sortByExposure(true);
        break;
      case 'sortByExposureDesc':
        this.sortByExposure(false);
        break;
      case 'sortBySharpnessAsc':
        this.sortBySharpness(true);
        break;
      case 'sortBySharpnessDesc':
        this.sortBySharpness(false);
        break;
      default:
        break;
    }
  }

  public sortByDate(ascending: boolean): void {
    this.dataImagesKey.sort((a, b) => {
      const dateA = new Date(a.data.createdAt).getTime();
      const dateB = new Date(b.data.createdAt).getTime();
      return ascending ? dateA - dateB : dateB - dateA;
    });
    this.rerender++;
  }

  public sortByExposure(ascending: boolean): void {
    this.dataImagesKey.sort((a, b) => {
      const exposureA = a.data.exposure !== undefined ? a.data.exposure : 0;
      const exposureB = b.data.exposure !== undefined ? b.data.exposure : 0;
      return ascending ? exposureA - exposureB : exposureB - exposureA;
    });
    this.rerender++;
  }

  public sortBySharpness(ascending: boolean): void {
    this.dataImagesKey.sort((a, b) => {
      const sharpnessA = a.data.sharpness !== undefined ? a.data.sharpness : 0;
      const sharpnessB = b.data.sharpness !== undefined ? b.data.sharpness : 0;
      return ascending ? sharpnessA - sharpnessB : sharpnessB - sharpnessA;
    });
    this.rerender++;
  }

  public toggleChart(): void {
    this.showChart = !this.showChart;
    this.graphActivated = this.showChart;
    if (this.showChart) {
      this.sortByDate(true);
      this.createChart();
    }
  }

  public async createChart() {
    let data = await Promise.all(
      this.dataImagesKey.map(async (photo) => {
        const imageUrl = await Storage.get(photo.data.smallKey);
        return {
          date: new Date(photo.data.createdAt),
          exposure: photo.data.exposure,
          iso: photo.data.gainControl,
          sharpness: photo.data.sharpness,
          imageUrl,
        };
      })
    );

    data = data.filter(
      (d) => d.date && d.exposure !== undefined && d.iso !== undefined && d.sharpness !== undefined && d.imageUrl
    );

    const xData = data.map((d) => d.date);
    const yData = data.map((d) => d.exposure);
    const textData = data.map((d) => {
      if (d.exposure !== undefined) {
        return `Exposure: ${d.exposure.toFixed(1)}<br>${d3.timeFormat('%d/%m/%Y - %H:%M:%S')(d.date)}`;
      } else {
        return '';
      }
    });

    // Exposure trace
    const trace: Partial<Plotly.ScatterData> = {
      x: xData,
      y: yData.filter((d): d is number => typeof d === 'number'),
      mode: 'lines+markers',
      type: 'scatter',
      name: this.$t('timelapseedition.exposure') as string,
      text: textData,
      customdata: data.map((d) => d.imageUrl) as any,
      marker: { size: 5 },
      hovertemplate: '%{text}<extra></extra>',
    };

    const textDataISO = data.map((d) => {
      if (d.iso !== undefined) {
        return `ISO: ${d.iso.toFixed(1)}<br>${d3.timeFormat('%d/%m/%Y - %H:%M:%S')(d.date)}`;
      } else {
        return '';
      }
    });
    // ISO trace
    const yDataISO = data.map((d) => d.iso);
    const traceISO: Partial<Plotly.ScatterData> = {
      x: xData,
      y: yDataISO.filter((d): d is number => typeof d === 'number'),
      mode: 'lines+markers',
      type: 'scatter',
      name: this.$t('timelapseedition.iso') as string,
      text: textDataISO,
      customdata: data.map((d) => d.imageUrl) as any,
      marker: { size: 5 },
      hovertemplate: '%{text}<extra></extra>',
    };

    const textDataSharpness = data.map((d) => {
      if (d.sharpness !== undefined) {
        return `Sharpness: ${d.sharpness.toFixed(1)}<br>${d3.timeFormat('%d/%m/%Y - %H:%M:%S')(d.date)}`;
      } else {
        return '';
      }
    });

    // Sharpness trace
    const yDataSharpness = data.map((d) => d.sharpness);
    const traceSharpness: Partial<Plotly.ScatterData> = {
      x: xData,
      y: yDataSharpness.filter((d): d is number => typeof d === 'number'),
      mode: 'lines+markers',
      type: 'scatter',
      name: this.$t('timelapseedition.clearness') as string,
      text: textDataSharpness,
      customdata: data.map((d) => d.imageUrl) as any,
      marker: { size: 5 },
      hovertemplate: '%{text}<extra></extra>',
    };

    const layout: Partial<Plotly.Layout> = {
      modebar: {
        orientation: 'v',
        bgcolor: '#001a35',
        color: '#6c757d',
        activecolor: '#5fc9e4',
      },
      xaxis: {
        color: '#5fc9e4',
        gridcolor: '#6c757d',
      },
      yaxis: {
        color: '#5fc9e4',
        gridcolor: '#6c757d',
      },
      autosize: true,
      margin: {
        l: 30,
        r: 0,
        b: 31,
        t: 10,
        pad: 0,
      },
      paper_bgcolor: 'transparent',
      plot_bgcolor: 'transparent',
      legend: {
        x: 0.4,
        xanchor: 'left' as const,
        y: 1.04,
        yanchor: 'middle' as const,
        orientation: 'h' as const,
        font: {
          color: '#5fc9e4',
        },
      },
    };

    const config = {
      responsive: true,
      scrollZoom: true,
      displaylogo: false,
      displayModeBar: true,
      modeBarButtonsToRemove: ['lasso2d', 'select2d'] as ModeBarDefaultButtons[],
    };

    const plotDiv: any = document.getElementById('chart');
    const hoverInfoDiv = document.getElementById('hoverinfo');
    await Plotly.newPlot(plotDiv, [trace, traceISO, traceSharpness], layout, config);

    plotDiv.on('plotly_hover', (data: any) => {
      const infotext = data.points.map((d: any) => {
        return `<img src="${d.customdata}" style="height: 100px; width: auto;">`;
      });
      this.hoverInfoPosition.x = data.event.clientX;
      this.hoverInfoPosition.y = data.event.clientY;
      hoverInfoDiv!.innerHTML = infotext.join('');
    });

    plotDiv.on('plotly_unhover', function () {
      hoverInfoDiv!.innerHTML = '';
    });

    plotDiv.on('mousemove', function (e: any) {
      hoverInfoDiv!.style.left = `${e.clientX}px`;
      hoverInfoDiv!.style.top = `${e.clientY}px`;
    });
  }

  public hoverInfoStyle() {
    return {
      position: 'absolute',
      left: `${this.hoverInfoPosition.x - 130}px`,
      top: `${this.hoverInfoPosition.y - 130}px`,
    };
  }

  public updateMousePosition(e: any) {
    this.mousePosition.x = e.pageX;
    this.mousePosition.y = e.pageY;
  }

  public updateZoomedPhoto(photoId: string | undefined): void {
    this.zoomedPhotoIndex = this.dataImagesKey.findIndex((image) => image.data.id === photoId);
  }

  public increasePhotosPerRow(): void {
    if (this.photosPerRow < this.maxPhotosPerRow) {
      this.photosPerRow++;
      this.updatePhotosSize();
    }
  }

  public decreasePhotosPerRow(): void {
    if (this.photosPerRow > this.minPhotosPerRow) {
      this.photosPerRow--;
      this.updatePhotosSize();
    }
  }

  public get captionFontSize(): string {
    const baseFontSize = 14; // Taille de police de base
    const minColumns = 4; // Nombre minimum de colonnes
    const maxColumns = 20; // Nombre maximum de colonnes
    const fontSizeRange = 9; // Plage de variation de la taille de police

    const columnsRatio = (this.photosPerRow - minColumns) / (maxColumns - minColumns);
    const fontSize = baseFontSize - fontSizeRange * columnsRatio;

    return `${fontSize}px`;
  }

  public unzoom(): void {
    this.zoomedPhotoIndex = undefined;
  }

  public zoomPrevious(): void {
    if (this.zoomedPhotoIndex !== undefined && this.zoomedPhotoIndex >= 0) {
      this.zoomedPhotoIndex = this.zoomedPhotoIndex - 1;
    }
  }

  public zoomNext(): void {
    if (this.zoomedPhotoIndex !== undefined && this.zoomedPhotoIndex < this.dataImagesKey.length - 1) {
      this.zoomedPhotoIndex = this.zoomedPhotoIndex + 1;
    }
  }

  private resizeObserver: ResizeObserver | null = null;
  private frameId: number | null = null;

  public initResizeObserver(): void {
    if (!this.resizeObserver && this.photos) {
      this.resizeObserver = new ResizeObserver(() => {
        if (this.frameId !== null) {
          cancelAnimationFrame(this.frameId);
        }
        this.frameId = requestAnimationFrame(this.updatePhotosSize);
      });
      this.resizeObserver.observe(this.photos);
      document.addEventListener('mousemove', this.updateMousePosition);
    }
  }

  public mounted(): void {
    logger.info('mounted');
    if (!this.loadingPhoto && !this.loadingTimelapse) {
      this.onDataReady();
    }
    this.initResizeObserver();
  }

  public updated(): void {
    if (this.dataReady) {
      this.initResizeObserver();
    }
  }

  public beforeDestroy(): void {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
    if (this.frameId !== null) {
      cancelAnimationFrame(this.frameId);
    }
  }

  public getRotation(): string {
    return this.camera && this.camera.rotate ? `rotate(${this.camera.rotate}deg)` : '';
  }

  public updatePhotosSize(): void {
    const w = this.photos.clientWidth;
    this.scrollerHeight = Math.max(0, this.photos.clientHeight - 22);
    this.scrollerWidth = w;
    this.scrollerImageWidth = (w - 15) / this.photosPerRow;
    this.$emit('update-photo-size');
  }

  public cellSizeAndPositionGetter(item: Photo, index: number): unknown {
    // compute size and position
    const w = this.scrollerImageWidth;
    const h = this.scrollerImageWidth * (112 / 150);
    return {
      width: w,
      height: h,
      x: (index % this.photosPerRow) * w,
      y: Math.floor(index / this.photosPerRow) * h,
    };
  }

  @Watch('dataImagesKey')
  public onPhotosChange(newVal: Photo[], oldVal: Photo[]): void {
    if (this.graphActivated) {
      this.createChart();
    }
  }

  @Watch('loadingTimelapse')
  public onLoadingTimelapse(val: boolean, oldVal: boolean): void {
    logger.info('loadingTimelapse');
    if (oldVal && !val && !this.loadingPhoto) {
      this.onDataReady();
    }
  }
  @Watch('loadingPhoto')
  public onLoadingPhoto(val: boolean, oldVal: boolean): void {
    logger.info('loadingPhoto');
    if (oldVal && !val && !this.loadingTimelapse) {
      this.onDataReady();
    }
  }

  public onDataReady(): void {
    logger.info('onDataReady', { model: this.model });
    this.dataReady = true;
    this.$nextTick().then(() => {
      this.updatePhotosSize();
    });
  }

  public toggleSelectAll(): void {
    if (this.selectedPhoto.length === this.dataImagesKey.length) {
      this.selectedPhoto = [];
    } else {
      this.selectedPhoto = this.dataImagesKey.map((d) => d.data);
    }
  }

  public togglePhotoInSelection(photo: Photo): void {
    if (this.selectedPhoto.indexOf(photo) === -1) {
      this.selectedPhoto.push(photo);
    } else {
      this.selectedPhoto = this.selectedPhoto.filter((p) => p.id !== photo.id);
    }
    this.lastSelectedPhoto = photo;
  }

  public selectClick(photo: Photo): void {
    if (this.lastSelectedPhoto === undefined) {
      this.togglePhotoInSelection(photo);
    } else {
      const image = this.dataImagesKey.map((d) => d.data);
      const firstIndex = image.indexOf(this.lastSelectedPhoto);
      const lastIndex = image.indexOf(photo);
      for (let i = firstIndex; i < lastIndex; i++) {
        this.togglePhotoInSelection(image[i + 1]);
      }
    }
  }

  public downloadZip(): void {
    this.showModalZip = true;
    this.zipLink = null;
    this.zipGenerating = true;
    this.connectSocket();
    photoService.requestZipPhoto(
      this.project.id,
      this.selectedPhoto.map((p) => p.id)
    );
  }

  @Watch('socketMessage.message')
  public onSocketMessageChange(val: NotificationMessage, oldVal: NotificationMessage): void {
    if (val && val.zip && val.zip === 'ready') {
      logger.info('onSocketMessageChange', { val, oldVal });
      this.zipGenerating = false;
      this.zipLink = (val.url as string).split('|');
      logger.info({ zipLink: this.zipLink });
    }
  }

  public disablePhoto(): void {
    if (this.selectedPhoto.length > 0) {
      this.selectedPhoto.forEach((photo) => {
        if (this.model.photoExclusion.indexOf(photo.id) === -1) {
          this.model.photoExclusion.push(photo.id);
        } else {
          this.model.photoExclusion = this.model.photoExclusion.filter((id) => id !== photo.id);
        }
      });
      this.selectedPhoto = [];
      // @ts-ignore vue bootstrap component
      this.$refs.removeTooltip.$emit('close');
    }
  }

  public async deletePhoto(): Promise<void> {
    if (this.selectedPhoto.length > 0) {
      const titleMessage = this.$t('global.confirm').toString();
      const deleteMessage = this.$t('global.delete').toString();
      const cancelMessage = this.$t('global.close').toString();
      this.$bvModal
        .msgBoxConfirm(this.$t('timelapsefilter.validatedeleteall').toString(), {
          title: titleMessage,
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: deleteMessage,
          cancelTitle: cancelMessage,
          headerClass: 'text-danger',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.deletePhotos({
              projectId: this.project.id,
              photoIds: this.selectedPhoto.map((p) => p.id),
              cameraId: this.camera.id,
            });
            this.selectedPhoto = [];
            // @ts-ignore vue boostrap component
            this.$refs.deleteTooltip.$emit('close');
          }
        });
    }
  }

  public save(): void {
    this.$emit('save');
  }
}
