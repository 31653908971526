import { Component, Vue, Prop, Watch, Ref } from 'vue-property-decorator';
import videojs, { VideoJsPlayer } from 'video.js';
import { Storage } from 'aws-amplify';
import { Camera } from '@/models/camera';
import { Project } from '@/models/project';
import { Video } from '@/models/video';
import { saveAs } from 'file-saver';
import ScenarioPlayer from '@/components/scenarioplayer/scenarioplayer.component.vue';
import { State } from 'vuex-class';
import { User } from '@/models/user';
import { Logger } from 'aws-amplify';
const logger = new Logger('TimelapseCard');

@Component({
  components: {
    ScenarioPlayer,
  },
})
export default class TimelapseCard extends Vue {
  @Prop({ required: true, type: Object }) readonly camera!: Camera;
  @Prop({ required: true, type: Object }) readonly project!: Project;
  @Prop({ type: Object }) readonly lastVideo!: Video | null;
  @Ref('video-player-player') readonly videoPlayerPlayer?: ScenarioPlayer;

  @State('user', { namespace: 'profile' }) private user!: User;

  private visible = true;
  private showVideo = true;

  private videoUrl: string | null = null;
  private player?: VideoJsPlayer;
  private options = {
    fluid: true,
    controls: true,
  };
  private videoDownloading = false;

  public get isAdmin(): boolean {
    return (
      this.user !== undefined &&
      (this.project.owner.id === this.user.id || this.project.administratorId.includes(this.user.id))
    );
  }

  public mounted(): void {
    this.videoDownloading = false;
    this.onLastVideoChange(this.lastVideo, null);
  }

  public getRotation(): string {
    return this.camera && this.camera.rotate ? `rotate(${this.camera.rotate}deg)` : '';
  }

  @Watch('lastVideo')
  public onLastVideoChange(val: Video | null, oldVal: Video | null): void {
    logger.info('onLastVideoChange', { val, oldVal });
    if (this.player) {
      this.player.dispose();
      this.player = undefined;
    }
    if (this.lastVideo && this.lastVideo.key) {
      Storage.get(this.lastVideo.key).then((url) => {
        this.videoUrl = url as string;
        this.$nextTick(() => {
          const videoPlayer = this.$refs.videoPlayer as Element | undefined;
          logger.info('', videoPlayer);
          if (videoPlayer !== undefined) {
            this.player = videojs(videoPlayer, this.options, () => {
              logger.info('onPlayerReady', this.player);
            });
          }
        });
      });
    } else {
      this.videoUrl = null;
    }
  }

  public onVideoDownload(): void {
    if (this.lastVideo?.key !== undefined) {
      this.videoDownloading = true;
      Storage.get(this.lastVideo.key, { download: true }).then((response) => {
        this.videoDownloading = false;
        // @ts-ignore amplify doc not complete
        saveAs(new Blob([response.Body], { type: 'video/mp4' }), 'video');
      });
    }
  }

  public beforeDestroy(): void {
    if (this.player) {
      this.player.dispose();
      this.player = undefined;
    }
  }

  onHide(): void {
    this.showVideo = true;
  }

  onShown(): void {
    this.showVideo = true;
    this.$nextTick(() => {
      this.onLastVideoChange(this.lastVideo, null);
    });
  }

  onShowPlayer(): void {
    logger.info({ videoPlayerPlayer: this.videoPlayerPlayer });
    if (this.videoPlayerPlayer !== undefined) {
      // @ts-ignore no docs
      this.videoPlayerPlayer.show();
    }
  }
}
