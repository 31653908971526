import 'reflect-metadata';
/* eslint-disable @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match */
import { jsonObject, jsonMember, toJson } from 'typedjson';

@jsonObject
@toJson
export class Shadow {
  @jsonMember Photo_Interval!: string; // Intervalle de temps entre 2 prise de vue 0 - 3600
  @jsonMember Photo_HDRmode!: '0' | '1' | '2'; // Activation du mode HDR (0 : pas de HDR, 1: HDR, 2 : automatique)
  @jsonMember Photo_Contrast!: '1' | '2' | '3' | '4' | '5'; // Niveau de contrast (1:très bas, 2:bas,3:moyen,4:haut,5:très haut)
  @jsonMember Date_Start!: string; // Date de début du timelapse (JJMMAAAA)
  @jsonMember Date_End!: string; // Date de fin du timelapse (JJMMAAAA)
  @jsonMember Time_Start!: string; // Heure de début du timelapse (HHMM)
  @jsonMember Time_End!: string; // Heure de fin du timelapse (HHMM)
  @jsonMember Except_Start!: string; // Heure de début d’exception dans la plage de timelapse (HHMM)
  @jsonMember Except_End!: string; // Heure de fin d’exception dans la plage de timelapse (HHMM)
  @jsonMember readonly Connection_Type!: string; // - Type de connexion (Wifi ou Cellulaire)
  @jsonMember Capture_Day!: string; // Champ de 7 bit avec 1 bit par jour (1 : actif, 0 : inactif)
  @jsonMember Camera_Mode!: '0' | '1' | '2' | '3'; // Etat de la prise de vue (0 : off, 1 : photo, 2 : slowmotion, 3 : live stream) 0-6
  @jsonMember Video_Timetrigger!: string; // Date et heure en heure de déclenchement de la prochaine vidéo « slowmotion » (AAAAMMJJ-HHMM)
  @jsonMember USB_Record!: '0' | '1'; // 0 pour non, 1 pour oui
  @jsonMember readonly USB_Full!: '0' | '1'; // État de la clé USB (0 : espace disponible, 1 : plus d’espace disponible) 0-1
  @jsonMember Timezone!: string; // Valeur de décalage par rapport à UTC (-12h à +12h) -12 - +12
  @jsonMember readonly Device_Id!: string; // - Identifiant du boîtier (numéro de série)
  @jsonMember readonly Device_Version!: string; // - Version du boîtier (ex : 1.0)
  @jsonMember readonly Software_Version!: string; // - Version du software du boîtier (ex : 1.0)
  @jsonMember readonly Device_Temperature!: string; // °C Température mesurée dans le boîtier -40°C - 80°C
  @jsonMember readonly Power_supply!: '0' | '1'; // Type d’alimentation (0 : batterie, 1 : Alimentation secteur)
  @jsonMember readonly Position_Latitude!: string; // Dernière position Latitude GPS (ddmm.mmmm N/S)
  @jsonMember readonly Position_Longitude!: string; // Dernière position Longitude GPS (ddmm.mmmm E/O)
  @jsonMember readonly Position_Altitude!: string; // en mètre Dernière altitude GPS mesurée
  @jsonMember readonly Device_Paired!: string; // Identifiant du projet appairé. 0 ou rien si pas d’appairage à un projet
  @jsonMember readonly Battery_Percent!: string; // % Pourcentage de batterie restant 0 % - 100 %
  @jsonMember readonly Battery_Status!: '0' | '1' | '2' | '3' | '4' | '5'; // - Niveau de batterie (échelle de 0 à 5)
  @jsonMember readonly Data_Sent!: string; // Mo Quantité de data envoyées au cours du mois 0 - 1000
  @jsonMember readonly Data_Rate!: string; // Ko/s Débit d’envoi de la dernière photo ou vidéo 0 - 10000
}
