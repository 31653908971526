























































































































































































































































import { Component, Vue, Ref, Watch } from 'vue-property-decorator';
import { State, Getter } from 'vuex-class';
import ProjectCard from '@/components/projectcard/projectcard.component.vue';
import ProjectModal from '@/components/projectmodal/projectmodal.component.vue';
import { Camera } from '@/models/camera';
import { City } from '@/models/city';
import { Project } from '@/models/project';
import { User } from '@/models/user';
// @ts-ignore: no d.ts file
import { vTeleport } from '@desislavsd/vue-teleport';
import { Storage } from 'aws-amplify';
import { Logger } from 'aws-amplify';
import { LMap, LTileLayer, LMarker, LPopup } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.webpack.css'; // Re-uses images from ~leaflet package
import 'leaflet-defaulticon-compatibility';
import L from 'leaflet';
import Shepherd from 'shepherd.js';
import 'shepherd.js/dist/css/shepherd.css';
import { offset } from '@floating-ui/dom';

enum SortType {
  Recent = 'recent',
  City = 'city',
}

enum FilterType {
  All = 'all',
  Active = 'active',
  Inactive = 'inactive',
  NoPhoto48 = 'noPhoto48',
}

@Component({
  components: {
    ProjectCard,
    ProjectModal,
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    vTeleport,
  },
})
export default class ProjectList extends Vue {
  @Ref('new-project-modal') projectModal!: ProjectModal;

  @State('user', { namespace: 'profile' }) private user!: User;

  @State('projects', { namespace: 'projects' }) private allProject!: Project[];
  @Getter('filter', { namespace: 'projects' }) private filterProject!: (search: string) => Project[];

  private projects: Project[] = [];
  private search = '';
  private displayedProjectsCount = 20; // Nombre initial de projets à afficher

  private url = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
  private attribution = '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors';
  private center: [number, number] = [48.858093, 2.294694]; // Coordinates for Paris, France
  private zoom = 4;
  private showMap = false; // Set to true to display the map by default
  private showMosaicAndList = true;
  private satelliteView = false;
  private mapButtonText = 'Map';
  private cardsPerRow = this.calculateCardsPerRow();
  public imageUrls: { [key: string]: string } = {};
  private imagesLoaded = 0;
  private readonly FilterType = FilterType;
  private filter: FilterType = FilterType.All;
  public defaultImageUrl = 'https://via.placeholder.com/150';

  /* masaïc view variable */
  private readonly SortType = SortType;
  private sort: SortType = SortType.Recent;

  /* list view variable */
  private fields = [
    { key: 'createdAt', label: 'Date', sortable: true },
    { key: 'name', label: 'Nom du projet', sortable: true },
    {
      key: 'city',
      label: 'Nom de la ville',
      formatter: (value: City) => value.value,
      sortable: true,
      sortByFormatted: true,
    },
    {
      key: 'administrator',
      label: 'Administrateur',
      formatter: (value?: User[]) => (value ? value.length : 0),
      sortable: true,
      sortByFormatted: true,
    },
    {
      key: 'guest',
      label: 'Invités',
      formatter: (value?: User[]) => (value ? value.length : 0),
      sortable: true,
      sortByFormatted: true,
    },
    {
      key: 'cameras',
      label: 'Caméras',
      formatter: (value?: Camera[]) => (value ? value.length : 0),
      sortable: true,
      sortByFormatted: true,
    },
    { key: 'id', label: 'Réglages' },
  ];

  private sortBy = 'createdAt';
  private sortDesc = false;

  private get displayedProjects(): Project[] {
    return this.projects.slice(0, this.displayedProjectsCount);
  }

  private loadMoreProjects(): void {
    this.displayedProjectsCount += 2; // Charger 20 projets supplémentaires
  }

  private checkScroll(): void {
    const threshold = 100; // 100 pixels avant d'atteindre le bas
    const position = window.innerHeight + window.scrollY;
    const height = document.body.offsetHeight;

    if (position >= height - threshold) {
      this.loadMoreProjects();
    }
  }

  private calculateCardsPerRow(): number {
    let windowWidth = window.innerWidth;

    if (windowWidth <= 800) {
      // pour écrans xs
      return 1;
    } else if (windowWidth <= 1000) {
      // pour écrans sm
      return 2;
    } else if (windowWidth <= 1500) {
      // pour écrans md
      return 3;
    } else if (windowWidth <= 2500) {
      // pour écrans md
      return 4;
    } else {
      // pour écrans lg et plus
      return 5;
    }
  }

  private onFilterChange(value: FilterType) {
    if (value === FilterType.All) {
      this.projects = this.allProject.slice(0);
    } else if (value === FilterType.Active) {
      this.projects = this.allProject.filter((project) =>
        project.cameras.some((camera) => camera.apiFetchInterval !== -1)
      );
    } else if (value === FilterType.Inactive) {
      this.projects = this.allProject.filter((project) =>
        project.cameras.every((camera) => camera.apiFetchInterval === -1)
      );
    } else if (value === FilterType.NoPhoto48) {
      const threshold = Date.now() - 48 * 60 * 60 * 1000; // 48 hours ago
      this.projects = this.allProject.filter((project) =>
        project.cameras.some((camera) => {
          if (camera.apiFetchInterval !== -1 && camera.lastPhoto?.createdAt) {
            return new Date(camera.lastPhoto.createdAt).getTime() < threshold;
          } else {
            return false; // or true, depending on your logic
          }
        })
      );
    }
    this.onSortChange(this.sort);
  }

  private mounted() {
    window.addEventListener('scroll', this.checkScroll);
    this.onSearchChange('', '');
    this.calculateMapBounds();
    this.updateProjectsAndMap();
    window.addEventListener('resize', () => {
      this.cardsPerRow = this.calculateCardsPerRow();
    });
    this.showPopup;
    this.$nextTick(() => {
      setTimeout(() => {
        if (localStorage.getItem('tourIgnored')) {
          return;
        }
        if (window.innerWidth >= 700) {
          const tour = new Shepherd.Tour({
            defaultStepOptions: {
              cancelIcon: {
                enabled: true,
              },
              classes: 'shepherd-content', // Ajoutez votre propre classe CSS
              scrollTo: true,
              when: {
                show() {
                  const currentStep = Shepherd.activeTour?.getCurrentStep();
                  if (!currentStep) return;
                  const currentStepElement = currentStep.getElement();
                  if (!currentStepElement) return;
                  const header = currentStepElement.querySelector('.shepherd-footer');
                  if (!header) return;
                  const progress = document.createElement('div');
                  const innerBar = document.createElement('span');
                  const progressPercentage = ((tour.steps.indexOf(currentStep) + 1) / tour.steps.length) * 100 + '%';
                  progress.className = 'shepherd-progress-bar';
                  innerBar.style.width = progressPercentage;
                  if (document.getElementsByClassName('shepherd-button').length == 1) {
                    progress.style.minWidth = '260px';
                  }
                  progress.appendChild(innerBar);
                  const headerh = currentStepElement.querySelector('.shepherd-header');
                  const progressh = document.createElement('span');
                  progressh.style.marginRight = '0px';
                  progressh.innerText = `${tour.steps.indexOf(currentStep) + 1}/${tour.steps.length}`;
                  if (headerh) {
                    headerh.insertBefore(progressh, currentStepElement.querySelector('.shepherd-cancel-icon'));
                  }
                  header.insertBefore(progress, currentStepElement.querySelector('.shepherd-button'));
                },
              },
            },
            useModalOverlay: true,
            keyboardNavigation: false,
          });

          tour.addStep({
            title: this.$t('projectlist.step1title').toString(),
            text: this.$t('projectlist.step1text').toString(),
            attachTo: {
              element: 'nothing', // La classe CSS d  e l'élément que vous voulez souligner
              on: 'left',
            },
            buttons: [
              {
                action: () => {
                  localStorage.setItem('tourIgnored', 'true');
                  tour.complete();
                },
                text: this.$t('projectlist.donotshow').toString(),
                classes: 'shepherd-button-ignore',
              },
              {
                action: tour.next,
                text: this.$t('projectlist.next').toString(),
                classes: 'shepherd-button-next',
              },
            ],
          });
          tour.addStep({
            title: this.$t('projectlist.step2title').toString(),
            text: this.$t('projectlist.step2text').toString(),
            attachTo: {
              element: 'nothing', // La classe CSS de l'élément que vous voulez souligner
              on: 'left',
            },
            buttons: [
              {
                action: () => {
                  localStorage.setItem('tourIgnored', 'true');
                  tour.complete();
                },
                text: this.$t('projectlist.donotshow').toString(),
                classes: 'shepherd-button-ignore',
              },
              {
                action: tour.next,
                text: this.$t('projectlist.next').toString(),
                classes: 'shepherd-button-next',
              },
            ],
          });
          tour.addStep({
            title: this.$t('projectlist.step3title').toString(),
            text: this.$t('projectlist.step3text').toString(),
            attachTo: {
              element: '.searchproject', // La classe CSS de l'élément que vous voulez souligner
              on: 'bottom',
            },
            buttons: [
              {
                action: tour.back,
                text: this.$t('projectlist.previous').toString(),
                classes: 'shepherd-button-ignore',
              },
              {
                action: tour.next,
                text: this.$t('projectlist.next').toString(),
                classes: 'shepherd-button-next',
              },
            ],
            floatingUIOptions: {
              middleware: [offset({ mainAxis: 20, crossAxis: 0 })],
            },
          });

          tour.addStep({
            title: this.$t('projectlist.step4title').toString(),
            text: this.$t('projectlist.step4text').toString(),
            attachTo: {
              element: '.diffvue', // La classe CSS de l'élément que vous voulez souligner
              on: 'bottom',
            },
            buttons: [
              {
                action: tour.back,
                text: this.$t('projectlist.previous').toString(),
                classes: 'shepherd-button-ignore',
              },
              {
                action: function () {
                  let gripElement = document.querySelector('.fa-grip');
                  if (gripElement) {
                    (gripElement as HTMLElement).click();
                  }
                  setTimeout(function () {
                    tour.next();
                  }, 100);
                },
                text: this.$t('projectlist.next').toString(),
                classes: 'shepherd-button-next',
              },
            ],
            floatingUIOptions: {
              middleware: [offset({ mainAxis: 10, crossAxis: 0 })],
            },
          });
          tour.addStep({
            title: this.$t('projectlist.step5title').toString(),
            text: this.$t('projectlist.step5text').toString(),
            attachTo: {
              element: '.vuezoom', // La classe CSS de l'élément que vous voulez souligner
              on: 'bottom',
            },
            buttons: [
              {
                action: tour.back,
                text: this.$t('projectlist.previous').toString(),
                classes: 'shepherd-button-ignore',
              },
              {
                action: tour.next,
                text: this.$t('projectlist.next').toString(),
                classes: 'shepherd-button-next',
              },
            ],
            advanceOn: { selector: '.vuemap', event: 'click' },
            floatingUIOptions: {
              middleware: [offset({ mainAxis: 11, crossAxis: 0 })],
            },
          });
          tour.addStep({
            title: this.$t('projectlist.step6title').toString(),
            text: this.$t('projectlist.step6text').toString(),
            attachTo: {
              element: '.projetcarte', // La classe CSS de l'élément que vous voulez souligner
              on: 'bottom',
            },
            buttons: [
              {
                action: tour.back,
                text: this.$t('projectlist.previous').toString(),
                classes: 'shepherd-button-ignore',
              },
              {
                action: tour.next,
                text: this.$t('projectlist.next').toString(),
                classes: 'shepherd-button-next',
              },
            ],
            floatingUIOptions: {
              middleware: [offset({ mainAxis: 15, crossAxis: 0 })],
            },
            scrollTo: false,
          });
          tour.addStep({
            title: this.$t('projectlist.step61title').toString(),
            text: this.$t('projectlist.step61text').toString(),
            attachTo: {
              element: '.invite', // La classe CSS de l'élément que vous voulez souligner
              on: 'bottom',
            },
            buttons: [
              {
                action: tour.back,
                text: this.$t('projectlist.previous').toString(),
                classes: 'shepherd-button-ignore',
              },
              {
                action: tour.next,
                text: this.$t('projectlist.next').toString(),
                classes: 'shepherd-button-next',
              },
            ],
            advanceOn: { selector: '.picto-settings', event: 'click' },
            floatingUIOptions: {
              middleware: [offset({ mainAxis: 15, crossAxis: 0 })],
            },
            scrollTo: false,
          });
          tour.addStep({
            title: this.$t('projectlist.step62title').toString(),
            text: this.$t('projectlist.step62text').toString(),
            attachTo: {
              element: '.picto-settings', // La classe CSS de l'élément que vous voulez souligner
              on: 'right',
            },
            buttons: [
              {
                action: tour.back,
                text: this.$t('projectlist.previous').toString(),
                classes: 'shepherd-button-ignore',
              },
              {
                action: function () {
                  // Aller directement à l'étape 10 (index 9)
                  tour.show(11);
                },
                text: this.$t('projectlist.next').toString(),
                classes: 'shepherd-button-next',
              },
            ],
            advanceOn: { selector: '.picto-settings', event: 'click' },
            floatingUIOptions: {
              middleware: [offset({ mainAxis: 20, crossAxis: 0 })],
            },
            scrollTo: false,
          });
          tour.addStep({
            title: this.$t('projectlist.step7title').toString(),
            text: this.$t('projectlist.step7text').toString(),
            attachTo: {
              element: '.description', // La classe CSS de l'élément que vous voulez souligner
              on: 'bottom',
            },
            buttons: [
              {
                action: function () {
                  // Déclenche un clic sur l'élément de classe '.close'
                  let closeElement = document.querySelector('.close');
                  if (closeElement) {
                    (closeElement as HTMLElement).click();
                  }
                  tour.back();
                },
                text: this.$t('projectlist.previous').toString(),
                classes: 'shepherd-button-ignore',
              },
              {
                action: tour.next,
                text: this.$t('projectlist.next').toString(),
                classes: 'shepherd-button-next',
              },
            ],
            advanceOn: { selector: '.picto-settings', event: 'click' },
            floatingUIOptions: {
              middleware: [offset({ mainAxis: 70, crossAxis: 0 })],
            },
            scrollTo: false,
          });
          tour.addStep({
            title: this.$t('projectlist.step8title').toString(),
            text: this.$t('projectlist.step8text').toString(),
            attachTo: {
              element: '.col-12', // La classe CSS de l'élément que vous voulez souligner
              on: 'top',
            },
            buttons: [
              {
                action: tour.back,
                text: this.$t('projectlist.previous').toString(),
                classes: 'shepherd-button-ignore',
              },
              {
                action: tour.next,
                text: this.$t('projectlist.next').toString(),
                classes: 'shepherd-button-next',
              },
            ],
            advanceOn: { selector: '.picto-settings', event: 'click' },
            floatingUIOptions: {
              middleware: [offset({ mainAxis: 10, crossAxis: 0 })],
            },
            scrollTo: false,
          });
          tour.addStep({
            title: this.$t('projectlist.step9title').toString(),
            text: this.$t('projectlist.step9text').toString(),
            attachTo: {
              element: '.iframe', // La classe CSS de l'élément que vous voulez souligner
              on: 'left',
            },
            buttons: [
              {
                action: tour.back,
                text: this.$t('projectlist.previous').toString(),
                classes: 'shepherd-button-ignore',
              },
              {
                action: function () {
                  // Déclenche un clic sur l'élément de classe '.close'
                  let closeElement = document.querySelector('.close');
                  if (closeElement) {
                    (closeElement as HTMLElement).click();
                  }
                  tour.next();
                },
                text: this.$t('projectlist.next').toString(),
                classes: 'shepherd-button-next',
              },
            ],
            advanceOn: { selector: '.picto-settings', event: 'click' },
            floatingUIOptions: {
              middleware: [offset({ mainAxis: 10, crossAxis: 0 })],
            },
            scrollTo: false,
          });
          tour.addStep({
            title: this.$t('projectlist.step10title').toString(),
            text: this.$t('projectlist.step10text').toString(),
            attachTo: {
              element: '.projetcarte', // La classe CSS de l'élément que vous voulez souligner
              on: 'right',
            },
            buttons: [
              {
                action: function () {
                  // Aller directement à l'étape 10 (index 9)
                  tour.show(6);
                },
                text: this.$t('projectlist.previous').toString(),
                classes: 'shepherd-button-ignore',
              },
              {
                action: () => {
                  localStorage.setItem('tourIgnored', 'true');
                  tour.complete();
                },
                text: this.$t('projectlist.donotshow').toString(),
                classes: 'shepherd-button-ignore',
              },
            ],
            advanceOn: { selector: '.projetcarte', event: 'click' },
            floatingUIOptions: {
              middleware: [offset({ mainAxis: 10, crossAxis: 0 })],
            },
            scrollTo: false,
          });
          if (!window.location.pathname.endsWith('/login')) {
            tour.start();
          }
        }
      }, 2000);
    });
  }

  public startTour(): void {
    const tour = new Shepherd.Tour({
      defaultStepOptions: {
        cancelIcon: {
          enabled: true,
        },
        classes: 'shepherd-content', // Ajoutez votre propre classe CSS
        scrollTo: true,
        when: {
          show() {
            const currentStep = Shepherd.activeTour?.getCurrentStep();
            if (!currentStep) return;
            const currentStepElement = currentStep.getElement();
            if (!currentStepElement) return;
            const header = currentStepElement.querySelector('.shepherd-footer');
            if (!header) return;
            const progress = document.createElement('div');
            const innerBar = document.createElement('span');
            const progressPercentage = ((tour.steps.indexOf(currentStep) + 1) / tour.steps.length) * 100 + '%';
            progress.className = 'shepherd-progress-bar';
            innerBar.style.width = progressPercentage;
            if (document.getElementsByClassName('shepherd-button').length == 1) {
              progress.style.minWidth = '260px';
            }
            progress.appendChild(innerBar);
            const headerh = currentStepElement.querySelector('.shepherd-header');
            const progressh = document.createElement('span');
            progressh.style.marginRight = '0px';
            progressh.innerText = `${tour.steps.indexOf(currentStep) + 1}/${tour.steps.length}`;
            if (headerh) {
              headerh.insertBefore(progressh, currentStepElement.querySelector('.shepherd-cancel-icon'));
            }
            header.insertBefore(progress, currentStepElement.querySelector('.shepherd-button'));
          },
        },
      },
      useModalOverlay: true,
      keyboardNavigation: false,
    });

    tour.addStep({
      title: this.$t('projectlist.step1title').toString(),
      text: this.$t('projectlist.step1text').toString(),
      attachTo: {
        element: 'nothing', // La classe CSS d  e l'élément que vous voulez souligner
        on: 'left',
      },
      buttons: [
        {
          action: () => {
            localStorage.setItem('tourIgnored', 'true');
            tour.complete();
          },
          text: this.$t('projectlist.donotshow').toString(),
          classes: 'shepherd-button-ignore',
        },
        {
          action: tour.next,
          text: this.$t('projectlist.next').toString(),
          classes: 'shepherd-button-next',
        },
      ],
    });
    tour.addStep({
      title: this.$t('projectlist.step2title').toString(),
      text: this.$t('projectlist.step2text').toString(),
      attachTo: {
        element: 'nothing', // La classe CSS de l'élément que vous voulez souligner
        on: 'left',
      },
      buttons: [
        {
          action: () => {
            localStorage.setItem('tourIgnored', 'true');
            tour.complete();
          },
          text: this.$t('projectlist.donotshow').toString(),
          classes: 'shepherd-button-ignore',
        },
        {
          action: tour.next,
          text: this.$t('projectlist.next').toString(),
          classes: 'shepherd-button-next',
        },
      ],
    });
    tour.addStep({
      title: this.$t('projectlist.step3title').toString(),
      text: this.$t('projectlist.step3text').toString(),
      attachTo: {
        element: '.searchproject', // La classe CSS de l'élément que vous voulez souligner
        on: 'bottom',
      },
      buttons: [
        {
          action: tour.back,
          text: this.$t('projectlist.previous').toString(),
          classes: 'shepherd-button-ignore',
        },
        {
          action: tour.next,
          text: this.$t('projectlist.next').toString(),
          classes: 'shepherd-button-next',
        },
      ],
      floatingUIOptions: {
        middleware: [offset({ mainAxis: 20, crossAxis: 0 })],
      },
    });

    tour.addStep({
      title: this.$t('projectlist.step4title').toString(),
      text: this.$t('projectlist.step4text').toString(),
      attachTo: {
        element: '.diffvue', // La classe CSS de l'élément que vous voulez souligner
        on: 'bottom',
      },
      buttons: [
        {
          action: tour.back,
          text: this.$t('projectlist.previous').toString(),
          classes: 'shepherd-button-ignore',
        },
        {
          action: function () {
            let gripElement = document.querySelector('.fa-grip');
            if (gripElement) {
              (gripElement as HTMLElement).click();
            }
            setTimeout(function () {
              tour.next();
            }, 100);
          },
          text: this.$t('projectlist.next').toString(),
          classes: 'shepherd-button-next',
        },
      ],
      floatingUIOptions: {
        middleware: [offset({ mainAxis: 10, crossAxis: 0 })],
      },
    });
    tour.addStep({
      title: this.$t('projectlist.step5title').toString(),
      text: this.$t('projectlist.step5text').toString(),
      attachTo: {
        element: '.vuezoom', // La classe CSS de l'élément que vous voulez souligner
        on: 'bottom',
      },
      buttons: [
        {
          action: tour.back,
          text: this.$t('projectlist.previous').toString(),
          classes: 'shepherd-button-ignore',
        },
        {
          action: tour.next,
          text: this.$t('projectlist.next').toString(),
          classes: 'shepherd-button-next',
        },
      ],
      advanceOn: { selector: '.vuemap', event: 'click' },
      floatingUIOptions: {
        middleware: [offset({ mainAxis: 11, crossAxis: 0 })],
      },
    });
    tour.addStep({
      title: this.$t('projectlist.step6title').toString(),
      text: this.$t('projectlist.step6text').toString(),
      attachTo: {
        element: '.projetcarte', // La classe CSS de l'élément que vous voulez souligner
        on: 'bottom',
      },
      buttons: [
        {
          action: tour.back,
          text: this.$t('projectlist.previous').toString(),
          classes: 'shepherd-button-ignore',
        },
        {
          action: tour.next,
          text: this.$t('projectlist.next').toString(),
          classes: 'shepherd-button-next',
        },
      ],
      floatingUIOptions: {
        middleware: [offset({ mainAxis: 15, crossAxis: 0 })],
      },
      scrollTo: false,
    });
    tour.addStep({
      title: this.$t('projectlist.step61title').toString(),
      text: this.$t('projectlist.step61text').toString(),
      attachTo: {
        element: '.invite', // La classe CSS de l'élément que vous voulez souligner
        on: 'bottom',
      },
      buttons: [
        {
          action: tour.back,
          text: this.$t('projectlist.previous').toString(),
          classes: 'shepherd-button-ignore',
        },
        {
          action: tour.next,
          text: this.$t('projectlist.next').toString(),
          classes: 'shepherd-button-next',
        },
      ],
      advanceOn: { selector: '.picto-settings', event: 'click' },
      floatingUIOptions: {
        middleware: [offset({ mainAxis: 15, crossAxis: 0 })],
      },
      scrollTo: false,
    });
    tour.addStep({
      title: this.$t('projectlist.step62title').toString(),
      text: this.$t('projectlist.step62text').toString(),
      attachTo: {
        element: '.picto-settings', // La classe CSS de l'élément que vous voulez souligner
        on: 'right',
      },
      buttons: [
        {
          action: tour.back,
          text: this.$t('projectlist.previous').toString(),
          classes: 'shepherd-button-ignore',
        },
        {
          action: function () {
            // Aller directement à l'étape 10 (index 9)
            tour.show(11);
          },
          text: this.$t('projectlist.next').toString(),
          classes: 'shepherd-button-next',
        },
      ],
      advanceOn: { selector: '.picto-settings', event: 'click' },
      floatingUIOptions: {
        middleware: [offset({ mainAxis: 20, crossAxis: 0 })],
      },
      scrollTo: false,
    });
    tour.addStep({
      title: this.$t('projectlist.step7title').toString(),
      text: this.$t('projectlist.step7text').toString(),
      attachTo: {
        element: '.description', // La classe CSS de l'élément que vous voulez souligner
        on: 'bottom',
      },
      buttons: [
        {
          action: function () {
            // Déclenche un clic sur l'élément de classe '.close'
            let closeElement = document.querySelector('.close');
            if (closeElement) {
              (closeElement as HTMLElement).click();
            }
            tour.back();
          },
          text: this.$t('projectlist.previous').toString(),
          classes: 'shepherd-button-ignore',
        },
        {
          action: tour.next,
          text: this.$t('projectlist.next').toString(),
          classes: 'shepherd-button-next',
        },
      ],
      advanceOn: { selector: '.picto-settings', event: 'click' },
      floatingUIOptions: {
        middleware: [offset({ mainAxis: 70, crossAxis: 0 })],
      },
      scrollTo: false,
    });
    tour.addStep({
      title: this.$t('projectlist.step8title').toString(),
      text: this.$t('projectlist.step8text').toString(),
      attachTo: {
        element: '.col-12', // La classe CSS de l'élément que vous voulez souligner
        on: 'top',
      },
      buttons: [
        {
          action: tour.back,
          text: this.$t('projectlist.previous').toString(),
          classes: 'shepherd-button-ignore',
        },
        {
          action: tour.next,
          text: this.$t('projectlist.next').toString(),
          classes: 'shepherd-button-next',
        },
      ],
      advanceOn: { selector: '.picto-settings', event: 'click' },
      floatingUIOptions: {
        middleware: [offset({ mainAxis: 10, crossAxis: 0 })],
      },
      scrollTo: false,
    });
    tour.addStep({
      title: this.$t('projectlist.step9title').toString(),
      text: this.$t('projectlist.step9text').toString(),
      attachTo: {
        element: '.iframe', // La classe CSS de l'élément que vous voulez souligner
        on: 'left',
      },
      buttons: [
        {
          action: tour.back,
          text: this.$t('projectlist.previous').toString(),
          classes: 'shepherd-button-ignore',
        },
        {
          action: function () {
            // Déclenche un clic sur l'élément de classe '.close'
            let closeElement = document.querySelector('.close');
            if (closeElement) {
              (closeElement as HTMLElement).click();
            }
            tour.next();
          },
          text: this.$t('projectlist.next').toString(),
          classes: 'shepherd-button-next',
        },
      ],
      advanceOn: { selector: '.picto-settings', event: 'click' },
      floatingUIOptions: {
        middleware: [offset({ mainAxis: 10, crossAxis: 0 })],
      },
      scrollTo: false,
    });
    tour.addStep({
      title: this.$t('projectlist.step10title').toString(),
      text: this.$t('projectlist.step10text').toString(),
      attachTo: {
        element: '.projetcarte', // La classe CSS de l'élément que vous voulez souligner
        on: 'right',
      },
      buttons: [
        {
          action: function () {
            // Aller directement à l'étape 10 (index 9)
            tour.show(6);
          },
          text: this.$t('projectlist.previous').toString(),
          classes: 'shepherd-button-ignore',
        },
        {
          action: () => {
            localStorage.setItem('tourIgnored', 'true');
            tour.complete();
          },
          text: this.$t('projectlist.donotshow').toString(),
          classes: 'shepherd-button-ignore',
        },
      ],
      advanceOn: { selector: '.projetcarte', event: 'click' },
      floatingUIOptions: {
        middleware: [offset({ mainAxis: 10, crossAxis: 0 })],
      },
      scrollTo: false,
    });
    tour.start();
  }

  public showPopup(project: Project) {
    console.log(`Affichage du popup pour le projet ${project.id}`);
    this.preloadImages()
      .then(() => {
        // Toutes les images sont préchargées, maintenant vous pouvez afficher le popup
        this.loadImage(project);
        let popupRef = this.$refs['popup-' + project.id] as any[];
        if (popupRef.length > 0) {
          let leafletPopup = popupRef[0] as any;
          leafletPopup.mapObject.openPopup();
        }
      })
      .catch((error) => {
        console.error('Erreur lors du préchargement des images:', error);
      });
  }
  public preloadImages(): Promise<void[]> {
    const imagePromises: Promise<void>[] = [];
    for (const projectId in this.imageUrls) {
      const imageUrl = this.imageUrls[projectId];
      const imagePromise = new Promise<void>((resolve, reject) => {
        const image = new Image();
        image.onload = () => resolve();
        image.onerror = () => reject(new Error(`Erreur de chargement de l'image ${imageUrl}`));
        image.src = imageUrl;
      });
      imagePromises.push(imagePromise);
    }
    return Promise.all(imagePromises).catch((error) => {
      console.error('Erreur lors du préchargement des images:', error);
      throw error; // Nous lançons l'erreur à nouveau pour que la fonction appelante puisse la gérer si nécessaire
    });
  }

  public loadImage(project: Project) {
    if (project.lastPhoto?.smallKey && !this.imageUrls[project.id]) {
      const key = project.lastPhoto.smallKey;
      Storage.get(key).then((url) => {
        this.$set(this.imageUrls, project.id, url) as string;
      });
    } else if (this.imageUrls[project.id]) {
      console.log(`L'image pour le projet ${project.id} est déjà chargée: ${this.imageUrls[project.id]}`); // Ajout d'un log si l'image est déjà chargée
    } else {
      console.log(`Aucune image à charger pour le projet ${project.id}`); // Ajout d'un log si il n'y a pas d'image à charger
    }
  }

  beforeDestroy() {
    // Enlever l'écouteur d'événement au moment de la destruction du composant
    window.removeEventListener('scroll', this.checkScroll);
    window.removeEventListener('resize', () => {
      this.cardsPerRow = this.calculateCardsPerRow();
    });
  }

  private incrementCardsPerRow() {
    if (this.cardsPerRow < 6) {
      this.cardsPerRow++;
    }
  }

  private decrementCardsPerRow() {
    if (this.cardsPerRow > 1) {
      this.cardsPerRow--;
    }
  }

  public get breadcrumbs(): unknown[] {
    return [
      {
        text: this.$tc('global.project', 2),
        to: '#',
      },
    ];
  }

  public get listMode(): string | null {
    return (this.$route.query.listMode as string) || 'mosaic';
  }

  public set listMode(listMode: string | null) {
    if (listMode === null || listMode === 'mosaic') {
      this.$router.push({ path: this.$route.path, query: { ...this.$route.query, listMode: undefined } });
    } else {
      this.$router.push({ path: this.$route.path, query: { ...this.$route.query, listMode } });
    }
  }

  private async updateProjectsAndMap() {
    if (this.search === '') {
      this.projects = this.allProject.slice(0);
    } else {
      this.projects = this.filterProject(this.search);
    }
    this.onSortChange(this.sort);
    await this.$nextTick();
    this.calculateMapBounds(); // Appel pour mettre à jour les limites de la carte
  }

  public get isSmallScreen(): boolean {
    return window.innerWidth < 500;
  }

  @Watch('imageUrls', { deep: true, immediate: true })
  onImageUrlsChange() {
    this.$forceUpdate();
  }

  @Watch('search')
  private onSearchChange(val: string, oldVal: string) {
    if (val === '') {
      this.projects = this.allProject.slice(0);
    } else {
      this.projects = this.filterProject(val);
    }
    this.onSortChange(this.sort);
    this.updateProjectsAndMap();
  }

  @Watch('allProject')
  private onAllProjectChange(val: Project[], oldVal: Project[]) {
    this.onSearchChange(this.search, this.search);
    this.updateProjectsAndMap();
  }

  @Watch('projects')
  private onProjectsChange() {
    this.calculateMapBounds();
  }

  private switchToListMode() {
    this.listMode = 'list';
  }

  private switchToGridMode() {
    this.listMode = 'mosaic';
  }

  private onSortChange(value: SortType) {
    if (value === SortType.Recent) {
      this.projects = this.projects.sort((a: Project, b: Project) => {
        return b.createdAt.getTime() - a.createdAt.getTime();
      });
    } else {
      this.projects = this.projects.sort((a: Project, b: Project) => {
        if (a.city?.name !== undefined && b.city?.name !== undefined) {
          return a.city.name.localeCompare(b.city.name);
        } else if (a.city !== undefined) {
          return -1;
        } else if (b.city !== undefined) {
          return 1;
        } else {
          return 0;
        }
      });
    }
  }

  private isAdmin(project: Project) {
    return (
      this.user !== undefined && (project.owner.id === this.user.id || project.administratorId.includes(this.user.id))
    );
  }

  public viewProject(project: Project) {
    // Naviguer vers la page correspondant au projet dans un nouvel onglet
    const route = this.$router.resolve({ name: 'plateformcentral', params: { id: project.id } });
    window.open(route.href, '_blank');
  }

  private async calculateMapBounds() {
    if (this.projects.length === 0) {
      return;
    }

    const latitudes = this.projects.map((project) => project.city.latlng.lat);
    const longitudes = this.projects.map((project) => project.city.latlng.lng);

    const minLat = Math.min(...latitudes);
    const maxLat = Math.max(...latitudes);
    const minLng = Math.min(...longitudes);
    const maxLng = Math.max(...longitudes);

    const centerLat = (minLat + maxLat) / 2;
    const centerLng = (minLng + maxLng) / 2;

    const paddingFactor = 0.1;

    const bounds = new L.LatLngBounds([minLat, minLng], [maxLat, maxLng]).pad(paddingFactor);

    this.center = [centerLat, centerLng];

    const map = this.$refs.map as any;
    map.fitBounds(bounds); // Mise à jour du zoom en ajustant les limites de la carte

    // Décommentez la ligne suivante si vous souhaitez également mettre à jour le zoom manuellement
    // this.zoom = map.getZoom();
  }

  private toggleSatelliteView() {
    this.satelliteView = !this.satelliteView;
    if (this.satelliteView) {
      this.url = 'http://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}';
    } else {
      this.url = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
    }
  }
}
