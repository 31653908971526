import { Component, Vue, Prop, Ref, Watch } from 'vue-property-decorator';
import { Camera } from '@/models/camera';
import { Project } from '@/models/project';
import { Photo } from '@/models/photo';
import { Album } from '@/models/album';
import { User } from '@/models/user';
import { Getter, State, Action } from 'vuex-class';
import VueSlider from 'vue-slider-component';
// @ts-ignore no d.ts
import Datepicker from 'vuejs-datepicker';
// @ts-ignore no d.ts
import { en, fr } from 'vuejs-datepicker/dist/locale';
import { AlbumCreateRequest } from '@/services/albums/albums.interfaces';
import EditShareModal from '../editsharemodal/editsharemodal.component.vue';
import VueResizable from '../vue-resizable/vue-resizable.vue';
import S3Image from '../s3-image/S3Image.vue';
import { Logger } from 'aws-amplify';
const logger = new Logger('GalleryCompare');

@Component({
  components: {
    VueSlider,
    Datepicker,
    EditShareModal,
    VueResizable,
    S3Image,
  },
})
export default class GalleryCompare extends Vue {
  @Prop({ default: 'b' }) public direction!: string;

  public get dateFormat(): string {
    return this.locale === 'en' ? 'MM / DD / YYYY' : 'DD / MM / YYYY';
  }

  public get datepickerFormat(): string {
    return this.locale === 'en' ? 'yyyy-dd-MM' : 'yyyy-MM-dd';
  }

  public get datepickerLocal(): unknown {
    return this.locale === 'en' ? en : fr;
  }

  public get disabledDates(): unknown {
    const startDate = this.imagesKey[0] ? this.imagesKey[0].createdAt : null;
    const endDate = this.imagesKey[this.imagesKey.length - 1]
      ? this.imagesKey[this.imagesKey.length - 1].createdAt
      : null;
    if (startDate && endDate) {
      const to = new Date(startDate);
      to.setHours(to.getHours() + to.getTimezoneOffset() / 60);
      to.setHours(0);
      const from = new Date(endDate);
      from.setHours(to.getHours() + from.getTimezoneOffset() / 60);
      from.setDate(from.getDate() + 1);
      return {
        ranges: [
          {
            from: new Date(-8640000000000000), // Min date
            to,
          },
          {
            from,
            to: new Date(8640000000000000), // Max date
          },
        ],
      };
    } else {
      return {};
    }
  }

  public get sliderData(): string[] {
    return this.imagesKey.map((imageKey) => imageKey.smallKey);
  }

  public imagesKey: Photo[] = [];
  public switchBeforeAfter = true;

  public currentImageKey1: Photo | null = null;
  public sliderValue1: Photo | null = null;
  public sliderIsDragging1 = false;
  public isSliderFirstItem1 = true;
  public isSliderLastItem1 = false;

  public currentImageKey2: Photo | null = null;
  public sliderValue2: Photo | null = null;
  public sliderIsDragging2 = false;
  public isSliderFirstItem2 = false;
  public isSliderLastItem2 = true;
  public image2: HTMLImageElement | null = null;
  public image2Width = 0;
  public image2Height = 0;
  public image2Top = 0;
  public image2Left = 0;
  private pickedDate1: Date | null = null;
  private pickedDate2: Date | null = null;
  public isDragging = false;
  public offsetX = 0;
  public offsetY = 0;
  public isResizing = false;
  public startX = 0;
  public startY = 0;
  public zoomLevel1 = 1;
  public zoomLevel2 = 1;
  private lastLeftPosition = 0;
  private lastTopPosition = 0;

  @Prop({ required: true, type: Object }) private readonly camera!: Camera;
  @Prop({ required: true, type: Object }) private readonly project!: Project;
  @Prop({ required: true }) readonly isPremium!: boolean;
  @Prop({ required: true }) readonly isGuest!: boolean;

  @Ref('slider1') private readonly slider1!: VueSlider;
  @Ref('slider2') private readonly slider2!: VueSlider;

  @State('locale', { namespace: 'profile' }) private locale!: string;
  @State('user', { namespace: 'profile' }) private readonly user?: User;
  @State('photos', { namespace: 'photo' }) private readonly photosStore?: Photo[];
  @State('albums', { namespace: 'album' }) private readonly albums!: Album[];

  @Getter('onlyPhoto', { namespace: 'photo' }) private onlyPhoto!: Photo[];

  @Action('createNewAlbum', { namespace: 'album' }) private readonly createNewAlbum!: (
    cameraCreateRequest: AlbumCreateRequest
  ) => Promise<Album[]>;
  @Action('addPhoto', { namespace: 'album' }) private readonly addPhoto!: (payload: {
    albumId: string;
    photoId: string;
  }) => Promise<void>;

  public mounted(): void {
    logger.info('mounted');
    if (this.photosStore && this.photosStore.length > 0) {
      this.onPhotoStoreUpdated(this.photosStore, []);
    }
    this.$nextTick(() => {
      window.addEventListener('resize', this.updateRezisableSize);
      this.watchParentSize();
    });
    this.updateRezisableSize();
    this.sliderValue1 = this.currentImageKey1;
    this.sliderValue2 = this.currentImageKey2;
    this.$nextTick(() => {
      this.updateClipPath();
      const image2Element = document.querySelector('.image2-content') as HTMLElement;
      if (image2Element) {
        image2Element.addEventListener('wheel', this.handleZoomWheel2);
        image2Element.addEventListener('mousedown', this.startImageDragging);
      }
      const image2Element1 = document.querySelector('.crop-square') as HTMLElement;
      if (image2Element1) {
        image2Element1.addEventListener('wheel', this.handleZoomWheel1);
        image2Element1.addEventListener('mousedown', this.startImageDragging);
      }
    });
  }

  public beforeDestroy(): void {
    // Supprimer les écouteurs d'événements lors de la destruction du composant
    const image2Element = document.querySelector('.image2-content') as HTMLElement;
    if (image2Element) {
      image2Element.removeEventListener('mousedown', this.startImageDragging);
    }
    window.removeEventListener('mousemove', this.dragImage);
    window.removeEventListener('mouseup', this.stopImageDragging);
  }

  public updateClipPath() {
    const cropSquare = document.querySelector('#cropSquare') as HTMLElement;
    if (!cropSquare) {
      console.warn('cropSquare not found');
      return;
    }
    const rect = cropSquare.getBoundingClientRect();
    const containerRect = cropSquare.closest('.image1-layer')?.getBoundingClientRect();
    if (!containerRect) {
      console.warn('.image1-layer not found');
      return;
    }
    const clipPath = `polygon(
      ${rect.left - containerRect.left}px ${rect.top - containerRect.top}px, 
      ${rect.right - containerRect.left}px ${rect.top - containerRect.top}px, 
      ${rect.right - containerRect.left}px ${rect.bottom - containerRect.top}px, 
      ${rect.left - containerRect.left}px ${rect.bottom - containerRect.top}px
    )`;
    (cropSquare.closest('.image1-layer') as HTMLElement).style.clipPath = clipPath;
  }

  public startDragging(event: MouseEvent) {
    event.preventDefault();
    const cropSquare = this.$refs.cropSquare as HTMLElement;
    if (!cropSquare) return;

    const rect = cropSquare.getBoundingClientRect();
    const boundary = 7;

    // Vérifier si la souris est à moins de 10 pixels des bords
    if (
      event.clientX - rect.left < boundary ||
      rect.right - event.clientX < boundary ||
      event.clientY - rect.top < boundary ||
      rect.bottom - event.clientY < boundary
    ) {
      // Si oui, ne pas commencer le glissement
      return;
    }

    this.isDragging = true;
    this.offsetX = event.clientX;
    this.offsetY = event.clientY;
  }

  public drag(event: MouseEvent) {
    if (!this.isDragging) return;
    event.preventDefault();

    const cropSquare = this.$refs.cropSquare as HTMLElement;
    if (!cropSquare) return;

    const dx = event.clientX - this.offsetX;
    const dy = event.clientY - this.offsetY;

    const top = parseInt(cropSquare.style.top || '270') + dy;
    const left = parseInt(cropSquare.style.left || '400') + dx;

    cropSquare.style.top = `${top}px`;
    cropSquare.style.left = `${left}px`;

    this.offsetX = event.clientX;
    this.offsetY = event.clientY;

    this.updateClipPath();
  }

  public stopDragging() {
    this.isDragging = false;
  }

  public startResizing(event: MouseEvent) {
    this.isResizing = true;
    this.startX = event.clientX;
    this.startY = event.clientY;
    window.addEventListener('mousemove', this.resize);
    window.addEventListener('mouseup', this.stopResizing);
  }

  public resize(event: MouseEvent) {
    if (!this.isResizing) return;

    const cropSquare = this.$refs.cropSquare as HTMLElement;
    if (!cropSquare) return;

    const dx = event.clientX - this.startX;
    const dy = event.clientY - this.startY;

    const width = parseInt(cropSquare.style.width || '300px') + dx;
    const height = parseInt(cropSquare.style.height || '200px') + dy;

    cropSquare.style.width = `${width}px`;
    cropSquare.style.height = `${height}px`;

    this.startX = event.clientX;
    this.startY = event.clientY;

    this.updateClipPath();
  }
  public stopResizing() {
    this.isResizing = false;
    window.removeEventListener('mousemove', this.resize);
    window.removeEventListener('mouseup', this.stopResizing);
  }

  public updateZoomOnImageChange(): void {
    // Supprimer l'ancien écouteur d'événements
    const oldImage2Element = document.querySelector('.image2-content') as HTMLElement;
    if (oldImage2Element) {
      oldImage2Element.removeEventListener('wheel', this.handleZoomWheel2);
      oldImage2Element.removeEventListener('wheel', this.handleZoomWheel1);
      oldImage2Element.removeEventListener('mousedown', this.startImageDragging);
      oldImage2Element.removeEventListener('mousemove', this.dragImage);
      oldImage2Element.removeEventListener('mouseup', this.stopImageDragging);
      oldImage2Element.removeEventListener('dblclick', this.updateZoomOnImageChange2);
    }

    // Ajouter un nouvel écouteur d'événements
    this.$nextTick(() => {
      setTimeout(() => {
        const newImage2Element = document.querySelector('.image2-content') as HTMLElement;
        if (newImage2Element) {
          newImage2Element.addEventListener('wheel', this.handleZoomWheel2);
          newImage2Element.addEventListener('wheel', this.handleZoomWheel1);
          newImage2Element.addEventListener('mousedown', this.startImageDragging);
          newImage2Element.addEventListener('mousemove', this.dragImage);
          newImage2Element.addEventListener('mouseup', this.stopImageDragging);
          newImage2Element.addEventListener('dblclick', this.updateZoomOnImageChange2); // Ajout de l'écouteur de double-clic
        }
        // Réinitialiser le niveau de zoom
        this.applyZoom(this.zoomLevel2, '.image2-content');
        this.applyZoom(this.zoomLevel2, '.image1-content');
        // Réinitialiser la position de l'image
      }, 250); // Délai de 0.3 secondes
    });
  }

  public updateZoomOnImageChange2(): void {
    // Supprimer l'ancien écouteur d'événements
    const oldImage2Element = document.querySelector('.image2-content') as HTMLElement;
    if (oldImage2Element) {
      oldImage2Element.removeEventListener('dblclick', this.updateZoomOnImageChange2);
    }

    // Ajouter un nouvel écouteur d'événements
    this.$nextTick(() => {
      setTimeout(() => {
        const newImage2Element = document.querySelector('.image2-content') as HTMLElement;
        if (newImage2Element) {
          newImage2Element.addEventListener('dblclick', this.updateZoomOnImageChange2); // Ajout de l'écouteur de double-clic
        }
        // Réinitialiser le niveau de zoom
        this.zoomLevel2 = 1;
        this.applyZoom(this.zoomLevel2, '.image2-content');
        this.applyZoom(this.zoomLevel2, '.image1-content');
        this.resetImagePosition('.image2-content');
        this.resetImagePosition('.image1-content');
        // Réinitialiser la position de l'image
      }, 250); // Délai de 0.3 secondes
    });
  }

  private resetImagePosition(selector: string): void {
    const element = document.querySelector(selector) as HTMLElement;
    if (!element) return;

    element.style.left = '0px';
    element.style.top = '0px';
    this.lastLeftPosition = 0;
    this.lastTopPosition = 0;
  }

  // Appeler cette méthode lorsque l'image change
  public onImageChange(): void {
    this.updateZoomOnImageChange();
  }
  public startImageDragging(event: MouseEvent): void {
    event.preventDefault();
    this.isDragging = true;
    this.offsetX = event.clientX;
    this.offsetY = event.clientY;

    window.addEventListener('mousemove', this.dragImage);
    window.addEventListener('mouseup', this.stopImageDragging);
  }

  public dragImage(event: MouseEvent): void {
    console.log('draging');
    if (!this.isDragging) return;
    event.preventDefault();

    const dx = event.clientX - this.offsetX;
    const dy = event.clientY - this.offsetY;

    this.applyDrag(dx, dy, '.image2-content');
    this.applyDrag(dx, dy, '.image1-content');

    this.offsetX = event.clientX;
    this.offsetY = event.clientY;
  }

  public stopImageDragging(): void {
    this.isDragging = false;
    window.removeEventListener('mousemove', this.dragImage);
    window.removeEventListener('mouseup', this.stopImageDragging);
  }

  private applyDrag(dx: number, dy: number, selector: string): void {
    const element = document.querySelector(selector) as HTMLElement;
    if (!element) return;

    const left = parseInt(element.style.left || '0') + dx;
    const top = parseInt(element.style.top || '0') + dy;

    this.lastLeftPosition = left;
    this.lastTopPosition = top;

    element.style.left = `${left}px`;
    element.style.top = `${top}px`;
  }

  public watchParentSize(): void {
    const container = document.querySelector('.gallery-photo-preview-container-image');
    const resizeObserver = new ResizeObserver((entries) => {
      for (const _ of entries) {
        this.updateRezisableSize();
      }
    });

    if (container !== null) {
      resizeObserver.observe(container);
    }
  }

  private handleZoomWheel2(event: WheelEvent): void {
    if (event.deltaY < 0) {
      this.zoomLevel2 += 0.1;
    } else {
      this.zoomLevel2 -= 0.1;
    }
    this.applyZoom(this.zoomLevel2, '.image2-content');
    this.applyZoom(this.zoomLevel2, '.image1-content');
  }

  private handleZoomWheel1(event: WheelEvent): void {
    if (event.deltaY < 0) {
      this.zoomLevel2 += 0.1;
    } else {
      this.zoomLevel2 -= 0.1;
    }
    this.applyZoom(this.zoomLevel2, '.image2-content');
    this.applyZoom(this.zoomLevel2, '.image1-content');
  }

  // Méthode pour zoomer l'image 2
  public zoomInImage2(): void {
    this.zoomLevel2 += 0.1;
    this.applyZoom(this.zoomLevel2, '.image2-content');
    this.applyZoom(this.zoomLevel2, '.image1-content');
  }

  // Méthode pour dézoomer l'image 2
  public zoomOutImage2(): void {
    if (this.zoomLevel2 > 0.1) {
      this.zoomLevel2 -= 0.1;
      this.applyZoom(this.zoomLevel2, '.image2-content');
      this.applyZoom(this.zoomLevel2, '.image1-content');
    }
  }

  // Méthode pour appliquer le zoom à une image spécifique
  private applyZoom(zoomLevel: number, imageSelector: string): void {
    const imageElement = document.querySelector(imageSelector) as HTMLImageElement;
    if (imageElement) {
      imageElement.style.transform = `scale(${zoomLevel})`;
      imageElement.style.left = `${this.lastLeftPosition}px`;
      imageElement.style.top = `${this.lastTopPosition}px`;
    }
  }

  public getRotation(): string {
    return this.camera && this.camera.rotate ? `rotate(${this.camera.rotate}deg)` : '';
  }

  public togglDirection(mode: 'r' | 'b' | 's'): void {
    if (this.direction === mode) {
      this.$emit('switchmode', null);
    } else {
      this.$emit('switchmode', mode);
    }
  }

  public updateRezisableSize(): void {
    const update = () => {
      this.image2 = document.querySelector('#image2') as HTMLImageElement;
      if (this.image2 && this.image2.clientWidth !== 0 && this.image2.clientHeight !== 0) {
        logger.info('updateRezisableSize');
        const ratioHeight = this.image2.clientHeight / this.image2.naturalHeight;
        const ratioWidth = this.image2.clientWidth / this.image2.naturalWidth;
        const minRatio = Math.min(ratioHeight, ratioWidth);
        if (minRatio === ratioHeight) {
          this.image2Height = this.image2.clientHeight;
          this.image2Width = this.image2.naturalWidth * ratioHeight;
          this.image2Left = (this.image2.clientWidth - this.image2Width) / 2;
        } else {
          this.image2Height = this.image2.naturalHeight * ratioWidth;
          this.image2Width = this.image2.clientWidth;
          this.image2Top = (this.image2.clientHeight - this.image2Height) / 2;
        }
        if (this.direction === 'b') {
          this.image2Height /= 2;
        } else {
          this.image2Width /= 2;
        }
      } else {
        // If image is not ready, request next frame
        requestAnimationFrame(update);
      }
    };

    // Start the loop
    requestAnimationFrame(update);
  }

  @Watch('pickedDate1')
  public onDatePick1(val: Date, oldVal: Date | null): void {
    if (oldVal !== null) {
      const closestPhoto = this.searchClosestPhoto(val);
      if (closestPhoto) {
        this.currentImageKey1 = closestPhoto;
        this.sliderValue1 = this.currentImageKey1;
      }
    }
  }
  @Watch('pickedDate2')
  public onDatePick2(val: Date, oldVal: Date | null): void {
    if (oldVal !== null) {
      const closestPhoto = this.searchClosestPhoto(val);
      if (closestPhoto) {
        this.currentImageKey2 = closestPhoto;
        this.sliderValue2 = this.currentImageKey2;
      } else {
        alert('no image found');
      }
    }
  }
  public searchClosestPhoto(val: Date): Photo | null {
    let closestPhoto: Photo | null = null;
    let closestDistance = Infinity;
    this.imagesKey.forEach((imageKey: Photo) => {
      const photoDate = new Date(imageKey.createdAt);
      photoDate.setHours(photoDate.getHours() + photoDate.getTimezoneOffset() / 60);
      const localDistance = Math.sqrt(Math.pow(val.getTime() - photoDate.getTime(), 2));
      if (localDistance < closestDistance) {
        closestDistance = localDistance;
        closestPhoto = imageKey;
      }
    });
    return closestPhoto;
  }

  @Watch('photosStore')
  public onPhotoStoreUpdated(val: Photo[], oldVal: Photo[]): void {
    logger.info('onPhotoStoreUpdated', { val, oldVal });
    this.imagesKey = this.onlyPhoto.sort((photo1: Photo, photo2: Photo) => {
      return photo1.createdAt.getTime() - photo2.createdAt.getTime();
    });
    this.currentImageKey1 = this.imagesKey.length > 0 ? this.imagesKey[0] : null;
    if (this.currentImageKey1 && this.pickedDate1 === null) {
      this.pickedDate1 = new Date(this.currentImageKey1.createdAt);
      this.pickedDate1.setHours(this.pickedDate1.getHours() + this.pickedDate1.getTimezoneOffset() / 60);
      this.pickedDate1.setHours(12);
      this.pickedDate1.setMinutes(0);
      this.pickedDate1.setSeconds(0);
    }
    this.currentImageKey2 = this.imagesKey.length > 1 ? this.imagesKey[this.imagesKey.length - 1] : null;
    if (this.currentImageKey2 && this.pickedDate2 === null) {
      this.pickedDate2 = new Date(this.currentImageKey2.createdAt);
      this.pickedDate2.setHours(this.pickedDate2.getHours() + this.pickedDate2.getTimezoneOffset() / 60);
      this.pickedDate2.setHours(12);
      this.pickedDate2.setMinutes(0);
      this.pickedDate2.setSeconds(0);
    }
  }

  @Watch('currentImageKey2')
  public onCurrentImageKey2Change(val: Photo, oldVal: Photo): void {
    logger.info('onCurrentImageKey2Change', { val, oldVal });
    this.updateRezisableSize();
    this.onImageChange();
  }

  @Watch('currentImageKey1')
  public onCurrentImageKey1Change(val: Photo, oldVal: Photo): void {
    logger.info('onCurrentImageKey1Change', { val, oldVal });
    this.updateRezisableSize();
    this.onImageChange();
  }

  @Watch('direction')
  public onDirectionChange(val: string, oldVal: string): void {
    logger.info('direction', { val, oldVal });
    this.updateRezisableSize();
  }

  public onSliderChange1(value: Photo): void {
    if (!this.sliderIsDragging1) {
      this.currentImageKey1 = value;
    }
    this.isSliderFirstItem1 = this.slider1.getIndex() === 0;
    this.isSliderLastItem1 = this.slider1.getIndex() === this.imagesKey.length - 1;
    this.onImageChange();
  }

  public onSliderDragStart1(): void {
    this.sliderIsDragging1 = true;
  }

  public onSliderDragEnd1(): void {
    this.sliderIsDragging1 = false;
    this.currentImageKey1 = this.sliderValue1;
    this.onImageChange();
  }

  public onPrevImageClick1(): void {
    const index = this.slider1.getIndex() as number;
    if (index >= 1) {
      this.slider1.setIndex(index - 1);
    } else {
      this.slider1.setIndex(0);
    }
    this.onImageChange();
  }

  public onNextImageClick1(): void {
    const index = this.slider1.getIndex() as number;
    if (index < this.imagesKey.length - 1) {
      this.slider1.setIndex(index + 1);
    } else {
      this.slider1.setIndex(this.imagesKey.length - 1);
    }
    this.onImageChange();
  }

  public onSliderChange2(value: Photo): void {
    if (!this.sliderIsDragging2) {
      this.currentImageKey2 = value;
    }
    this.isSliderFirstItem2 = this.slider2.getIndex() === 0;
    this.isSliderLastItem2 = this.slider2.getIndex() === this.imagesKey.length - 1;
    this.onImageChange();
  }

  public onSliderDragStart2(): void {
    this.sliderIsDragging2 = true;
  }

  public onSliderDragEnd2(): void {
    this.sliderIsDragging2 = false;
    this.currentImageKey2 = this.sliderValue2;
    this.onImageChange();
  }

  public onPrevImageClick2(): void {
    const index = this.slider2.getIndex() as number;
    if (index >= 1) {
      this.slider2.setIndex(index - 1);
    } else {
      this.slider2.setIndex(0);
    }
    this.onImageChange();
  }

  public onNextImageClick2(): void {
    const index = this.slider2.getIndex() as number;
    if (index < this.imagesKey.length - 1) {
      this.slider2.setIndex(index + 1);
    } else {
      this.slider2.setIndex(this.imagesKey.length - 1);
    }
    this.onImageChange();
  }
}
