import { Component, Vue, Prop, Ref, Watch } from 'vue-property-decorator';
import { Camera } from '@/models/camera';
import { Project } from '@/models/project';
import { Video } from '@/models/video';
import videojs, { VideoJsPlayer } from 'video.js';
import { Storage } from 'aws-amplify';
import { State } from 'vuex-class';
// @ts-ignore no d.ts
import RadialProgressBar from 'vue-radial-progress';
import { NotificationMessage, Socket } from '@/store/types';
import { Logger } from 'aws-amplify';
const logger = new Logger('TimelapsePreview');

@Component({
  components: {
    RadialProgressBar,
  },
})
export default class TimelapsePreview extends Vue {
  @Prop({ required: true, type: Object }) public readonly camera!: Camera;
  @Prop({ required: true, type: Object }) public readonly project!: Project;
  @Prop({ type: Object }) public readonly lastVideo?: Video;
  @Prop({ required: true, type: Boolean }) public readonly timelapseRending!: boolean;

  @State('loadingTimelapse', { namespace: 'project' }) private loadingTimelapse!: boolean;
  @State('socket') private socketMessage!: Socket;

  @Ref('videoPlayer') private videoPlayer?: HTMLVideoElement;

  private videoUrl: string | null = null;
  private player: VideoJsPlayer | null = null;
  private options = {
    fluid: false,
    controls: true,
  };
  private progressValue = 0;
  private workerProgress: number[] = [];
  private intervalID: number[] = [];

  public mounted(): void {
    logger.info('mounted');
    if (this.lastVideo !== undefined) {
      this.onLastVideoChange(this.lastVideo, undefined);
    }
  }

  public getRotation(): string {
    return this.camera && this.camera.rotate ? `rotate(${this.camera.rotate}deg)` : '';
  }

  public beforeDestroy(): void {
    if (this.player) {
      this.player.dispose();
    }
  }

  @Watch('lastVideo')
  public onLastVideoChange(val: Video | undefined, oldVal: Video | undefined): void {
    logger.info('onLastVideoChange', { val, oldVal });
    if (this.player !== null) {
      this.player.dispose();
    }
    if (this.lastVideo && this.lastVideo.key) {
      Storage.get(this.lastVideo.key).then((url) => {
        this.videoUrl = url as string;
        this.$nextTick(this.setupVideoJs);
      });
    }
  }

  public setupVideoJs(): void {
    if (this.videoPlayer) {
      this.player = videojs(this.videoPlayer, this.options, () => {
        logger.info('onPlayerReady', this.player);
      });
    } else {
      logger.error('No video HTML5 found');
    }
  }

  @Watch('timelapseRending')
  public save(b: boolean): void {
    logger.info('timelapseRendingChange', { b });
    if (b) {
      this.progressValue = 0;
      if (this.player !== null) {
        this.player.dispose();
        this.player = null;
      }
    }
  }

  @Watch('socketMessage.message')
  public onSocketMessageChange(val: NotificationMessage): void {
    if (val.timelapse !== undefined && val.projectId === this.project.id && val.cameraId === this.camera.id) {
      switch (val.timelapse) {
        case 'startWorker':
          logger.info('startWorker ', val.workerCount);
          this.progressValue = 5;
          this.workerProgress = new Array(val.workerCount as number).fill(0);
          break;
        case 'workerDownloadDone':
          logger.info('workerDownloadDone ', val.workerId);
          clearInterval(this.intervalID[val.workerId as number]);
          this.intervalID[val.workerId as number] = window.setInterval(() => {
            this.workerProgress[val.workerId as number] += 0.55;
            this.refreshGlobalProgress();
          }, 1000);
          break;
        case 'workerFFmpegDone':
          logger.info('workerFFmpegDone ', val.workerId);
          this.workerProgress[val.workerId as number] = 100;
          window.clearInterval(this.intervalID[val.workerId as number]);
          this.refreshGlobalProgress();
          break;
        case 'timelapseFinalize':
          logger.info('timelapseFinalize ');
          this.progressValue = 90;
          break;
        case 'ready':
          this.progressValue = 100;
          break;
      }
    }
  }

  private refreshGlobalProgress() {
    this.progressValue =
      (25 * this.intervalID.filter((x: number) => x !== undefined).length) / this.workerProgress.length +
      this.workerProgress.reduce((pv, cv) => pv + cv, 0) / this.workerProgress.length / 2.0;
  }
}
