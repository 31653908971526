import { MutationTree } from 'vuex';
import { ProfileState } from './types';

export const mutations: MutationTree<ProfileState> = {
  cognitoUser(state: ProfileState, cognitoUser) {
    state.authorized = !!cognitoUser && cognitoUser.attributes;
    state.cognitoUser = cognitoUser;
    const groups =
      cognitoUser.signInUserSession !== null ? cognitoUser.signInUserSession.idToken.payload['cognito:groups'] : [];
    state.premium = Array.isArray(groups) && groups.indexOf('premium') !== -1;
    state.canUseTikee = Array.isArray(groups) && groups.indexOf('enlaps') !== -1;
  },
  setAccountVerified(state: ProfileState, bool) {
    state.accountVerified = bool;
  },
  user(state, user) {
    state.user = user;
    state.locale = user.locale;
  },
  locale(state, locale) {
    state.locale = locale;
  },
  newPassword(state: ProfileState, requiredAttributes: string[]) {
    state.requiredAttributes = requiredAttributes;
    state.newPasswordRequired = true;
  },
  resetLogin(state) {
    state.authorized = false;
    state.user = undefined;
    state.cognitoUser = undefined;
    state.loginError = '';
    state.newPasswordRequired = false;
    state.requiredAttributes = [];
  },
  errorLogin(state, error) {
    state.loginError = error;
  },
  resetErrorLogin(state) {
    state.loginError = '';
  },
};
