import { Component, Vue } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import { CompleteNewPasswordActionParam } from '@/store/profile/types';
import { Validations } from 'vuelidate-property-decorators';
import { required, email, minLength, sameAs } from 'vuelidate/lib/validators';
import { CognitoUser } from '@aws-amplify/auth';
// @ts-ignore no d.ts
import Datepicker from 'vuejs-datepicker';
// @ts-ignore no d.ts
import { en, fr } from 'vuejs-datepicker/dist/locale';
import { Logger } from 'aws-amplify';
const logger = new Logger('Signup');

@Component({
  components: {
    Datepicker,
  },
})
export default class Signup extends Vue {
  @State('locale', { namespace: 'profile' }) private locale!: string;
  @State('cognitoUser', { namespace: 'profile' }) private cognitoUser!: CognitoUser;
  @Action('completeNewPassword', { namespace: 'profile' }) private completeNewPassword!: (
    completeNewPasswordActionParam: CompleteNewPasswordActionParam
  ) => Promise<CognitoUser>;
  private loading = false;
  private errorMessage = '';
  private chkEmailAvailabilityTimer: NodeJS.Timeout | null = null;
  private form = {
    nom: '',
    prenom: '',
    entreprise: '',
    dateNaissance: '',
    fonction: '',
    email: '',
    password: '',
    repeatPassword: '',
    cgu: false,
    newsletter: false,
  };

  @Validations()
  private validations = {
    form: {
      nom: { required },
      prenom: { required },
      dateNaissance: { required },
      cgu: { required, checked: sameAs(() => true) },
      email: { required, email, emailAvailable: this.emailAvailable },
      password: {
        required,
        minLength: minLength(8),
        containsUppercase: (value: string) => /[A-Z]/.test(value),
        containsLowercase: (value: string) => /[a-z]/.test(value),
        containsNumber: (value: string) => /[0-9]/.test(value),
      },
      repeatPassword: {
        sameAsPassword: sameAs('password'),
      },
    },
  };

  public get datepickerFormat(): string {
    return this.locale === 'en' ? 'yyyy-dd-MM' : 'yyyy-MM-dd';
  }

  public get datepickerLocal(): unknown {
    return this.locale === 'en' ? en : fr;
  }

  public async onSubmit(event: Event): Promise<boolean> {
    event.preventDefault();
    this.loading = true;
    logger.info({ user: this.cognitoUser });
    const completeNewPasswordActionParam: CompleteNewPasswordActionParam = {
      cognitoUser: this.cognitoUser,
      updateUser: {
        email: this.form.email,
        firstname: this.form.prenom,
        lastname: this.form.nom,
        birthday: new Date(this.form.dateNaissance),
        company: this.form.entreprise,
        function: this.form.fonction,
        acceptedCgu: this.form.cgu,
        acceptedNewslettre: this.form.newsletter,
        locale: this.$i18n.locale,
      },
      newPassword: this.form.password,
      family_name: this.form.nom,
      given_name: this.form.prenom,
    };
    try {
      await this.completeNewPassword(completeNewPasswordActionParam);

      this.loading = false;
      return false;
    } catch (error) {
      logger.warn({ error });
      this.errorMessage = error.message;

      this.loading = false;
      return false;
    }
  }

  public mounted(): void {
    // @ts-ignore no doc
    const { userAttributes } = this.cognitoUser.challengeParam;
    this.form.email = userAttributes.email;
  }

  public emailAvailable(): true {
    /*return new Promise((resolve) => {
            // @ts-ignore
            if (value === this.cognitoUser.challengeParam.userAttributes.email || !this.$v.form.email.email) {
                resolve(true);
            } else {
                if (this.chkEmailAvailabilityTimer) {
                    clearTimeout(this.chkEmailAvailabilityTimer);
                    this.chkEmailAvailabilityTimer = null;
                }
                this.chkEmailAvailabilityTimer = setTimeout(() => {
                    userService.emailAvailable(value).then((exists) => resolve(exists));
                }, 500);
            }
        });*/
    return true;
  }
}
