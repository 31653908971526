

















































































































































































































import { Component, Ref, Vue, Watch } from 'vue-property-decorator';
import { State, Getter } from 'vuex-class';
import CameraCard from '@/components/cameracard/cameracard.component.vue';
import CameraModal from '@/components/cameramodal/cameramodal.component.vue';
import CameraModalCreate from '@/components/cameramodalcreate/cameramodalcreate.component.vue';
import { Camera } from '@/models/camera';
import { Project } from '@/models/project';
import { User } from '@/models/user';
// @ts-ignore: no d.ts file
import { vTeleport } from '@desislavsd/vue-teleport';
import { Logger } from 'aws-amplify';
import { orderBy } from 'lodash';
const logger = new Logger('CameraView');

@Component({
  components: {
    CameraCard,
    vTeleport,
    CameraModal,
    CameraModalCreate,
  },
})
export default class CameraView extends Vue {
  @Ref('new-camera-modal') cameraModal!: typeof CameraModalCreate;

  @State('user', { namespace: 'profile' }) private user!: User;
  @State('premium', { namespace: 'profile' }) private isPremium!: boolean;
  @State('canUseTikee', { namespace: 'profile' }) private canUseTikee!: boolean;
  @State('projects', { namespace: 'projects' }) private allProject!: Project[];
  @State('cameras', { namespace: 'camera' }) private allCamera?: Camera[];
  @Getter('filter', { namespace: 'camera' }) private filterCamera!: (
    search: string,
    projectId: string | null
  ) => Camera[];
  @Getter('projectWithId', { namespace: 'projects' }) private projectWithId!: (id: string) => Project | undefined;

  private cameras: Camera[] = [];
  private projectFilter: string | null = 'all';
  private search = '';
  private displayedCamerasCount = 20;
  private apiFetchIntervalFilter: string | null = 'all';

  // 01102019 to Date object
  public stringToDate(str: string): Date {
    return new Date(parseInt(str.slice(4, 8), 10), parseInt(str.slice(2, 4), 10) - 1, parseInt(str.slice(0, 2), 10));
  }

  /* list view variable */
  private get fields() {
    return [
      { key: 'serialNumber', label: this.$t('camera.serialnumber'), thClass: 'text-capitalize', sortable: true },
      { key: 'name', label: this.$t('cameramodal.cameraName'), thClass: 'text-capitalize', sortable: true },
      {
        key: 'projectId',
        label: this.$t('camera.pairedprojectname'),
        formatter: this.projectFromId,
        sortable: true,
        thClass: 'text-capitalize',
      },
      { key: 'apiFetchInterval', label: this.$t('camera.photointerval'), thClass: 'text-capitalize', sortable: true },
      {
        key: 'scheduling',
        label: 'programmation',
        sortable: true,
        sortByFormatted: true,
        formatter: (value: string, key: string, item: Camera) => {
          if (item.shadow === null || item.shadow === undefined) {
            return null;
          }
          return {
            dateStart: this.stringToDate(item.shadow.reported.Date_Start).toLocaleDateString(),
            dateEnd: this.stringToDate(item.shadow.reported.Date_End).toLocaleDateString(),
            photoInterval: item.shadow.reported.Photo_Interval,
          };
        },
        thClass: 'text-capitalize',
      },
      {
        key: 'lastPhoto.createdAt',
        label: 'Dernière photo chargée',
        sortable: true,
        formatter: (createdAt: string) => new Date(createdAt).toLocaleString(),
        thClass: 'text-capitalize',
      },
      {
        key: 'projectTotal',
        label: this.$t('cameramodal.projecttotal'),
        formatter: this.projectTotal,
        sortable: true,
        sortByFormatted: true,
        thClass: 'text-capitalize',
      },
      {
        key: 'Battery_Percent',
        label: this.$t('global.battery'),
        thClass: 'text-capitalize',
        sortable: true,
        sortByFormatted: true,
        formatter: (value: undefined, key: string, item: Camera) => item.shadow?.reported?.Battery_Percent,
      },
      {
        key: 'Data_Rate',
        label: this.$t('camera.datarate'),
        thClass: 'text-capitalize',
        sortable: true,
        sortByFormatted: true,
        formatter: (value: undefined, key: string, item: Camera) => item.shadow?.reported?.Data_Rate,
      },
      {
        key: 'Device_Temperature',
        label: this.$t('camera.cameratemperature'),
        thClass: 'text-capitalize',
        sortable: true,
        sortByFormatted: true,
        formatter: (value: undefined, key: string, item: Camera) => item.shadow?.reported.Device_Temperature,
      },
      { key: 'lastPosition', label: this.$t('camera.geolocation'), thClass: 'text-capitalize', sortable: true },
      {
        key: 'Software_Version',
        label: this.$t('camera.softwareversion'),
        thClass: 'text-capitalize',
        sortable: true,
        sortByFormatted: true,
        formatter: (value: undefined, key: string, item: Camera) => item.shadow?.reported.Software_Version,
      },
      {
        key: 'shadow.reported.Photo_HDRmode',
        label: this.$t('camera.hdrmode'),
        sortable: true,
        sortByFormatted: true,
        formatter: (hdr: string) => {
          if (hdr === '2') {
            return 'Auto';
          } else if (hdr === '1') {
            return 'On';
          } else {
            return 'Off';
          }
        },
        thClass: 'text-capitalize',
      },
    ];
  }

  public get listMode(): string | null {
    return (this.$route.query.listMode as string) || 'mosaic';
  }

  public set listMode(listMode: string | null) {
    this.$router.push({ path: this.$route.path, query: { ...this.$route.query, listMode } });
  }

  public get displayedCameras(): Camera[] {
    return this.cameras.slice(0, this.displayedCamerasCount);
  }

  private loadMoreCameras(): void {
    this.displayedCamerasCount += 2; // Charger 2 caméras supplémentaires
  }

  private checkScroll(): void {
    const threshold = 100; // 100 pixels avant d'atteindre le bas
    const position = window.innerHeight + window.scrollY;
    const height = document.body.offsetHeight;

    if (position >= height - threshold) {
      this.loadMoreCameras();
    }
  }

  private mounted() {
    window.addEventListener('scroll', this.checkScroll);
    this.refreshSearch();
  }

  public get breadcrumbs(): unknown[] {
    return [
      {
        text: this.$tc('global.camera', 2),
        to: '#',
      },
    ];
  }

  private clickCamera(camera: Camera): void {
    const foundCamera = this.cameras.find((item) => item.serialNumber === camera.serialNumber);
    if (foundCamera && this.$refs['camera-modal-' + foundCamera.id]) {
      // @ts-ignore ref définition non disponible
      this.$refs['camera-modal-' + foundCamera.id].show();
    }
  }

  private projectFromId(id: string): string {
    const project = this.projectWithId(id);
    return project && project.name ? project.name : 'Aucun projet';
  }

  private projectTotal(value: string, key: string, item: Camera): string {
    const project = item.projectId ? this.projectWithId(item.projectId) : undefined;
    if (project !== undefined) {
      return `${((project.volumeData || 0) / 1024).toFixed(2)} Go`;
    } else {
      return '';
    }
  }

  @Watch('search')
  private onSearchChange(val: string, oldVal: string) {
    logger.info('onSearchChange', { val, oldVal });
    this.refreshSearch();
  }

  @Watch('allCamera')
  private onAllCameraChange(val: Project[], oldVal: Project[]) {
    logger.info('onAllProjectChange', { val, oldVal });
    this.refreshSearch();
  }

  @Watch('projectFilter')
  private onProjectFilterChange(val: string, oldVal: string) {
    logger.info('onProjectFilterChange', { val, oldVal });
    this.refreshSearch();
  }

  @Watch('apiFetchIntervalFilter')
  private onApiFetchIntervalFilterChange() {
    this.refreshSearch();
  }

  private refreshSearch() {
    if (this.search !== '' || this.projectFilter !== 'all' || this.apiFetchIntervalFilter !== 'all') {
      this.cameras = [...this.filterCamera(this.search, this.projectFilter)].filter((camera) => {
        if (this.apiFetchIntervalFilter === '60') {
          return camera.apiFetchInterval === 60;
        } else if (this.apiFetchIntervalFilter === '1') {
          return camera.apiFetchInterval === 1;
        } else if (this.apiFetchIntervalFilter === '-1') {
          return camera.apiFetchInterval === -1;
        } else if (this.apiFetchIntervalFilter === '') {
          return camera.apiFetchInterval === undefined;
        } else {
          return true;
        }
      });
      this.cameras = orderBy(this.cameras, 'createdAt', 'desc');
    } else if (this.allCamera !== undefined) {
      this.cameras = this.allCamera.slice(0);
      this.cameras = orderBy(this.cameras, 'createdAt', 'desc');
    }

    // Réinitialiser le tri des colonnes
    const table = this.$refs['cameraTable'];
    if (table) {
      // @ts-ignore ref définition non disponible
      table.clearSorting();
    }
  }

  private switchToListMode() {
    if (this.listMode === 'mosaic') {
      this.listMode = 'list';
    } else {
      this.listMode = 'mosaic';
    }
  }
}
