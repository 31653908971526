import { GetterTree } from 'vuex';
import { ProjectState } from './types';
import { RootState } from '../types';

export const getters: GetterTree<ProjectState, RootState> = {
  timelapseForCamera: (state) => (cameraId: string) => {
    return state.timelapse.find((timelapse) => timelapse.cameraId === cameraId);
  },
  scenarioForCamera: (state) => (cameraId: string) => {
    return state.scenario.filter((scenario) => scenario.video.cameraId === cameraId);
  },
};
