import { Component, Vue, Prop, Watch, Ref } from 'vue-property-decorator';
import { Camera } from '@/models/camera';
import { Photo } from '@/models/photo';
import { TimelapseConfiguration } from '@/models/timelapseconfiguration';
import { State } from 'vuex-class';
import VueSlider from 'vue-slider-component';
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue';
import { subDays, differenceInDays, eachDayOfInterval, startOfDay, endOfDay, parse, isBefore } from 'date-fns';
// @ts-ignore no d.ts
import Datepicker from 'vuejs-datepicker';
// @ts-ignore no d.ts
import { en, fr } from 'vuejs-datepicker/dist/locale';
// @ts-ignore no d.ts
import VirtualCollection from 'vue-virtual-collection';
import S3Image from '../s3-image/S3Image.vue';
import { Logger } from 'aws-amplify';
const logger = new Logger('TimelapseFilter');

interface Period {
  begin: {
    HH?: string;
    mm?: string;
  };
  end: {
    HH?: string;
    mm?: string;
  };
}

@Component({
  components: {
    VueSlider,
    VueTimepicker,
    S3Image,
    Datepicker,
  },
})
export default class TimelapseFilter extends Vue {
  /*
      private get computedModel(): TimelapseConfiguration {
        return Object.assign({}, this.model);
      }
      */
  @Prop({ type: Number, default: 0 }) private datebegin!: number;
  @Prop({ type: Number, default: 0 }) private dateend!: number;
  @Prop({ type: Boolean, default: true }) readonly showLastDiv!: boolean;

  public get datepickerLocal(): unknown {
    return this.locale === 'en' ? en : fr;
  }

  private get allPhotosDates(): number[] {
    logger.info('refresh allPhotosDates');
    if (this.withinInterval && this.imagesKey.length > 0) {
      return eachDayOfInterval({
        start: this.imagesKey[0].createdAt,
        end: this.imagesKey[this.imagesKey.length - 1].createdAt,
      }).map((date) => date.getTime() / 1000);
    } else {
      return [];
    }
  }

  public get dateFormat(): string {
    return this.locale === 'en' ? 'MM/DD/YY' : 'DD/MM/YY';
  }

  public get duree(): string {
    if (this.withinInterval) {
      const date = new Date(0);
      date.setSeconds(this.dataImagesKey.length / this.model.fps);
      return date.toISOString().substr(11, 8);
    } else {
      return '';
    }
  }

  public onStartDateSelected(date: Date): void {
    const timestamp = Math.floor(date.getTime() / 1000);
    this.$set(this.sliderDates, 0, timestamp);
    this.model.interval.begin = new Date(timestamp * 1000).getTime() / 1000;
    this.updateSlider();
  }

  public onEndDateSelected(date: Date): void {
    const timestamp = Math.floor(date.getTime() / 1000);
    this.$set(this.sliderDates, 1, timestamp);
    this.model.interval.end = new Date(timestamp * 1000).getTime() / 1000;
    this.updateSlider();
  }

  public onStartDateSelected1(date: Date): void {
    const timestamp = Math.floor(date.getTime() / 1000);
    this.datebegin = new Date(timestamp * 1000).getTime();
    console.log('configbegin', this.datebegin);
    this.$emit('start-date-selected', this.datebegin);
  }

  public onEndDateSelected1(date: Date): void {
    const timestamp = Math.floor(date.getTime() / 1000);
    this.dateend = new Date(timestamp * 1000).getTime();
    console.log('configbegin', this.dateend);
    this.$emit('end-date-selected', this.dateend);
  }

  private updateSlider(): void {
    // Vérifier que les deux dates sont sélectionnées
    if (this.sliderDates.length === 2) {
      // Mettre à jour les valeurs du slider avec les nouvelles dates sélectionnées
      const startDate = new Date(this.sliderDates[0] * 1000).getTime();
      const endDate = new Date(this.sliderDates[1] * 1000).getTime();

      // Mettre à jour les valeurs du slider en fonction des nouvelles dates
      const slider = this.$refs.slider as VueSlider;
      slider.setValue([startDate, endDate]);
    }
  }

  private formatDate(timestamp: number | undefined): string {
    if (timestamp && !isNaN(timestamp)) {
      const date = new Date(timestamp * 1000);
      return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
    } else {
      return '-/-/-';
    }
  }

  @Ref('scroller') readonly scroller!: VirtualCollection;
  @Ref('photos') readonly photos!: HTMLDivElement;

  @Prop({ required: true, type: Object }) readonly camera!: Camera;
  @Prop({ type: Boolean, default: true }) readonly withinInterval!: boolean;
  @Prop({ required: true, type: Object }) readonly value!: TimelapseConfiguration;
  @Prop() readonly imagesKey!: Photo[];
  @Prop() readonly dataImagesKey!: Photo[];
  @Prop({ required: true }) readonly isPremium!: boolean;

  @State('locale', { namespace: 'profile' }) private locale!: string;
  @State('isLoading', { namespace: 'photo' }) readonly loadingPhoto!: boolean;
  @State('loadingTimelapse', { namespace: 'project' }) readonly loadingTimelapse!: boolean;

  // private model: TimelapseConfiguration = { ...this.value };
  private get model(): TimelapseConfiguration {
    return this.value;
  }

  private set model(val: TimelapseConfiguration) {
    const compare = JSON.stringify(val).localeCompare(JSON.stringify(this.value));
    if (compare !== 0) {
      this.$emit('input', val);
    }
  }

  private visible = true;

  private sliderDates: number[] = [];
  private lastNDaysDate = NaN;
  private period: Period = { begin: { HH: '00', mm: '00' }, end: { HH: '23', mm: '59' } };
  private periodError: boolean | null = null;
  private except: Period = { begin: { HH: '', mm: '' }, end: { HH: '', mm: '' } };
  private exceptError: boolean | null = null;
  private exposureRange: { min: number; max: number } = { min: Infinity, max: -Infinity };

  private dataReady = false;

  public mounted(): void {
    logger.info('mounted', { model: this.model });
    if (!this.loadingPhoto && !this.loadingTimelapse) {
      this.onDataReady();
    }
  }

  public datepickerSelectedBegin(date: Date): void {
    this.model.interval.begin = startOfDay(date).getTime() / 1000;
  }

  public datepickerSelectedEnd(date: Date): void {
    this.model.interval.end = endOfDay(date).getTime() / 1000;
  }

  /*
      @Watch('computedModel', { deep: true })
      public onModelChange(val: TimelapseConfiguration, oldVal: TimelapseConfiguration): void {
        const compare = JSON.stringify(val).localeCompare(JSON.stringify(oldVal));
        if (compare !== 0) {
          this.$emit('input', val);
        }
      }
      */

  /*
      @Watch('value', { deep: true })
      public onValueChange(val: TimelapseConfiguration): void {
        this.model = { ...val };
      }
      */

  @Watch('period', { deep: true })
  public onPeriodChange(val: Period): void {
    logger.info('onPeriodChange', JSON.stringify(val));
    if (this.period.begin.HH === undefined || this.period.begin.HH === '') {
      this.period.begin.HH = '00';
      this.period.begin.mm = '00';
    }
    if (this.period.end.HH === undefined || this.period.end.HH === '') {
      this.period.end.HH = '23';
      this.period.end.mm = '59';
    }

    const startHourStr = this.period.begin.HH + ':' + this.period.begin.mm;
    const endHourStr = this.period.end.HH + ':' + this.period.end.mm;

    const startHour = parse(startHourStr, 'HH:mm', new Date());
    const endHour = parse(endHourStr, 'HH:mm', new Date());
    if (isBefore(startHour, endHour)) {
      this.model.period.startHour = startHourStr;
      this.model.period.endHour = endHourStr;
      this.periodError = null;
    } else {
      this.periodError = true;
    }
  }

  @Watch('except', { deep: true })
  public onExceptChange(val: Period): void {
    logger.info('onExceptChange', JSON.stringify(val));
    if (val.begin.HH !== '' && val.begin.mm === '') {
      this.except.begin.mm = '00';
    }
    if (val.end.HH !== '' && val.end.mm === '') {
      this.except.end.mm = '00';
    }
    if (val.begin.HH === undefined || val.begin.HH === '' || val.end.HH === undefined || val.end.HH === '') {
      this.model.exclude = undefined;
    } else {
      const startHourStr = this.except.begin.HH + ':' + this.except.begin.mm;
      const endHourStr = this.except.end.HH + ':' + this.except.end.mm;

      const startHour = parse(startHourStr, 'HH:mm', new Date());
      const endHour = parse(endHourStr, 'HH:mm', new Date());
      if (isBefore(startHour, endHour)) {
        this.model.exclude = [
          {
            startHour: startHourStr,
            endHour: endHourStr,
          },
        ];
        this.exceptError = null;
      } else {
        this.exceptError = true;
      }
    }
  }

  @Watch('loadingTimelapse')
  public onLoadingTimelapse(val: boolean, oldVal: boolean): void {
    logger.info('loadingTimelapse', { val, oldVal, loadingPhoto: this.loadingPhoto });
    if (oldVal && !val && !this.loadingPhoto) {
      this.onDataReady();
    }
  }

  @Watch('loadingPhoto')
  public onLoadingPhoto(val: boolean, oldVal: boolean): void {
    logger.info('loadingPhoto', { val, oldVal, loadingTimelapse: this.loadingTimelapse });
    if (oldVal && !val && !this.loadingTimelapse) {
      this.onDataReady();
    }
  }

  public onDataReady(): void {
    logger.info('onDataReady', { model: this.model });
    const firstPhotoDate = this.allPhotosDates[0];
    const lastPhotoDate = this.allPhotosDates[this.allPhotosDates.length];
    this.model.interval.begin = firstPhotoDate ?? startOfDay(new Date()).getTime() / 1000;
    this.model.interval.end = lastPhotoDate ?? endOfDay(new Date()).getTime() / 1000;
    this.sliderDates = [this.model.interval.begin, this.model.interval.end];
    this.lastNDaysDate = this.model.interval.begin;
    let begin = ['00', '00'];
    if (this.model.period.startHour) {
      begin = this.model.period.startHour.split(':');
    } else {
      this.model.period.startHour = '00:00';
    }
    this.period.begin.HH = begin[0];
    this.period.begin.mm = begin[1];
    let end = ['23', '59'];
    if (this.model.period.endHour) {
      end = this.model.period.endHour.split(':');
    } else {
      this.model.period.endHour = '23:59';
    }
    this.period.end.HH = end[0];
    this.period.end.mm = end[1];

    if (this.model.exclude && this.model.exclude.length > 0) {
      this.except.begin.HH = this.model.exclude[0].startHour.split(':')[0];
      this.except.begin.mm = this.model.exclude[0].startHour.split(':')[1];
      this.except.end.HH = this.model.exclude[0].endHour.split(':')[0];
      this.except.end.mm = this.model.exclude[0].endHour.split(':')[1];
    }

    if (this.withinInterval && this.allPhotosDates.length > 0) {
      const firstPhotoDate = this.allPhotosDates[0];
      const lastPhotoDate = this.allPhotosDates[this.allPhotosDates.length - 1];
      if (this.sliderDates[0] < firstPhotoDate) {
        this.sliderDates[0] = firstPhotoDate;
      }
      if (this.sliderDates[1] < firstPhotoDate) {
        this.sliderDates[1] = firstPhotoDate;
      }

      if (this.sliderDates[0] > lastPhotoDate) {
        this.sliderDates[0] = lastPhotoDate;
      }
      if (this.sliderDates[1] > lastPhotoDate) {
        this.sliderDates[1] = lastPhotoDate;
      }
    }
    this.dataReady = true;
    if (this.model.lastNDays) {
      this.onLastNDaySpanChange(`${this.model.lastNDaysSpan}`);
      this.sliderDates = [this.model.interval.begin, this.model.interval.end];
    }
  }

  public onSliderDatesChange(values: number[]): void {
    logger.info('onSliderDatesChange', { values });
    this.sliderDates = values;
    this.model.interval.begin = startOfDay(this.sliderDates[0] * 1000).getTime() / 1000;
    this.model.interval.end = endOfDay(this.sliderDates[1] * 1000).getTime() / 1000;
  }

  public onLastNDaysChange(value: boolean): void {
    logger.info('onLastNDaysChange', { value });
    this.model.lastNDays = value;
  }

  public onLastNDaySliderChange(value: number): void {
    this.lastNDaysDate = value;
    this.model.lastNDaysSpan = differenceInDays(
      this.allPhotosDates[this.allPhotosDates.length - 1] * 1000,
      value * 1000
    );
    this.updateLastNDaysDate(this.model.lastNDaysSpan);
  }

  public onLastNDaySpanChange(value: string): void {
    this.model.lastNDaysSpan = parseInt(value, 10);
    this.updateLastNDaysDate(this.model.lastNDaysSpan);
  }

  private sliderLastNDays(dotsPos: number[]): number[][] {
    return [[dotsPos[0], 100]];
  }

  private updateLastNDaysDate(days: number): void {
    const firstPhotoDate = this.allPhotosDates[0];
    const lastPhotoDate = this.allPhotosDates[this.allPhotosDates.length - 1] || new Date().getTime() / 1000;
    const nDaysAgo = Math.floor(subDays(lastPhotoDate * 1000, days).getTime() / 1000);
    this.lastNDaysDate = firstPhotoDate === undefined || nDaysAgo > firstPhotoDate ? nDaysAgo : firstPhotoDate;
    this.model.interval.begin = startOfDay(this.lastNDaysDate * 1000).getTime() / 1000;
    this.model.interval.end = endOfDay(lastPhotoDate * 1000).getTime() / 1000;
  }
}
