import { GetterTree } from 'vuex';
import { CameraState } from './types';
import { RootState } from '../types';
import { Camera } from '@/models/camera';

export const getters: GetterTree<CameraState, RootState> = {
  cameraWithId: (state) => (id?: string) => {
    const found = state.cameras.find((camera) => {
      return camera.id === id;
    });
    return found;
  },
  filter: (state) => (name: string, projectId: string | null) => {
    const search = (camera: Camera) => {
      return (
        camera.name.toLowerCase().indexOf(name.toLowerCase()) !== -1 ||
        camera.id.toLowerCase().indexOf(name.toLowerCase()) !== -1
      );
    };

    return state.cameras.filter((camera) => {
      if (projectId === 'all') {
        return search(camera);
      } else {
        return search(camera) && (!projectId || projectId === camera.projectId);
      }
    });
  },
};
