import { WeatherResult, ForecastResult } from './weather.interfaces';

export class WeatherService {
  public constructor(private readonly apiKey: string) {}

  public async weatherByCoordinate(lat: number, lng: number, tempUnit?: 'metric' | 'imperial'): Promise<WeatherResult> {
    const url = `https://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${lng}&appid=1a834bd9e4ed04b615474844828a9ca2&units=${tempUnit}&lang=fr`;
    return fetch(url)
      .then((resp) => resp.json())
      .then((data) => {
        return data as WeatherResult;
      });
  }

  public async forecastByCoordinate(lat: number, lng: number): Promise<ForecastResult> {
    const url = `https://api.openweathermap.org/data/2.5/forecast?lat=${lat}&lon=${lng}&appid=1a834bd9e4ed04b615474844828a9ca2&units=metric&cnt=5&lang=fr`;
    return fetch(url)
      .then((resp) => resp.json())
      .then((data) => {
        return data as ForecastResult;
      });
  }
}
