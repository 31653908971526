import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Camera } from '@/models/camera';
import { Project } from '@/models/project';
import PremiumOverlay from '../premiumoverlay/premiumoverlay.component.vue';
import GuestOverlay from '../guestoverlay/guestoverlay.component.vue';
import { cameraService } from '@/services/';
import videojs, { VideoJsPlayer } from 'video.js';
import { Auth } from 'aws-amplify';
import AWS from 'aws-sdk';
import KinesisVideo from 'aws-sdk/clients/kinesisvideo';
import KinesisVideoArchivedMedia from 'aws-sdk/clients/kinesisvideoarchivedmedia';
import { Endpoint } from 'aws-sdk/lib/core';
import { Logger } from 'aws-amplify';
import axios from 'axios';
const logger = new Logger('Livestream');

const dynamoDb = new AWS.DynamoDB.DocumentClient();

// Placez cette fonction ici, avant la déclaration de la classe
async function configureAWSCredentials() {
  try {
    const credentials = await Auth.currentCredentials();
    AWS.config.update({
      credentials: credentials,
      region: 'eu-west-3', // Remplacez par votre région AWS
    });
    console.log('AWS Credentials configurés avec succès.');
  } catch (error) {
    console.error('Erreur lors de la configuration des credentials AWS:', error);
  }
}

@Component({
  components: {
    PremiumOverlay,
    GuestOverlay,
  },
})
export default class Livestream extends Vue {
  @Prop({ type: Object, required: true }) private readonly camera!: Camera;
  @Prop({ type: Object, required: true }) private readonly project!: Project;
  @Prop({ required: true }) readonly isPremium!: boolean;
  @Prop({ required: true }) readonly isGuest!: boolean;

  private visible = false;
  private cameraremaining: number | null = null;
  private timeout: null | number = null;

  private livestreamwait = false;
  private isLoading = true;
  private showplayer = false;
  private showIframe = false;

  private channelARN: string | null = null;

  private player?: VideoJsPlayer;
  private options = {
    fluid: true,
    controls: true,
  };

  public get isPreviousCamera(): boolean {
    return this.project.camerasId.indexOf(this.camera.id) === -1;
  }

  public get computedIframeSrc(): string {
    return `https://vdo.ninja/?v=${this.camera.id}_bitrate=10000&p=0&autoplay=1&noaudio`;
  }

  private isLoadingRemaining = true;

  @Watch('camera')
  private onCameraChange(newCamera: Camera, oldCamera: Camera) {
    if (newCamera.id !== oldCamera.id) {
      this.fetchCameraRemaining(); // Récupérer les nouvelles données pour la nouvelle caméra
      this.resetDailySessions();
    }
  }

  public async mounted(): Promise<void> {
    await configureAWSCredentials();

    if (this.camera.shadow !== undefined) {
      logger.info(this.camera.shadow.desired.Camera_Mode);
      this.livestreamwait = this.camera.shadow.desired.Camera_Mode === '3';
    }

    await this.resetDailySessions(); // Réinitialiser les sessions quotidiennes si nécessaire
    await this.fetchCameraRemaining(); // Récupérer la valeur de `camera.remaining` après réinitialisation
    this.isLoading = false; // Indicateur de fin de chargement
  }

  private async fetchCameraRemaining(): Promise<void> {
    const dynamoDb = new AWS.DynamoDB.DocumentClient({
      region: 'eu-west-3',
    });

    const params = {
      TableName: 'erige-cameras-prod',
      Key: {
        id: this.camera.id,
      },
      ConsistentRead: true, // Lecture cohérente pour s'assurer que vous obtenez la dernière valeur
    };

    try {
      const result = await dynamoDb.get(params).promise();
      if (result.Item && result.Item.remaining !== undefined) {
        this.camera.remaining = result.Item.remaining;
        console.log(`1- Valeur récupérée de DynamoDB pour camera.remaining: ${this.camera.remaining}`);
      } else {
        console.error('L\'attribut "remaining" est introuvable dans l\'élément DynamoDB.');
        this.camera.remaining = 0; // Valeur par défaut si non trouvée
      }
    } catch (error) {
      console.error('Erreur lors de la récupération de camera.remaining depuis DynamoDB:', error);
      this.camera.remaining = 0; // Valeur par défaut en cas d'erreur
    }
  }

  private async resetDailySessions(): Promise<void> {
    // Obtenir la date actuelle
    const now = new Date();

    // Ajouter une heure pour ajuster au fuseau horaire de Paris
    now.setHours(now.getHours() + 2);

    // Formater la date au format 'YYYY-MM-DD'
    const today = now.toISOString().split('T')[0];
    console.log(`La date d'aujourd'hui à l'heure de Paris est: ${today}`);
    console.log(`La date d'aujourd'hui est: ${today}`);

    // Configurer explicitement la région AWS
    const dynamoDb = new AWS.DynamoDB.DocumentClient({
      region: 'eu-west-3',
    });

    const params = {
      TableName: 'erige-cameras-prod',
      Key: {
        id: this.camera.id,
      },
      UpdateExpression: 'set remaining = :r, lastReset = :lr',
      ConditionExpression: 'attribute_not_exists(lastReset) OR lastReset < :lr',
      ExpressionAttributeValues: {
        ':r': 3,
        ':lr': today,
      },
      ReturnValues: 'UPDATED_NEW',
    };

    try {
      const result = await dynamoDb.update(params).promise();
      if (result.Attributes) {
        this.camera.remaining = result.Attributes.remaining;
        console.log(`Les sessions ont été réinitialisées à 3 pour aujourd'hui (${today}).`);
      }
    } catch (error) {
      if (error.code === 'ConditionalCheckFailedException') {
        console.log("Les sessions ont déjà été réinitialisées aujourd'hui.");
      } else {
        console.error('Erreur lors de la réinitialisation des sessions:', error);
      }
    }
  }

  private beforeDestroy() {
    if (this.timeout !== null) {
      logger.info('clearTimeout');
      clearTimeout(this.timeout);
    }
  }

  private async sendSms1(phoneNumber: string, message: string): Promise<void> {
    try {
      const sns = new AWS.SNS({ region: 'eu-west-3' });
      const params = {
        Message: message,
        PhoneNumber: phoneNumber,
        MessageAttributes: {
          'AWS.MM.SMS.OriginationNumber': {
            DataType: 'String',
            StringValue: '+15482908469', // Remplacez par le numéro long que vous avez acheté
          },
        },
      };
      await sns.publish(params).promise();
      console.log('Live streaming démarré');
    } catch (error) {
      console.error('Erreur lors du déclenchement du Live', error);
    }
  }

  private async sendSms(phoneNumber: string, message: string): Promise<void> {
    try {
      const response = await axios.post(
        'https://api.brevo.com/v3/transactionalSMS/sms',
        {
          sender: 'AppTEYE', // Nom ou numéro qui apparaîtra comme expéditeur
          recipient: phoneNumber, // Numéro de téléphone du destinataire
          content: message, // Message à envoyer
        },
        {
          headers: {
            'api-key': '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', // Remplacez par votre clé API
            'Content-Type': 'application/json',
          },
        }
      );
      console.log('SMS envoyé via Sendinblue', response.data);
    } catch (error) {
      if (error.response) {
        console.error("Erreur lors de l'envoi du SMS avec Brevo:");
        console.error('Status:', error.response.status);
        console.error('Data:', error.response.data);
        console.error('Headers:', error.response.headers);
      } else if (error.request) {
        console.error("La requête a été envoyée mais aucune réponse n'a été reçue:", error.request);
      } else {
        console.error('Erreur lors de la configuration de la requête:', error.message);
      }
    }
  }

  public async onPlayIconClick(): Promise<void> {
    if (this.camera && this.camera.remaining! > 0) {
      // Vérifier si des sessions sont disponibles
      this.showIframe = true;
      await this.decrementSessionState();
      const message = 'Live';
      if (this.camera && this.camera.phonenumber) {
        await this.sendSms(this.camera.phonenumber, message);
      }
      this.$nextTick(() => {
        const iframe = this.$refs.videoIframe as HTMLIFrameElement;
        if (iframe) {
          iframe.onload = () => {
            iframe.contentWindow?.postMessage({ type: 'start' }, '*');
            setTimeout(() => {
              iframe.contentWindow?.postMessage({ type: 'play' }, '*');
            }, 500);
          };
        }
      });
      // Décrémenter le nombre de sessions restantes après 1 minute (60 000 ms)
      setTimeout(async () => {
        this.showIframe = false;
      }, 72000);
    } else {
      alert('Aucune session live restante.');
    }
  }

  private async decrementSessionState(): Promise<void> {
    if (this.camera && this.camera.remaining! > 0) {
      this.camera.remaining! -= 1; // Décrémenter le nombre de sessions restantes

      // Configurer explicitement la région AWS
      const dynamoDb = new AWS.DynamoDB.DocumentClient({
        region: 'eu-west-3', // Remplacez par votre région AWS
      });

      // Mettre à jour la valeur dans DynamoDB
      const params = {
        TableName: 'erige-cameras-prod', // Remplacez par votre table DynamoDB
        Key: {
          id: this.camera.id, // Clé primaire (remplacez par vos attributs de clé primaire)
        },
        UpdateExpression: 'set remaining = :r',
        ExpressionAttributeValues: {
          ':r': this.camera.remaining,
        },
        ReturnValues: 'UPDATED_NEW',
      };
      try {
        await dynamoDb.update(params).promise();
        console.log(`Sessions restantes mises à jour: ${this.camera.remaining}`);
      } catch (error) {
        console.error('Erreur lors de la mise à jour de DynamoDB:', error);
      }
    }
  }
}
