import { Module } from 'vuex';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import { ProfileState } from './types';
import { RootState } from '../types';

export const state: ProfileState = {
  locale: 'fr',
  authorized: false,
  user: undefined,
  cognitoUser: undefined,
  loginError: '',
  newPasswordRequired: false,
  requiredAttributes: [],
  accountVerified: true,
  premium: false,
  canUseTikee: false,
};

const namespaced = true;

export const profile: Module<ProfileState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
