import { IframeProject, iframeProjectSerializer } from '../../models/project';
import { API } from 'aws-amplify';
import { IframePhoto, iframePhotoSerializer } from '../../models/photo';
import { GetPhotoResult } from '../photos/photos.interfaces';

export class IframesService {
  public constructor(private readonly apiName: string) {}

  public async iframeProject(projectId: string): Promise<IframeProject | undefined> {
    return API.get(this.apiName, `/iframe/photos/${projectId}`, {}).then((json) => iframeProjectSerializer.parse(json));
  }

  public async iframePhoto(projectId: string, cameraId: string): Promise<IframePhoto[]> {
    return this.findAllLoop(`/iframe/photos/${projectId}/${cameraId}`);
  }
  private async findAllLoop(url: string): Promise<IframePhoto[]> {
    return API.get(this.apiName, url, {}).then((response: GetPhotoResult<IframePhoto>) => {
      if (response.next) {
        return this.findAllLoop(response.next).then((arr) => [
          ...arr,
          ...iframePhotoSerializer.parseAsArray(response.photos),
        ]);
      } else {
        return iframePhotoSerializer.parseAsArray(response.photos);
      }
    });
  }
}
