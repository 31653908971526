import { Component, Vue, Watch } from 'vue-property-decorator';
import { Action, State, Mutation } from 'vuex-class';
import { LoginActionParam } from '@/store/profile/types';
import { Validations } from 'vuelidate-property-decorators';
import { required, email, minLength } from 'vuelidate/lib/validators';

@Component
export default class Login extends Vue {
  @Action('login', { namespace: 'profile' }) private actionLogin!: (
    loginActionParam: LoginActionParam
  ) => Promise<void>;
  @State('loginError', { namespace: 'profile' }) private errorLogin?: string;
  @Mutation('resetErrorLogin', { namespace: 'profile' }) private resetErrorLogin!: () => void;

  private loading = false;
  private showPassword = false;
  private form = {
    email: '',
    password: '',
    error: '',
  };

  @Validations()
  private validations = {
    form: {
      email: { required, email, notExist: this.notExist },
      password: { required, minLength: minLength(8) },
    },
  };

  public async onSubmit(event: Event): Promise<boolean> {
    event.preventDefault();
    this.loading = true;
    const loginActionParam: LoginActionParam = { email: this.form.email, password: this.form.password };
    await this.actionLogin(loginActionParam);
    this.loading = false;
    return false;
  }

  public toggleShowPassword(): void {
    this.showPassword = !this.showPassword;
  }

  public forgotPassword(): void {
    this.$emit('forgot-password');
  }

  @Watch('form.email')
  private onEmailChange() {
    this.resetErrorLogin();
  }
  @Watch('form.password')
  private onPasswordChange() {
    this.resetErrorLogin();
  }

  private notExist() {
    return this.errorLogin === '';
  }
}
