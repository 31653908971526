import { ActionTree } from 'vuex';
import { AlbumState } from './types';
import { RootState } from '../types';
import { albumsService } from '@/services';
import { Album } from '@/models/album';
import { AlbumCreateRequest, AlbumUpdateRequest, AlbumDeleteRequest } from '@/services/albums/albums.interfaces';
import { Logger } from 'aws-amplify';
const logger = new Logger('AlbumStore');

export const actions: ActionTree<AlbumState, RootState> = {
  async loadAlbum({ commit }, { projectId, cameraId }) {
    logger.info('loadAlbum');
    commit('loadingAlbum');
    if (projectId && cameraId) {
      const albums: Album[] = await albumsService.getMyAlbum(projectId, cameraId);
      commit('albums', albums);
      return albums;
    } else {
      return [];
    }
  },
  async loadOneAlbum({ commit }, albumId) {
    if (albumId) {
      commit('loadingAlbum');
      const album: Album | undefined = await albumsService.getOne(albumId);
      commit('album', album);
      return album;
    } else {
      return undefined;
    }
  },
  async addPhoto({ commit }, { albumId, photoId }) {
    commit('loadingAlbum');
    const album: Album | undefined = await albumsService.albumAddPhoto(albumId, photoId);
    commit('updateAlbum', album);
  },
  async removePhoto({ commit }, { albumId, photoId }) {
    commit('loadingAlbum');
    const album: Album | undefined = await albumsService.albumRemovePhoto(albumId, photoId);
    if (album && album.photos.length === 0) {
      commit('removeAlbum', album);
    } else {
      commit('updateAlbum', album);
    }
    return album;
  },
  async createNewAlbum({ commit }, cameraCreateRequest: AlbumCreateRequest) {
    commit('loadingAlbum');
    const albums: Album[] = await albumsService.createNewAlbum(cameraCreateRequest);
    commit('albums', albums);
    return albums;
  },
  async updateAlbum({ commit }, cameraUpdateRequest: AlbumUpdateRequest) {
    commit('loadingAlbum');
    const albums: Album[] = await albumsService.updateAlbum(cameraUpdateRequest);
    commit('albums', albums);
    return albums;
  },
  async deleteAlbum({ commit, dispatch }, cameraDeleteRequest: AlbumDeleteRequest) {
    commit('loadingAlbum');
    await albumsService.deleteAlbum(cameraDeleteRequest);
    return await dispatch('loadAlbum');
  },
};
