import { Module } from 'vuex';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import { CameraState } from './types';
import { RootState } from '../types';

export const state: CameraState = {
  loadingCamera: false,
  cameras: [],
};

const namespaced = true;

export const camera: Module<CameraState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
