import 'reflect-metadata';
import { jsonObject, jsonMember, toJson, TypedJSON } from 'typedjson';

interface Latlng {
  lat: number;
  lng: number;
}

@jsonObject
@toJson
export class City {
  @jsonMember public name?: string;
  @jsonMember public country!: string;
  @jsonMember public countryCode!: string;
  @jsonMember public latlng!: Latlng;
  @jsonMember public value!: string;
  @jsonMember public gmtOffset?: number;
  @jsonMember public timeZone?: string;
}

export const serializer = new TypedJSON(City);
