









import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { Logger } from 'aws-amplify';
const logger = new Logger('VResizable');

const ELEMENT_MASK: { [key: string]: { bit: number; cursor: string } } = {
  'resizable-r': { bit: 0x0001, cursor: 'e-resize' },
  'resizable-rb': { bit: 0x0011, cursor: 'se-resize' },
  'resizable-b': { bit: 0x0010, cursor: 's-resize' },
  'resizable-lb': { bit: 0x0110, cursor: 'sw-resize' },
  'resizable-l': { bit: 0x0100, cursor: 'w-resize' },
  'resizable-lt': { bit: 0x1100, cursor: 'nw-resize' },
  'resizable-t': { bit: 0x1000, cursor: 'n-resize' },
  'resizable-rt': { bit: 0x1001, cursor: 'ne-resize' },
};

@Component
export default class VResizable extends Vue {
  @Prop({ default: 200, type: Number }) readonly width!: number;
  @Prop({ default: 0, type: Number }) readonly minWidth!: number;
  @Prop({ default: undefined, type: Number }) readonly maxWidth!: number | undefined;

  @Prop({ default: 200, type: Number }) readonly height!: number;
  @Prop({ default: 0, type: Number }) readonly minHeight!: number;
  @Prop({ default: undefined, type: Number }) readonly maxHeight!: number | undefined;

  @Prop({ default: 0, type: Number }) readonly left!: number;
  @Prop({ default: 0, type: Number }) readonly top!: number;

  @Prop({
    default: ['r', 'rb', 'b', 'lb', 'l', 'lt', 't', 'rt'],
    type: Array,
    validator: (val: string[]): boolean =>
      ['r', 'rb', 'b', 'lb', 'l', 'lt', 't', 'rt'].filter((value) => val.indexOf(value) !== -1).length === val.length,
  })
  readonly active!: string[];

  @Prop({ default: false, type: Boolean }) readonly fitParent!: boolean;

  private w = this.width ?? 0;
  private h = this.height ?? 0;
  private minW = this.minWidth ?? 0;
  private minH = this.minHeight ?? 0;
  private maxW = this.maxWidth ?? 0;
  private maxH = this.maxHeight ?? 0;
  private l = this.left ?? 0;
  private t = this.top ?? 0;
  private mouseX = 0;
  private mouseY = 0;
  private offsetX = 0;
  private offsetY = 0;
  private parent = { width: 0, height: 0 };
  private resizeState = 0;

  get style(): unknown {
    return {
      width: typeof this.w === 'number' ? this.w + 'px' : this.w,
      height: typeof this.h === 'number' ? this.h + 'px' : this.h,
      left: typeof this.l === 'number' ? this.l + 'px' : this.l,
      top: typeof this.t === 'number' ? this.t + 'px' : this.t,
    };
  }

  @Watch('active')
  onActiveChange(): void {
    this.w = this.width;
    this.h = this.height;
    this.l = this.left;
    this.t = this.top;
  }

  @Watch('maxWidth')
  onMaxWidthChange(value: number): void {
    this.maxW = value;
  }
  @Watch('maxHeight')
  onMaxHeightChange(value: number): void {
    this.maxH = value;
  }
  @Watch('minWidth')
  onMinWidthChange(value: number): void {
    this.minW = value;
  }
  @Watch('minHeight')
  onminHeightChange(value: number): void {
    this.minH = value;
  }
  @Watch('width')
  onWidthChange(value: number): void {
    this.w = value;
  }
  @Watch('height')
  onHeightChange(value: number): void {
    this.h = value;
  }
  @Watch('left')
  onLeftChange(value: number): void {
    this.l = value;
  }
  @Watch('top')
  onTopChange(value: number): void {
    this.t = value;
  }

  mounted(): void {
    logger.info('mounted', { value: this.width });
    if (typeof this.width !== 'number') {
      this.w = this.$el.clientWidth;
    }
    if (typeof this.height !== 'number') {
      this.h = this.$el.clientHeight;
    }
    if (typeof this.left !== 'number') {
      // @ts-ignore $el no yet documented
      this.l = this.$el.offsetLeft - this.$el.parentElement.offsetLeft;
    }
    if (typeof this.top !== 'number') {
      // @ts-ignore $el no yet documented
      this.t = this.$el.offsetTop - this.$el.parentElement.offsetTop;
    }
    if (this.w < this.minW) {
      this.w = this.minW;
    }
    if (this.h < this.minH) {
      this.h = this.minH;
    }
    if (this.maxW !== undefined && this.w > this.maxW) {
      this.w = this.maxW;
    }
    if (this.maxH !== undefined && this.h > this.maxH) {
      this.h = this.maxH;
    }
    document.documentElement.addEventListener('mousemove', this.handleMove, true);
    document.documentElement.addEventListener('mousedown', this.handleDown, true);
    document.documentElement.addEventListener('mouseup', this.handleUp, true);
    // eslint-disable-next-line vue/custom-event-name-casing
    this.$emit('resize:mount', { left: this.l, top: this.t, width: this.w, height: this.h });
  }

  beforeDestroy(): void {
    document.documentElement.removeEventListener('mousemove', this.handleMove, true);
    document.documentElement.removeEventListener('mousedown', this.handleDown, true);
    document.documentElement.removeEventListener('mouseup', this.handleUp, true);
    // eslint-disable-next-line vue/custom-event-name-casing
    this.$emit('resize:destroy', { left: this.l, top: this.t, width: this.w, height: this.h });
  }

  handleMove(event: MouseEvent): void {
    if (this.resizeState !== 0) {
      let diffX = event.clientX - this.mouseX + this.offsetX;
      let diffY = event.clientY - this.mouseY + this.offsetY;
      this.offsetX = this.offsetY = 0;
      if (typeof this.w === 'number' && typeof this.l === 'number' && this.resizeState === 0x0001) {
        if (this.w + diffX < this.minW) {
          this.offsetX = diffX - (diffX = this.minW - this.w);
        } else if (
          this.maxW &&
          this.w + diffX > this.maxW &&
          (!this.fitParent || this.w + this.l < this.parent.width)
        ) {
          this.offsetX = diffX - (diffX = this.maxW - this.w);
        } else if (this.fitParent && this.l + this.w + diffX > this.parent.width) {
          this.offsetX = diffX - (diffX = this.parent.width - this.l - this.w);
        }
        this.w += diffX;
      }
      if (typeof this.h === 'number' && typeof this.t === 'number' && this.resizeState === 0x0010) {
        if (this.h + diffY < this.minH) {
          this.offsetY = diffY - (diffY = this.minH - this.h);
        } else if (
          this.maxH &&
          this.h + diffY > this.maxH &&
          (!this.fitParent || this.h + this.t < this.parent.height)
        ) {
          this.offsetY = diffY - (diffY = this.maxH - this.h);
        } else if (this.fitParent && this.t + this.h + diffY > this.parent.height) {
          this.offsetY = diffY - (diffY = this.parent.height - this.t - this.h);
        }
        this.h += diffY;
      }
      if (typeof this.w === 'number' && typeof this.l === 'number' && this.resizeState === 0x0100) {
        if (this.w - diffX < this.minW) {
          this.offsetX = diffX - (diffX = this.w - this.minW);
        } else if (this.maxW && this.w - diffX > this.maxW && this.l > 0) {
          this.offsetX = diffX - (diffX = this.w - this.maxW);
        } else if (this.fitParent && this.l + diffX < 0) {
          this.offsetX = diffX - (diffX = -this.l);
        }
        this.l += diffX;
        this.w -= diffX;
      }
      if (typeof this.h === 'number' && typeof this.t === 'number' && this.resizeState === 0x1000) {
        if (this.h - diffY < this.minH) {
          this.offsetY = diffY - (diffY = this.h - this.minH);
        } else if (this.maxH && this.h - diffY > this.maxH && this.t > 0) {
          this.offsetY = diffY - (diffY = this.h - this.maxH);
        } else if (this.fitParent && this.t + diffY < 0) {
          this.offsetY = diffY - (diffY = -this.t);
        }
        this.t += diffY;
        this.h -= diffY;
      }
      this.mouseX = event.clientX;
      this.mouseY = event.clientY;
      // eslint-disable-next-line vue/custom-event-name-casing
      this.$emit('resize:move', { left: this.l, top: this.t, width: this.w, height: this.h });
    }
  }

  handleDown(event: MouseEvent): void {
    for (const elClass in ELEMENT_MASK) {
      // @ts-ignore $el not yet documented and EventTarget too
      if (this.$el.contains(event.target) && event.target?.classList.contains(elClass)) {
        document.body.style.cursor = ELEMENT_MASK[elClass].cursor;
        if (event.preventDefault) {
          event.preventDefault();
        }
        this.offsetX = this.offsetY = 0;
        this.mouseX = event.clientX;
        this.mouseY = event.clientY;
        this.resizeState = ELEMENT_MASK[elClass].bit;
        this.parent.height = this.$el.parentElement?.clientHeight ?? 0;
        this.parent.width = this.$el.parentElement?.clientWidth ?? 0;
        // eslint-disable-next-line vue/custom-event-name-casing
        this.$emit('resize:start', { left: this.l, top: this.t, width: this.w, height: this.h });
        break;
      }
    }
  }

  handleUp(): void {
    if (this.resizeState !== 0) {
      this.resizeState = 0;
      document.body.style.cursor = '';
      // eslint-disable-next-line vue/custom-event-name-casing
      this.$emit('resize:end', { left: this.l, top: this.t, width: this.w, height: this.h });
    }
  }
}
