import 'reflect-metadata';
import { jsonObject, jsonMember, jsonArrayMember, toJson, TypedJSON } from 'typedjson';
import { Photo } from './photo';

@jsonObject
@toJson
export class PhotoAlbum implements Pick<Photo, 'id' | 'isSlowmotion' | 'key' | 'isSelected' | 'createdAt'> {
  @jsonMember id!: string;
  @jsonMember isSlowmotion!: boolean;
  @jsonMember key!: string;
  @jsonMember createdAt!: Date;
  isSelected!: boolean;

  public get smallKey(): string {
    return `${this.key}-small`;
  }

  public get thumbnailKey(): string | undefined {
    return this.isSlowmotion ? `${this.key}-thumbnail` : undefined;
  }
}

// eslint-disable-next-line max-classes-per-file
@jsonObject
@toJson
export class Album {
  @jsonMember
  public id!: string;

  @jsonMember
  public name!: string;

  @jsonMember
  public projectId!: string;

  @jsonMember
  public cameraId!: string;

  @jsonArrayMember(PhotoAlbum)
  public photos: PhotoAlbum[] = [];
}

export const serializer = new TypedJSON(Album);
