import { Component, Vue, Prop, Ref } from 'vue-property-decorator';
import { Camera } from '@/models/camera';
import { format, differenceInDays } from 'date-fns';
import { State } from 'vuex-class';
import Popper from 'popper.js';
import { Logger } from 'aws-amplify';
import { BatteryLevel } from '@/models/batterylevel';
const logger = new Logger('BatterieLevelComponent');

type Dataset = { data: number[]; smooth: boolean; fill: boolean };
type TooltipData = { index: number; data: Dataset[] };
type Labels = { xLabels: string[]; yLabels: number; yLabelsTextFormatter?: (val: string) => string };

@Component({
  components: {},
})
export default class BatterieLevelComponent extends Vue {
  @Prop({ type: Object, required: true }) public readonly camera!: Camera;
  @Prop({ type: Boolean, default: false }) public readonly noLabel!: boolean;

  @Ref() readonly tooltip!: HTMLDivElement;

  @State('locale', { namespace: 'profile' }) public locale!: string;

  public tooltipData: TooltipData | null = null;
  public popper: Popper | null = null;
  public popperIsActive = false;

  private toggleBatVide = false;
  private interval: number | null = null;
  public get batteriePicto(): string {
    const base = 'picto-erige ';
    if (this.camera.shadow === undefined) {
      return `${base}`;
    } else if (this.camera.shadow.reported.Power_supply === '1') {
      return `${base} picto-batterie-charge`;
    } else if (this.camera.shadow.reported.Battery_Status !== '0') {
      return `${base} picto-batterie-${this.camera.shadow.reported.Battery_Status}`;
    } else {
      if (this.interval) {
        clearInterval(this.interval);
      }
      this.interval = window.setInterval(() => {
        this.toggleBatVide = !this.toggleBatVide;
      }, 2000);
      return `${base} picto-batterie-0`;
    }
  }

  private _groupBy<T>(list: T[], keyGetter: (item: T) => string): Map<string, T[]> {
    const map = new Map<string, T[]>();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }

  public get dateFormat(): string {
    return this.locale === 'en' ? 'MM/dd' : 'dd/MM';
  }

  public get datas(): BatteryLevel[] {
    if (this.camera.batteryLevels.length === 0) {
      return [];
    }
    const levels = this.camera.batteryLevels;
    const lastDate = levels[levels.length - 1].date;
    const data = levels.filter((batteryLevel) => differenceInDays(lastDate, batteryLevel.date) <= 5);
    logger.info(this.dateFormat);
    const maps = this._groupBy(data, (item) => format(new Date(item.date), this.dateFormat));

    return Array.from(maps.values()).map((levels) => {
      const sum = levels.reduce((acc, i) => acc + i.level, 0);
      const avg = sum / levels.length || 0;
      return { date: levels[0].date, level: avg };
    });
  }

  public get datasets(): Dataset[] {
    if (this.datas.length > 1) {
      return [
        {
          data: this.datas.map((i) => i.level),
          smooth: true,
          fill: true,
        },
      ];
    } else {
      return [];
    }
  }

  public get labels(): Labels {
    if (this.datas.length > 1) {
      logger.info(this.dateFormat);
      return {
        xLabels: this.datas.map((i) => format(new Date(i.date), this.dateFormat)),
        yLabels: 5,
        yLabelsTextFormatter: (val: string) => val + '%',
      };
    } else {
      return {
        xLabels: ['0'],
        yLabels: 5,
      };
    }
  }

  public mounted(): void {
    logger.info('mounted', { camera: this.camera });
    this.$nextTick(() => {
      if (this.datasets) {
        this.initPopper();
      }
    });
  }

  public initPopper(): void {
    const chart = document.querySelector('.batterie-level-chart');
    if (chart !== null) {
      const ref = chart.querySelector('.active-line');
      if (ref !== null) {
        this.popper = new Popper(ref, this.tooltip, {
          placement: 'right',
          modifiers: {
            offset: { offset: '0,10' },
            preventOverflow: {
              boundariesElement: chart,
            },
          },
        });
      }
    }
  }
  public onMouseMove(params: TooltipData | null): void {
    if (this.popper !== null) {
      this.popperIsActive = !!params;
      this.popper.scheduleUpdate();
      this.tooltipData = params;
    }
  }
}
