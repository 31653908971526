import { Vue, Ref, Prop, Watch } from 'vue-property-decorator';
import Component from 'vue-class-component';
import { BModal } from 'bootstrap-vue/esm/components/modal';
import { TimelapseConfiguration } from '@/models/timelapseconfiguration';
import TimelapseFilter from '@/components/timelapsefilter/timelapsefilter.component.vue';
import TimelapseExclusion from '@/components/timelapseexclusion/timelapseexclusion.component.vue';
import TimelapseSettings from '@/components/timelapsesettings/timelapsesettings.component.vue';
import { Camera } from '@/models/camera';
import S3Image from '@/components/s3-image/S3Image.vue';
import { Scenario } from '@/models/scenario';
import { User } from '@/models/user';
import { Action } from 'vuex-class';
import {
  ScenarioConfigurationCreateRequest,
  ScenarioConfigurationUpdateRequest,
} from '@/services/projects/projects.interfaces';
import { Project } from '@/models/project';
import { Position, Crop } from '@/models/enums';
import videojs, { VideoJsPlayer } from 'video.js';
import { Storage } from 'aws-amplify';
import { State } from 'vuex-class';
import { Logger } from 'aws-amplify';
const logger = new Logger('ScenarioModal');

@Component({
  components: {
    TimelapseFilter,
    TimelapseExclusion,
    TimelapseSettings,
    S3Image,
  },
})
export default class ScenarioModal extends Vue {
  @Prop({ type: Object, required: true }) private readonly camera!: Camera;
  @Prop({ type: Object, required: true }) private readonly project!: Project;
  @Prop({ type: Boolean, default: true }) readonly withinInterval!: boolean;
  @Prop({ type: Object, default: null }) private readonly scenario!: Scenario | null;
  @Prop({ required: true }) readonly isPremium!: boolean;
  @Prop({ default: true }) readonly showLastDiv!: boolean;

  @Ref('scenario-modal') private modal!: BModal;

  @Action('loadScenario', { namespace: 'project' }) private loadScenario!: () => Promise<void>;
  @Action('createScenario', { namespace: 'project' }) private createScenario!: (
    config: ScenarioConfigurationCreateRequest
  ) => Promise<void>;
  @Action('updateScenario', { namespace: 'project' }) private updateScenario!: (
    config: ScenarioConfigurationUpdateRequest
  ) => Promise<void>;

  @State('user', { namespace: 'profile' }) private user!: User;

  private formatDateFromObject(dateObject: Date): string {
    const year = dateObject.getFullYear();
    let month = '' + (dateObject.getMonth() + 1), // Les mois sont indexés de 0
        day = '' + dateObject.getDate();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
  };

  private now = new Date();
  private working = false;
  private startDate = '';
  private endDate = this.formatDateFromObject(new Date());
  private visible = false;
  private visible1 = true;
  private periodicity = 'Mensuel';
  private datebegin = 0;
  private dateend = 0;
  public selectedRadio = 'ponctuel'; // valeur par défaut car flexRadioDefault2 est coché
  

  private defaultImageModifier = {
    iso: 0,
    brightness: 0,
    contrast: 0,
    saturation: 0,
    white: 0,
    crop: Crop.original,
    erigeWatermark: true,
    watermarkPosition: Position.bottomright,
  };

  private exposureRange = { min: 0, max: 250 };
  private gainRange = { min: 0, max: 1800 };
  private sharpRange = { min: 0, max: 60 };

  private defaultConfig: TimelapseConfiguration = {
    ...this.defaultImageModifier,
    interval: {
      begin: new Date().getTime(), // Date d'aujourd'hui
      end: new Date(new Date().setMonth(new Date().getMonth() + 3)).getTime(),
    },
    lastNDays: false,
    lastNDaysSpan: 0,
    period: {
      startHour: '00:00',
      endHour: '23:59',
    },
    exclude: [],
    days: [0, 1, 2, 3, 4, 5, 6],
    fps: 25,
    hdr: false,
    blue: [0, 6],
    gain: [0, 103],
    exposure: [0, 20],
    clearness: [7, 60],
    photoExclusion: [] as string[],
    slowmotion: true,
  };
  private config: TimelapseConfiguration = Object.assign({}, this.defaultConfig);

  private videoUrl: string | null = null;
  private player?: VideoJsPlayer;
  private options = {
    fluid: true,
    controls: true,
  };
  private scenarioName: string = this.scenario ? this.scenario.name : '';

  public mounted(): void {
    this.startDate = this.formatDateFromObject(this.camera.createdAt);
    logger.info('mounted');
    if (this.player) {
      this.player.dispose();
      this.player = undefined;
    }
  }
    
  public beforeDestroy(): void {
    if (this.player) {
      this.player.dispose();
    }
  }

  private isConverted = false;

  public show(): void {
    if (this.scenario !== null) {
      this.config = Object.assign({}, this.scenario.video.timelapseConfiguration);

      // Check if the conversion is needed
      if (!this.isConverted) {
        this.config.interval.begin = this.config.interval.begin * 1000;
        this.config.interval.end = this.config.interval.end * 1000;
        this.isConverted = true; // Mark the conversion as done
      }
    } else {
      this.config = Object.assign({}, this.defaultConfig);
      this.scenarioName = '';
      this.isConverted = false; // Reset the conversion flag if there's no scenario
    }
    this.datebegin = this.config.interval.begin;
    this.dateend = this.config.interval.end;
    this.modal.show();
  }

  public onShown(): void {
    logger.info('onShown');
    const currentScenario = this.scenario;
    if (currentScenario !== null && currentScenario.video.key !== undefined) {
      Storage.get(currentScenario.video.key).then((url) => {
        this.videoUrl = url as string;
        logger.info('', url);
        this.$nextTick(() => {
          logger.info('', this.$refs);
          const videoPlayer = this.$refs[`videoPlayer-${currentScenario.id}`] as Element | undefined;
          if (videoPlayer !== undefined) {
            this.player = videojs(videoPlayer, this.options, () => {
              logger.info('onPlayerReady', this.player);
            });
          }
        });
      });
    }
  }

  public close(): void {
    logger.info('close');
    this.working = false;
    if (this.isConverted) {
      this.config.interval.begin = this.config.interval.begin / 1000;
      this.config.interval.end = this.config.interval.end / 1000;
      this.isConverted = false; // Mark the conversion as done
    }
    this.modal.hide();
  }

  public onStartDateSelected(value: number): void {
    this.datebegin = value;
  }

  public onEndDateSelected(value: number): void {
    this.dateend = value;
  }

  public save(): void {
    this.working = true;
    const config = { ...this.config };

    // force lastNDays to false before save
    this.config.lastNDays = false;
    if (this.scenario === null) {
      // create

      // Régler les dates de début et de fin pour ce scénario
      config.interval.begin = this.datebegin;
      config.interval.end = this.dateend;
      this.createScenario({
        name: this.scenarioName,
        cameraId: this.camera.id,
        projectId: this.project.id,
        timelapseConfiguration: config,
      })
        .then(this.loadScenario)
        .then(this.close);
    } else {
      // update
      config.interval.begin = this.datebegin;
      config.interval.end = this.dateend;
      this.updateScenario({
        name: this.scenarioName,
        scenarioId: this.scenario.id,
        timelapseConfiguration: config,
      })
        .then(this.loadScenario)
        .then(this.close);
    }
  }

  public save1(): void {
    this.working = true;
    const start = new Date(this.startDate);
    const end = new Date(this.endDate);

    switch (this.periodicity) {
      case 'Mensuel':
        this.saveMonthly(start, end);
        break;
      case 'Hebdomadaire':
        this.saveWeekly(start, end);
        break;
      case 'Semestriel':
        this.saveSemester(start, end);
        break;
      case 'Trimestriel':
        this.saveQuarter(start, end);
        break;
    }

    // Fermer la modale après la création
    this.close();
  }

  public saveMonthly(start: Date, end: Date): void {
    this.working = true;
    const monthNames = [
      'Janvier',
      'Février',
      'Mars',
      'Avril',
      'Mai',
      'Juin',
      'Juillet',
      'Août',
      'Septembre',
      'Octobre',
      'Novembre',
      'Décembre',
    ];

    // Définir le jour du mois au premier du mois pour le début
    start.setDate(1);
    // Créer des scénarios pour chaque mois dans l'intervalle de dates
    const currentMonth = new Date(start);
    while (currentMonth <= end) {
      // Copier la configuration pour ce scénario
      const config = { ...this.config };

      // Régler les dates de début et de fin pour ce scénario
      config.interval.begin = currentMonth.getTime();
      console.log('datebeginmonth', config.interval.begin);
      config.interval.end = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 0).getTime();

      const monthName = monthNames[currentMonth.getMonth()];
      const scenarioName = `${currentMonth.getFullYear()}-${String(currentMonth.getMonth() + 1).padStart(
        2,
        '0'
      )}-${monthName}`;

      // Créer le scénario avec le nom généré
      this.createScenario({
        name: scenarioName,
        cameraId: this.camera.id,
        projectId: this.project.id,
        timelapseConfiguration: config,
      }).then((newScenario) => {
        this.loadScenario();

        // Émettre un événement personnalisé avec le nouveau scénario
        this.$emit('monthlyScenarioCreated', newScenario);
      });

      // Passer au mois suivant
      currentMonth.setMonth(currentMonth.getMonth() + 1);
      currentMonth.setDate(1); // Assurez-vous que le jour est réglé sur le premier du mois
    }
  }

  public saveWeekly(start: Date, end: Date): void {
    this.working = true;

    // Définir le jour de la semaine au lundi pour le début
    start.setDate(start.getDate() - start.getDay() + 1);

    // Créer des scénarios pour chaque semaine dans l'intervalle de dates
    const currentWeek = new Date(start);
    while (currentWeek <= end) {
      // Copier la configuration pour ce scénario
      const config = { ...this.config };

      // Régler les dates de début et de fin pour ce scénario
      config.interval.begin = currentWeek.getTime();
      config.interval.end = new Date(
        currentWeek.getFullYear(),
        currentWeek.getMonth(),
        currentWeek.getDate() + 6
      ).getTime();

      const scenarioName = `Semaine du ${String(currentWeek.getDate()).padStart(2, '0')}-${String(
        currentWeek.getMonth() + 1
      ).padStart(2, '0')}-${currentWeek.getFullYear()}`;

      // Créer le scénario avec le nom généré
      this.createScenario({
        name: scenarioName,
        cameraId: this.camera.id,
        projectId: this.project.id,
        timelapseConfiguration: config,
      }).then((newScenario) => {
        this.loadScenario();

        // Émettre un événement personnalisé avec le nouveau scénario
        this.$emit('weeklyScenarioCreated', newScenario);
      });

      // Passer à la semaine suivante
      currentWeek.setDate(currentWeek.getDate() + 7);
    }
  }

  public saveSemester(start: Date, end: Date): void {
    this.working = true;

    // Créer des scénarios pour chaque semestre dans l'intervalle de dates
    const currentSemester = new Date(start);
    while (currentSemester <= end) {
      // Copier la configuration pour ce scénario
      const config = { ...this.config };

      // Régler les dates de début et de fin pour ce scénario
      config.interval.begin = currentSemester.getTime();
      config.interval.end = new Date(
        currentSemester.getMonth() < 6 ? currentSemester.getFullYear() : currentSemester.getFullYear() + 1,
        currentSemester.getMonth() < 6 ? 5 : 11,
        30
      ).getTime();

      const scenarioName = `${currentSemester.getFullYear()} - ${
        currentSemester.getMonth() < 6 ? '1er' : '2ème'
      } Semestre`;

      // Créer le scénario avec le nom généré
      this.createScenario({
        name: scenarioName,
        cameraId: this.camera.id,
        projectId: this.project.id,
        timelapseConfiguration: config,
      }).then((newScenario) => {
        this.loadScenario();

        // Émettre un événement personnalisé avec le nouveau scénario
        this.$emit('semesterScenarioCreated', newScenario);
      });

      // Passer au semestre suivant
      currentSemester.setMonth(currentSemester.getMonth() < 6 ? 6 : 0);
      if (currentSemester.getMonth() === 0) {
        currentSemester.setFullYear(currentSemester.getFullYear() + 1);
      }
    }
  }

  public saveQuarter(start: Date, end: Date): void {
    this.working = true;

    // Aligner le début sur le trimestre le plus proche
    let currentQuarter: Date;
    if (start.getMonth() < 3) {
      currentQuarter = new Date(start.getFullYear(), 0, 1); // 1er janvier
    } else if (start.getMonth() < 6) {
      currentQuarter = new Date(start.getFullYear(), 3, 1); // 1er avril
    } else if (start.getMonth() < 9) {
      currentQuarter = new Date(start.getFullYear(), 6, 1); // 1er juillet
    } else {
      currentQuarter = new Date(start.getFullYear(), 9, 1); // 1er octobre
    }

    while (currentQuarter <= end) {
      // Copier la configuration pour ce scénario
      const config = { ...this.config };
      config.interval.begin = currentQuarter.getTime();

      // Régler les dates de début et de fin pour ce scénario
      let endOfMonth: number;
      switch (currentQuarter.getMonth()) {
        case 0:
          endOfMonth = 31; // Janvier a 31 jours
          break;
        case 3:
          endOfMonth = 30; // Avril a 30 jours
          break;
        case 6:
          endOfMonth = 30; // Juillet a 30 jours
          break;
        case 9:
          endOfMonth = 31; // Octobre a 31 jours
          break;
      }
      config.interval.end = new Date(currentQuarter.getFullYear(), currentQuarter.getMonth() + 3, 0).getTime(); // le 0 va donner le dernier jour du mois précédent

      let quarterName = '';
      switch (currentQuarter.getMonth()) {
        case 0:
          quarterName = '1er';
          break;
        case 3:
          quarterName = '2ème';
          break;
        case 6:
          quarterName = '3ème';
          break;
        case 9:
          quarterName = '4ème';
          break;
      }

      const scenarioName = `${currentQuarter.getFullYear()} - ${quarterName} Trimestre`;

      // Créer le scénario avec le nom généré
      this.createScenario({
        name: scenarioName,
        cameraId: this.camera.id,
        projectId: this.project.id,
        timelapseConfiguration: config,
      }).then((newScenario) => {
        this.loadScenario();

        // Émettre un événement personnalisé avec le nouveau scénario
        this.$emit('quarterScenarioCreated', newScenario);
      });

      // Passer au trimestre suivant
      currentQuarter.setMonth(currentQuarter.getMonth() + 3);
    }
  }

  @Watch('scenario')
  public onScenarioChange(val: Scenario | null): void {
    if (val === null) {
      this.scenarioName = '';
    } else {
      this.scenarioName = val.name;
    }
  } 
}
