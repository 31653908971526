import 'reflect-metadata';
import { jsonObject, jsonMember, toJson, TypedJSON } from 'typedjson';
import { TimelapseConfiguration } from './timelapseconfiguration';

@jsonObject
@toJson
export class Video {
  @jsonMember
  public projectId?: string;

  @jsonMember
  public cameraId?: string;

  @jsonMember
  public createdAt?: Date;

  @jsonMember
  public key?: string;

  @jsonMember
  public scenarioId?: string;

  @jsonMember
  public timelapseConfiguration?: TimelapseConfiguration;

  @jsonMember
  public thumbnail?: string;
}

export const serializer = new TypedJSON(Video);
