import { Component, Vue } from 'vue-property-decorator';
import { State, Action } from 'vuex-class';
import { Locale } from 'vue-i18n';

@Component
export default class LocalChanger extends Vue {
  @State('locale', { namespace: 'profile' }) private locale!: string;
  @Action('changeUserLocal', { namespace: 'profile' }) private changeUserLocal!: (locale: Locale) => Promise<void>;

  public async changeLocal(locale: string): Promise<void> {
    await this.changeUserLocal(locale);
  }
}
