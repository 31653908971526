import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Camera } from '@/models/camera';
import { Photo } from '@/models/photo';
import { State } from 'vuex-class';
import { TimelapseConfiguration } from '@/models/timelapseconfiguration';
import VueSlider from 'vue-slider-component';
import { Logger } from 'aws-amplify';
import S3Image from '../s3-image/S3Image.vue';
import PremiumOverlay from '../premiumoverlay/premiumoverlay.component.vue';
const logger = new Logger('TimelapseExclusion');

@Component({
  components: {
    VueSlider,
    PremiumOverlay,
    S3Image,
  },
})
export default class TimelapseExclusion extends Vue {
  @Prop({ required: true, type: Object }) private readonly camera!: Camera;
  @Prop({ required: true, type: Object }) private readonly value!: TimelapseConfiguration;
  @Prop() readonly imagesKey!: Photo[];
  @Prop({ required: true }) readonly isPremium!: boolean;

  @State('isLoading', { namespace: 'photo' }) readonly loadingPhoto!: boolean;
  @State('loadingTimelapse', { namespace: 'project' }) readonly loadingTimelapse!: boolean;

  private get model(): TimelapseConfiguration {
    return this.value;
  }
  private set model(val: TimelapseConfiguration) {
    const compare = JSON.stringify(val).localeCompare(JSON.stringify(this.value));
    if (compare !== 0) {
      this.$emit('input', val);
    }
  }

  private visible = true;
  private exposureRange: { min: number; max: number } = { min: 0, max: 250 };
  private gainRange: { min: number; max: number } = { min: 0, max: 1800 };
  private sharpRange: { min: number; max: number } = { min: 0, max: 60 };

  public mounted(): void {
    logger.info('mounted', { model: this.model });
    if (!this.loadingPhoto && !this.loadingTimelapse) {
      this.$nextTick(() => {
        this.getExposureRange();
        this.getGainRange();
        this.getSharpRange();
      });
    }
  }

  public created(): void {
    this.getExposureRange();
  }

  private getExposureRange(): void {
    logger.info('imagesKey', this.imagesKey);
    let min = Infinity;
    let max = -Infinity;
    this.imagesKey.forEach((image: Photo) => {
      logger.info('image', image);
      if (image.exposure !== undefined) {
        if (image.exposure < min) {
          min = image.exposure - 0.1;
        }
        if (image.exposure > max) {
          max = image.exposure + 0.1;
        }
      }
    });
    this.exposureRange = { min, max };
    if (!this.model.exposure || this.model.exposure.length < 2) {
      this.model.exposure = [min, max];
    }
    logger.info('exposureRange', this.exposureRange);
  }

  private getGainRange(): void {
    logger.info('imagesKey', this.imagesKey);
    let min = Infinity;
    let max = -Infinity;
    this.imagesKey.forEach((image: Photo) => {
      logger.info('image', image);
      if (image.gainControl !== undefined) {
        if (image.gainControl < min) {
          min = image.gainControl - 1;
        }
        if (image.gainControl > max) {
          max = image.gainControl + 1;
        }
      }
    });
    this.gainRange = { min, max };
    this.model.gain = [min, max];
    logger.info('gainRange', this.gainRange);
  }

  private getSharpRange(): void {
    logger.info('imagesKey', this.imagesKey);
    let min = Infinity;
    let max = -Infinity;
    this.imagesKey.forEach((image: Photo) => {
      logger.info('image', image);
      if (image.sharpness !== undefined) {
        if (image.sharpness < min) {
          min = image.sharpness - 0.1;
        }
        if (image.sharpness > max) {
          max = image.sharpness + 0.1;
        }
      }
    });
    this.sharpRange = { min, max };
    this.model.clearness = [min, max];
    logger.info('sharpRangemaxouille', max);
  }

  public onExposureMinSelected(value: number): void {
    this.$set(this.model.exposure, 0, value);
    this.updateSlider();
  }

  public onExposureMaxSelected(value: number): void {
    this.$set(this.model.exposure, 1, Number(value));
    this.updateSlider();
  }

  private updateSlider(): void {
    if (this.model.exposure.length === 2) {
      const exposureMin = this.exposureRange.min;
      const exposureMax = this.exposureRange.max;
      const slider = this.$refs.slider as VueSlider;
      slider.setValue([exposureMin, exposureMax]);
    }
  }

  @Watch('loadingPhoto')
  public onLoadingPhoto(val: boolean, oldVal: boolean): void {
    logger.info('loadingPhoto', { val, oldVal, loadingTimelapse: this.loadingTimelapse });
    if (oldVal && !val && !this.loadingTimelapse) {
      this.getExposureRange();
      this.getGainRange();
      this.getSharpRange();
    }
  }

  @Watch('exposureRange', { deep: true, immediate: true })
  onExposureRangeChanged(newRange: { min: number; max: number }) {
    const modelCopy = JSON.parse(JSON.stringify(this.model));
    modelCopy.exposure[0] = parseFloat(newRange.min.toFixed(1));
    modelCopy.exposure[1] = parseFloat(newRange.max.toFixed(1));
    this.model = modelCopy;
  }

  @Watch('gainRange', { deep: true, immediate: true })
  onGainRangeChanged(newRange: { min: number; max: number }) {
    const modelCopy = JSON.parse(JSON.stringify(this.model));
    modelCopy.gain[0] = parseFloat(newRange.min.toFixed(1));
    modelCopy.gain[1] = parseFloat(newRange.max.toFixed(1));
    this.model = modelCopy;
  }

  @Watch('sharpRange', { deep: true, immediate: true })
  onSharpRangeChanged(newRange: { min: number; max: number }) {
    const modelCopy = JSON.parse(JSON.stringify(this.model));
    modelCopy.clearness[0] = parseFloat(newRange.min.toFixed(1));
    modelCopy.clearness[1] = parseFloat(newRange.max.toFixed(1));
    this.model = modelCopy;
  }
}
