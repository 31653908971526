import { Vue, Ref, Prop } from 'vue-property-decorator';
import Component from 'vue-class-component';
import { BModal } from 'bootstrap-vue/esm/components/modal';
import { Camera } from '@/models/camera';
import { Project } from '@/models/project';
import { User } from '@/models/user';
import { Getter, State, Action } from 'vuex-class';
import {
  CameraTikeeCreateRequest,
  CameraTikeeUpdateRequest,
  CameraDeleteRequest,
} from '@/services/cameras/cameras.interfaces';
import VueSlider from 'vue-slider-component';
// @ts-ignore no d.ts
import Datepicker from 'vuejs-datepicker';
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue';
import BatterieLevelComponent from '@/components/batterielevel/batterielevel.component.vue';
import InputDays from '@/components/inputdays/inputdays.component.vue';
import { Validations } from 'vuelidate-property-decorators';
import { alphaNum, required, url, helpers } from 'vuelidate/lib/validators';
import { Logger } from 'aws-amplify';
import { Storage } from 'aws-amplify';
import { AxiosError } from 'axios';
const logger = new Logger('CameraModalCreate');

@Component({
  components: {
    BatterieLevelComponent,
    VueSlider,
    VueTimepicker,
    Datepicker,
    'input-days': InputDays,
  },
})
export default class CameraModalCreate extends Vue {
  public lastPhoto = '';

  @Ref('camera-modal-create') private modal!: BModal;
  @Prop({ type: Object }) private readonly camera?: Camera;

  @State('projects', { namespace: 'projects' }) private projects!: Project[];
  @State('user', { namespace: 'profile' }) private user!: User;
  @State('locale', { namespace: 'profile' }) private locale!: string;
  @State('loadingCamera', { namespace: 'camera' }) private loadingCamera!: boolean;

  @Action('loadProject', { namespace: 'projects' }) private loadProject!: () => Promise<Project[]>;
  @Action('loadCamera', { namespace: 'camera' }) private loadCamera!: () => Promise<Camera[]>;
  @Action('createCameraTikee', { namespace: 'camera' }) private createCameraTikee!: (
    cameraCreateRequest: CameraTikeeCreateRequest
  ) => Promise<Camera[]>;
  @Action('updateCameraTikee', { namespace: 'camera' }) private updateCameraService!: (
    cameraCreateRequest: CameraTikeeUpdateRequest
  ) => Promise<Camera[]>;
  @Action('deleteCamera', { namespace: 'camera' }) private deleteCameraService!: (
    cameraDeleteRequest: CameraDeleteRequest
  ) => Promise<Camera[]>;
  @Action('importAllPhotoCameraTikee', { namespace: 'camera' }) private importAllPhotoCameraTikee!: (
    cameraId: string
  ) => Promise<void>;
  @Getter('projectWithId', { namespace: 'projects' }) private projectWithId!: (id: string) => Project;

  private modalShown = false;
  private form = {
    name: '',
    nameError: null as null | boolean,
    serialNumber: '',
    projectId: null as string | null,
    apiUrl: '',
    interval: -1,
    rotate: 0,
    blur: false,
    importErrorMessage: null as null | string,
    optic: null as null | '4260x1534' | '2664x1998',
    phonenumber: '',
    live: false,
    remaining: 3,
  };

  @Validations()
  private validations = {
    form: {
      serialNumber: {
        alphaNum,
      },
      optic: {
        required,
      },
      apiUrl: {
        url,
        urlValid: (value: string) => {
          if (value === '') {
            return true;
          } else {
            return value.startsWith('https://my.tikee.io/v2/photo_sets/');
          }
        },
      },
      phonenumber: {
        required,
        phoneNumberFormat: helpers.regex('phoneNumberFormat', /^\+33\d{9}$/),
      },
    },
  };

  private intervalsOptions = [
    { text: '1 heure', value: '60' },
    { text: '2 heures', value: '120' },
    { text: '4 heures', value: '240' },
    { text: 'off', value: '-1' },
  ];

  private rotateOptions = [
    { text: '0 degré', value: '0' },
    { text: '90 degrés', value: '90' },
    { text: '180 degrés', value: '180' },
    { text: '270 degrés', value: '270' },
  ];

  private blurOptions = [
    { text: 'Oui', value: true },
    { text: 'Non', value: false },
  ];

  private liveOptions = [
    { text: 'Oui', value: true },
    { text: 'Non', value: false },
  ];

  private remainingOptions = [
    { text: '0', value: '0' },
    { text: '1', value: '1' },
    { text: '2', value: '5' },
    { text: '3', value: '3' },
    { text: '4', value: '4' },
    { text: '5', value: '5' },
  ];

  private get opticOptions() {
    return [
      { text: 'Tikee 1 Optique', value: '2664x1998' },
      { text: 'Tikee 2 Optiques', value: '4260x1534' },
      { text: 'Iris', value: '2664x1998' },
    ];
  }

  public get projectsOptions(): unknown[] {
    if (this.projects) {
      const sortedProjects = this.projects.slice().sort((a: Project, b: Project) => {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });

      return [
        { text: ' -- ', value: null },
        ...sortedProjects.map((project: Project) => {
          return {
            text: project.name,
            value: project.id,
          };
        }),
      ];
    } else {
      return [{ text: ' -- ', value: null }];
    }
  }

  public getRotation(): string {
    return this.camera && this.camera.rotate ? `rotate(${this.camera.rotate}deg)` : '';
  }

  public mounted(): void {
    if (this.camera !== undefined) {
      this.form.name = this.camera.name;
      this.form.serialNumber = this.camera.serialNumber;
      this.form.phonenumber = this.camera.phonenumber ?? '';
      this.form.projectId = this.camera.projectId ?? null;
      this.form.apiUrl = this.camera.apiUrl ?? '';
      this.form.interval = this.camera.apiFetchInterval ?? -1;
      this.form.rotate = this.camera.rotate ?? -1;
      this.form.blur = this.camera.blur ?? false;
      this.form.live = this.camera.live ?? false;
      this.form.remaining = this.camera.remaining ?? 0;
      if (this.camera.optic !== '5496x3672' && this.camera.optic !== undefined) {
        this.form.optic = this.camera.optic;
      }
      if (this.camera.lastPhoto) {
        Storage.get(this.camera.lastPhoto.smallKey).then((result) => {
          this.$nextTick(() => {
            this.lastPhoto = result as string;
          });
        });
      }
    }
  }

  public show(): void {
    this.modal.show();
  }

  public close(): void {
    this.modal.hide();
  }

  public onModalShown(): void {
    this.modalShown = true;
  }
  public onModalHidden(): void {
    this.modalShown = false;
  }

  public async importAllPhoto(): Promise<void> {
    logger.info('importAllPhoto');
    if (this.camera !== undefined) {
      this.importAllPhotoCameraTikee(this.camera.id)
        .then(() => this.close())
        .catch((error) => {
          if (error.isAxiosError) {
            const e = <AxiosError>error;
            if (e.response?.data.error.message === 'already importing') {
              this.form.importErrorMessage = e.response?.data.error.message;
              return this.loadCamera();
            } else if (e.response?.data.error.message === 'No api url.') {
              this.form.importErrorMessage = e.response?.data.error.message;
              return this.loadCamera();
            }
          }
          throw error;
        });
    }
  }

  public async createCamera(): Promise<void> {
    if (this.form.optic === null) {
      return;
    }
    logger.info('createCamera');
    const apiUrl = this.form.apiUrl.indexOf('?') !== -1 ? this.form.apiUrl.split('?')[0] : this.form.apiUrl;
    this.form.nameError = null;
    const cameraCreateRequest: CameraTikeeCreateRequest = {
      name: this.form.name,
      serialNumber: this.form.serialNumber,
      phonenumber: this.form.phonenumber,
      project: this.form.projectId ? this.projectWithId(this.form.projectId) : undefined,
      apiUrl,
      interval: this.form.interval,
      optic: this.form.optic,
      rotate: this.form.rotate ?? 0,
      blur: this.form.blur ?? false,
      live: this.form.live ?? false,
      remaining: this.form.remaining ?? 3,
    };
    this.createCameraTikee(cameraCreateRequest)
      .then(() => Promise.all([this.loadProject(), this.loadCamera()]))
      .then(() => this.close())
      .catch((error) => {
        if (error.isAxiosError) {
          const e = <AxiosError>error;
          // @ts-ignore catch is unknown
          this.form.nameError = e.response?.data.error.code === 'INVALID_NAME';
          return this.loadCamera();
        }
        throw error;
      });
  }

  public async updateCamera(): Promise<void> {
    if (
      this.form.optic === null ||
      (this.form.live && (!this.$v.form || !this.$v.form.phonenumber || this.$v.form.phonenumber.$invalid))
    ) {
      return;
    }
    logger.info('updateCamera');
    if (this.camera !== undefined) {
      const apiUrl = this.form.apiUrl.indexOf('?') !== -1 ? this.form.apiUrl.split('?')[0] : this.form.apiUrl;
      const cameraUpdateRequest: CameraTikeeUpdateRequest = {
        id: this.camera.id,
        name: this.form.name,
        serialNumber: this.form.serialNumber,
        phonenumber: this.form.phonenumber,
        project: this.form.projectId ? this.projectWithId(this.form.projectId) : undefined,
        apiUrl,
        interval: this.form.interval,
        optic: this.form.optic,
        rotate: this.form.rotate ?? 0,
        blur: this.form.blur ?? false,
        live: this.form.live ?? false,
        remaining: this.form.remaining,
      };
      return this.updateCameraService(cameraUpdateRequest)
        .then(() => Promise.all([this.loadProject(), this.loadCamera()]))
        .then(() => this.close());
    } else {
      this.close();
    }
  }

  public async deleteCamera(): Promise<void> {
    logger.info('deleteCamera');
    this.$bvModal
      .msgBoxConfirm('Please confirm that you want to delete everything.', {
        title: 'Please Confirm',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      })
      .then(async (value) => {
        logger.info('', { value });
        if (value && this.camera) {
          await this.deleteCameraService({ id: this.camera.id });
          this.close();
        }
      })
      .catch((err) => {
        logger.error({ err });
      });
  }
}
